@charset "UTF-8";
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #868e96;
  --gray-dark: #343a40;
  --primary: #4AA2EE;
  --secondary: #868e96;
  --success: #77c13a;
  --info: #17a2b8;
  --warning: #E4A93C;
  --danger: #d9534f;
  --light: #f8f9fa;
  --dark: #303840;
  --black: #272C33;
  --accent: #00BCC2;
  --accent-red: #ED0B4C;
  --accent-yellow: #E4A93C;
  --accent-dodger-blue: #5567FF;
  --accent-pickled-bluewood: #303956;
  --accent-electric-violet: #9C42FF;
  --primary-purple: #824EE1;
  --primary-red: #ED0B4C;
  --primary-yellow: #E4A93C;
  --primary-light: #99ccff;
  --primary-dodger-blue: #5567FF;
  --primary-pickled-bluewood: #303956;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1366px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(39, 44, 51, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #272C33;
  text-align: left;
  background-color: #F5F7FA;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(39, 44, 51, 0.7);
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: rgba(6, 7, 8, 0.7);
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem 1rem;
  padding-bottom: 0.75rem 1rem;
  color: rgba(39, 44, 51, 0.5);
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1rem;
  font-family: "Exo 2", "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  line-height: 1.5;
  color: #272C33;
}

h1, .h1 {
  font-size: 2.827rem;
}

h2, .h2 {
  font-size: 1.999rem;
}

h3, .h3 {
  font-size: 1.414rem;
}

h4, .h4 {
  font-size: 1.414rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.8125rem;
}

.lead {
  font-size: 0.9140625rem;
  font-weight: 400;
}

.display-1 {
  font-size: 3.998rem;
  font-weight: 700;
  line-height: 1.5;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.5;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.5;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.5;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(39, 44, 51, 0.1);
}

small,
.small {
  font-size: 0.8125rem;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.015625rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.8125rem;
  color: #868e96;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #F5F7FA;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #868e96;
}

code {
  font-size: 87.5%;
  color: #00BCC2;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 500;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -12px;
  margin-left: -12px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xxl,
.col-xxl-auto, .col-xxl-12, .col-xxl-11, .col-xxl-10, .col-xxl-9, .col-xxl-8, .col-xxl-7, .col-xxl-6, .col-xxl-5, .col-xxl-4, .col-xxl-3, .col-xxl-2, .col-xxl-1, .col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1366px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xxl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xxl-first {
    order: -1;
  }

  .order-xxl-last {
    order: 13;
  }

  .order-xxl-0 {
    order: 0;
  }

  .order-xxl-1 {
    order: 1;
  }

  .order-xxl-2 {
    order: 2;
  }

  .order-xxl-3 {
    order: 3;
  }

  .order-xxl-4 {
    order: 4;
  }

  .order-xxl-5 {
    order: 5;
  }

  .order-xxl-6 {
    order: 6;
  }

  .order-xxl-7 {
    order: 7;
  }

  .order-xxl-8 {
    order: 8;
  }

  .order-xxl-9 {
    order: 9;
  }

  .order-xxl-10 {
    order: 10;
  }

  .order-xxl-11 {
    order: 11;
  }

  .order-xxl-12 {
    order: 12;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #272C33;
}
.table th,
.table td {
  padding: 0.75rem 1rem;
  vertical-align: top;
  border-top: 1px solid #E9EDF2;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #E9EDF2;
}
.table tbody + tbody {
  border-top: 2px solid #E9EDF2;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #E9EDF2;
}
.table-bordered th,
.table-bordered td {
  border: 1px solid #E9EDF2;
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(39, 44, 51, 0.05);
}

.table-hover tbody tr:hover {
  color: #272C33;
  background-color: rgba(39, 44, 51, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cce5fa;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #a1cff6;
}

.table-hover .table-primary:hover {
  background-color: #b5d9f8;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #b5d9f8;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #dddfe2;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #c0c4c8;
}

.table-hover .table-secondary:hover {
  background-color: #cfd2d6;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #cfd2d6;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #d9eec8;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #b8df99;
}

.table-hover .table-success:hover {
  background-color: #cce8b5;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #cce8b5;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #f7e7c8;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f1d29a;
}

.table-hover .table-warning:hover {
  background-color: #f4ddb2;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f4ddb2;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f4cfce;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #eba6a3;
}

.table-hover .table-danger:hover {
  background-color: #efbbb9;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #efbbb9;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c5c7ca;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #93989c;
}

.table-hover .table-dark:hover {
  background-color: #b8babe;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b8babe;
}

.table-black,
.table-black > th,
.table-black > td {
  background-color: #c3c4c6;
}
.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #8f9195;
}

.table-hover .table-black:hover {
  background-color: #b6b7ba;
}
.table-hover .table-black:hover > td,
.table-hover .table-black:hover > th {
  background-color: #b6b7ba;
}

.table-accent,
.table-accent > th,
.table-accent > td {
  background-color: #b8ecee;
}
.table-accent th,
.table-accent td,
.table-accent thead th,
.table-accent tbody + tbody {
  border-color: #7adcdf;
}

.table-hover .table-accent:hover {
  background-color: #a3e6e9;
}
.table-hover .table-accent:hover > td,
.table-hover .table-accent:hover > th {
  background-color: #a3e6e9;
}

.table-accent-red,
.table-accent-red > th,
.table-accent-red > td {
  background-color: #fabbcd;
}
.table-accent-red th,
.table-accent-red td,
.table-accent-red thead th,
.table-accent-red tbody + tbody {
  border-color: #f680a2;
}

.table-hover .table-accent-red:hover {
  background-color: #f8a3bc;
}
.table-hover .table-accent-red:hover > td,
.table-hover .table-accent-red:hover > th {
  background-color: #f8a3bc;
}

.table-accent-yellow,
.table-accent-yellow > th,
.table-accent-yellow > td {
  background-color: #f7e7c8;
}
.table-accent-yellow th,
.table-accent-yellow td,
.table-accent-yellow thead th,
.table-accent-yellow tbody + tbody {
  border-color: #f1d29a;
}

.table-hover .table-accent-yellow:hover {
  background-color: #f4ddb2;
}
.table-hover .table-accent-yellow:hover > td,
.table-hover .table-accent-yellow:hover > th {
  background-color: #f4ddb2;
}

.table-accent-dodger-blue,
.table-accent-dodger-blue > th,
.table-accent-dodger-blue > td {
  background-color: #cfd4ff;
}
.table-accent-dodger-blue th,
.table-accent-dodger-blue td,
.table-accent-dodger-blue thead th,
.table-accent-dodger-blue tbody + tbody {
  border-color: #a7b0ff;
}

.table-hover .table-accent-dodger-blue:hover {
  background-color: #b6bdff;
}
.table-hover .table-accent-dodger-blue:hover > td,
.table-hover .table-accent-dodger-blue:hover > th {
  background-color: #b6bdff;
}

.table-accent-pickled-bluewood,
.table-accent-pickled-bluewood > th,
.table-accent-pickled-bluewood > td {
  background-color: #c5c8d0;
}
.table-accent-pickled-bluewood th,
.table-accent-pickled-bluewood td,
.table-accent-pickled-bluewood thead th,
.table-accent-pickled-bluewood tbody + tbody {
  border-color: #9398a7;
}

.table-hover .table-accent-pickled-bluewood:hover {
  background-color: #b7bbc5;
}
.table-hover .table-accent-pickled-bluewood:hover > td,
.table-hover .table-accent-pickled-bluewood:hover > th {
  background-color: #b7bbc5;
}

.table-accent-electric-violet,
.table-accent-electric-violet > th,
.table-accent-electric-violet > td {
  background-color: #e3caff;
}
.table-accent-electric-violet th,
.table-accent-electric-violet td,
.table-accent-electric-violet thead th,
.table-accent-electric-violet tbody + tbody {
  border-color: #cc9dff;
}

.table-hover .table-accent-electric-violet:hover {
  background-color: #d6b1ff;
}
.table-hover .table-accent-electric-violet:hover > td,
.table-hover .table-accent-electric-violet:hover > th {
  background-color: #d6b1ff;
}

.table-primary-purple,
.table-primary-purple > th,
.table-primary-purple > td {
  background-color: #dccdf7;
}
.table-primary-purple th,
.table-primary-purple td,
.table-primary-purple thead th,
.table-primary-purple tbody + tbody {
  border-color: #bea3ef;
}

.table-hover .table-primary-purple:hover {
  background-color: #cdb7f3;
}
.table-hover .table-primary-purple:hover > td,
.table-hover .table-primary-purple:hover > th {
  background-color: #cdb7f3;
}

.table-primary-red,
.table-primary-red > th,
.table-primary-red > td {
  background-color: #fabbcd;
}
.table-primary-red th,
.table-primary-red td,
.table-primary-red thead th,
.table-primary-red tbody + tbody {
  border-color: #f680a2;
}

.table-hover .table-primary-red:hover {
  background-color: #f8a3bc;
}
.table-hover .table-primary-red:hover > td,
.table-hover .table-primary-red:hover > th {
  background-color: #f8a3bc;
}

.table-primary-yellow,
.table-primary-yellow > th,
.table-primary-yellow > td {
  background-color: #f7e7c8;
}
.table-primary-yellow th,
.table-primary-yellow td,
.table-primary-yellow thead th,
.table-primary-yellow tbody + tbody {
  border-color: #f1d29a;
}

.table-hover .table-primary-yellow:hover {
  background-color: #f4ddb2;
}
.table-hover .table-primary-yellow:hover > td,
.table-hover .table-primary-yellow:hover > th {
  background-color: #f4ddb2;
}

.table-primary-light,
.table-primary-light > th,
.table-primary-light > td {
  background-color: #e2f1ff;
}
.table-primary-light th,
.table-primary-light td,
.table-primary-light thead th,
.table-primary-light tbody + tbody {
  border-color: #cae4ff;
}

.table-hover .table-primary-light:hover {
  background-color: #c9e5ff;
}
.table-hover .table-primary-light:hover > td,
.table-hover .table-primary-light:hover > th {
  background-color: #c9e5ff;
}

.table-primary-dodger-blue,
.table-primary-dodger-blue > th,
.table-primary-dodger-blue > td {
  background-color: #cfd4ff;
}
.table-primary-dodger-blue th,
.table-primary-dodger-blue td,
.table-primary-dodger-blue thead th,
.table-primary-dodger-blue tbody + tbody {
  border-color: #a7b0ff;
}

.table-hover .table-primary-dodger-blue:hover {
  background-color: #b6bdff;
}
.table-hover .table-primary-dodger-blue:hover > td,
.table-hover .table-primary-dodger-blue:hover > th {
  background-color: #b6bdff;
}

.table-primary-pickled-bluewood,
.table-primary-pickled-bluewood > th,
.table-primary-pickled-bluewood > td {
  background-color: #c5c8d0;
}
.table-primary-pickled-bluewood th,
.table-primary-pickled-bluewood td,
.table-primary-pickled-bluewood thead th,
.table-primary-pickled-bluewood tbody + tbody {
  border-color: #9398a7;
}

.table-hover .table-primary-pickled-bluewood:hover {
  background-color: #b7bbc5;
}
.table-hover .table-primary-pickled-bluewood:hover > td,
.table-hover .table-primary-pickled-bluewood:hover > th {
  background-color: #b7bbc5;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(39, 44, 51, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(28, 32, 37, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(28, 32, 37, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #E9EDF2;
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1365.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #272C33;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #EDF0F2;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #272C33;
}
.form-control:focus {
  color: #272C33;
  background-color: white;
  border-color: white;
  outline: 0;
  box-shadow: 0 0 0 1px #4AA2EE;
}
.form-control::placeholder {
  color: #949595;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #272C33;
  background-color: white;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.21875rem;
  line-height: 1.75;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.7109375rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  font-size: 0.8125rem;
  line-height: 1.5;
  color: #272C33;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.7109375rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.75em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.21875rem;
  line-height: 1.75;
  border-radius: 0.5rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: rgba(39, 44, 51, 0.5);
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.8125rem;
  color: #77c13a;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 1rem;
  margin-top: 0.1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: #77c13a;
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #77c13a;
  padding-right: calc(1.5em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2377c13a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.25rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #77c13a;
  box-shadow: 0 0 0 1px rgba(119, 193, 58, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 1rem);
  background-position: top calc(0.375em + 0.25rem) right calc(0.375em + 0.25rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #77c13a;
  padding-right: calc(0.75em + 2.5rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='rgba%2839, 44, 51, 0.5%29' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px !important no-repeat, white url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2377c13a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.5rem) calc(0.75em + 0.5rem) no-repeat;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #77c13a;
  box-shadow: 0 0 0 1px rgba(119, 193, 58, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #77c13a;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #77c13a;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #77c13a;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #92cf5f;
  background-color: #92cf5f;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px rgba(119, 193, 58, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #77c13a;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #77c13a;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #77c13a;
  box-shadow: 0 0 0 1px rgba(119, 193, 58, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.8125rem;
  color: #d9534f;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 1rem;
  margin-top: 0.1rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #fff;
  background-color: #d9534f;
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #d9534f;
  padding-right: calc(1.5em + 1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d9534f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d9534f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.25rem) center;
  background-size: calc(0.75em + 0.5rem) calc(0.75em + 0.5rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #d9534f;
  box-shadow: 0 0 0 1px rgba(217, 83, 79, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 1rem);
  background-position: top calc(0.375em + 0.25rem) right calc(0.375em + 0.25rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #d9534f;
  padding-right: calc(0.75em + 2.5rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='rgba%2839, 44, 51, 0.5%29' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px !important no-repeat, white url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d9534f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23d9534f' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.5rem) calc(0.75em + 0.5rem) no-repeat;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #d9534f;
  box-shadow: 0 0 0 1px rgba(217, 83, 79, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #d9534f;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d9534f;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #d9534f;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e27c79;
  background-color: #e27c79;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 1px rgba(217, 83, 79, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #d9534f;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #d9534f;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #d9534f;
  box-shadow: 0 0 0 1px rgba(217, 83, 79, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-family: "Exo 2", "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  color: #272C33;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #272C33;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 1px #4AA2EE;
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #4AA2EE;
  border-color: #4AA2EE;
}
.btn-primary:hover {
  color: #fff;
  background-color: #2790eb;
  border-color: #1b8aea;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #2790eb;
  border-color: #1b8aea;
  box-shadow: 0 0 0 1px rgba(101, 176, 241, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #4AA2EE;
  border-color: #4AA2EE;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1b8aea;
  border-color: #1584e3;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(101, 176, 241, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #727b84;
  border-color: #6c757d;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #727b84;
  border-color: #6c757d;
  box-shadow: 0 0 0 1px rgba(152, 159, 166, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #666e76;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(152, 159, 166, 0.5);
}

.btn-success {
  color: #212529;
  background-color: #77c13a;
  border-color: #77c13a;
}
.btn-success:hover {
  color: #fff;
  background-color: #65a431;
  border-color: #5f9a2e;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #65a431;
  border-color: #5f9a2e;
  box-shadow: 0 0 0 1px rgba(106, 170, 55, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #212529;
  background-color: #77c13a;
  border-color: #77c13a;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #5f9a2e;
  border-color: #59902b;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(106, 170, 55, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 1px rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.btn-warning:hover {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
  box-shadow: 0 0 0 1px rgba(199, 149, 57, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d0911d;
  border-color: #c5891b;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.btn-danger:hover {
  color: #fff;
  background-color: #d23430;
  border-color: #c9302c;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #d23430;
  border-color: #c9302c;
  box-shadow: 0 0 0 1px rgba(223, 109, 105, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #bf2e29;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(223, 109, 105, 0.5);
}

.btn-light, .navbar-light .navbar-btn {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover, .navbar-light .navbar-btn:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .navbar-light .navbar-btn:focus, .btn-light.focus, .navbar-light .focus.navbar-btn {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 1px rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .navbar-light .disabled.navbar-btn, .btn-light:disabled, .navbar-light .navbar-btn:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .navbar-light .navbar-btn:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .navbar-light .navbar-btn:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle, .navbar-light .show > .dropdown-toggle.navbar-btn {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .navbar-light .navbar-btn:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .navbar-light .navbar-btn:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus, .navbar-light .show > .dropdown-toggle.navbar-btn:focus {
  box-shadow: 0 0 0 1px rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #303840;
  border-color: #303840;
}
.btn-dark:hover {
  color: #fff;
  background-color: #20252a;
  border-color: #1a1f23;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #20252a;
  border-color: #1a1f23;
  box-shadow: 0 0 0 1px rgba(79, 86, 93, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #303840;
  border-color: #303840;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1f23;
  border-color: #15181c;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(79, 86, 93, 0.5);
}

.btn-black, .navbar-dark .navbar-btn {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}
.btn-black:hover, .navbar-dark .navbar-btn:hover {
  color: #fff;
  background-color: #16191d;
  border-color: #111316;
}
.btn-black:focus, .navbar-dark .navbar-btn:focus, .btn-black.focus, .navbar-dark .focus.navbar-btn {
  color: #fff;
  background-color: #16191d;
  border-color: #111316;
  box-shadow: 0 0 0 1px rgba(71, 76, 82, 0.5);
}
.btn-black.disabled, .navbar-dark .disabled.navbar-btn, .btn-black:disabled, .navbar-dark .navbar-btn:disabled {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}
.btn-black:not(:disabled):not(.disabled):active, .navbar-dark .navbar-btn:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active, .navbar-dark .navbar-btn:not(:disabled):not(.disabled).active, .show > .btn-black.dropdown-toggle, .navbar-dark .show > .dropdown-toggle.navbar-btn {
  color: #fff;
  background-color: #111316;
  border-color: #0b0d0f;
}
.btn-black:not(:disabled):not(.disabled):active:focus, .navbar-dark .navbar-btn:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus, .navbar-dark .navbar-btn:not(:disabled):not(.disabled).active:focus, .show > .btn-black.dropdown-toggle:focus, .navbar-dark .show > .dropdown-toggle.navbar-btn:focus {
  box-shadow: 0 0 0 1px rgba(71, 76, 82, 0.5);
}

.btn-accent {
  color: #fff;
  background-color: #00BCC2;
  border-color: #00BCC2;
}
.btn-accent:hover {
  color: #fff;
  background-color: #00979c;
  border-color: #008b8f;
}
.btn-accent:focus, .btn-accent.focus {
  color: #fff;
  background-color: #00979c;
  border-color: #008b8f;
  box-shadow: 0 0 0 1px rgba(38, 198, 203, 0.5);
}
.btn-accent.disabled, .btn-accent:disabled {
  color: #fff;
  background-color: #00BCC2;
  border-color: #00BCC2;
}
.btn-accent:not(:disabled):not(.disabled):active, .btn-accent:not(:disabled):not(.disabled).active, .show > .btn-accent.dropdown-toggle {
  color: #fff;
  background-color: #008b8f;
  border-color: #007e82;
}
.btn-accent:not(:disabled):not(.disabled):active:focus, .btn-accent:not(:disabled):not(.disabled).active:focus, .show > .btn-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(38, 198, 203, 0.5);
}

.btn-accent-red {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.btn-accent-red:hover {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
}
.btn-accent-red:focus, .btn-accent-red.focus {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
  box-shadow: 0 0 0 1px rgba(240, 48, 103, 0.5);
}
.btn-accent-red.disabled, .btn-accent-red:disabled {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.btn-accent-red:not(:disabled):not(.disabled):active, .btn-accent-red:not(:disabled):not(.disabled).active, .show > .btn-accent-red.dropdown-toggle {
  color: #fff;
  background-color: #bc093c;
  border-color: #b00838;
}
.btn-accent-red:not(:disabled):not(.disabled):active:focus, .btn-accent-red:not(:disabled):not(.disabled).active:focus, .show > .btn-accent-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(240, 48, 103, 0.5);
}

.btn-accent-yellow {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.btn-accent-yellow:hover {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
}
.btn-accent-yellow:focus, .btn-accent-yellow.focus {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
  box-shadow: 0 0 0 1px rgba(199, 149, 57, 0.5);
}
.btn-accent-yellow.disabled, .btn-accent-yellow:disabled {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.btn-accent-yellow:not(:disabled):not(.disabled):active, .btn-accent-yellow:not(:disabled):not(.disabled).active, .show > .btn-accent-yellow.dropdown-toggle {
  color: #212529;
  background-color: #d0911d;
  border-color: #c5891b;
}
.btn-accent-yellow:not(:disabled):not(.disabled):active:focus, .btn-accent-yellow:not(:disabled):not(.disabled).active:focus, .show > .btn-accent-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.btn-accent-dodger-blue {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.btn-accent-dodger-blue:hover {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
}
.btn-accent-dodger-blue:focus, .btn-accent-dodger-blue.focus {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
  box-shadow: 0 0 0 1px rgba(111, 126, 255, 0.5);
}
.btn-accent-dodger-blue.disabled, .btn-accent-dodger-blue:disabled {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.btn-accent-dodger-blue:not(:disabled):not(.disabled):active, .btn-accent-dodger-blue:not(:disabled):not(.disabled).active, .show > .btn-accent-dodger-blue.dropdown-toggle {
  color: #fff;
  background-color: #2239ff;
  border-color: #152eff;
}
.btn-accent-dodger-blue:not(:disabled):not(.disabled):active:focus, .btn-accent-dodger-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-accent-dodger-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(111, 126, 255, 0.5);
}

.btn-accent-pickled-bluewood {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.btn-accent-pickled-bluewood:hover {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
}
.btn-accent-pickled-bluewood:focus, .btn-accent-pickled-bluewood.focus {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
  box-shadow: 0 0 0 1px rgba(79, 87, 111, 0.5);
}
.btn-accent-pickled-bluewood.disabled, .btn-accent-pickled-bluewood:disabled {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.btn-accent-pickled-bluewood:not(:disabled):not(.disabled):active, .btn-accent-pickled-bluewood:not(:disabled):not(.disabled).active, .show > .btn-accent-pickled-bluewood.dropdown-toggle {
  color: #fff;
  background-color: #1e2335;
  border-color: #191e2d;
}
.btn-accent-pickled-bluewood:not(:disabled):not(.disabled):active:focus, .btn-accent-pickled-bluewood:not(:disabled):not(.disabled).active:focus, .show > .btn-accent-pickled-bluewood.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(79, 87, 111, 0.5);
}

.btn-accent-electric-violet {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}
.btn-accent-electric-violet:hover {
  color: #fff;
  background-color: #881cff;
  border-color: #810fff;
}
.btn-accent-electric-violet:focus, .btn-accent-electric-violet.focus {
  color: #fff;
  background-color: #881cff;
  border-color: #810fff;
  box-shadow: 0 0 0 1px rgba(171, 94, 255, 0.5);
}
.btn-accent-electric-violet.disabled, .btn-accent-electric-violet:disabled {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}
.btn-accent-electric-violet:not(:disabled):not(.disabled):active, .btn-accent-electric-violet:not(:disabled):not(.disabled).active, .show > .btn-accent-electric-violet.dropdown-toggle {
  color: #fff;
  background-color: #810fff;
  border-color: #7b02ff;
}
.btn-accent-electric-violet:not(:disabled):not(.disabled):active:focus, .btn-accent-electric-violet:not(:disabled):not(.disabled).active:focus, .show > .btn-accent-electric-violet.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(171, 94, 255, 0.5);
}

.btn-primary-purple {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}
.btn-primary-purple:hover {
  color: #fff;
  background-color: #6b2ddb;
  border-color: #6425d7;
}
.btn-primary-purple:focus, .btn-primary-purple.focus {
  color: #fff;
  background-color: #6b2ddb;
  border-color: #6425d7;
  box-shadow: 0 0 0 1px rgba(149, 105, 230, 0.5);
}
.btn-primary-purple.disabled, .btn-primary-purple:disabled {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}
.btn-primary-purple:not(:disabled):not(.disabled):active, .btn-primary-purple:not(:disabled):not(.disabled).active, .show > .btn-primary-purple.dropdown-toggle {
  color: #fff;
  background-color: #6425d7;
  border-color: #5f23cd;
}
.btn-primary-purple:not(:disabled):not(.disabled):active:focus, .btn-primary-purple:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(149, 105, 230, 0.5);
}

.btn-primary-red {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.btn-primary-red:hover {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
}
.btn-primary-red:focus, .btn-primary-red.focus {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
  box-shadow: 0 0 0 1px rgba(240, 48, 103, 0.5);
}
.btn-primary-red.disabled, .btn-primary-red:disabled {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.btn-primary-red:not(:disabled):not(.disabled):active, .btn-primary-red:not(:disabled):not(.disabled).active, .show > .btn-primary-red.dropdown-toggle {
  color: #fff;
  background-color: #bc093c;
  border-color: #b00838;
}
.btn-primary-red:not(:disabled):not(.disabled):active:focus, .btn-primary-red:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(240, 48, 103, 0.5);
}

.btn-primary-yellow {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.btn-primary-yellow:hover {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
}
.btn-primary-yellow:focus, .btn-primary-yellow.focus {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
  box-shadow: 0 0 0 1px rgba(199, 149, 57, 0.5);
}
.btn-primary-yellow.disabled, .btn-primary-yellow:disabled {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.btn-primary-yellow:not(:disabled):not(.disabled):active, .btn-primary-yellow:not(:disabled):not(.disabled).active, .show > .btn-primary-yellow.dropdown-toggle {
  color: #212529;
  background-color: #d0911d;
  border-color: #c5891b;
}
.btn-primary-yellow:not(:disabled):not(.disabled):active:focus, .btn-primary-yellow:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.btn-primary-light {
  color: #212529;
  background-color: #99ccff;
  border-color: #99ccff;
}
.btn-primary-light:hover {
  color: #212529;
  background-color: #73b9ff;
  border-color: #66b3ff;
}
.btn-primary-light:focus, .btn-primary-light.focus {
  color: #212529;
  background-color: #73b9ff;
  border-color: #66b3ff;
  box-shadow: 0 0 0 1px rgba(135, 179, 223, 0.5);
}
.btn-primary-light.disabled, .btn-primary-light:disabled {
  color: #212529;
  background-color: #99ccff;
  border-color: #99ccff;
}
.btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active, .show > .btn-primary-light.dropdown-toggle {
  color: #212529;
  background-color: #66b3ff;
  border-color: #59acff;
}
.btn-primary-light:not(:disabled):not(.disabled):active:focus, .btn-primary-light:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(135, 179, 223, 0.5);
}

.btn-primary-dodger-blue {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.btn-primary-dodger-blue:hover {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
}
.btn-primary-dodger-blue:focus, .btn-primary-dodger-blue.focus {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
  box-shadow: 0 0 0 1px rgba(111, 126, 255, 0.5);
}
.btn-primary-dodger-blue.disabled, .btn-primary-dodger-blue:disabled {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.btn-primary-dodger-blue:not(:disabled):not(.disabled):active, .btn-primary-dodger-blue:not(:disabled):not(.disabled).active, .show > .btn-primary-dodger-blue.dropdown-toggle {
  color: #fff;
  background-color: #2239ff;
  border-color: #152eff;
}
.btn-primary-dodger-blue:not(:disabled):not(.disabled):active:focus, .btn-primary-dodger-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-dodger-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(111, 126, 255, 0.5);
}

.btn-primary-pickled-bluewood {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.btn-primary-pickled-bluewood:hover {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
}
.btn-primary-pickled-bluewood:focus, .btn-primary-pickled-bluewood.focus {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
  box-shadow: 0 0 0 1px rgba(79, 87, 111, 0.5);
}
.btn-primary-pickled-bluewood.disabled, .btn-primary-pickled-bluewood:disabled {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.btn-primary-pickled-bluewood:not(:disabled):not(.disabled):active, .btn-primary-pickled-bluewood:not(:disabled):not(.disabled).active, .show > .btn-primary-pickled-bluewood.dropdown-toggle {
  color: #fff;
  background-color: #1e2335;
  border-color: #191e2d;
}
.btn-primary-pickled-bluewood:not(:disabled):not(.disabled):active:focus, .btn-primary-pickled-bluewood:not(:disabled):not(.disabled).active:focus, .show > .btn-primary-pickled-bluewood.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(79, 87, 111, 0.5);
}

.btn-outline-primary {
  color: #4AA2EE;
  border-color: #4AA2EE;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #4AA2EE;
  border-color: #4AA2EE;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 1px rgba(74, 162, 238, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #4AA2EE;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #4AA2EE;
  border-color: #4AA2EE;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(74, 162, 238, 0.5);
}

.btn-outline-secondary {
  color: #868e96;
  border-color: #868e96;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 1px rgba(134, 142, 150, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #868e96;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(134, 142, 150, 0.5);
}

.btn-outline-success {
  color: #77c13a;
  border-color: #77c13a;
}
.btn-outline-success:hover {
  color: #212529;
  background-color: #77c13a;
  border-color: #77c13a;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 1px rgba(119, 193, 58, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #77c13a;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #212529;
  background-color: #77c13a;
  border-color: #77c13a;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(119, 193, 58, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #E4A93C;
  border-color: #E4A93C;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #E4A93C;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.btn-outline-danger {
  color: #d9534f;
  border-color: #d9534f;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 1px rgba(217, 83, 79, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #d9534f;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(217, 83, 79, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 1px rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #303840;
  border-color: #303840;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #303840;
  border-color: #303840;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 1px rgba(48, 56, 64, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #303840;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #303840;
  border-color: #303840;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(48, 56, 64, 0.5);
}

.btn-outline-black {
  color: #272C33;
  border-color: #272C33;
}
.btn-outline-black:hover {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}
.btn-outline-black:focus, .btn-outline-black.focus {
  box-shadow: 0 0 0 1px rgba(39, 44, 51, 0.5);
}
.btn-outline-black.disabled, .btn-outline-black:disabled {
  color: #272C33;
  background-color: transparent;
}
.btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active, .show > .btn-outline-black.dropdown-toggle {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}
.btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(39, 44, 51, 0.5);
}

.btn-outline-accent {
  color: #00BCC2;
  border-color: #00BCC2;
}
.btn-outline-accent:hover {
  color: #fff;
  background-color: #00BCC2;
  border-color: #00BCC2;
}
.btn-outline-accent:focus, .btn-outline-accent.focus {
  box-shadow: 0 0 0 1px rgba(0, 188, 194, 0.5);
}
.btn-outline-accent.disabled, .btn-outline-accent:disabled {
  color: #00BCC2;
  background-color: transparent;
}
.btn-outline-accent:not(:disabled):not(.disabled):active, .btn-outline-accent:not(:disabled):not(.disabled).active, .show > .btn-outline-accent.dropdown-toggle {
  color: #fff;
  background-color: #00BCC2;
  border-color: #00BCC2;
}
.btn-outline-accent:not(:disabled):not(.disabled):active:focus, .btn-outline-accent:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(0, 188, 194, 0.5);
}

.btn-outline-accent-red {
  color: #ED0B4C;
  border-color: #ED0B4C;
}
.btn-outline-accent-red:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.btn-outline-accent-red:focus, .btn-outline-accent-red.focus {
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}
.btn-outline-accent-red.disabled, .btn-outline-accent-red:disabled {
  color: #ED0B4C;
  background-color: transparent;
}
.btn-outline-accent-red:not(:disabled):not(.disabled):active, .btn-outline-accent-red:not(:disabled):not(.disabled).active, .show > .btn-outline-accent-red.dropdown-toggle {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.btn-outline-accent-red:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-red:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-accent-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.btn-outline-accent-yellow {
  color: #E4A93C;
  border-color: #E4A93C;
}
.btn-outline-accent-yellow:hover {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.btn-outline-accent-yellow:focus, .btn-outline-accent-yellow.focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}
.btn-outline-accent-yellow.disabled, .btn-outline-accent-yellow:disabled {
  color: #E4A93C;
  background-color: transparent;
}
.btn-outline-accent-yellow:not(:disabled):not(.disabled):active, .btn-outline-accent-yellow:not(:disabled):not(.disabled).active, .show > .btn-outline-accent-yellow.dropdown-toggle {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.btn-outline-accent-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-yellow:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-accent-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.btn-outline-accent-dodger-blue {
  color: #5567FF;
  border-color: #5567FF;
}
.btn-outline-accent-dodger-blue:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.btn-outline-accent-dodger-blue:focus, .btn-outline-accent-dodger-blue.focus {
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}
.btn-outline-accent-dodger-blue.disabled, .btn-outline-accent-dodger-blue:disabled {
  color: #5567FF;
  background-color: transparent;
}
.btn-outline-accent-dodger-blue:not(:disabled):not(.disabled):active, .btn-outline-accent-dodger-blue:not(:disabled):not(.disabled).active, .show > .btn-outline-accent-dodger-blue.dropdown-toggle {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.btn-outline-accent-dodger-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-dodger-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-accent-dodger-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.btn-outline-accent-pickled-bluewood {
  color: #303956;
  border-color: #303956;
}
.btn-outline-accent-pickled-bluewood:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.btn-outline-accent-pickled-bluewood:focus, .btn-outline-accent-pickled-bluewood.focus {
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}
.btn-outline-accent-pickled-bluewood.disabled, .btn-outline-accent-pickled-bluewood:disabled {
  color: #303956;
  background-color: transparent;
}
.btn-outline-accent-pickled-bluewood:not(:disabled):not(.disabled):active, .btn-outline-accent-pickled-bluewood:not(:disabled):not(.disabled).active, .show > .btn-outline-accent-pickled-bluewood.dropdown-toggle {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.btn-outline-accent-pickled-bluewood:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-pickled-bluewood:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-accent-pickled-bluewood.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.btn-outline-accent-electric-violet {
  color: #9C42FF;
  border-color: #9C42FF;
}
.btn-outline-accent-electric-violet:hover {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}
.btn-outline-accent-electric-violet:focus, .btn-outline-accent-electric-violet.focus {
  box-shadow: 0 0 0 1px rgba(156, 66, 255, 0.5);
}
.btn-outline-accent-electric-violet.disabled, .btn-outline-accent-electric-violet:disabled {
  color: #9C42FF;
  background-color: transparent;
}
.btn-outline-accent-electric-violet:not(:disabled):not(.disabled):active, .btn-outline-accent-electric-violet:not(:disabled):not(.disabled).active, .show > .btn-outline-accent-electric-violet.dropdown-toggle {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}
.btn-outline-accent-electric-violet:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-electric-violet:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-accent-electric-violet.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(156, 66, 255, 0.5);
}

.btn-outline-primary-purple {
  color: #824EE1;
  border-color: #824EE1;
}
.btn-outline-primary-purple:hover {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}
.btn-outline-primary-purple:focus, .btn-outline-primary-purple.focus {
  box-shadow: 0 0 0 1px rgba(130, 78, 225, 0.5);
}
.btn-outline-primary-purple.disabled, .btn-outline-primary-purple:disabled {
  color: #824EE1;
  background-color: transparent;
}
.btn-outline-primary-purple:not(:disabled):not(.disabled):active, .btn-outline-primary-purple:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-purple.dropdown-toggle {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}
.btn-outline-primary-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-purple:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(130, 78, 225, 0.5);
}

.btn-outline-primary-red {
  color: #ED0B4C;
  border-color: #ED0B4C;
}
.btn-outline-primary-red:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.btn-outline-primary-red:focus, .btn-outline-primary-red.focus {
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}
.btn-outline-primary-red.disabled, .btn-outline-primary-red:disabled {
  color: #ED0B4C;
  background-color: transparent;
}
.btn-outline-primary-red:not(:disabled):not(.disabled):active, .btn-outline-primary-red:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-red.dropdown-toggle {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.btn-outline-primary-red:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-red:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.btn-outline-primary-yellow {
  color: #E4A93C;
  border-color: #E4A93C;
}
.btn-outline-primary-yellow:hover {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.btn-outline-primary-yellow:focus, .btn-outline-primary-yellow.focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}
.btn-outline-primary-yellow.disabled, .btn-outline-primary-yellow:disabled {
  color: #E4A93C;
  background-color: transparent;
}
.btn-outline-primary-yellow:not(:disabled):not(.disabled):active, .btn-outline-primary-yellow:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-yellow.dropdown-toggle {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.btn-outline-primary-yellow:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-yellow:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.btn-outline-primary-light {
  color: #99ccff;
  border-color: #99ccff;
}
.btn-outline-primary-light:hover {
  color: #212529;
  background-color: #99ccff;
  border-color: #99ccff;
}
.btn-outline-primary-light:focus, .btn-outline-primary-light.focus {
  box-shadow: 0 0 0 1px rgba(153, 204, 255, 0.5);
}
.btn-outline-primary-light.disabled, .btn-outline-primary-light:disabled {
  color: #99ccff;
  background-color: transparent;
}
.btn-outline-primary-light:not(:disabled):not(.disabled):active, .btn-outline-primary-light:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-light.dropdown-toggle {
  color: #212529;
  background-color: #99ccff;
  border-color: #99ccff;
}
.btn-outline-primary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(153, 204, 255, 0.5);
}

.btn-outline-primary-dodger-blue {
  color: #5567FF;
  border-color: #5567FF;
}
.btn-outline-primary-dodger-blue:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.btn-outline-primary-dodger-blue:focus, .btn-outline-primary-dodger-blue.focus {
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}
.btn-outline-primary-dodger-blue.disabled, .btn-outline-primary-dodger-blue:disabled {
  color: #5567FF;
  background-color: transparent;
}
.btn-outline-primary-dodger-blue:not(:disabled):not(.disabled):active, .btn-outline-primary-dodger-blue:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-dodger-blue.dropdown-toggle {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.btn-outline-primary-dodger-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-dodger-blue:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-dodger-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.btn-outline-primary-pickled-bluewood {
  color: #303956;
  border-color: #303956;
}
.btn-outline-primary-pickled-bluewood:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.btn-outline-primary-pickled-bluewood:focus, .btn-outline-primary-pickled-bluewood.focus {
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}
.btn-outline-primary-pickled-bluewood.disabled, .btn-outline-primary-pickled-bluewood:disabled {
  color: #303956;
  background-color: transparent;
}
.btn-outline-primary-pickled-bluewood:not(:disabled):not(.disabled):active, .btn-outline-primary-pickled-bluewood:not(:disabled):not(.disabled).active, .show > .btn-outline-primary-pickled-bluewood.dropdown-toggle {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.btn-outline-primary-pickled-bluewood:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-pickled-bluewood:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary-pickled-bluewood.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.btn-link {
  font-weight: 400;
  color: rgba(39, 44, 51, 0.7);
  text-decoration: none;
}
.btn-link:hover {
  color: rgba(6, 7, 8, 0.7);
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #868e96;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 2rem;
  font-size: 1.21875rem;
  line-height: 1.75;
  border-radius: 0.5rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7109375rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.5rem 0 0;
  font-size: 0.875rem;
  color: #272C33;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0.1875rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1366px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.5rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.5rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.25rem;
  clear: both;
  font-weight: 400;
  color: rgba(39, 44, 51, 0.7);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: rgba(39, 44, 51, 0.9);
  text-decoration: none;
  background-color: transparent;
}
.dropdown-item.active, .dropdown-item:active {
  color: #272C33;
  text-decoration: none;
  background-color: transparent;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: rgba(39, 44, 51, 0.3);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.25rem;
  margin-bottom: 0;
  font-size: 0.7109375rem;
  color: #868e96;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.25rem;
  color: rgba(39, 44, 51, 0.7);
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #272C33;
  text-align: center;
  white-space: nowrap;
  background-color: white;
  border: 1px solid #EDF0F2;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.75em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.21875rem;
  line-height: 1.75;
  border-radius: 0.5rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.7109375rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.21875rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.109375rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #4AA2EE;
  background-color: #4AA2EE;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #4AA2EE;
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: white;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #edf6fd;
  border-color: #edf6fd;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #868e96;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.109375rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: white;
  border: #EDF0F2 solid 1px;
}
.custom-control-label::after {
  position: absolute;
  top: 0.109375rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #4AA2EE;
  background-color: #4AA2EE;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(74, 162, 238, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(74, 162, 238, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(74, 162, 238, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.109375rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #EDF0F2;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: white;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(74, 162, 238, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1.75rem 0.5rem 0.75rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  color: #272C33;
  vertical-align: middle;
  background: white url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='rgba%2839, 44, 51, 0.5%29' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px !important no-repeat;
  border: 1px solid #EDF0F2;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #4AA2EE;
  outline: 0;
  box-shadow: 0 0 0 1px #4AA2EE;
}
.custom-select:focus::-ms-value {
  color: #272C33;
  background-color: white;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #868e96;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #272C33;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.7109375rem;
}

.custom-select-lg {
  height: calc(1.75em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.21875rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: white;
  box-shadow: 0 0 0 1px #4AA2EE;
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #272C33;
  background-color: white;
  border: 1px solid #EDF0F2;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 1rem);
  padding: 0.5rem 0.75rem;
  line-height: 1.5;
  color: #fff;
  content: "Browse";
  background-color: #868e96;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: calc(1rem + 2px);
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #F5F7FA, 0 0 0 1px #4AA2EE;
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #F5F7FA, 0 0 0 1px #4AA2EE;
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #F5F7FA, 0 0 0 1px #4AA2EE;
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #4AA2EE;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #edf6fd;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #4AA2EE;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #edf6fd;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 1px;
  margin-left: 1px;
  background-color: #4AA2EE;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #edf6fd;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #868e96;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #E9EDF2;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: transparent transparent #E9EDF2;
}
.nav-tabs .nav-link.disabled {
  color: #868e96;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #272C33;
  background-color: #F5F7FA;
  border-color: transparent transparent #F5F7FA;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #4AA2EE;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.1953125rem;
  padding-bottom: 0.1953125rem;
  margin-right: 1rem;
  font-size: 1.21875rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.21875rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1365.98px) {
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1366px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
.navbar-expand-xxl > .container-fluid,
.navbar-expand-xxl > .container-sm,
.navbar-expand-xxl > .container-md,
.navbar-expand-xxl > .container-lg,
.navbar-expand-xxl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(39, 44, 51, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(39, 44, 51, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(39, 44, 51, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(39, 44, 51, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(39, 44, 51, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(39, 44, 51, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(39, 44, 51, 0.5);
  border-color: rgba(39, 44, 51, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2839, 44, 51, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(39, 44, 51, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(39, 44, 51, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(39, 44, 51, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: #858D94;
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffff;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: #858D94;
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='%23858D94' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: #858D94;
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card, .card-nav .tab-content {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #DFE2E6;
  border-radius: 0.5rem;
}
.card > hr, .card-nav .tab-content > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group, .card-nav .tab-content > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child, .card-nav .tab-content > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.card > .list-group:last-child, .card-nav .tab-content > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.card > .card-header + .list-group, .card-nav .tab-content > .card-header + .list-group,
.card > .list-group + .card-footer,
.card-nav .tab-content > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.card-title {
  margin-bottom: 1rem;
}

.card-subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 1rem 1rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #DFE2E6;
}
.card-header:first-child {
  border-radius: 0.5rem 0.5rem 0 0;
}

.card-footer {
  padding: 1rem 1rem;
  background-color: #ffffff;
  border-top: 1px solid #DFE2E6;
}
.card-footer:last-child {
  border-radius: 0 0 0.5rem 0.5rem;
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -1rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0.5rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card-deck .card, .card-deck .card-nav .tab-content, .card-nav .card-deck .tab-content {
  margin-bottom: 12px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -12px;
    margin-left: -12px;
  }
  .card-deck .card, .card-deck .card-nav .tab-content, .card-nav .card-deck .tab-content {
    flex: 1 0 0%;
    margin-right: 12px;
    margin-bottom: 0;
    margin-left: 12px;
  }
}

.card-group > .card, .card-nav .card-group > .tab-content {
  margin-bottom: 12px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card, .card-nav .card-group > .tab-content {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card, .card-nav .card-group > .tab-content + .card, .card-nav .card-group > .card + .tab-content, .card-nav .card-group > .tab-content + .tab-content {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child), .card-nav .card-group > .tab-content:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top, .card-nav .card-group > .tab-content:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header,
.card-nav .card-group > .tab-content:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom, .card-nav .card-group > .tab-content:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer,
.card-nav .card-group > .tab-content:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child), .card-nav .card-group > .tab-content:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top, .card-nav .card-group > .tab-content:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header,
.card-nav .card-group > .tab-content:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom, .card-nav .card-group > .tab-content:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer,
.card-nav .card-group > .tab-content:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card, .card-columns .card-nav .tab-content, .card-nav .card-columns .tab-content {
  margin-bottom: 1rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 24px;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card, .card-columns .card-nav .tab-content, .card-nav .card-columns .tab-content {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card, .card-nav .accordion > .tab-content {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type), .card-nav .accordion > .tab-content:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type), .card-nav .accordion > .tab-content:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header, .card-nav .accordion > .tab-content > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  font-size: 0.8125rem;
  list-style: none;
  background-color: transparent;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0;
  color: rgba(39, 44, 51, 0.5);
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: rgba(39, 44, 51, 0.35);
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: rgba(39, 44, 51, 0.7);
  background-color: transparent;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: rgba(6, 7, 8, 0.7);
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 1px #4AA2EE;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4AA2EE;
  border-color: #4AA2EE;
}
.page-item.disabled .page-link {
  color: #cfcfcf;
  pointer-events: none;
  cursor: auto;
  background-color: transparent;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.21875rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.7109375rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #4AA2EE;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #1b8aea;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(74, 162, 238, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #868e96;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #fff;
  background-color: #6c757d;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(134, 142, 150, 0.5);
}

.badge-success {
  color: #212529;
  background-color: #77c13a;
}
a.badge-success:hover, a.badge-success:focus {
  color: #212529;
  background-color: #5f9a2e;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(119, 193, 58, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #E4A93C;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d0911d;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #d9534f;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #c9302c;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(217, 83, 79, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #303840;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #1a1f23;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(48, 56, 64, 0.5);
}

.badge-black {
  color: #fff;
  background-color: #272C33;
}
a.badge-black:hover, a.badge-black:focus {
  color: #fff;
  background-color: #111316;
}
a.badge-black:focus, a.badge-black.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(39, 44, 51, 0.5);
}

.badge-accent {
  color: #fff;
  background-color: #00BCC2;
}
a.badge-accent:hover, a.badge-accent:focus {
  color: #fff;
  background-color: #008b8f;
}
a.badge-accent:focus, a.badge-accent.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(0, 188, 194, 0.5);
}

.badge-accent-red {
  color: #fff;
  background-color: #ED0B4C;
}
a.badge-accent-red:hover, a.badge-accent-red:focus {
  color: #fff;
  background-color: #bc093c;
}
a.badge-accent-red:focus, a.badge-accent-red.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.badge-accent-yellow {
  color: #212529;
  background-color: #E4A93C;
}
a.badge-accent-yellow:hover, a.badge-accent-yellow:focus {
  color: #212529;
  background-color: #d0911d;
}
a.badge-accent-yellow:focus, a.badge-accent-yellow.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.badge-accent-dodger-blue {
  color: #fff;
  background-color: #5567FF;
}
a.badge-accent-dodger-blue:hover, a.badge-accent-dodger-blue:focus {
  color: #fff;
  background-color: #2239ff;
}
a.badge-accent-dodger-blue:focus, a.badge-accent-dodger-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.badge-accent-pickled-bluewood {
  color: #fff;
  background-color: #303956;
}
a.badge-accent-pickled-bluewood:hover, a.badge-accent-pickled-bluewood:focus {
  color: #fff;
  background-color: #1e2335;
}
a.badge-accent-pickled-bluewood:focus, a.badge-accent-pickled-bluewood.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.badge-accent-electric-violet {
  color: #fff;
  background-color: #9C42FF;
}
a.badge-accent-electric-violet:hover, a.badge-accent-electric-violet:focus {
  color: #fff;
  background-color: #810fff;
}
a.badge-accent-electric-violet:focus, a.badge-accent-electric-violet.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(156, 66, 255, 0.5);
}

.badge-primary-purple {
  color: #fff;
  background-color: #824EE1;
}
a.badge-primary-purple:hover, a.badge-primary-purple:focus {
  color: #fff;
  background-color: #6425d7;
}
a.badge-primary-purple:focus, a.badge-primary-purple.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(130, 78, 225, 0.5);
}

.badge-primary-red {
  color: #fff;
  background-color: #ED0B4C;
}
a.badge-primary-red:hover, a.badge-primary-red:focus {
  color: #fff;
  background-color: #bc093c;
}
a.badge-primary-red:focus, a.badge-primary-red.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.badge-primary-yellow {
  color: #212529;
  background-color: #E4A93C;
}
a.badge-primary-yellow:hover, a.badge-primary-yellow:focus {
  color: #212529;
  background-color: #d0911d;
}
a.badge-primary-yellow:focus, a.badge-primary-yellow.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.badge-primary-light {
  color: #212529;
  background-color: #99ccff;
}
a.badge-primary-light:hover, a.badge-primary-light:focus {
  color: #212529;
  background-color: #66b3ff;
}
a.badge-primary-light:focus, a.badge-primary-light.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(153, 204, 255, 0.5);
}

.badge-primary-dodger-blue {
  color: #fff;
  background-color: #5567FF;
}
a.badge-primary-dodger-blue:hover, a.badge-primary-dodger-blue:focus {
  color: #fff;
  background-color: #2239ff;
}
a.badge-primary-dodger-blue:focus, a.badge-primary-dodger-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.badge-primary-pickled-bluewood {
  color: #fff;
  background-color: #303956;
}
a.badge-primary-pickled-bluewood:hover, a.badge-primary-pickled-bluewood:focus {
  color: #fff;
  background-color: #1e2335;
}
a.badge-primary-pickled-bluewood:focus, a.badge-primary-pickled-bluewood.focus {
  outline: 0;
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.5rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.5rem 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 500;
}

.alert-dismissible {
  padding-right: 2.21875rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.5rem 0.5rem;
  color: inherit;
}

.alert-primary {
  color: #396994;
  background-color: #dbecfc;
  border-color: #cce5fa;
}
.alert-primary hr {
  border-top-color: #b5d9f8;
}
.alert-primary .alert-link {
  color: #2b4f6f;
}

.alert-secondary {
  color: #585f66;
  background-color: #e7e8ea;
  border-color: #dddfe2;
}
.alert-secondary hr {
  border-top-color: #cfd2d6;
}
.alert-secondary .alert-link {
  color: #40464b;
}

.alert-success {
  color: #517937;
  background-color: #e4f3d8;
  border-color: #d9eec8;
}
.alert-success hr {
  border-top-color: #cce8b5;
}
.alert-success .alert-link {
  color: #3a5627;
}

.alert-info {
  color: #1f6978;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #15464f;
}

.alert-warning {
  color: #896d38;
  background-color: #faeed8;
  border-color: #f7e7c8;
}
.alert-warning hr {
  border-top-color: #f4ddb2;
}
.alert-warning .alert-link {
  color: #655029;
}

.alert-danger {
  color: #844042;
  background-color: #f7dddc;
  border-color: #f4cfce;
}
.alert-danger hr {
  border-top-color: #efbbb9;
}
.alert-danger .alert-link {
  color: #622f31;
}

.alert-light {
  color: #94979a;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #7a7e81;
}

.alert-dark {
  color: #2c323a;
  background-color: #d6d7d9;
  border-color: #c5c7ca;
}
.alert-dark hr {
  border-top-color: #b8babe;
}
.alert-dark .alert-link {
  color: #16191d;
}

.alert-black {
  color: #272c33;
  background-color: #d4d5d6;
  border-color: #c3c4c6;
}
.alert-black hr {
  border-top-color: #b6b7ba;
}
.alert-black .alert-link {
  color: #111316;
}

.alert-accent {
  color: #13777d;
  background-color: #ccf2f3;
  border-color: #b8ecee;
}
.alert-accent hr {
  border-top-color: #a3e6e9;
}
.alert-accent .alert-link {
  color: #0c4d51;
}

.alert-accent-red {
  color: #8e1b40;
  background-color: #fbcedb;
  border-color: #fabbcd;
}
.alert-accent-red hr {
  border-top-color: #f8a3bc;
}
.alert-accent-red .alert-link {
  color: #63132d;
}

.alert-accent-yellow {
  color: #896d38;
  background-color: #faeed8;
  border-color: #f7e7c8;
}
.alert-accent-yellow hr {
  border-top-color: #f4ddb2;
}
.alert-accent-yellow .alert-link {
  color: #655029;
}

.alert-accent-dodger-blue {
  color: #3f4b9d;
  background-color: #dde1ff;
  border-color: #cfd4ff;
}
.alert-accent-dodger-blue hr {
  border-top-color: #b6bdff;
}
.alert-accent-dodger-blue .alert-link {
  color: #303a79;
}

.alert-accent-pickled-bluewood {
  color: #2c3345;
  background-color: #d6d7dd;
  border-color: #c5c8d0;
}
.alert-accent-pickled-bluewood hr {
  border-top-color: #b7bbc5;
}
.alert-accent-pickled-bluewood .alert-link {
  color: #181c26;
}

.alert-accent-electric-violet {
  color: #64379d;
  background-color: #ebd9ff;
  border-color: #e3caff;
}
.alert-accent-electric-violet hr {
  border-top-color: #d6b1ff;
}
.alert-accent-electric-violet .alert-link {
  color: #4c2a77;
}

.alert-primary-purple {
  color: #563e8d;
  background-color: #e6dcf9;
  border-color: #dccdf7;
}
.alert-primary-purple hr {
  border-top-color: #cdb7f3;
}
.alert-primary-purple .alert-link {
  color: #402e6a;
}

.alert-primary-red {
  color: #8e1b40;
  background-color: #fbcedb;
  border-color: #fabbcd;
}
.alert-primary-red hr {
  border-top-color: #f8a3bc;
}
.alert-primary-red .alert-link {
  color: #63132d;
}

.alert-primary-yellow {
  color: #896d38;
  background-color: #faeed8;
  border-color: #f7e7c8;
}
.alert-primary-yellow hr {
  border-top-color: #f4ddb2;
}
.alert-primary-yellow .alert-link {
  color: #655029;
}

.alert-primary-light {
  color: #627f9d;
  background-color: #ebf5ff;
  border-color: #e2f1ff;
}
.alert-primary-light hr {
  border-top-color: #c9e5ff;
}
.alert-primary-light .alert-link {
  color: #4e667e;
}

.alert-primary-dodger-blue {
  color: #3f4b9d;
  background-color: #dde1ff;
  border-color: #cfd4ff;
}
.alert-primary-dodger-blue hr {
  border-top-color: #b6bdff;
}
.alert-primary-dodger-blue .alert-link {
  color: #303a79;
}

.alert-primary-pickled-bluewood {
  color: #2c3345;
  background-color: #d6d7dd;
  border-color: #c5c8d0;
}
.alert-primary-pickled-bluewood hr {
  border-top-color: #b7bbc5;
}
.alert-primary-pickled-bluewood .alert-link {
  color: #181c26;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.609375rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #4AA2EE;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #272C33;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.5rem;
  background-color: #fff;
  border: 1px solid #E9EDF2;
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #868e96;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #272C33;
  background-color: transparent;
  border-color: transparent;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1366px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #396994;
  background-color: #cce5fa;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #396994;
  background-color: #b5d9f8;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #396994;
  border-color: #396994;
}

.list-group-item-secondary {
  color: #585f66;
  background-color: #dddfe2;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #585f66;
  background-color: #cfd2d6;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #585f66;
  border-color: #585f66;
}

.list-group-item-success {
  color: #517937;
  background-color: #d9eec8;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #517937;
  background-color: #cce8b5;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #517937;
  border-color: #517937;
}

.list-group-item-info {
  color: #1f6978;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #1f6978;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #1f6978;
  border-color: #1f6978;
}

.list-group-item-warning {
  color: #896d38;
  background-color: #f7e7c8;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #896d38;
  background-color: #f4ddb2;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #896d38;
  border-color: #896d38;
}

.list-group-item-danger {
  color: #844042;
  background-color: #f4cfce;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #844042;
  background-color: #efbbb9;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #844042;
  border-color: #844042;
}

.list-group-item-light {
  color: #94979a;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #94979a;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #94979a;
  border-color: #94979a;
}

.list-group-item-dark {
  color: #2c323a;
  background-color: #c5c7ca;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #2c323a;
  background-color: #b8babe;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #2c323a;
  border-color: #2c323a;
}

.list-group-item-black {
  color: #272c33;
  background-color: #c3c4c6;
}
.list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
  color: #272c33;
  background-color: #b6b7ba;
}
.list-group-item-black.list-group-item-action.active {
  color: #fff;
  background-color: #272c33;
  border-color: #272c33;
}

.list-group-item-accent {
  color: #13777d;
  background-color: #b8ecee;
}
.list-group-item-accent.list-group-item-action:hover, .list-group-item-accent.list-group-item-action:focus {
  color: #13777d;
  background-color: #a3e6e9;
}
.list-group-item-accent.list-group-item-action.active {
  color: #fff;
  background-color: #13777d;
  border-color: #13777d;
}

.list-group-item-accent-red {
  color: #8e1b40;
  background-color: #fabbcd;
}
.list-group-item-accent-red.list-group-item-action:hover, .list-group-item-accent-red.list-group-item-action:focus {
  color: #8e1b40;
  background-color: #f8a3bc;
}
.list-group-item-accent-red.list-group-item-action.active {
  color: #fff;
  background-color: #8e1b40;
  border-color: #8e1b40;
}

.list-group-item-accent-yellow {
  color: #896d38;
  background-color: #f7e7c8;
}
.list-group-item-accent-yellow.list-group-item-action:hover, .list-group-item-accent-yellow.list-group-item-action:focus {
  color: #896d38;
  background-color: #f4ddb2;
}
.list-group-item-accent-yellow.list-group-item-action.active {
  color: #fff;
  background-color: #896d38;
  border-color: #896d38;
}

.list-group-item-accent-dodger-blue {
  color: #3f4b9d;
  background-color: #cfd4ff;
}
.list-group-item-accent-dodger-blue.list-group-item-action:hover, .list-group-item-accent-dodger-blue.list-group-item-action:focus {
  color: #3f4b9d;
  background-color: #b6bdff;
}
.list-group-item-accent-dodger-blue.list-group-item-action.active {
  color: #fff;
  background-color: #3f4b9d;
  border-color: #3f4b9d;
}

.list-group-item-accent-pickled-bluewood {
  color: #2c3345;
  background-color: #c5c8d0;
}
.list-group-item-accent-pickled-bluewood.list-group-item-action:hover, .list-group-item-accent-pickled-bluewood.list-group-item-action:focus {
  color: #2c3345;
  background-color: #b7bbc5;
}
.list-group-item-accent-pickled-bluewood.list-group-item-action.active {
  color: #fff;
  background-color: #2c3345;
  border-color: #2c3345;
}

.list-group-item-accent-electric-violet {
  color: #64379d;
  background-color: #e3caff;
}
.list-group-item-accent-electric-violet.list-group-item-action:hover, .list-group-item-accent-electric-violet.list-group-item-action:focus {
  color: #64379d;
  background-color: #d6b1ff;
}
.list-group-item-accent-electric-violet.list-group-item-action.active {
  color: #fff;
  background-color: #64379d;
  border-color: #64379d;
}

.list-group-item-primary-purple {
  color: #563e8d;
  background-color: #dccdf7;
}
.list-group-item-primary-purple.list-group-item-action:hover, .list-group-item-primary-purple.list-group-item-action:focus {
  color: #563e8d;
  background-color: #cdb7f3;
}
.list-group-item-primary-purple.list-group-item-action.active {
  color: #fff;
  background-color: #563e8d;
  border-color: #563e8d;
}

.list-group-item-primary-red {
  color: #8e1b40;
  background-color: #fabbcd;
}
.list-group-item-primary-red.list-group-item-action:hover, .list-group-item-primary-red.list-group-item-action:focus {
  color: #8e1b40;
  background-color: #f8a3bc;
}
.list-group-item-primary-red.list-group-item-action.active {
  color: #fff;
  background-color: #8e1b40;
  border-color: #8e1b40;
}

.list-group-item-primary-yellow {
  color: #896d38;
  background-color: #f7e7c8;
}
.list-group-item-primary-yellow.list-group-item-action:hover, .list-group-item-primary-yellow.list-group-item-action:focus {
  color: #896d38;
  background-color: #f4ddb2;
}
.list-group-item-primary-yellow.list-group-item-action.active {
  color: #fff;
  background-color: #896d38;
  border-color: #896d38;
}

.list-group-item-primary-light {
  color: #627f9d;
  background-color: #e2f1ff;
}
.list-group-item-primary-light.list-group-item-action:hover, .list-group-item-primary-light.list-group-item-action:focus {
  color: #627f9d;
  background-color: #c9e5ff;
}
.list-group-item-primary-light.list-group-item-action.active {
  color: #fff;
  background-color: #627f9d;
  border-color: #627f9d;
}

.list-group-item-primary-dodger-blue {
  color: #3f4b9d;
  background-color: #cfd4ff;
}
.list-group-item-primary-dodger-blue.list-group-item-action:hover, .list-group-item-primary-dodger-blue.list-group-item-action:focus {
  color: #3f4b9d;
  background-color: #b6bdff;
}
.list-group-item-primary-dodger-blue.list-group-item-action.active {
  color: #fff;
  background-color: #3f4b9d;
  border-color: #3f4b9d;
}

.list-group-item-primary-pickled-bluewood {
  color: #2c3345;
  background-color: #c5c8d0;
}
.list-group-item-primary-pickled-bluewood.list-group-item-action:hover, .list-group-item-primary-pickled-bluewood.list-group-item-action:focus {
  color: #2c3345;
  background-color: #b7bbc5;
}
.list-group-item-primary-pickled-bluewood.list-group-item-action.active {
  color: #fff;
  background-color: #2c3345;
  border-color: #2c3345;
}

.close {
  float: right;
  font-size: 1.21875rem;
  font-weight: 500;
  line-height: 1;
  color: #272C33;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #272C33;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(39, 44, 51, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #868e96;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(39, 44, 51, 0.2);
  border-radius: 0.5rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: rgba(39, 44, 51, 0.8);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 1;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #E9EDF2;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #E9EDF2;
  border-bottom-right-radius: calc(0.5rem - 1px);
  border-bottom-left-radius: calc(0.5rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0.5rem;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: white;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: white;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: white;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: white;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.5rem 1rem;
  color: #272C33;
  text-align: center;
  background-color: white;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 304px;
  font-family: Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #E9EDF2;
  border-radius: 0.5rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.5rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #e9edf2;
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.5rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #e9edf2;
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #e9edf2;
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #F5F7FA;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.5rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #e9edf2;
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  color: #272C33;
  background-color: #F5F7FA;
  border-bottom: 1px solid #e4e9f2;
  border-top-left-radius: calc(0.5rem - 1px);
  border-top-right-radius: calc(0.5rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #272C33;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #4AA2EE !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1b8aea !important;
}

.bg-secondary {
  background-color: #868e96 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #77c13a !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #5f9a2e !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #E4A93C !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d0911d !important;
}

.bg-danger {
  background-color: #d9534f !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c9302c !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #303840 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1a1f23 !important;
}

.bg-black {
  background-color: #272C33 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: #111316 !important;
}

.bg-accent {
  background-color: #00BCC2 !important;
}

a.bg-accent:hover, a.bg-accent:focus,
button.bg-accent:hover,
button.bg-accent:focus {
  background-color: #008b8f !important;
}

.bg-accent-red {
  background-color: #ED0B4C !important;
}

a.bg-accent-red:hover, a.bg-accent-red:focus,
button.bg-accent-red:hover,
button.bg-accent-red:focus {
  background-color: #bc093c !important;
}

.bg-accent-yellow {
  background-color: #E4A93C !important;
}

a.bg-accent-yellow:hover, a.bg-accent-yellow:focus,
button.bg-accent-yellow:hover,
button.bg-accent-yellow:focus {
  background-color: #d0911d !important;
}

.bg-accent-dodger-blue {
  background-color: #5567FF !important;
}

a.bg-accent-dodger-blue:hover, a.bg-accent-dodger-blue:focus,
button.bg-accent-dodger-blue:hover,
button.bg-accent-dodger-blue:focus {
  background-color: #2239ff !important;
}

.bg-accent-pickled-bluewood {
  background-color: #303956 !important;
}

a.bg-accent-pickled-bluewood:hover, a.bg-accent-pickled-bluewood:focus,
button.bg-accent-pickled-bluewood:hover,
button.bg-accent-pickled-bluewood:focus {
  background-color: #1e2335 !important;
}

.bg-accent-electric-violet {
  background-color: #9C42FF !important;
}

a.bg-accent-electric-violet:hover, a.bg-accent-electric-violet:focus,
button.bg-accent-electric-violet:hover,
button.bg-accent-electric-violet:focus {
  background-color: #810fff !important;
}

.bg-primary-purple {
  background-color: #824EE1 !important;
}

a.bg-primary-purple:hover, a.bg-primary-purple:focus,
button.bg-primary-purple:hover,
button.bg-primary-purple:focus {
  background-color: #6425d7 !important;
}

.bg-primary-red {
  background-color: #ED0B4C !important;
}

a.bg-primary-red:hover, a.bg-primary-red:focus,
button.bg-primary-red:hover,
button.bg-primary-red:focus {
  background-color: #bc093c !important;
}

.bg-primary-yellow {
  background-color: #E4A93C !important;
}

a.bg-primary-yellow:hover, a.bg-primary-yellow:focus,
button.bg-primary-yellow:hover,
button.bg-primary-yellow:focus {
  background-color: #d0911d !important;
}

.bg-primary-light {
  background-color: #99ccff !important;
}

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #66b3ff !important;
}

.bg-primary-dodger-blue {
  background-color: #5567FF !important;
}

a.bg-primary-dodger-blue:hover, a.bg-primary-dodger-blue:focus,
button.bg-primary-dodger-blue:hover,
button.bg-primary-dodger-blue:focus {
  background-color: #2239ff !important;
}

.bg-primary-pickled-bluewood {
  background-color: #303956 !important;
}

a.bg-primary-pickled-bluewood:hover, a.bg-primary-pickled-bluewood:focus,
button.bg-primary-pickled-bluewood:hover,
button.bg-primary-pickled-bluewood:focus {
  background-color: #1e2335 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #E9EDF2 !important;
}

.border-top {
  border-top: 1px solid #E9EDF2 !important;
}

.border-right {
  border-right: 1px solid #E9EDF2 !important;
}

.border-bottom {
  border-bottom: 1px solid #E9EDF2 !important;
}

.border-left {
  border-left: 1px solid #E9EDF2 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #4AA2EE !important;
}

.border-secondary {
  border-color: #868e96 !important;
}

.border-success {
  border-color: #77c13a !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #E4A93C !important;
}

.border-danger {
  border-color: #d9534f !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #303840 !important;
}

.border-black {
  border-color: #272C33 !important;
}

.border-accent {
  border-color: #00BCC2 !important;
}

.border-accent-red {
  border-color: #ED0B4C !important;
}

.border-accent-yellow {
  border-color: #E4A93C !important;
}

.border-accent-dodger-blue {
  border-color: #5567FF !important;
}

.border-accent-pickled-bluewood {
  border-color: #303956 !important;
}

.border-accent-electric-violet {
  border-color: #9C42FF !important;
}

.border-primary-purple {
  border-color: #824EE1 !important;
}

.border-primary-red {
  border-color: #ED0B4C !important;
}

.border-primary-yellow {
  border-color: #E4A93C !important;
}

.border-primary-light {
  border-color: #99ccff !important;
}

.border-primary-dodger-blue {
  border-color: #5567FF !important;
}

.border-primary-pickled-bluewood {
  border-color: #303956 !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.5rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1366px) {
  .d-xxl-none {
    display: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1366px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
@media (min-width: 1366px) {
  .float-xxl-left {
    float: left !important;
  }

  .float-xxl-right {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(39, 44, 51, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(39, 44, 51, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(39, 44, 51, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-64 {
  width: 64px !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-64 {
  height: 64px !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.m-4pt {
  margin: 0.25rem !important;
}

.mt-4pt,
.my-4pt {
  margin-top: 0.25rem !important;
}

.mr-4pt,
.mx-4pt {
  margin-right: 0.25rem !important;
}

.mb-4pt,
.my-4pt {
  margin-bottom: 0.25rem !important;
}

.ml-4pt,
.mx-4pt {
  margin-left: 0.25rem !important;
}

.m-8pt {
  margin: 0.5rem !important;
}

.mt-8pt,
.my-8pt {
  margin-top: 0.5rem !important;
}

.mr-8pt,
.mx-8pt {
  margin-right: 0.5rem !important;
}

.mb-8pt,
.my-8pt {
  margin-bottom: 0.5rem !important;
}

.ml-8pt,
.mx-8pt {
  margin-left: 0.5rem !important;
}

.m-12pt {
  margin: 0.75rem !important;
}

.mt-12pt,
.my-12pt {
  margin-top: 0.75rem !important;
}

.mr-12pt,
.mx-12pt {
  margin-right: 0.75rem !important;
}

.mb-12pt,
.my-12pt {
  margin-bottom: 0.75rem !important;
}

.ml-12pt,
.mx-12pt {
  margin-left: 0.75rem !important;
}

.m-16pt {
  margin: 1rem !important;
}

.mt-16pt,
.my-16pt {
  margin-top: 1rem !important;
}

.mr-16pt,
.mx-16pt {
  margin-right: 1rem !important;
}

.mb-16pt,
.my-16pt {
  margin-bottom: 1rem !important;
}

.ml-16pt,
.mx-16pt {
  margin-left: 1rem !important;
}

.m-20pt {
  margin: 1.25rem !important;
}

.mt-20pt,
.my-20pt {
  margin-top: 1.25rem !important;
}

.mr-20pt,
.mx-20pt {
  margin-right: 1.25rem !important;
}

.mb-20pt,
.my-20pt {
  margin-bottom: 1.25rem !important;
}

.ml-20pt,
.mx-20pt {
  margin-left: 1.25rem !important;
}

.m-24pt {
  margin: 1.5rem !important;
}

.mt-24pt,
.my-24pt {
  margin-top: 1.5rem !important;
}

.mr-24pt,
.mx-24pt {
  margin-right: 1.5rem !important;
}

.mb-24pt,
.my-24pt {
  margin-bottom: 1.5rem !important;
}

.ml-24pt,
.mx-24pt {
  margin-left: 1.5rem !important;
}

.m-32pt {
  margin: 2rem !important;
}

.mt-32pt,
.my-32pt {
  margin-top: 2rem !important;
}

.mr-32pt,
.mx-32pt {
  margin-right: 2rem !important;
}

.mb-32pt,
.my-32pt {
  margin-bottom: 2rem !important;
}

.ml-32pt,
.mx-32pt {
  margin-left: 2rem !important;
}

.m-40pt {
  margin: 2.5rem !important;
}

.mt-40pt,
.my-40pt {
  margin-top: 2.5rem !important;
}

.mr-40pt,
.mx-40pt {
  margin-right: 2.5rem !important;
}

.mb-40pt,
.my-40pt {
  margin-bottom: 2.5rem !important;
}

.ml-40pt,
.mx-40pt {
  margin-left: 2.5rem !important;
}

.m-44pt {
  margin: 2.75rem !important;
}

.mt-44pt,
.my-44pt {
  margin-top: 2.75rem !important;
}

.mr-44pt,
.mx-44pt {
  margin-right: 2.75rem !important;
}

.mb-44pt,
.my-44pt {
  margin-bottom: 2.75rem !important;
}

.ml-44pt,
.mx-44pt {
  margin-left: 2.75rem !important;
}

.m-48pt {
  margin: 3rem !important;
}

.mt-48pt,
.my-48pt {
  margin-top: 3rem !important;
}

.mr-48pt,
.mx-48pt {
  margin-right: 3rem !important;
}

.mb-48pt,
.my-48pt {
  margin-bottom: 3rem !important;
}

.ml-48pt,
.mx-48pt {
  margin-left: 3rem !important;
}

.m-64pt {
  margin: 4rem !important;
}

.mt-64pt,
.my-64pt {
  margin-top: 4rem !important;
}

.mr-64pt,
.mx-64pt {
  margin-right: 4rem !important;
}

.mb-64pt,
.my-64pt {
  margin-bottom: 4rem !important;
}

.ml-64pt,
.mx-64pt {
  margin-left: 4rem !important;
}

.m-112pt {
  margin: 7rem !important;
}

.mt-112pt,
.my-112pt {
  margin-top: 7rem !important;
}

.mr-112pt,
.mx-112pt {
  margin-right: 7rem !important;
}

.mb-112pt,
.my-112pt {
  margin-bottom: 7rem !important;
}

.ml-112pt,
.mx-112pt {
  margin-left: 7rem !important;
}

.m-128pt {
  margin: 8rem !important;
}

.mt-128pt,
.my-128pt {
  margin-top: 8rem !important;
}

.mr-128pt,
.mx-128pt {
  margin-right: 8rem !important;
}

.mb-128pt,
.my-128pt {
  margin-bottom: 8rem !important;
}

.ml-128pt,
.mx-128pt {
  margin-left: 8rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.p-4pt {
  padding: 0.25rem !important;
}

.pt-4pt,
.py-4pt {
  padding-top: 0.25rem !important;
}

.pr-4pt,
.px-4pt {
  padding-right: 0.25rem !important;
}

.pb-4pt,
.py-4pt {
  padding-bottom: 0.25rem !important;
}

.pl-4pt,
.px-4pt {
  padding-left: 0.25rem !important;
}

.p-8pt {
  padding: 0.5rem !important;
}

.pt-8pt,
.py-8pt {
  padding-top: 0.5rem !important;
}

.pr-8pt,
.px-8pt {
  padding-right: 0.5rem !important;
}

.pb-8pt,
.py-8pt {
  padding-bottom: 0.5rem !important;
}

.pl-8pt,
.px-8pt {
  padding-left: 0.5rem !important;
}

.p-12pt {
  padding: 0.75rem !important;
}

.pt-12pt,
.py-12pt {
  padding-top: 0.75rem !important;
}

.pr-12pt,
.px-12pt {
  padding-right: 0.75rem !important;
}

.pb-12pt,
.py-12pt {
  padding-bottom: 0.75rem !important;
}

.pl-12pt,
.px-12pt {
  padding-left: 0.75rem !important;
}

.p-16pt {
  padding: 1rem !important;
}

.pt-16pt,
.py-16pt {
  padding-top: 1rem !important;
}

.pr-16pt,
.px-16pt {
  padding-right: 1rem !important;
}

.pb-16pt,
.py-16pt {
  padding-bottom: 1rem !important;
}

.pl-16pt,
.px-16pt {
  padding-left: 1rem !important;
}

.p-20pt {
  padding: 1.25rem !important;
}

.pt-20pt,
.py-20pt {
  padding-top: 1.25rem !important;
}

.pr-20pt,
.px-20pt {
  padding-right: 1.25rem !important;
}

.pb-20pt,
.py-20pt {
  padding-bottom: 1.25rem !important;
}

.pl-20pt,
.px-20pt {
  padding-left: 1.25rem !important;
}

.p-24pt {
  padding: 1.5rem !important;
}

.pt-24pt,
.py-24pt {
  padding-top: 1.5rem !important;
}

.pr-24pt,
.px-24pt {
  padding-right: 1.5rem !important;
}

.pb-24pt,
.py-24pt {
  padding-bottom: 1.5rem !important;
}

.pl-24pt,
.px-24pt {
  padding-left: 1.5rem !important;
}

.p-32pt {
  padding: 2rem !important;
}

.pt-32pt,
.py-32pt {
  padding-top: 2rem !important;
}

.pr-32pt,
.px-32pt {
  padding-right: 2rem !important;
}

.pb-32pt,
.py-32pt {
  padding-bottom: 2rem !important;
}

.pl-32pt,
.px-32pt {
  padding-left: 2rem !important;
}

.p-40pt {
  padding: 2.5rem !important;
}

.pt-40pt,
.py-40pt {
  padding-top: 2.5rem !important;
}

.pr-40pt,
.px-40pt {
  padding-right: 2.5rem !important;
}

.pb-40pt,
.py-40pt {
  padding-bottom: 2.5rem !important;
}

.pl-40pt,
.px-40pt {
  padding-left: 2.5rem !important;
}

.p-44pt {
  padding: 2.75rem !important;
}

.pt-44pt,
.py-44pt {
  padding-top: 2.75rem !important;
}

.pr-44pt,
.px-44pt {
  padding-right: 2.75rem !important;
}

.pb-44pt,
.py-44pt {
  padding-bottom: 2.75rem !important;
}

.pl-44pt,
.px-44pt {
  padding-left: 2.75rem !important;
}

.p-48pt {
  padding: 3rem !important;
}

.pt-48pt,
.py-48pt {
  padding-top: 3rem !important;
}

.pr-48pt,
.px-48pt {
  padding-right: 3rem !important;
}

.pb-48pt,
.py-48pt {
  padding-bottom: 3rem !important;
}

.pl-48pt,
.px-48pt {
  padding-left: 3rem !important;
}

.p-64pt {
  padding: 4rem !important;
}

.pt-64pt,
.py-64pt {
  padding-top: 4rem !important;
}

.pr-64pt,
.px-64pt {
  padding-right: 4rem !important;
}

.pb-64pt,
.py-64pt {
  padding-bottom: 4rem !important;
}

.pl-64pt,
.px-64pt {
  padding-left: 4rem !important;
}

.p-112pt {
  padding: 7rem !important;
}

.pt-112pt,
.py-112pt {
  padding-top: 7rem !important;
}

.pr-112pt,
.px-112pt {
  padding-right: 7rem !important;
}

.pb-112pt,
.py-112pt {
  padding-bottom: 7rem !important;
}

.pl-112pt,
.px-112pt {
  padding-left: 7rem !important;
}

.p-128pt {
  padding: 8rem !important;
}

.pt-128pt,
.py-128pt {
  padding-top: 8rem !important;
}

.pr-128pt,
.px-128pt {
  padding-right: 8rem !important;
}

.pb-128pt,
.py-128pt {
  padding-bottom: 8rem !important;
}

.pl-128pt,
.px-128pt {
  padding-left: 8rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-n4pt {
  margin: -0.25rem !important;
}

.mt-n4pt,
.my-n4pt {
  margin-top: -0.25rem !important;
}

.mr-n4pt,
.mx-n4pt {
  margin-right: -0.25rem !important;
}

.mb-n4pt,
.my-n4pt {
  margin-bottom: -0.25rem !important;
}

.ml-n4pt,
.mx-n4pt {
  margin-left: -0.25rem !important;
}

.m-n8pt {
  margin: -0.5rem !important;
}

.mt-n8pt,
.my-n8pt {
  margin-top: -0.5rem !important;
}

.mr-n8pt,
.mx-n8pt {
  margin-right: -0.5rem !important;
}

.mb-n8pt,
.my-n8pt {
  margin-bottom: -0.5rem !important;
}

.ml-n8pt,
.mx-n8pt {
  margin-left: -0.5rem !important;
}

.m-n12pt {
  margin: -0.75rem !important;
}

.mt-n12pt,
.my-n12pt {
  margin-top: -0.75rem !important;
}

.mr-n12pt,
.mx-n12pt {
  margin-right: -0.75rem !important;
}

.mb-n12pt,
.my-n12pt {
  margin-bottom: -0.75rem !important;
}

.ml-n12pt,
.mx-n12pt {
  margin-left: -0.75rem !important;
}

.m-n16pt {
  margin: -1rem !important;
}

.mt-n16pt,
.my-n16pt {
  margin-top: -1rem !important;
}

.mr-n16pt,
.mx-n16pt {
  margin-right: -1rem !important;
}

.mb-n16pt,
.my-n16pt {
  margin-bottom: -1rem !important;
}

.ml-n16pt,
.mx-n16pt {
  margin-left: -1rem !important;
}

.m-n20pt {
  margin: -1.25rem !important;
}

.mt-n20pt,
.my-n20pt {
  margin-top: -1.25rem !important;
}

.mr-n20pt,
.mx-n20pt {
  margin-right: -1.25rem !important;
}

.mb-n20pt,
.my-n20pt {
  margin-bottom: -1.25rem !important;
}

.ml-n20pt,
.mx-n20pt {
  margin-left: -1.25rem !important;
}

.m-n24pt {
  margin: -1.5rem !important;
}

.mt-n24pt,
.my-n24pt {
  margin-top: -1.5rem !important;
}

.mr-n24pt,
.mx-n24pt {
  margin-right: -1.5rem !important;
}

.mb-n24pt,
.my-n24pt {
  margin-bottom: -1.5rem !important;
}

.ml-n24pt,
.mx-n24pt {
  margin-left: -1.5rem !important;
}

.m-n32pt {
  margin: -2rem !important;
}

.mt-n32pt,
.my-n32pt {
  margin-top: -2rem !important;
}

.mr-n32pt,
.mx-n32pt {
  margin-right: -2rem !important;
}

.mb-n32pt,
.my-n32pt {
  margin-bottom: -2rem !important;
}

.ml-n32pt,
.mx-n32pt {
  margin-left: -2rem !important;
}

.m-n40pt {
  margin: -2.5rem !important;
}

.mt-n40pt,
.my-n40pt {
  margin-top: -2.5rem !important;
}

.mr-n40pt,
.mx-n40pt {
  margin-right: -2.5rem !important;
}

.mb-n40pt,
.my-n40pt {
  margin-bottom: -2.5rem !important;
}

.ml-n40pt,
.mx-n40pt {
  margin-left: -2.5rem !important;
}

.m-n44pt {
  margin: -2.75rem !important;
}

.mt-n44pt,
.my-n44pt {
  margin-top: -2.75rem !important;
}

.mr-n44pt,
.mx-n44pt {
  margin-right: -2.75rem !important;
}

.mb-n44pt,
.my-n44pt {
  margin-bottom: -2.75rem !important;
}

.ml-n44pt,
.mx-n44pt {
  margin-left: -2.75rem !important;
}

.m-n48pt {
  margin: -3rem !important;
}

.mt-n48pt,
.my-n48pt {
  margin-top: -3rem !important;
}

.mr-n48pt,
.mx-n48pt {
  margin-right: -3rem !important;
}

.mb-n48pt,
.my-n48pt {
  margin-bottom: -3rem !important;
}

.ml-n48pt,
.mx-n48pt {
  margin-left: -3rem !important;
}

.m-n64pt {
  margin: -4rem !important;
}

.mt-n64pt,
.my-n64pt {
  margin-top: -4rem !important;
}

.mr-n64pt,
.mx-n64pt {
  margin-right: -4rem !important;
}

.mb-n64pt,
.my-n64pt {
  margin-bottom: -4rem !important;
}

.ml-n64pt,
.mx-n64pt {
  margin-left: -4rem !important;
}

.m-n112pt {
  margin: -7rem !important;
}

.mt-n112pt,
.my-n112pt {
  margin-top: -7rem !important;
}

.mr-n112pt,
.mx-n112pt {
  margin-right: -7rem !important;
}

.mb-n112pt,
.my-n112pt {
  margin-bottom: -7rem !important;
}

.ml-n112pt,
.mx-n112pt {
  margin-left: -7rem !important;
}

.m-n128pt {
  margin: -8rem !important;
}

.mt-n128pt,
.my-n128pt {
  margin-top: -8rem !important;
}

.mr-n128pt,
.mx-n128pt {
  margin-right: -8rem !important;
}

.mb-n128pt,
.my-n128pt {
  margin-bottom: -8rem !important;
}

.ml-n128pt,
.mx-n128pt {
  margin-left: -8rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .m-sm-4pt {
    margin: 0.25rem !important;
  }

  .mt-sm-4pt,
.my-sm-4pt {
    margin-top: 0.25rem !important;
  }

  .mr-sm-4pt,
.mx-sm-4pt {
    margin-right: 0.25rem !important;
  }

  .mb-sm-4pt,
.my-sm-4pt {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-4pt,
.mx-sm-4pt {
    margin-left: 0.25rem !important;
  }

  .m-sm-8pt {
    margin: 0.5rem !important;
  }

  .mt-sm-8pt,
.my-sm-8pt {
    margin-top: 0.5rem !important;
  }

  .mr-sm-8pt,
.mx-sm-8pt {
    margin-right: 0.5rem !important;
  }

  .mb-sm-8pt,
.my-sm-8pt {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-8pt,
.mx-sm-8pt {
    margin-left: 0.5rem !important;
  }

  .m-sm-12pt {
    margin: 0.75rem !important;
  }

  .mt-sm-12pt,
.my-sm-12pt {
    margin-top: 0.75rem !important;
  }

  .mr-sm-12pt,
.mx-sm-12pt {
    margin-right: 0.75rem !important;
  }

  .mb-sm-12pt,
.my-sm-12pt {
    margin-bottom: 0.75rem !important;
  }

  .ml-sm-12pt,
.mx-sm-12pt {
    margin-left: 0.75rem !important;
  }

  .m-sm-16pt {
    margin: 1rem !important;
  }

  .mt-sm-16pt,
.my-sm-16pt {
    margin-top: 1rem !important;
  }

  .mr-sm-16pt,
.mx-sm-16pt {
    margin-right: 1rem !important;
  }

  .mb-sm-16pt,
.my-sm-16pt {
    margin-bottom: 1rem !important;
  }

  .ml-sm-16pt,
.mx-sm-16pt {
    margin-left: 1rem !important;
  }

  .m-sm-20pt {
    margin: 1.25rem !important;
  }

  .mt-sm-20pt,
.my-sm-20pt {
    margin-top: 1.25rem !important;
  }

  .mr-sm-20pt,
.mx-sm-20pt {
    margin-right: 1.25rem !important;
  }

  .mb-sm-20pt,
.my-sm-20pt {
    margin-bottom: 1.25rem !important;
  }

  .ml-sm-20pt,
.mx-sm-20pt {
    margin-left: 1.25rem !important;
  }

  .m-sm-24pt {
    margin: 1.5rem !important;
  }

  .mt-sm-24pt,
.my-sm-24pt {
    margin-top: 1.5rem !important;
  }

  .mr-sm-24pt,
.mx-sm-24pt {
    margin-right: 1.5rem !important;
  }

  .mb-sm-24pt,
.my-sm-24pt {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-24pt,
.mx-sm-24pt {
    margin-left: 1.5rem !important;
  }

  .m-sm-32pt {
    margin: 2rem !important;
  }

  .mt-sm-32pt,
.my-sm-32pt {
    margin-top: 2rem !important;
  }

  .mr-sm-32pt,
.mx-sm-32pt {
    margin-right: 2rem !important;
  }

  .mb-sm-32pt,
.my-sm-32pt {
    margin-bottom: 2rem !important;
  }

  .ml-sm-32pt,
.mx-sm-32pt {
    margin-left: 2rem !important;
  }

  .m-sm-40pt {
    margin: 2.5rem !important;
  }

  .mt-sm-40pt,
.my-sm-40pt {
    margin-top: 2.5rem !important;
  }

  .mr-sm-40pt,
.mx-sm-40pt {
    margin-right: 2.5rem !important;
  }

  .mb-sm-40pt,
.my-sm-40pt {
    margin-bottom: 2.5rem !important;
  }

  .ml-sm-40pt,
.mx-sm-40pt {
    margin-left: 2.5rem !important;
  }

  .m-sm-44pt {
    margin: 2.75rem !important;
  }

  .mt-sm-44pt,
.my-sm-44pt {
    margin-top: 2.75rem !important;
  }

  .mr-sm-44pt,
.mx-sm-44pt {
    margin-right: 2.75rem !important;
  }

  .mb-sm-44pt,
.my-sm-44pt {
    margin-bottom: 2.75rem !important;
  }

  .ml-sm-44pt,
.mx-sm-44pt {
    margin-left: 2.75rem !important;
  }

  .m-sm-48pt {
    margin: 3rem !important;
  }

  .mt-sm-48pt,
.my-sm-48pt {
    margin-top: 3rem !important;
  }

  .mr-sm-48pt,
.mx-sm-48pt {
    margin-right: 3rem !important;
  }

  .mb-sm-48pt,
.my-sm-48pt {
    margin-bottom: 3rem !important;
  }

  .ml-sm-48pt,
.mx-sm-48pt {
    margin-left: 3rem !important;
  }

  .m-sm-64pt {
    margin: 4rem !important;
  }

  .mt-sm-64pt,
.my-sm-64pt {
    margin-top: 4rem !important;
  }

  .mr-sm-64pt,
.mx-sm-64pt {
    margin-right: 4rem !important;
  }

  .mb-sm-64pt,
.my-sm-64pt {
    margin-bottom: 4rem !important;
  }

  .ml-sm-64pt,
.mx-sm-64pt {
    margin-left: 4rem !important;
  }

  .m-sm-112pt {
    margin: 7rem !important;
  }

  .mt-sm-112pt,
.my-sm-112pt {
    margin-top: 7rem !important;
  }

  .mr-sm-112pt,
.mx-sm-112pt {
    margin-right: 7rem !important;
  }

  .mb-sm-112pt,
.my-sm-112pt {
    margin-bottom: 7rem !important;
  }

  .ml-sm-112pt,
.mx-sm-112pt {
    margin-left: 7rem !important;
  }

  .m-sm-128pt {
    margin: 8rem !important;
  }

  .mt-sm-128pt,
.my-sm-128pt {
    margin-top: 8rem !important;
  }

  .mr-sm-128pt,
.mx-sm-128pt {
    margin-right: 8rem !important;
  }

  .mb-sm-128pt,
.my-sm-128pt {
    margin-bottom: 8rem !important;
  }

  .ml-sm-128pt,
.mx-sm-128pt {
    margin-left: 8rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .p-sm-4pt {
    padding: 0.25rem !important;
  }

  .pt-sm-4pt,
.py-sm-4pt {
    padding-top: 0.25rem !important;
  }

  .pr-sm-4pt,
.px-sm-4pt {
    padding-right: 0.25rem !important;
  }

  .pb-sm-4pt,
.py-sm-4pt {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-4pt,
.px-sm-4pt {
    padding-left: 0.25rem !important;
  }

  .p-sm-8pt {
    padding: 0.5rem !important;
  }

  .pt-sm-8pt,
.py-sm-8pt {
    padding-top: 0.5rem !important;
  }

  .pr-sm-8pt,
.px-sm-8pt {
    padding-right: 0.5rem !important;
  }

  .pb-sm-8pt,
.py-sm-8pt {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-8pt,
.px-sm-8pt {
    padding-left: 0.5rem !important;
  }

  .p-sm-12pt {
    padding: 0.75rem !important;
  }

  .pt-sm-12pt,
.py-sm-12pt {
    padding-top: 0.75rem !important;
  }

  .pr-sm-12pt,
.px-sm-12pt {
    padding-right: 0.75rem !important;
  }

  .pb-sm-12pt,
.py-sm-12pt {
    padding-bottom: 0.75rem !important;
  }

  .pl-sm-12pt,
.px-sm-12pt {
    padding-left: 0.75rem !important;
  }

  .p-sm-16pt {
    padding: 1rem !important;
  }

  .pt-sm-16pt,
.py-sm-16pt {
    padding-top: 1rem !important;
  }

  .pr-sm-16pt,
.px-sm-16pt {
    padding-right: 1rem !important;
  }

  .pb-sm-16pt,
.py-sm-16pt {
    padding-bottom: 1rem !important;
  }

  .pl-sm-16pt,
.px-sm-16pt {
    padding-left: 1rem !important;
  }

  .p-sm-20pt {
    padding: 1.25rem !important;
  }

  .pt-sm-20pt,
.py-sm-20pt {
    padding-top: 1.25rem !important;
  }

  .pr-sm-20pt,
.px-sm-20pt {
    padding-right: 1.25rem !important;
  }

  .pb-sm-20pt,
.py-sm-20pt {
    padding-bottom: 1.25rem !important;
  }

  .pl-sm-20pt,
.px-sm-20pt {
    padding-left: 1.25rem !important;
  }

  .p-sm-24pt {
    padding: 1.5rem !important;
  }

  .pt-sm-24pt,
.py-sm-24pt {
    padding-top: 1.5rem !important;
  }

  .pr-sm-24pt,
.px-sm-24pt {
    padding-right: 1.5rem !important;
  }

  .pb-sm-24pt,
.py-sm-24pt {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-24pt,
.px-sm-24pt {
    padding-left: 1.5rem !important;
  }

  .p-sm-32pt {
    padding: 2rem !important;
  }

  .pt-sm-32pt,
.py-sm-32pt {
    padding-top: 2rem !important;
  }

  .pr-sm-32pt,
.px-sm-32pt {
    padding-right: 2rem !important;
  }

  .pb-sm-32pt,
.py-sm-32pt {
    padding-bottom: 2rem !important;
  }

  .pl-sm-32pt,
.px-sm-32pt {
    padding-left: 2rem !important;
  }

  .p-sm-40pt {
    padding: 2.5rem !important;
  }

  .pt-sm-40pt,
.py-sm-40pt {
    padding-top: 2.5rem !important;
  }

  .pr-sm-40pt,
.px-sm-40pt {
    padding-right: 2.5rem !important;
  }

  .pb-sm-40pt,
.py-sm-40pt {
    padding-bottom: 2.5rem !important;
  }

  .pl-sm-40pt,
.px-sm-40pt {
    padding-left: 2.5rem !important;
  }

  .p-sm-44pt {
    padding: 2.75rem !important;
  }

  .pt-sm-44pt,
.py-sm-44pt {
    padding-top: 2.75rem !important;
  }

  .pr-sm-44pt,
.px-sm-44pt {
    padding-right: 2.75rem !important;
  }

  .pb-sm-44pt,
.py-sm-44pt {
    padding-bottom: 2.75rem !important;
  }

  .pl-sm-44pt,
.px-sm-44pt {
    padding-left: 2.75rem !important;
  }

  .p-sm-48pt {
    padding: 3rem !important;
  }

  .pt-sm-48pt,
.py-sm-48pt {
    padding-top: 3rem !important;
  }

  .pr-sm-48pt,
.px-sm-48pt {
    padding-right: 3rem !important;
  }

  .pb-sm-48pt,
.py-sm-48pt {
    padding-bottom: 3rem !important;
  }

  .pl-sm-48pt,
.px-sm-48pt {
    padding-left: 3rem !important;
  }

  .p-sm-64pt {
    padding: 4rem !important;
  }

  .pt-sm-64pt,
.py-sm-64pt {
    padding-top: 4rem !important;
  }

  .pr-sm-64pt,
.px-sm-64pt {
    padding-right: 4rem !important;
  }

  .pb-sm-64pt,
.py-sm-64pt {
    padding-bottom: 4rem !important;
  }

  .pl-sm-64pt,
.px-sm-64pt {
    padding-left: 4rem !important;
  }

  .p-sm-112pt {
    padding: 7rem !important;
  }

  .pt-sm-112pt,
.py-sm-112pt {
    padding-top: 7rem !important;
  }

  .pr-sm-112pt,
.px-sm-112pt {
    padding-right: 7rem !important;
  }

  .pb-sm-112pt,
.py-sm-112pt {
    padding-bottom: 7rem !important;
  }

  .pl-sm-112pt,
.px-sm-112pt {
    padding-left: 7rem !important;
  }

  .p-sm-128pt {
    padding: 8rem !important;
  }

  .pt-sm-128pt,
.py-sm-128pt {
    padding-top: 8rem !important;
  }

  .pr-sm-128pt,
.px-sm-128pt {
    padding-right: 8rem !important;
  }

  .pb-sm-128pt,
.py-sm-128pt {
    padding-bottom: 8rem !important;
  }

  .pl-sm-128pt,
.px-sm-128pt {
    padding-left: 8rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-n4pt {
    margin: -0.25rem !important;
  }

  .mt-sm-n4pt,
.my-sm-n4pt {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n4pt,
.mx-sm-n4pt {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n4pt,
.my-sm-n4pt {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n4pt,
.mx-sm-n4pt {
    margin-left: -0.25rem !important;
  }

  .m-sm-n8pt {
    margin: -0.5rem !important;
  }

  .mt-sm-n8pt,
.my-sm-n8pt {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n8pt,
.mx-sm-n8pt {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n8pt,
.my-sm-n8pt {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n8pt,
.mx-sm-n8pt {
    margin-left: -0.5rem !important;
  }

  .m-sm-n12pt {
    margin: -0.75rem !important;
  }

  .mt-sm-n12pt,
.my-sm-n12pt {
    margin-top: -0.75rem !important;
  }

  .mr-sm-n12pt,
.mx-sm-n12pt {
    margin-right: -0.75rem !important;
  }

  .mb-sm-n12pt,
.my-sm-n12pt {
    margin-bottom: -0.75rem !important;
  }

  .ml-sm-n12pt,
.mx-sm-n12pt {
    margin-left: -0.75rem !important;
  }

  .m-sm-n16pt {
    margin: -1rem !important;
  }

  .mt-sm-n16pt,
.my-sm-n16pt {
    margin-top: -1rem !important;
  }

  .mr-sm-n16pt,
.mx-sm-n16pt {
    margin-right: -1rem !important;
  }

  .mb-sm-n16pt,
.my-sm-n16pt {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n16pt,
.mx-sm-n16pt {
    margin-left: -1rem !important;
  }

  .m-sm-n20pt {
    margin: -1.25rem !important;
  }

  .mt-sm-n20pt,
.my-sm-n20pt {
    margin-top: -1.25rem !important;
  }

  .mr-sm-n20pt,
.mx-sm-n20pt {
    margin-right: -1.25rem !important;
  }

  .mb-sm-n20pt,
.my-sm-n20pt {
    margin-bottom: -1.25rem !important;
  }

  .ml-sm-n20pt,
.mx-sm-n20pt {
    margin-left: -1.25rem !important;
  }

  .m-sm-n24pt {
    margin: -1.5rem !important;
  }

  .mt-sm-n24pt,
.my-sm-n24pt {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n24pt,
.mx-sm-n24pt {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n24pt,
.my-sm-n24pt {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n24pt,
.mx-sm-n24pt {
    margin-left: -1.5rem !important;
  }

  .m-sm-n32pt {
    margin: -2rem !important;
  }

  .mt-sm-n32pt,
.my-sm-n32pt {
    margin-top: -2rem !important;
  }

  .mr-sm-n32pt,
.mx-sm-n32pt {
    margin-right: -2rem !important;
  }

  .mb-sm-n32pt,
.my-sm-n32pt {
    margin-bottom: -2rem !important;
  }

  .ml-sm-n32pt,
.mx-sm-n32pt {
    margin-left: -2rem !important;
  }

  .m-sm-n40pt {
    margin: -2.5rem !important;
  }

  .mt-sm-n40pt,
.my-sm-n40pt {
    margin-top: -2.5rem !important;
  }

  .mr-sm-n40pt,
.mx-sm-n40pt {
    margin-right: -2.5rem !important;
  }

  .mb-sm-n40pt,
.my-sm-n40pt {
    margin-bottom: -2.5rem !important;
  }

  .ml-sm-n40pt,
.mx-sm-n40pt {
    margin-left: -2.5rem !important;
  }

  .m-sm-n44pt {
    margin: -2.75rem !important;
  }

  .mt-sm-n44pt,
.my-sm-n44pt {
    margin-top: -2.75rem !important;
  }

  .mr-sm-n44pt,
.mx-sm-n44pt {
    margin-right: -2.75rem !important;
  }

  .mb-sm-n44pt,
.my-sm-n44pt {
    margin-bottom: -2.75rem !important;
  }

  .ml-sm-n44pt,
.mx-sm-n44pt {
    margin-left: -2.75rem !important;
  }

  .m-sm-n48pt {
    margin: -3rem !important;
  }

  .mt-sm-n48pt,
.my-sm-n48pt {
    margin-top: -3rem !important;
  }

  .mr-sm-n48pt,
.mx-sm-n48pt {
    margin-right: -3rem !important;
  }

  .mb-sm-n48pt,
.my-sm-n48pt {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n48pt,
.mx-sm-n48pt {
    margin-left: -3rem !important;
  }

  .m-sm-n64pt {
    margin: -4rem !important;
  }

  .mt-sm-n64pt,
.my-sm-n64pt {
    margin-top: -4rem !important;
  }

  .mr-sm-n64pt,
.mx-sm-n64pt {
    margin-right: -4rem !important;
  }

  .mb-sm-n64pt,
.my-sm-n64pt {
    margin-bottom: -4rem !important;
  }

  .ml-sm-n64pt,
.mx-sm-n64pt {
    margin-left: -4rem !important;
  }

  .m-sm-n112pt {
    margin: -7rem !important;
  }

  .mt-sm-n112pt,
.my-sm-n112pt {
    margin-top: -7rem !important;
  }

  .mr-sm-n112pt,
.mx-sm-n112pt {
    margin-right: -7rem !important;
  }

  .mb-sm-n112pt,
.my-sm-n112pt {
    margin-bottom: -7rem !important;
  }

  .ml-sm-n112pt,
.mx-sm-n112pt {
    margin-left: -7rem !important;
  }

  .m-sm-n128pt {
    margin: -8rem !important;
  }

  .mt-sm-n128pt,
.my-sm-n128pt {
    margin-top: -8rem !important;
  }

  .mr-sm-n128pt,
.mx-sm-n128pt {
    margin-right: -8rem !important;
  }

  .mb-sm-n128pt,
.my-sm-n128pt {
    margin-bottom: -8rem !important;
  }

  .ml-sm-n128pt,
.mx-sm-n128pt {
    margin-left: -8rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .m-md-4pt {
    margin: 0.25rem !important;
  }

  .mt-md-4pt,
.my-md-4pt {
    margin-top: 0.25rem !important;
  }

  .mr-md-4pt,
.mx-md-4pt {
    margin-right: 0.25rem !important;
  }

  .mb-md-4pt,
.my-md-4pt {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-4pt,
.mx-md-4pt {
    margin-left: 0.25rem !important;
  }

  .m-md-8pt {
    margin: 0.5rem !important;
  }

  .mt-md-8pt,
.my-md-8pt {
    margin-top: 0.5rem !important;
  }

  .mr-md-8pt,
.mx-md-8pt {
    margin-right: 0.5rem !important;
  }

  .mb-md-8pt,
.my-md-8pt {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-8pt,
.mx-md-8pt {
    margin-left: 0.5rem !important;
  }

  .m-md-12pt {
    margin: 0.75rem !important;
  }

  .mt-md-12pt,
.my-md-12pt {
    margin-top: 0.75rem !important;
  }

  .mr-md-12pt,
.mx-md-12pt {
    margin-right: 0.75rem !important;
  }

  .mb-md-12pt,
.my-md-12pt {
    margin-bottom: 0.75rem !important;
  }

  .ml-md-12pt,
.mx-md-12pt {
    margin-left: 0.75rem !important;
  }

  .m-md-16pt {
    margin: 1rem !important;
  }

  .mt-md-16pt,
.my-md-16pt {
    margin-top: 1rem !important;
  }

  .mr-md-16pt,
.mx-md-16pt {
    margin-right: 1rem !important;
  }

  .mb-md-16pt,
.my-md-16pt {
    margin-bottom: 1rem !important;
  }

  .ml-md-16pt,
.mx-md-16pt {
    margin-left: 1rem !important;
  }

  .m-md-20pt {
    margin: 1.25rem !important;
  }

  .mt-md-20pt,
.my-md-20pt {
    margin-top: 1.25rem !important;
  }

  .mr-md-20pt,
.mx-md-20pt {
    margin-right: 1.25rem !important;
  }

  .mb-md-20pt,
.my-md-20pt {
    margin-bottom: 1.25rem !important;
  }

  .ml-md-20pt,
.mx-md-20pt {
    margin-left: 1.25rem !important;
  }

  .m-md-24pt {
    margin: 1.5rem !important;
  }

  .mt-md-24pt,
.my-md-24pt {
    margin-top: 1.5rem !important;
  }

  .mr-md-24pt,
.mx-md-24pt {
    margin-right: 1.5rem !important;
  }

  .mb-md-24pt,
.my-md-24pt {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-24pt,
.mx-md-24pt {
    margin-left: 1.5rem !important;
  }

  .m-md-32pt {
    margin: 2rem !important;
  }

  .mt-md-32pt,
.my-md-32pt {
    margin-top: 2rem !important;
  }

  .mr-md-32pt,
.mx-md-32pt {
    margin-right: 2rem !important;
  }

  .mb-md-32pt,
.my-md-32pt {
    margin-bottom: 2rem !important;
  }

  .ml-md-32pt,
.mx-md-32pt {
    margin-left: 2rem !important;
  }

  .m-md-40pt {
    margin: 2.5rem !important;
  }

  .mt-md-40pt,
.my-md-40pt {
    margin-top: 2.5rem !important;
  }

  .mr-md-40pt,
.mx-md-40pt {
    margin-right: 2.5rem !important;
  }

  .mb-md-40pt,
.my-md-40pt {
    margin-bottom: 2.5rem !important;
  }

  .ml-md-40pt,
.mx-md-40pt {
    margin-left: 2.5rem !important;
  }

  .m-md-44pt {
    margin: 2.75rem !important;
  }

  .mt-md-44pt,
.my-md-44pt {
    margin-top: 2.75rem !important;
  }

  .mr-md-44pt,
.mx-md-44pt {
    margin-right: 2.75rem !important;
  }

  .mb-md-44pt,
.my-md-44pt {
    margin-bottom: 2.75rem !important;
  }

  .ml-md-44pt,
.mx-md-44pt {
    margin-left: 2.75rem !important;
  }

  .m-md-48pt {
    margin: 3rem !important;
  }

  .mt-md-48pt,
.my-md-48pt {
    margin-top: 3rem !important;
  }

  .mr-md-48pt,
.mx-md-48pt {
    margin-right: 3rem !important;
  }

  .mb-md-48pt,
.my-md-48pt {
    margin-bottom: 3rem !important;
  }

  .ml-md-48pt,
.mx-md-48pt {
    margin-left: 3rem !important;
  }

  .m-md-64pt {
    margin: 4rem !important;
  }

  .mt-md-64pt,
.my-md-64pt {
    margin-top: 4rem !important;
  }

  .mr-md-64pt,
.mx-md-64pt {
    margin-right: 4rem !important;
  }

  .mb-md-64pt,
.my-md-64pt {
    margin-bottom: 4rem !important;
  }

  .ml-md-64pt,
.mx-md-64pt {
    margin-left: 4rem !important;
  }

  .m-md-112pt {
    margin: 7rem !important;
  }

  .mt-md-112pt,
.my-md-112pt {
    margin-top: 7rem !important;
  }

  .mr-md-112pt,
.mx-md-112pt {
    margin-right: 7rem !important;
  }

  .mb-md-112pt,
.my-md-112pt {
    margin-bottom: 7rem !important;
  }

  .ml-md-112pt,
.mx-md-112pt {
    margin-left: 7rem !important;
  }

  .m-md-128pt {
    margin: 8rem !important;
  }

  .mt-md-128pt,
.my-md-128pt {
    margin-top: 8rem !important;
  }

  .mr-md-128pt,
.mx-md-128pt {
    margin-right: 8rem !important;
  }

  .mb-md-128pt,
.my-md-128pt {
    margin-bottom: 8rem !important;
  }

  .ml-md-128pt,
.mx-md-128pt {
    margin-left: 8rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .p-md-4pt {
    padding: 0.25rem !important;
  }

  .pt-md-4pt,
.py-md-4pt {
    padding-top: 0.25rem !important;
  }

  .pr-md-4pt,
.px-md-4pt {
    padding-right: 0.25rem !important;
  }

  .pb-md-4pt,
.py-md-4pt {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-4pt,
.px-md-4pt {
    padding-left: 0.25rem !important;
  }

  .p-md-8pt {
    padding: 0.5rem !important;
  }

  .pt-md-8pt,
.py-md-8pt {
    padding-top: 0.5rem !important;
  }

  .pr-md-8pt,
.px-md-8pt {
    padding-right: 0.5rem !important;
  }

  .pb-md-8pt,
.py-md-8pt {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-8pt,
.px-md-8pt {
    padding-left: 0.5rem !important;
  }

  .p-md-12pt {
    padding: 0.75rem !important;
  }

  .pt-md-12pt,
.py-md-12pt {
    padding-top: 0.75rem !important;
  }

  .pr-md-12pt,
.px-md-12pt {
    padding-right: 0.75rem !important;
  }

  .pb-md-12pt,
.py-md-12pt {
    padding-bottom: 0.75rem !important;
  }

  .pl-md-12pt,
.px-md-12pt {
    padding-left: 0.75rem !important;
  }

  .p-md-16pt {
    padding: 1rem !important;
  }

  .pt-md-16pt,
.py-md-16pt {
    padding-top: 1rem !important;
  }

  .pr-md-16pt,
.px-md-16pt {
    padding-right: 1rem !important;
  }

  .pb-md-16pt,
.py-md-16pt {
    padding-bottom: 1rem !important;
  }

  .pl-md-16pt,
.px-md-16pt {
    padding-left: 1rem !important;
  }

  .p-md-20pt {
    padding: 1.25rem !important;
  }

  .pt-md-20pt,
.py-md-20pt {
    padding-top: 1.25rem !important;
  }

  .pr-md-20pt,
.px-md-20pt {
    padding-right: 1.25rem !important;
  }

  .pb-md-20pt,
.py-md-20pt {
    padding-bottom: 1.25rem !important;
  }

  .pl-md-20pt,
.px-md-20pt {
    padding-left: 1.25rem !important;
  }

  .p-md-24pt {
    padding: 1.5rem !important;
  }

  .pt-md-24pt,
.py-md-24pt {
    padding-top: 1.5rem !important;
  }

  .pr-md-24pt,
.px-md-24pt {
    padding-right: 1.5rem !important;
  }

  .pb-md-24pt,
.py-md-24pt {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-24pt,
.px-md-24pt {
    padding-left: 1.5rem !important;
  }

  .p-md-32pt {
    padding: 2rem !important;
  }

  .pt-md-32pt,
.py-md-32pt {
    padding-top: 2rem !important;
  }

  .pr-md-32pt,
.px-md-32pt {
    padding-right: 2rem !important;
  }

  .pb-md-32pt,
.py-md-32pt {
    padding-bottom: 2rem !important;
  }

  .pl-md-32pt,
.px-md-32pt {
    padding-left: 2rem !important;
  }

  .p-md-40pt {
    padding: 2.5rem !important;
  }

  .pt-md-40pt,
.py-md-40pt {
    padding-top: 2.5rem !important;
  }

  .pr-md-40pt,
.px-md-40pt {
    padding-right: 2.5rem !important;
  }

  .pb-md-40pt,
.py-md-40pt {
    padding-bottom: 2.5rem !important;
  }

  .pl-md-40pt,
.px-md-40pt {
    padding-left: 2.5rem !important;
  }

  .p-md-44pt {
    padding: 2.75rem !important;
  }

  .pt-md-44pt,
.py-md-44pt {
    padding-top: 2.75rem !important;
  }

  .pr-md-44pt,
.px-md-44pt {
    padding-right: 2.75rem !important;
  }

  .pb-md-44pt,
.py-md-44pt {
    padding-bottom: 2.75rem !important;
  }

  .pl-md-44pt,
.px-md-44pt {
    padding-left: 2.75rem !important;
  }

  .p-md-48pt {
    padding: 3rem !important;
  }

  .pt-md-48pt,
.py-md-48pt {
    padding-top: 3rem !important;
  }

  .pr-md-48pt,
.px-md-48pt {
    padding-right: 3rem !important;
  }

  .pb-md-48pt,
.py-md-48pt {
    padding-bottom: 3rem !important;
  }

  .pl-md-48pt,
.px-md-48pt {
    padding-left: 3rem !important;
  }

  .p-md-64pt {
    padding: 4rem !important;
  }

  .pt-md-64pt,
.py-md-64pt {
    padding-top: 4rem !important;
  }

  .pr-md-64pt,
.px-md-64pt {
    padding-right: 4rem !important;
  }

  .pb-md-64pt,
.py-md-64pt {
    padding-bottom: 4rem !important;
  }

  .pl-md-64pt,
.px-md-64pt {
    padding-left: 4rem !important;
  }

  .p-md-112pt {
    padding: 7rem !important;
  }

  .pt-md-112pt,
.py-md-112pt {
    padding-top: 7rem !important;
  }

  .pr-md-112pt,
.px-md-112pt {
    padding-right: 7rem !important;
  }

  .pb-md-112pt,
.py-md-112pt {
    padding-bottom: 7rem !important;
  }

  .pl-md-112pt,
.px-md-112pt {
    padding-left: 7rem !important;
  }

  .p-md-128pt {
    padding: 8rem !important;
  }

  .pt-md-128pt,
.py-md-128pt {
    padding-top: 8rem !important;
  }

  .pr-md-128pt,
.px-md-128pt {
    padding-right: 8rem !important;
  }

  .pb-md-128pt,
.py-md-128pt {
    padding-bottom: 8rem !important;
  }

  .pl-md-128pt,
.px-md-128pt {
    padding-left: 8rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-n4pt {
    margin: -0.25rem !important;
  }

  .mt-md-n4pt,
.my-md-n4pt {
    margin-top: -0.25rem !important;
  }

  .mr-md-n4pt,
.mx-md-n4pt {
    margin-right: -0.25rem !important;
  }

  .mb-md-n4pt,
.my-md-n4pt {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n4pt,
.mx-md-n4pt {
    margin-left: -0.25rem !important;
  }

  .m-md-n8pt {
    margin: -0.5rem !important;
  }

  .mt-md-n8pt,
.my-md-n8pt {
    margin-top: -0.5rem !important;
  }

  .mr-md-n8pt,
.mx-md-n8pt {
    margin-right: -0.5rem !important;
  }

  .mb-md-n8pt,
.my-md-n8pt {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n8pt,
.mx-md-n8pt {
    margin-left: -0.5rem !important;
  }

  .m-md-n12pt {
    margin: -0.75rem !important;
  }

  .mt-md-n12pt,
.my-md-n12pt {
    margin-top: -0.75rem !important;
  }

  .mr-md-n12pt,
.mx-md-n12pt {
    margin-right: -0.75rem !important;
  }

  .mb-md-n12pt,
.my-md-n12pt {
    margin-bottom: -0.75rem !important;
  }

  .ml-md-n12pt,
.mx-md-n12pt {
    margin-left: -0.75rem !important;
  }

  .m-md-n16pt {
    margin: -1rem !important;
  }

  .mt-md-n16pt,
.my-md-n16pt {
    margin-top: -1rem !important;
  }

  .mr-md-n16pt,
.mx-md-n16pt {
    margin-right: -1rem !important;
  }

  .mb-md-n16pt,
.my-md-n16pt {
    margin-bottom: -1rem !important;
  }

  .ml-md-n16pt,
.mx-md-n16pt {
    margin-left: -1rem !important;
  }

  .m-md-n20pt {
    margin: -1.25rem !important;
  }

  .mt-md-n20pt,
.my-md-n20pt {
    margin-top: -1.25rem !important;
  }

  .mr-md-n20pt,
.mx-md-n20pt {
    margin-right: -1.25rem !important;
  }

  .mb-md-n20pt,
.my-md-n20pt {
    margin-bottom: -1.25rem !important;
  }

  .ml-md-n20pt,
.mx-md-n20pt {
    margin-left: -1.25rem !important;
  }

  .m-md-n24pt {
    margin: -1.5rem !important;
  }

  .mt-md-n24pt,
.my-md-n24pt {
    margin-top: -1.5rem !important;
  }

  .mr-md-n24pt,
.mx-md-n24pt {
    margin-right: -1.5rem !important;
  }

  .mb-md-n24pt,
.my-md-n24pt {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n24pt,
.mx-md-n24pt {
    margin-left: -1.5rem !important;
  }

  .m-md-n32pt {
    margin: -2rem !important;
  }

  .mt-md-n32pt,
.my-md-n32pt {
    margin-top: -2rem !important;
  }

  .mr-md-n32pt,
.mx-md-n32pt {
    margin-right: -2rem !important;
  }

  .mb-md-n32pt,
.my-md-n32pt {
    margin-bottom: -2rem !important;
  }

  .ml-md-n32pt,
.mx-md-n32pt {
    margin-left: -2rem !important;
  }

  .m-md-n40pt {
    margin: -2.5rem !important;
  }

  .mt-md-n40pt,
.my-md-n40pt {
    margin-top: -2.5rem !important;
  }

  .mr-md-n40pt,
.mx-md-n40pt {
    margin-right: -2.5rem !important;
  }

  .mb-md-n40pt,
.my-md-n40pt {
    margin-bottom: -2.5rem !important;
  }

  .ml-md-n40pt,
.mx-md-n40pt {
    margin-left: -2.5rem !important;
  }

  .m-md-n44pt {
    margin: -2.75rem !important;
  }

  .mt-md-n44pt,
.my-md-n44pt {
    margin-top: -2.75rem !important;
  }

  .mr-md-n44pt,
.mx-md-n44pt {
    margin-right: -2.75rem !important;
  }

  .mb-md-n44pt,
.my-md-n44pt {
    margin-bottom: -2.75rem !important;
  }

  .ml-md-n44pt,
.mx-md-n44pt {
    margin-left: -2.75rem !important;
  }

  .m-md-n48pt {
    margin: -3rem !important;
  }

  .mt-md-n48pt,
.my-md-n48pt {
    margin-top: -3rem !important;
  }

  .mr-md-n48pt,
.mx-md-n48pt {
    margin-right: -3rem !important;
  }

  .mb-md-n48pt,
.my-md-n48pt {
    margin-bottom: -3rem !important;
  }

  .ml-md-n48pt,
.mx-md-n48pt {
    margin-left: -3rem !important;
  }

  .m-md-n64pt {
    margin: -4rem !important;
  }

  .mt-md-n64pt,
.my-md-n64pt {
    margin-top: -4rem !important;
  }

  .mr-md-n64pt,
.mx-md-n64pt {
    margin-right: -4rem !important;
  }

  .mb-md-n64pt,
.my-md-n64pt {
    margin-bottom: -4rem !important;
  }

  .ml-md-n64pt,
.mx-md-n64pt {
    margin-left: -4rem !important;
  }

  .m-md-n112pt {
    margin: -7rem !important;
  }

  .mt-md-n112pt,
.my-md-n112pt {
    margin-top: -7rem !important;
  }

  .mr-md-n112pt,
.mx-md-n112pt {
    margin-right: -7rem !important;
  }

  .mb-md-n112pt,
.my-md-n112pt {
    margin-bottom: -7rem !important;
  }

  .ml-md-n112pt,
.mx-md-n112pt {
    margin-left: -7rem !important;
  }

  .m-md-n128pt {
    margin: -8rem !important;
  }

  .mt-md-n128pt,
.my-md-n128pt {
    margin-top: -8rem !important;
  }

  .mr-md-n128pt,
.mx-md-n128pt {
    margin-right: -8rem !important;
  }

  .mb-md-n128pt,
.my-md-n128pt {
    margin-bottom: -8rem !important;
  }

  .ml-md-n128pt,
.mx-md-n128pt {
    margin-left: -8rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .m-lg-4pt {
    margin: 0.25rem !important;
  }

  .mt-lg-4pt,
.my-lg-4pt {
    margin-top: 0.25rem !important;
  }

  .mr-lg-4pt,
.mx-lg-4pt {
    margin-right: 0.25rem !important;
  }

  .mb-lg-4pt,
.my-lg-4pt {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-4pt,
.mx-lg-4pt {
    margin-left: 0.25rem !important;
  }

  .m-lg-8pt {
    margin: 0.5rem !important;
  }

  .mt-lg-8pt,
.my-lg-8pt {
    margin-top: 0.5rem !important;
  }

  .mr-lg-8pt,
.mx-lg-8pt {
    margin-right: 0.5rem !important;
  }

  .mb-lg-8pt,
.my-lg-8pt {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-8pt,
.mx-lg-8pt {
    margin-left: 0.5rem !important;
  }

  .m-lg-12pt {
    margin: 0.75rem !important;
  }

  .mt-lg-12pt,
.my-lg-12pt {
    margin-top: 0.75rem !important;
  }

  .mr-lg-12pt,
.mx-lg-12pt {
    margin-right: 0.75rem !important;
  }

  .mb-lg-12pt,
.my-lg-12pt {
    margin-bottom: 0.75rem !important;
  }

  .ml-lg-12pt,
.mx-lg-12pt {
    margin-left: 0.75rem !important;
  }

  .m-lg-16pt {
    margin: 1rem !important;
  }

  .mt-lg-16pt,
.my-lg-16pt {
    margin-top: 1rem !important;
  }

  .mr-lg-16pt,
.mx-lg-16pt {
    margin-right: 1rem !important;
  }

  .mb-lg-16pt,
.my-lg-16pt {
    margin-bottom: 1rem !important;
  }

  .ml-lg-16pt,
.mx-lg-16pt {
    margin-left: 1rem !important;
  }

  .m-lg-20pt {
    margin: 1.25rem !important;
  }

  .mt-lg-20pt,
.my-lg-20pt {
    margin-top: 1.25rem !important;
  }

  .mr-lg-20pt,
.mx-lg-20pt {
    margin-right: 1.25rem !important;
  }

  .mb-lg-20pt,
.my-lg-20pt {
    margin-bottom: 1.25rem !important;
  }

  .ml-lg-20pt,
.mx-lg-20pt {
    margin-left: 1.25rem !important;
  }

  .m-lg-24pt {
    margin: 1.5rem !important;
  }

  .mt-lg-24pt,
.my-lg-24pt {
    margin-top: 1.5rem !important;
  }

  .mr-lg-24pt,
.mx-lg-24pt {
    margin-right: 1.5rem !important;
  }

  .mb-lg-24pt,
.my-lg-24pt {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-24pt,
.mx-lg-24pt {
    margin-left: 1.5rem !important;
  }

  .m-lg-32pt {
    margin: 2rem !important;
  }

  .mt-lg-32pt,
.my-lg-32pt {
    margin-top: 2rem !important;
  }

  .mr-lg-32pt,
.mx-lg-32pt {
    margin-right: 2rem !important;
  }

  .mb-lg-32pt,
.my-lg-32pt {
    margin-bottom: 2rem !important;
  }

  .ml-lg-32pt,
.mx-lg-32pt {
    margin-left: 2rem !important;
  }

  .m-lg-40pt {
    margin: 2.5rem !important;
  }

  .mt-lg-40pt,
.my-lg-40pt {
    margin-top: 2.5rem !important;
  }

  .mr-lg-40pt,
.mx-lg-40pt {
    margin-right: 2.5rem !important;
  }

  .mb-lg-40pt,
.my-lg-40pt {
    margin-bottom: 2.5rem !important;
  }

  .ml-lg-40pt,
.mx-lg-40pt {
    margin-left: 2.5rem !important;
  }

  .m-lg-44pt {
    margin: 2.75rem !important;
  }

  .mt-lg-44pt,
.my-lg-44pt {
    margin-top: 2.75rem !important;
  }

  .mr-lg-44pt,
.mx-lg-44pt {
    margin-right: 2.75rem !important;
  }

  .mb-lg-44pt,
.my-lg-44pt {
    margin-bottom: 2.75rem !important;
  }

  .ml-lg-44pt,
.mx-lg-44pt {
    margin-left: 2.75rem !important;
  }

  .m-lg-48pt {
    margin: 3rem !important;
  }

  .mt-lg-48pt,
.my-lg-48pt {
    margin-top: 3rem !important;
  }

  .mr-lg-48pt,
.mx-lg-48pt {
    margin-right: 3rem !important;
  }

  .mb-lg-48pt,
.my-lg-48pt {
    margin-bottom: 3rem !important;
  }

  .ml-lg-48pt,
.mx-lg-48pt {
    margin-left: 3rem !important;
  }

  .m-lg-64pt {
    margin: 4rem !important;
  }

  .mt-lg-64pt,
.my-lg-64pt {
    margin-top: 4rem !important;
  }

  .mr-lg-64pt,
.mx-lg-64pt {
    margin-right: 4rem !important;
  }

  .mb-lg-64pt,
.my-lg-64pt {
    margin-bottom: 4rem !important;
  }

  .ml-lg-64pt,
.mx-lg-64pt {
    margin-left: 4rem !important;
  }

  .m-lg-112pt {
    margin: 7rem !important;
  }

  .mt-lg-112pt,
.my-lg-112pt {
    margin-top: 7rem !important;
  }

  .mr-lg-112pt,
.mx-lg-112pt {
    margin-right: 7rem !important;
  }

  .mb-lg-112pt,
.my-lg-112pt {
    margin-bottom: 7rem !important;
  }

  .ml-lg-112pt,
.mx-lg-112pt {
    margin-left: 7rem !important;
  }

  .m-lg-128pt {
    margin: 8rem !important;
  }

  .mt-lg-128pt,
.my-lg-128pt {
    margin-top: 8rem !important;
  }

  .mr-lg-128pt,
.mx-lg-128pt {
    margin-right: 8rem !important;
  }

  .mb-lg-128pt,
.my-lg-128pt {
    margin-bottom: 8rem !important;
  }

  .ml-lg-128pt,
.mx-lg-128pt {
    margin-left: 8rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .p-lg-4pt {
    padding: 0.25rem !important;
  }

  .pt-lg-4pt,
.py-lg-4pt {
    padding-top: 0.25rem !important;
  }

  .pr-lg-4pt,
.px-lg-4pt {
    padding-right: 0.25rem !important;
  }

  .pb-lg-4pt,
.py-lg-4pt {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-4pt,
.px-lg-4pt {
    padding-left: 0.25rem !important;
  }

  .p-lg-8pt {
    padding: 0.5rem !important;
  }

  .pt-lg-8pt,
.py-lg-8pt {
    padding-top: 0.5rem !important;
  }

  .pr-lg-8pt,
.px-lg-8pt {
    padding-right: 0.5rem !important;
  }

  .pb-lg-8pt,
.py-lg-8pt {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-8pt,
.px-lg-8pt {
    padding-left: 0.5rem !important;
  }

  .p-lg-12pt {
    padding: 0.75rem !important;
  }

  .pt-lg-12pt,
.py-lg-12pt {
    padding-top: 0.75rem !important;
  }

  .pr-lg-12pt,
.px-lg-12pt {
    padding-right: 0.75rem !important;
  }

  .pb-lg-12pt,
.py-lg-12pt {
    padding-bottom: 0.75rem !important;
  }

  .pl-lg-12pt,
.px-lg-12pt {
    padding-left: 0.75rem !important;
  }

  .p-lg-16pt {
    padding: 1rem !important;
  }

  .pt-lg-16pt,
.py-lg-16pt {
    padding-top: 1rem !important;
  }

  .pr-lg-16pt,
.px-lg-16pt {
    padding-right: 1rem !important;
  }

  .pb-lg-16pt,
.py-lg-16pt {
    padding-bottom: 1rem !important;
  }

  .pl-lg-16pt,
.px-lg-16pt {
    padding-left: 1rem !important;
  }

  .p-lg-20pt {
    padding: 1.25rem !important;
  }

  .pt-lg-20pt,
.py-lg-20pt {
    padding-top: 1.25rem !important;
  }

  .pr-lg-20pt,
.px-lg-20pt {
    padding-right: 1.25rem !important;
  }

  .pb-lg-20pt,
.py-lg-20pt {
    padding-bottom: 1.25rem !important;
  }

  .pl-lg-20pt,
.px-lg-20pt {
    padding-left: 1.25rem !important;
  }

  .p-lg-24pt {
    padding: 1.5rem !important;
  }

  .pt-lg-24pt,
.py-lg-24pt {
    padding-top: 1.5rem !important;
  }

  .pr-lg-24pt,
.px-lg-24pt {
    padding-right: 1.5rem !important;
  }

  .pb-lg-24pt,
.py-lg-24pt {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-24pt,
.px-lg-24pt {
    padding-left: 1.5rem !important;
  }

  .p-lg-32pt {
    padding: 2rem !important;
  }

  .pt-lg-32pt,
.py-lg-32pt {
    padding-top: 2rem !important;
  }

  .pr-lg-32pt,
.px-lg-32pt {
    padding-right: 2rem !important;
  }

  .pb-lg-32pt,
.py-lg-32pt {
    padding-bottom: 2rem !important;
  }

  .pl-lg-32pt,
.px-lg-32pt {
    padding-left: 2rem !important;
  }

  .p-lg-40pt {
    padding: 2.5rem !important;
  }

  .pt-lg-40pt,
.py-lg-40pt {
    padding-top: 2.5rem !important;
  }

  .pr-lg-40pt,
.px-lg-40pt {
    padding-right: 2.5rem !important;
  }

  .pb-lg-40pt,
.py-lg-40pt {
    padding-bottom: 2.5rem !important;
  }

  .pl-lg-40pt,
.px-lg-40pt {
    padding-left: 2.5rem !important;
  }

  .p-lg-44pt {
    padding: 2.75rem !important;
  }

  .pt-lg-44pt,
.py-lg-44pt {
    padding-top: 2.75rem !important;
  }

  .pr-lg-44pt,
.px-lg-44pt {
    padding-right: 2.75rem !important;
  }

  .pb-lg-44pt,
.py-lg-44pt {
    padding-bottom: 2.75rem !important;
  }

  .pl-lg-44pt,
.px-lg-44pt {
    padding-left: 2.75rem !important;
  }

  .p-lg-48pt {
    padding: 3rem !important;
  }

  .pt-lg-48pt,
.py-lg-48pt {
    padding-top: 3rem !important;
  }

  .pr-lg-48pt,
.px-lg-48pt {
    padding-right: 3rem !important;
  }

  .pb-lg-48pt,
.py-lg-48pt {
    padding-bottom: 3rem !important;
  }

  .pl-lg-48pt,
.px-lg-48pt {
    padding-left: 3rem !important;
  }

  .p-lg-64pt {
    padding: 4rem !important;
  }

  .pt-lg-64pt,
.py-lg-64pt {
    padding-top: 4rem !important;
  }

  .pr-lg-64pt,
.px-lg-64pt {
    padding-right: 4rem !important;
  }

  .pb-lg-64pt,
.py-lg-64pt {
    padding-bottom: 4rem !important;
  }

  .pl-lg-64pt,
.px-lg-64pt {
    padding-left: 4rem !important;
  }

  .p-lg-112pt {
    padding: 7rem !important;
  }

  .pt-lg-112pt,
.py-lg-112pt {
    padding-top: 7rem !important;
  }

  .pr-lg-112pt,
.px-lg-112pt {
    padding-right: 7rem !important;
  }

  .pb-lg-112pt,
.py-lg-112pt {
    padding-bottom: 7rem !important;
  }

  .pl-lg-112pt,
.px-lg-112pt {
    padding-left: 7rem !important;
  }

  .p-lg-128pt {
    padding: 8rem !important;
  }

  .pt-lg-128pt,
.py-lg-128pt {
    padding-top: 8rem !important;
  }

  .pr-lg-128pt,
.px-lg-128pt {
    padding-right: 8rem !important;
  }

  .pb-lg-128pt,
.py-lg-128pt {
    padding-bottom: 8rem !important;
  }

  .pl-lg-128pt,
.px-lg-128pt {
    padding-left: 8rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-n4pt {
    margin: -0.25rem !important;
  }

  .mt-lg-n4pt,
.my-lg-n4pt {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n4pt,
.mx-lg-n4pt {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n4pt,
.my-lg-n4pt {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n4pt,
.mx-lg-n4pt {
    margin-left: -0.25rem !important;
  }

  .m-lg-n8pt {
    margin: -0.5rem !important;
  }

  .mt-lg-n8pt,
.my-lg-n8pt {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n8pt,
.mx-lg-n8pt {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n8pt,
.my-lg-n8pt {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n8pt,
.mx-lg-n8pt {
    margin-left: -0.5rem !important;
  }

  .m-lg-n12pt {
    margin: -0.75rem !important;
  }

  .mt-lg-n12pt,
.my-lg-n12pt {
    margin-top: -0.75rem !important;
  }

  .mr-lg-n12pt,
.mx-lg-n12pt {
    margin-right: -0.75rem !important;
  }

  .mb-lg-n12pt,
.my-lg-n12pt {
    margin-bottom: -0.75rem !important;
  }

  .ml-lg-n12pt,
.mx-lg-n12pt {
    margin-left: -0.75rem !important;
  }

  .m-lg-n16pt {
    margin: -1rem !important;
  }

  .mt-lg-n16pt,
.my-lg-n16pt {
    margin-top: -1rem !important;
  }

  .mr-lg-n16pt,
.mx-lg-n16pt {
    margin-right: -1rem !important;
  }

  .mb-lg-n16pt,
.my-lg-n16pt {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n16pt,
.mx-lg-n16pt {
    margin-left: -1rem !important;
  }

  .m-lg-n20pt {
    margin: -1.25rem !important;
  }

  .mt-lg-n20pt,
.my-lg-n20pt {
    margin-top: -1.25rem !important;
  }

  .mr-lg-n20pt,
.mx-lg-n20pt {
    margin-right: -1.25rem !important;
  }

  .mb-lg-n20pt,
.my-lg-n20pt {
    margin-bottom: -1.25rem !important;
  }

  .ml-lg-n20pt,
.mx-lg-n20pt {
    margin-left: -1.25rem !important;
  }

  .m-lg-n24pt {
    margin: -1.5rem !important;
  }

  .mt-lg-n24pt,
.my-lg-n24pt {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n24pt,
.mx-lg-n24pt {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n24pt,
.my-lg-n24pt {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n24pt,
.mx-lg-n24pt {
    margin-left: -1.5rem !important;
  }

  .m-lg-n32pt {
    margin: -2rem !important;
  }

  .mt-lg-n32pt,
.my-lg-n32pt {
    margin-top: -2rem !important;
  }

  .mr-lg-n32pt,
.mx-lg-n32pt {
    margin-right: -2rem !important;
  }

  .mb-lg-n32pt,
.my-lg-n32pt {
    margin-bottom: -2rem !important;
  }

  .ml-lg-n32pt,
.mx-lg-n32pt {
    margin-left: -2rem !important;
  }

  .m-lg-n40pt {
    margin: -2.5rem !important;
  }

  .mt-lg-n40pt,
.my-lg-n40pt {
    margin-top: -2.5rem !important;
  }

  .mr-lg-n40pt,
.mx-lg-n40pt {
    margin-right: -2.5rem !important;
  }

  .mb-lg-n40pt,
.my-lg-n40pt {
    margin-bottom: -2.5rem !important;
  }

  .ml-lg-n40pt,
.mx-lg-n40pt {
    margin-left: -2.5rem !important;
  }

  .m-lg-n44pt {
    margin: -2.75rem !important;
  }

  .mt-lg-n44pt,
.my-lg-n44pt {
    margin-top: -2.75rem !important;
  }

  .mr-lg-n44pt,
.mx-lg-n44pt {
    margin-right: -2.75rem !important;
  }

  .mb-lg-n44pt,
.my-lg-n44pt {
    margin-bottom: -2.75rem !important;
  }

  .ml-lg-n44pt,
.mx-lg-n44pt {
    margin-left: -2.75rem !important;
  }

  .m-lg-n48pt {
    margin: -3rem !important;
  }

  .mt-lg-n48pt,
.my-lg-n48pt {
    margin-top: -3rem !important;
  }

  .mr-lg-n48pt,
.mx-lg-n48pt {
    margin-right: -3rem !important;
  }

  .mb-lg-n48pt,
.my-lg-n48pt {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n48pt,
.mx-lg-n48pt {
    margin-left: -3rem !important;
  }

  .m-lg-n64pt {
    margin: -4rem !important;
  }

  .mt-lg-n64pt,
.my-lg-n64pt {
    margin-top: -4rem !important;
  }

  .mr-lg-n64pt,
.mx-lg-n64pt {
    margin-right: -4rem !important;
  }

  .mb-lg-n64pt,
.my-lg-n64pt {
    margin-bottom: -4rem !important;
  }

  .ml-lg-n64pt,
.mx-lg-n64pt {
    margin-left: -4rem !important;
  }

  .m-lg-n112pt {
    margin: -7rem !important;
  }

  .mt-lg-n112pt,
.my-lg-n112pt {
    margin-top: -7rem !important;
  }

  .mr-lg-n112pt,
.mx-lg-n112pt {
    margin-right: -7rem !important;
  }

  .mb-lg-n112pt,
.my-lg-n112pt {
    margin-bottom: -7rem !important;
  }

  .ml-lg-n112pt,
.mx-lg-n112pt {
    margin-left: -7rem !important;
  }

  .m-lg-n128pt {
    margin: -8rem !important;
  }

  .mt-lg-n128pt,
.my-lg-n128pt {
    margin-top: -8rem !important;
  }

  .mr-lg-n128pt,
.mx-lg-n128pt {
    margin-right: -8rem !important;
  }

  .mb-lg-n128pt,
.my-lg-n128pt {
    margin-bottom: -8rem !important;
  }

  .ml-lg-n128pt,
.mx-lg-n128pt {
    margin-left: -8rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .m-xl-4pt {
    margin: 0.25rem !important;
  }

  .mt-xl-4pt,
.my-xl-4pt {
    margin-top: 0.25rem !important;
  }

  .mr-xl-4pt,
.mx-xl-4pt {
    margin-right: 0.25rem !important;
  }

  .mb-xl-4pt,
.my-xl-4pt {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-4pt,
.mx-xl-4pt {
    margin-left: 0.25rem !important;
  }

  .m-xl-8pt {
    margin: 0.5rem !important;
  }

  .mt-xl-8pt,
.my-xl-8pt {
    margin-top: 0.5rem !important;
  }

  .mr-xl-8pt,
.mx-xl-8pt {
    margin-right: 0.5rem !important;
  }

  .mb-xl-8pt,
.my-xl-8pt {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-8pt,
.mx-xl-8pt {
    margin-left: 0.5rem !important;
  }

  .m-xl-12pt {
    margin: 0.75rem !important;
  }

  .mt-xl-12pt,
.my-xl-12pt {
    margin-top: 0.75rem !important;
  }

  .mr-xl-12pt,
.mx-xl-12pt {
    margin-right: 0.75rem !important;
  }

  .mb-xl-12pt,
.my-xl-12pt {
    margin-bottom: 0.75rem !important;
  }

  .ml-xl-12pt,
.mx-xl-12pt {
    margin-left: 0.75rem !important;
  }

  .m-xl-16pt {
    margin: 1rem !important;
  }

  .mt-xl-16pt,
.my-xl-16pt {
    margin-top: 1rem !important;
  }

  .mr-xl-16pt,
.mx-xl-16pt {
    margin-right: 1rem !important;
  }

  .mb-xl-16pt,
.my-xl-16pt {
    margin-bottom: 1rem !important;
  }

  .ml-xl-16pt,
.mx-xl-16pt {
    margin-left: 1rem !important;
  }

  .m-xl-20pt {
    margin: 1.25rem !important;
  }

  .mt-xl-20pt,
.my-xl-20pt {
    margin-top: 1.25rem !important;
  }

  .mr-xl-20pt,
.mx-xl-20pt {
    margin-right: 1.25rem !important;
  }

  .mb-xl-20pt,
.my-xl-20pt {
    margin-bottom: 1.25rem !important;
  }

  .ml-xl-20pt,
.mx-xl-20pt {
    margin-left: 1.25rem !important;
  }

  .m-xl-24pt {
    margin: 1.5rem !important;
  }

  .mt-xl-24pt,
.my-xl-24pt {
    margin-top: 1.5rem !important;
  }

  .mr-xl-24pt,
.mx-xl-24pt {
    margin-right: 1.5rem !important;
  }

  .mb-xl-24pt,
.my-xl-24pt {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-24pt,
.mx-xl-24pt {
    margin-left: 1.5rem !important;
  }

  .m-xl-32pt {
    margin: 2rem !important;
  }

  .mt-xl-32pt,
.my-xl-32pt {
    margin-top: 2rem !important;
  }

  .mr-xl-32pt,
.mx-xl-32pt {
    margin-right: 2rem !important;
  }

  .mb-xl-32pt,
.my-xl-32pt {
    margin-bottom: 2rem !important;
  }

  .ml-xl-32pt,
.mx-xl-32pt {
    margin-left: 2rem !important;
  }

  .m-xl-40pt {
    margin: 2.5rem !important;
  }

  .mt-xl-40pt,
.my-xl-40pt {
    margin-top: 2.5rem !important;
  }

  .mr-xl-40pt,
.mx-xl-40pt {
    margin-right: 2.5rem !important;
  }

  .mb-xl-40pt,
.my-xl-40pt {
    margin-bottom: 2.5rem !important;
  }

  .ml-xl-40pt,
.mx-xl-40pt {
    margin-left: 2.5rem !important;
  }

  .m-xl-44pt {
    margin: 2.75rem !important;
  }

  .mt-xl-44pt,
.my-xl-44pt {
    margin-top: 2.75rem !important;
  }

  .mr-xl-44pt,
.mx-xl-44pt {
    margin-right: 2.75rem !important;
  }

  .mb-xl-44pt,
.my-xl-44pt {
    margin-bottom: 2.75rem !important;
  }

  .ml-xl-44pt,
.mx-xl-44pt {
    margin-left: 2.75rem !important;
  }

  .m-xl-48pt {
    margin: 3rem !important;
  }

  .mt-xl-48pt,
.my-xl-48pt {
    margin-top: 3rem !important;
  }

  .mr-xl-48pt,
.mx-xl-48pt {
    margin-right: 3rem !important;
  }

  .mb-xl-48pt,
.my-xl-48pt {
    margin-bottom: 3rem !important;
  }

  .ml-xl-48pt,
.mx-xl-48pt {
    margin-left: 3rem !important;
  }

  .m-xl-64pt {
    margin: 4rem !important;
  }

  .mt-xl-64pt,
.my-xl-64pt {
    margin-top: 4rem !important;
  }

  .mr-xl-64pt,
.mx-xl-64pt {
    margin-right: 4rem !important;
  }

  .mb-xl-64pt,
.my-xl-64pt {
    margin-bottom: 4rem !important;
  }

  .ml-xl-64pt,
.mx-xl-64pt {
    margin-left: 4rem !important;
  }

  .m-xl-112pt {
    margin: 7rem !important;
  }

  .mt-xl-112pt,
.my-xl-112pt {
    margin-top: 7rem !important;
  }

  .mr-xl-112pt,
.mx-xl-112pt {
    margin-right: 7rem !important;
  }

  .mb-xl-112pt,
.my-xl-112pt {
    margin-bottom: 7rem !important;
  }

  .ml-xl-112pt,
.mx-xl-112pt {
    margin-left: 7rem !important;
  }

  .m-xl-128pt {
    margin: 8rem !important;
  }

  .mt-xl-128pt,
.my-xl-128pt {
    margin-top: 8rem !important;
  }

  .mr-xl-128pt,
.mx-xl-128pt {
    margin-right: 8rem !important;
  }

  .mb-xl-128pt,
.my-xl-128pt {
    margin-bottom: 8rem !important;
  }

  .ml-xl-128pt,
.mx-xl-128pt {
    margin-left: 8rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .p-xl-4pt {
    padding: 0.25rem !important;
  }

  .pt-xl-4pt,
.py-xl-4pt {
    padding-top: 0.25rem !important;
  }

  .pr-xl-4pt,
.px-xl-4pt {
    padding-right: 0.25rem !important;
  }

  .pb-xl-4pt,
.py-xl-4pt {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-4pt,
.px-xl-4pt {
    padding-left: 0.25rem !important;
  }

  .p-xl-8pt {
    padding: 0.5rem !important;
  }

  .pt-xl-8pt,
.py-xl-8pt {
    padding-top: 0.5rem !important;
  }

  .pr-xl-8pt,
.px-xl-8pt {
    padding-right: 0.5rem !important;
  }

  .pb-xl-8pt,
.py-xl-8pt {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-8pt,
.px-xl-8pt {
    padding-left: 0.5rem !important;
  }

  .p-xl-12pt {
    padding: 0.75rem !important;
  }

  .pt-xl-12pt,
.py-xl-12pt {
    padding-top: 0.75rem !important;
  }

  .pr-xl-12pt,
.px-xl-12pt {
    padding-right: 0.75rem !important;
  }

  .pb-xl-12pt,
.py-xl-12pt {
    padding-bottom: 0.75rem !important;
  }

  .pl-xl-12pt,
.px-xl-12pt {
    padding-left: 0.75rem !important;
  }

  .p-xl-16pt {
    padding: 1rem !important;
  }

  .pt-xl-16pt,
.py-xl-16pt {
    padding-top: 1rem !important;
  }

  .pr-xl-16pt,
.px-xl-16pt {
    padding-right: 1rem !important;
  }

  .pb-xl-16pt,
.py-xl-16pt {
    padding-bottom: 1rem !important;
  }

  .pl-xl-16pt,
.px-xl-16pt {
    padding-left: 1rem !important;
  }

  .p-xl-20pt {
    padding: 1.25rem !important;
  }

  .pt-xl-20pt,
.py-xl-20pt {
    padding-top: 1.25rem !important;
  }

  .pr-xl-20pt,
.px-xl-20pt {
    padding-right: 1.25rem !important;
  }

  .pb-xl-20pt,
.py-xl-20pt {
    padding-bottom: 1.25rem !important;
  }

  .pl-xl-20pt,
.px-xl-20pt {
    padding-left: 1.25rem !important;
  }

  .p-xl-24pt {
    padding: 1.5rem !important;
  }

  .pt-xl-24pt,
.py-xl-24pt {
    padding-top: 1.5rem !important;
  }

  .pr-xl-24pt,
.px-xl-24pt {
    padding-right: 1.5rem !important;
  }

  .pb-xl-24pt,
.py-xl-24pt {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-24pt,
.px-xl-24pt {
    padding-left: 1.5rem !important;
  }

  .p-xl-32pt {
    padding: 2rem !important;
  }

  .pt-xl-32pt,
.py-xl-32pt {
    padding-top: 2rem !important;
  }

  .pr-xl-32pt,
.px-xl-32pt {
    padding-right: 2rem !important;
  }

  .pb-xl-32pt,
.py-xl-32pt {
    padding-bottom: 2rem !important;
  }

  .pl-xl-32pt,
.px-xl-32pt {
    padding-left: 2rem !important;
  }

  .p-xl-40pt {
    padding: 2.5rem !important;
  }

  .pt-xl-40pt,
.py-xl-40pt {
    padding-top: 2.5rem !important;
  }

  .pr-xl-40pt,
.px-xl-40pt {
    padding-right: 2.5rem !important;
  }

  .pb-xl-40pt,
.py-xl-40pt {
    padding-bottom: 2.5rem !important;
  }

  .pl-xl-40pt,
.px-xl-40pt {
    padding-left: 2.5rem !important;
  }

  .p-xl-44pt {
    padding: 2.75rem !important;
  }

  .pt-xl-44pt,
.py-xl-44pt {
    padding-top: 2.75rem !important;
  }

  .pr-xl-44pt,
.px-xl-44pt {
    padding-right: 2.75rem !important;
  }

  .pb-xl-44pt,
.py-xl-44pt {
    padding-bottom: 2.75rem !important;
  }

  .pl-xl-44pt,
.px-xl-44pt {
    padding-left: 2.75rem !important;
  }

  .p-xl-48pt {
    padding: 3rem !important;
  }

  .pt-xl-48pt,
.py-xl-48pt {
    padding-top: 3rem !important;
  }

  .pr-xl-48pt,
.px-xl-48pt {
    padding-right: 3rem !important;
  }

  .pb-xl-48pt,
.py-xl-48pt {
    padding-bottom: 3rem !important;
  }

  .pl-xl-48pt,
.px-xl-48pt {
    padding-left: 3rem !important;
  }

  .p-xl-64pt {
    padding: 4rem !important;
  }

  .pt-xl-64pt,
.py-xl-64pt {
    padding-top: 4rem !important;
  }

  .pr-xl-64pt,
.px-xl-64pt {
    padding-right: 4rem !important;
  }

  .pb-xl-64pt,
.py-xl-64pt {
    padding-bottom: 4rem !important;
  }

  .pl-xl-64pt,
.px-xl-64pt {
    padding-left: 4rem !important;
  }

  .p-xl-112pt {
    padding: 7rem !important;
  }

  .pt-xl-112pt,
.py-xl-112pt {
    padding-top: 7rem !important;
  }

  .pr-xl-112pt,
.px-xl-112pt {
    padding-right: 7rem !important;
  }

  .pb-xl-112pt,
.py-xl-112pt {
    padding-bottom: 7rem !important;
  }

  .pl-xl-112pt,
.px-xl-112pt {
    padding-left: 7rem !important;
  }

  .p-xl-128pt {
    padding: 8rem !important;
  }

  .pt-xl-128pt,
.py-xl-128pt {
    padding-top: 8rem !important;
  }

  .pr-xl-128pt,
.px-xl-128pt {
    padding-right: 8rem !important;
  }

  .pb-xl-128pt,
.py-xl-128pt {
    padding-bottom: 8rem !important;
  }

  .pl-xl-128pt,
.px-xl-128pt {
    padding-left: 8rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-n4pt {
    margin: -0.25rem !important;
  }

  .mt-xl-n4pt,
.my-xl-n4pt {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n4pt,
.mx-xl-n4pt {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n4pt,
.my-xl-n4pt {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n4pt,
.mx-xl-n4pt {
    margin-left: -0.25rem !important;
  }

  .m-xl-n8pt {
    margin: -0.5rem !important;
  }

  .mt-xl-n8pt,
.my-xl-n8pt {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n8pt,
.mx-xl-n8pt {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n8pt,
.my-xl-n8pt {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n8pt,
.mx-xl-n8pt {
    margin-left: -0.5rem !important;
  }

  .m-xl-n12pt {
    margin: -0.75rem !important;
  }

  .mt-xl-n12pt,
.my-xl-n12pt {
    margin-top: -0.75rem !important;
  }

  .mr-xl-n12pt,
.mx-xl-n12pt {
    margin-right: -0.75rem !important;
  }

  .mb-xl-n12pt,
.my-xl-n12pt {
    margin-bottom: -0.75rem !important;
  }

  .ml-xl-n12pt,
.mx-xl-n12pt {
    margin-left: -0.75rem !important;
  }

  .m-xl-n16pt {
    margin: -1rem !important;
  }

  .mt-xl-n16pt,
.my-xl-n16pt {
    margin-top: -1rem !important;
  }

  .mr-xl-n16pt,
.mx-xl-n16pt {
    margin-right: -1rem !important;
  }

  .mb-xl-n16pt,
.my-xl-n16pt {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n16pt,
.mx-xl-n16pt {
    margin-left: -1rem !important;
  }

  .m-xl-n20pt {
    margin: -1.25rem !important;
  }

  .mt-xl-n20pt,
.my-xl-n20pt {
    margin-top: -1.25rem !important;
  }

  .mr-xl-n20pt,
.mx-xl-n20pt {
    margin-right: -1.25rem !important;
  }

  .mb-xl-n20pt,
.my-xl-n20pt {
    margin-bottom: -1.25rem !important;
  }

  .ml-xl-n20pt,
.mx-xl-n20pt {
    margin-left: -1.25rem !important;
  }

  .m-xl-n24pt {
    margin: -1.5rem !important;
  }

  .mt-xl-n24pt,
.my-xl-n24pt {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n24pt,
.mx-xl-n24pt {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n24pt,
.my-xl-n24pt {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n24pt,
.mx-xl-n24pt {
    margin-left: -1.5rem !important;
  }

  .m-xl-n32pt {
    margin: -2rem !important;
  }

  .mt-xl-n32pt,
.my-xl-n32pt {
    margin-top: -2rem !important;
  }

  .mr-xl-n32pt,
.mx-xl-n32pt {
    margin-right: -2rem !important;
  }

  .mb-xl-n32pt,
.my-xl-n32pt {
    margin-bottom: -2rem !important;
  }

  .ml-xl-n32pt,
.mx-xl-n32pt {
    margin-left: -2rem !important;
  }

  .m-xl-n40pt {
    margin: -2.5rem !important;
  }

  .mt-xl-n40pt,
.my-xl-n40pt {
    margin-top: -2.5rem !important;
  }

  .mr-xl-n40pt,
.mx-xl-n40pt {
    margin-right: -2.5rem !important;
  }

  .mb-xl-n40pt,
.my-xl-n40pt {
    margin-bottom: -2.5rem !important;
  }

  .ml-xl-n40pt,
.mx-xl-n40pt {
    margin-left: -2.5rem !important;
  }

  .m-xl-n44pt {
    margin: -2.75rem !important;
  }

  .mt-xl-n44pt,
.my-xl-n44pt {
    margin-top: -2.75rem !important;
  }

  .mr-xl-n44pt,
.mx-xl-n44pt {
    margin-right: -2.75rem !important;
  }

  .mb-xl-n44pt,
.my-xl-n44pt {
    margin-bottom: -2.75rem !important;
  }

  .ml-xl-n44pt,
.mx-xl-n44pt {
    margin-left: -2.75rem !important;
  }

  .m-xl-n48pt {
    margin: -3rem !important;
  }

  .mt-xl-n48pt,
.my-xl-n48pt {
    margin-top: -3rem !important;
  }

  .mr-xl-n48pt,
.mx-xl-n48pt {
    margin-right: -3rem !important;
  }

  .mb-xl-n48pt,
.my-xl-n48pt {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n48pt,
.mx-xl-n48pt {
    margin-left: -3rem !important;
  }

  .m-xl-n64pt {
    margin: -4rem !important;
  }

  .mt-xl-n64pt,
.my-xl-n64pt {
    margin-top: -4rem !important;
  }

  .mr-xl-n64pt,
.mx-xl-n64pt {
    margin-right: -4rem !important;
  }

  .mb-xl-n64pt,
.my-xl-n64pt {
    margin-bottom: -4rem !important;
  }

  .ml-xl-n64pt,
.mx-xl-n64pt {
    margin-left: -4rem !important;
  }

  .m-xl-n112pt {
    margin: -7rem !important;
  }

  .mt-xl-n112pt,
.my-xl-n112pt {
    margin-top: -7rem !important;
  }

  .mr-xl-n112pt,
.mx-xl-n112pt {
    margin-right: -7rem !important;
  }

  .mb-xl-n112pt,
.my-xl-n112pt {
    margin-bottom: -7rem !important;
  }

  .ml-xl-n112pt,
.mx-xl-n112pt {
    margin-left: -7rem !important;
  }

  .m-xl-n128pt {
    margin: -8rem !important;
  }

  .mt-xl-n128pt,
.my-xl-n128pt {
    margin-top: -8rem !important;
  }

  .mr-xl-n128pt,
.mx-xl-n128pt {
    margin-right: -8rem !important;
  }

  .mb-xl-n128pt,
.my-xl-n128pt {
    margin-bottom: -8rem !important;
  }

  .ml-xl-n128pt,
.mx-xl-n128pt {
    margin-left: -8rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1366px) {
  .m-xxl-0 {
    margin: 0 !important;
  }

  .mt-xxl-0,
.my-xxl-0 {
    margin-top: 0 !important;
  }

  .mr-xxl-0,
.mx-xxl-0 {
    margin-right: 0 !important;
  }

  .mb-xxl-0,
.my-xxl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xxl-0,
.mx-xxl-0 {
    margin-left: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .mt-xxl-1,
.my-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xxl-1,
.mx-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xxl-1,
.my-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxl-1,
.mx-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .mt-xxl-2,
.my-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xxl-2,
.mx-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xxl-2,
.my-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxl-2,
.mx-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .mt-xxl-3,
.my-xxl-3 {
    margin-top: 1rem !important;
  }

  .mr-xxl-3,
.mx-xxl-3 {
    margin-right: 1rem !important;
  }

  .mb-xxl-3,
.my-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xxl-3,
.mx-xxl-3 {
    margin-left: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .mt-xxl-4,
.my-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xxl-4,
.mx-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xxl-4,
.my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxl-4,
.mx-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .mt-xxl-5,
.my-xxl-5 {
    margin-top: 3rem !important;
  }

  .mr-xxl-5,
.mx-xxl-5 {
    margin-right: 3rem !important;
  }

  .mb-xxl-5,
.my-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xxl-5,
.mx-xxl-5 {
    margin-left: 3rem !important;
  }

  .m-xxl-4pt {
    margin: 0.25rem !important;
  }

  .mt-xxl-4pt,
.my-xxl-4pt {
    margin-top: 0.25rem !important;
  }

  .mr-xxl-4pt,
.mx-xxl-4pt {
    margin-right: 0.25rem !important;
  }

  .mb-xxl-4pt,
.my-xxl-4pt {
    margin-bottom: 0.25rem !important;
  }

  .ml-xxl-4pt,
.mx-xxl-4pt {
    margin-left: 0.25rem !important;
  }

  .m-xxl-8pt {
    margin: 0.5rem !important;
  }

  .mt-xxl-8pt,
.my-xxl-8pt {
    margin-top: 0.5rem !important;
  }

  .mr-xxl-8pt,
.mx-xxl-8pt {
    margin-right: 0.5rem !important;
  }

  .mb-xxl-8pt,
.my-xxl-8pt {
    margin-bottom: 0.5rem !important;
  }

  .ml-xxl-8pt,
.mx-xxl-8pt {
    margin-left: 0.5rem !important;
  }

  .m-xxl-12pt {
    margin: 0.75rem !important;
  }

  .mt-xxl-12pt,
.my-xxl-12pt {
    margin-top: 0.75rem !important;
  }

  .mr-xxl-12pt,
.mx-xxl-12pt {
    margin-right: 0.75rem !important;
  }

  .mb-xxl-12pt,
.my-xxl-12pt {
    margin-bottom: 0.75rem !important;
  }

  .ml-xxl-12pt,
.mx-xxl-12pt {
    margin-left: 0.75rem !important;
  }

  .m-xxl-16pt {
    margin: 1rem !important;
  }

  .mt-xxl-16pt,
.my-xxl-16pt {
    margin-top: 1rem !important;
  }

  .mr-xxl-16pt,
.mx-xxl-16pt {
    margin-right: 1rem !important;
  }

  .mb-xxl-16pt,
.my-xxl-16pt {
    margin-bottom: 1rem !important;
  }

  .ml-xxl-16pt,
.mx-xxl-16pt {
    margin-left: 1rem !important;
  }

  .m-xxl-20pt {
    margin: 1.25rem !important;
  }

  .mt-xxl-20pt,
.my-xxl-20pt {
    margin-top: 1.25rem !important;
  }

  .mr-xxl-20pt,
.mx-xxl-20pt {
    margin-right: 1.25rem !important;
  }

  .mb-xxl-20pt,
.my-xxl-20pt {
    margin-bottom: 1.25rem !important;
  }

  .ml-xxl-20pt,
.mx-xxl-20pt {
    margin-left: 1.25rem !important;
  }

  .m-xxl-24pt {
    margin: 1.5rem !important;
  }

  .mt-xxl-24pt,
.my-xxl-24pt {
    margin-top: 1.5rem !important;
  }

  .mr-xxl-24pt,
.mx-xxl-24pt {
    margin-right: 1.5rem !important;
  }

  .mb-xxl-24pt,
.my-xxl-24pt {
    margin-bottom: 1.5rem !important;
  }

  .ml-xxl-24pt,
.mx-xxl-24pt {
    margin-left: 1.5rem !important;
  }

  .m-xxl-32pt {
    margin: 2rem !important;
  }

  .mt-xxl-32pt,
.my-xxl-32pt {
    margin-top: 2rem !important;
  }

  .mr-xxl-32pt,
.mx-xxl-32pt {
    margin-right: 2rem !important;
  }

  .mb-xxl-32pt,
.my-xxl-32pt {
    margin-bottom: 2rem !important;
  }

  .ml-xxl-32pt,
.mx-xxl-32pt {
    margin-left: 2rem !important;
  }

  .m-xxl-40pt {
    margin: 2.5rem !important;
  }

  .mt-xxl-40pt,
.my-xxl-40pt {
    margin-top: 2.5rem !important;
  }

  .mr-xxl-40pt,
.mx-xxl-40pt {
    margin-right: 2.5rem !important;
  }

  .mb-xxl-40pt,
.my-xxl-40pt {
    margin-bottom: 2.5rem !important;
  }

  .ml-xxl-40pt,
.mx-xxl-40pt {
    margin-left: 2.5rem !important;
  }

  .m-xxl-44pt {
    margin: 2.75rem !important;
  }

  .mt-xxl-44pt,
.my-xxl-44pt {
    margin-top: 2.75rem !important;
  }

  .mr-xxl-44pt,
.mx-xxl-44pt {
    margin-right: 2.75rem !important;
  }

  .mb-xxl-44pt,
.my-xxl-44pt {
    margin-bottom: 2.75rem !important;
  }

  .ml-xxl-44pt,
.mx-xxl-44pt {
    margin-left: 2.75rem !important;
  }

  .m-xxl-48pt {
    margin: 3rem !important;
  }

  .mt-xxl-48pt,
.my-xxl-48pt {
    margin-top: 3rem !important;
  }

  .mr-xxl-48pt,
.mx-xxl-48pt {
    margin-right: 3rem !important;
  }

  .mb-xxl-48pt,
.my-xxl-48pt {
    margin-bottom: 3rem !important;
  }

  .ml-xxl-48pt,
.mx-xxl-48pt {
    margin-left: 3rem !important;
  }

  .m-xxl-64pt {
    margin: 4rem !important;
  }

  .mt-xxl-64pt,
.my-xxl-64pt {
    margin-top: 4rem !important;
  }

  .mr-xxl-64pt,
.mx-xxl-64pt {
    margin-right: 4rem !important;
  }

  .mb-xxl-64pt,
.my-xxl-64pt {
    margin-bottom: 4rem !important;
  }

  .ml-xxl-64pt,
.mx-xxl-64pt {
    margin-left: 4rem !important;
  }

  .m-xxl-112pt {
    margin: 7rem !important;
  }

  .mt-xxl-112pt,
.my-xxl-112pt {
    margin-top: 7rem !important;
  }

  .mr-xxl-112pt,
.mx-xxl-112pt {
    margin-right: 7rem !important;
  }

  .mb-xxl-112pt,
.my-xxl-112pt {
    margin-bottom: 7rem !important;
  }

  .ml-xxl-112pt,
.mx-xxl-112pt {
    margin-left: 7rem !important;
  }

  .m-xxl-128pt {
    margin: 8rem !important;
  }

  .mt-xxl-128pt,
.my-xxl-128pt {
    margin-top: 8rem !important;
  }

  .mr-xxl-128pt,
.mx-xxl-128pt {
    margin-right: 8rem !important;
  }

  .mb-xxl-128pt,
.my-xxl-128pt {
    margin-bottom: 8rem !important;
  }

  .ml-xxl-128pt,
.mx-xxl-128pt {
    margin-left: 8rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .pt-xxl-0,
.py-xxl-0 {
    padding-top: 0 !important;
  }

  .pr-xxl-0,
.px-xxl-0 {
    padding-right: 0 !important;
  }

  .pb-xxl-0,
.py-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xxl-0,
.px-xxl-0 {
    padding-left: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .pt-xxl-1,
.py-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xxl-1,
.px-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xxl-1,
.py-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxl-1,
.px-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .pt-xxl-2,
.py-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xxl-2,
.px-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xxl-2,
.py-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxl-2,
.px-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .pt-xxl-3,
.py-xxl-3 {
    padding-top: 1rem !important;
  }

  .pr-xxl-3,
.px-xxl-3 {
    padding-right: 1rem !important;
  }

  .pb-xxl-3,
.py-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xxl-3,
.px-xxl-3 {
    padding-left: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .pt-xxl-4,
.py-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xxl-4,
.px-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xxl-4,
.py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxl-4,
.px-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .pt-xxl-5,
.py-xxl-5 {
    padding-top: 3rem !important;
  }

  .pr-xxl-5,
.px-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-5,
.py-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xxl-5,
.px-xxl-5 {
    padding-left: 3rem !important;
  }

  .p-xxl-4pt {
    padding: 0.25rem !important;
  }

  .pt-xxl-4pt,
.py-xxl-4pt {
    padding-top: 0.25rem !important;
  }

  .pr-xxl-4pt,
.px-xxl-4pt {
    padding-right: 0.25rem !important;
  }

  .pb-xxl-4pt,
.py-xxl-4pt {
    padding-bottom: 0.25rem !important;
  }

  .pl-xxl-4pt,
.px-xxl-4pt {
    padding-left: 0.25rem !important;
  }

  .p-xxl-8pt {
    padding: 0.5rem !important;
  }

  .pt-xxl-8pt,
.py-xxl-8pt {
    padding-top: 0.5rem !important;
  }

  .pr-xxl-8pt,
.px-xxl-8pt {
    padding-right: 0.5rem !important;
  }

  .pb-xxl-8pt,
.py-xxl-8pt {
    padding-bottom: 0.5rem !important;
  }

  .pl-xxl-8pt,
.px-xxl-8pt {
    padding-left: 0.5rem !important;
  }

  .p-xxl-12pt {
    padding: 0.75rem !important;
  }

  .pt-xxl-12pt,
.py-xxl-12pt {
    padding-top: 0.75rem !important;
  }

  .pr-xxl-12pt,
.px-xxl-12pt {
    padding-right: 0.75rem !important;
  }

  .pb-xxl-12pt,
.py-xxl-12pt {
    padding-bottom: 0.75rem !important;
  }

  .pl-xxl-12pt,
.px-xxl-12pt {
    padding-left: 0.75rem !important;
  }

  .p-xxl-16pt {
    padding: 1rem !important;
  }

  .pt-xxl-16pt,
.py-xxl-16pt {
    padding-top: 1rem !important;
  }

  .pr-xxl-16pt,
.px-xxl-16pt {
    padding-right: 1rem !important;
  }

  .pb-xxl-16pt,
.py-xxl-16pt {
    padding-bottom: 1rem !important;
  }

  .pl-xxl-16pt,
.px-xxl-16pt {
    padding-left: 1rem !important;
  }

  .p-xxl-20pt {
    padding: 1.25rem !important;
  }

  .pt-xxl-20pt,
.py-xxl-20pt {
    padding-top: 1.25rem !important;
  }

  .pr-xxl-20pt,
.px-xxl-20pt {
    padding-right: 1.25rem !important;
  }

  .pb-xxl-20pt,
.py-xxl-20pt {
    padding-bottom: 1.25rem !important;
  }

  .pl-xxl-20pt,
.px-xxl-20pt {
    padding-left: 1.25rem !important;
  }

  .p-xxl-24pt {
    padding: 1.5rem !important;
  }

  .pt-xxl-24pt,
.py-xxl-24pt {
    padding-top: 1.5rem !important;
  }

  .pr-xxl-24pt,
.px-xxl-24pt {
    padding-right: 1.5rem !important;
  }

  .pb-xxl-24pt,
.py-xxl-24pt {
    padding-bottom: 1.5rem !important;
  }

  .pl-xxl-24pt,
.px-xxl-24pt {
    padding-left: 1.5rem !important;
  }

  .p-xxl-32pt {
    padding: 2rem !important;
  }

  .pt-xxl-32pt,
.py-xxl-32pt {
    padding-top: 2rem !important;
  }

  .pr-xxl-32pt,
.px-xxl-32pt {
    padding-right: 2rem !important;
  }

  .pb-xxl-32pt,
.py-xxl-32pt {
    padding-bottom: 2rem !important;
  }

  .pl-xxl-32pt,
.px-xxl-32pt {
    padding-left: 2rem !important;
  }

  .p-xxl-40pt {
    padding: 2.5rem !important;
  }

  .pt-xxl-40pt,
.py-xxl-40pt {
    padding-top: 2.5rem !important;
  }

  .pr-xxl-40pt,
.px-xxl-40pt {
    padding-right: 2.5rem !important;
  }

  .pb-xxl-40pt,
.py-xxl-40pt {
    padding-bottom: 2.5rem !important;
  }

  .pl-xxl-40pt,
.px-xxl-40pt {
    padding-left: 2.5rem !important;
  }

  .p-xxl-44pt {
    padding: 2.75rem !important;
  }

  .pt-xxl-44pt,
.py-xxl-44pt {
    padding-top: 2.75rem !important;
  }

  .pr-xxl-44pt,
.px-xxl-44pt {
    padding-right: 2.75rem !important;
  }

  .pb-xxl-44pt,
.py-xxl-44pt {
    padding-bottom: 2.75rem !important;
  }

  .pl-xxl-44pt,
.px-xxl-44pt {
    padding-left: 2.75rem !important;
  }

  .p-xxl-48pt {
    padding: 3rem !important;
  }

  .pt-xxl-48pt,
.py-xxl-48pt {
    padding-top: 3rem !important;
  }

  .pr-xxl-48pt,
.px-xxl-48pt {
    padding-right: 3rem !important;
  }

  .pb-xxl-48pt,
.py-xxl-48pt {
    padding-bottom: 3rem !important;
  }

  .pl-xxl-48pt,
.px-xxl-48pt {
    padding-left: 3rem !important;
  }

  .p-xxl-64pt {
    padding: 4rem !important;
  }

  .pt-xxl-64pt,
.py-xxl-64pt {
    padding-top: 4rem !important;
  }

  .pr-xxl-64pt,
.px-xxl-64pt {
    padding-right: 4rem !important;
  }

  .pb-xxl-64pt,
.py-xxl-64pt {
    padding-bottom: 4rem !important;
  }

  .pl-xxl-64pt,
.px-xxl-64pt {
    padding-left: 4rem !important;
  }

  .p-xxl-112pt {
    padding: 7rem !important;
  }

  .pt-xxl-112pt,
.py-xxl-112pt {
    padding-top: 7rem !important;
  }

  .pr-xxl-112pt,
.px-xxl-112pt {
    padding-right: 7rem !important;
  }

  .pb-xxl-112pt,
.py-xxl-112pt {
    padding-bottom: 7rem !important;
  }

  .pl-xxl-112pt,
.px-xxl-112pt {
    padding-left: 7rem !important;
  }

  .p-xxl-128pt {
    padding: 8rem !important;
  }

  .pt-xxl-128pt,
.py-xxl-128pt {
    padding-top: 8rem !important;
  }

  .pr-xxl-128pt,
.px-xxl-128pt {
    padding-right: 8rem !important;
  }

  .pb-xxl-128pt,
.py-xxl-128pt {
    padding-bottom: 8rem !important;
  }

  .pl-xxl-128pt,
.px-xxl-128pt {
    padding-left: 8rem !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xxl-n1,
.my-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xxl-n1,
.mx-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xxl-n1,
.my-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxl-n1,
.mx-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xxl-n2,
.my-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xxl-n2,
.mx-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xxl-n2,
.my-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxl-n2,
.mx-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .mt-xxl-n3,
.my-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xxl-n3,
.mx-xxl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xxl-n3,
.my-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xxl-n3,
.mx-xxl-n3 {
    margin-left: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xxl-n4,
.my-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xxl-n4,
.mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xxl-n4,
.my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxl-n4,
.mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mt-xxl-n5,
.my-xxl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xxl-n5,
.mx-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n5,
.my-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xxl-n5,
.mx-xxl-n5 {
    margin-left: -3rem !important;
  }

  .m-xxl-n4pt {
    margin: -0.25rem !important;
  }

  .mt-xxl-n4pt,
.my-xxl-n4pt {
    margin-top: -0.25rem !important;
  }

  .mr-xxl-n4pt,
.mx-xxl-n4pt {
    margin-right: -0.25rem !important;
  }

  .mb-xxl-n4pt,
.my-xxl-n4pt {
    margin-bottom: -0.25rem !important;
  }

  .ml-xxl-n4pt,
.mx-xxl-n4pt {
    margin-left: -0.25rem !important;
  }

  .m-xxl-n8pt {
    margin: -0.5rem !important;
  }

  .mt-xxl-n8pt,
.my-xxl-n8pt {
    margin-top: -0.5rem !important;
  }

  .mr-xxl-n8pt,
.mx-xxl-n8pt {
    margin-right: -0.5rem !important;
  }

  .mb-xxl-n8pt,
.my-xxl-n8pt {
    margin-bottom: -0.5rem !important;
  }

  .ml-xxl-n8pt,
.mx-xxl-n8pt {
    margin-left: -0.5rem !important;
  }

  .m-xxl-n12pt {
    margin: -0.75rem !important;
  }

  .mt-xxl-n12pt,
.my-xxl-n12pt {
    margin-top: -0.75rem !important;
  }

  .mr-xxl-n12pt,
.mx-xxl-n12pt {
    margin-right: -0.75rem !important;
  }

  .mb-xxl-n12pt,
.my-xxl-n12pt {
    margin-bottom: -0.75rem !important;
  }

  .ml-xxl-n12pt,
.mx-xxl-n12pt {
    margin-left: -0.75rem !important;
  }

  .m-xxl-n16pt {
    margin: -1rem !important;
  }

  .mt-xxl-n16pt,
.my-xxl-n16pt {
    margin-top: -1rem !important;
  }

  .mr-xxl-n16pt,
.mx-xxl-n16pt {
    margin-right: -1rem !important;
  }

  .mb-xxl-n16pt,
.my-xxl-n16pt {
    margin-bottom: -1rem !important;
  }

  .ml-xxl-n16pt,
.mx-xxl-n16pt {
    margin-left: -1rem !important;
  }

  .m-xxl-n20pt {
    margin: -1.25rem !important;
  }

  .mt-xxl-n20pt,
.my-xxl-n20pt {
    margin-top: -1.25rem !important;
  }

  .mr-xxl-n20pt,
.mx-xxl-n20pt {
    margin-right: -1.25rem !important;
  }

  .mb-xxl-n20pt,
.my-xxl-n20pt {
    margin-bottom: -1.25rem !important;
  }

  .ml-xxl-n20pt,
.mx-xxl-n20pt {
    margin-left: -1.25rem !important;
  }

  .m-xxl-n24pt {
    margin: -1.5rem !important;
  }

  .mt-xxl-n24pt,
.my-xxl-n24pt {
    margin-top: -1.5rem !important;
  }

  .mr-xxl-n24pt,
.mx-xxl-n24pt {
    margin-right: -1.5rem !important;
  }

  .mb-xxl-n24pt,
.my-xxl-n24pt {
    margin-bottom: -1.5rem !important;
  }

  .ml-xxl-n24pt,
.mx-xxl-n24pt {
    margin-left: -1.5rem !important;
  }

  .m-xxl-n32pt {
    margin: -2rem !important;
  }

  .mt-xxl-n32pt,
.my-xxl-n32pt {
    margin-top: -2rem !important;
  }

  .mr-xxl-n32pt,
.mx-xxl-n32pt {
    margin-right: -2rem !important;
  }

  .mb-xxl-n32pt,
.my-xxl-n32pt {
    margin-bottom: -2rem !important;
  }

  .ml-xxl-n32pt,
.mx-xxl-n32pt {
    margin-left: -2rem !important;
  }

  .m-xxl-n40pt {
    margin: -2.5rem !important;
  }

  .mt-xxl-n40pt,
.my-xxl-n40pt {
    margin-top: -2.5rem !important;
  }

  .mr-xxl-n40pt,
.mx-xxl-n40pt {
    margin-right: -2.5rem !important;
  }

  .mb-xxl-n40pt,
.my-xxl-n40pt {
    margin-bottom: -2.5rem !important;
  }

  .ml-xxl-n40pt,
.mx-xxl-n40pt {
    margin-left: -2.5rem !important;
  }

  .m-xxl-n44pt {
    margin: -2.75rem !important;
  }

  .mt-xxl-n44pt,
.my-xxl-n44pt {
    margin-top: -2.75rem !important;
  }

  .mr-xxl-n44pt,
.mx-xxl-n44pt {
    margin-right: -2.75rem !important;
  }

  .mb-xxl-n44pt,
.my-xxl-n44pt {
    margin-bottom: -2.75rem !important;
  }

  .ml-xxl-n44pt,
.mx-xxl-n44pt {
    margin-left: -2.75rem !important;
  }

  .m-xxl-n48pt {
    margin: -3rem !important;
  }

  .mt-xxl-n48pt,
.my-xxl-n48pt {
    margin-top: -3rem !important;
  }

  .mr-xxl-n48pt,
.mx-xxl-n48pt {
    margin-right: -3rem !important;
  }

  .mb-xxl-n48pt,
.my-xxl-n48pt {
    margin-bottom: -3rem !important;
  }

  .ml-xxl-n48pt,
.mx-xxl-n48pt {
    margin-left: -3rem !important;
  }

  .m-xxl-n64pt {
    margin: -4rem !important;
  }

  .mt-xxl-n64pt,
.my-xxl-n64pt {
    margin-top: -4rem !important;
  }

  .mr-xxl-n64pt,
.mx-xxl-n64pt {
    margin-right: -4rem !important;
  }

  .mb-xxl-n64pt,
.my-xxl-n64pt {
    margin-bottom: -4rem !important;
  }

  .ml-xxl-n64pt,
.mx-xxl-n64pt {
    margin-left: -4rem !important;
  }

  .m-xxl-n112pt {
    margin: -7rem !important;
  }

  .mt-xxl-n112pt,
.my-xxl-n112pt {
    margin-top: -7rem !important;
  }

  .mr-xxl-n112pt,
.mx-xxl-n112pt {
    margin-right: -7rem !important;
  }

  .mb-xxl-n112pt,
.my-xxl-n112pt {
    margin-bottom: -7rem !important;
  }

  .ml-xxl-n112pt,
.mx-xxl-n112pt {
    margin-left: -7rem !important;
  }

  .m-xxl-n128pt {
    margin: -8rem !important;
  }

  .mt-xxl-n128pt,
.my-xxl-n128pt {
    margin-top: -8rem !important;
  }

  .mr-xxl-n128pt,
.mx-xxl-n128pt {
    margin-right: -8rem !important;
  }

  .mb-xxl-n128pt,
.my-xxl-n128pt {
    margin-bottom: -8rem !important;
  }

  .ml-xxl-n128pt,
.mx-xxl-n128pt {
    margin-left: -8rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mt-xxl-auto,
.my-xxl-auto {
    margin-top: auto !important;
  }

  .mr-xxl-auto,
.mx-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-auto,
.my-xxl-auto {
    margin-bottom: auto !important;
  }

  .ml-xxl-auto,
.mx-xxl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1366px) {
  .text-xxl-left {
    text-align: left !important;
  }

  .text-xxl-right {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #4AA2EE !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #147dd7 !important;
}

.text-secondary {
  color: #868e96 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #60686f !important;
}

.text-success {
  color: #77c13a !important;
}

a.text-success:hover, a.text-success:focus {
  color: #538628 !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #E4A93C !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba821a !important;
}

.text-danger {
  color: #d9534f !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b52b27 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #303840 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #0f1214 !important;
}

.text-black {
  color: #272C33 !important;
}

a.text-black:hover, a.text-black:focus {
  color: #060708 !important;
}

.text-accent {
  color: #00BCC2 !important;
}

a.text-accent:hover, a.text-accent:focus {
  color: #007276 !important;
}

.text-accent-red {
  color: #ED0B4C !important;
}

a.text-accent-red:hover, a.text-accent-red:focus {
  color: #a40835 !important;
}

.text-accent-yellow {
  color: #E4A93C !important;
}

a.text-accent-yellow:hover, a.text-accent-yellow:focus {
  color: #ba821a !important;
}

.text-accent-dodger-blue {
  color: #5567FF !important;
}

a.text-accent-dodger-blue:hover, a.text-accent-dodger-blue:focus {
  color: #0923ff !important;
}

.text-accent-pickled-bluewood {
  color: #303956 !important;
}

a.text-accent-pickled-bluewood:hover, a.text-accent-pickled-bluewood:focus {
  color: #151825 !important;
}

.text-accent-electric-violet {
  color: #9C42FF !important;
}

a.text-accent-electric-violet:hover, a.text-accent-electric-violet:focus {
  color: #7400f5 !important;
}

.text-primary-purple {
  color: #824EE1 !important;
}

a.text-primary-purple:hover, a.text-primary-purple:focus {
  color: #5a21c2 !important;
}

.text-primary-red {
  color: #ED0B4C !important;
}

a.text-primary-red:hover, a.text-primary-red:focus {
  color: #a40835 !important;
}

.text-primary-yellow {
  color: #E4A93C !important;
}

a.text-primary-yellow:hover, a.text-primary-yellow:focus {
  color: #ba821a !important;
}

.text-primary-light {
  color: #99ccff !important;
}

a.text-primary-light:hover, a.text-primary-light:focus {
  color: #4da6ff !important;
}

.text-primary-dodger-blue {
  color: #5567FF !important;
}

a.text-primary-dodger-blue:hover, a.text-primary-dodger-blue:focus {
  color: #0923ff !important;
}

.text-primary-pickled-bluewood {
  color: #303956 !important;
}

a.text-primary-pickled-bluewood:hover, a.text-primary-pickled-bluewood:focus {
  color: #151825 !important;
}

.text-body {
  color: #272C33 !important;
}

.text-muted {
  color: rgba(39, 44, 51, 0.5) !important;
}

.text-black-50 {
  color: rgba(39, 44, 51, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #272C33;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: #E9EDF2;
  }

  .table .thead-dark th {
    color: inherit;
    border-color: #E9EDF2;
  }
}
.custom-checkbox-toggle {
  width: 3rem;
  height: 1.5rem;
  padding-left: 0;
}
.custom-checkbox-toggle .custom-control-label {
  position: relative;
  width: 100%;
  height: 100%;
}
.custom-checkbox-toggle .custom-control-label::after, .custom-checkbox-toggle .custom-control-label::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  transition: all 0.2s ease;
  border-radius: 1.5rem;
}
.custom-checkbox-toggle .custom-control-label:before {
  width: 100%;
  height: 100%;
  background-color: #e3ebf6;
}
.custom-checkbox-toggle .custom-control-label::after {
  width: 1.5rem;
  height: 100%;
  transform: scale(0.8);
  background-color: #fff;
}
.custom-checkbox-toggle .custom-control-input:checked ~ .custom-control-label::after {
  right: 0;
  left: 1.5rem;
  background-color: #fff;
}

.flatpickr-input[readonly] {
  background-color: white;
}

.flatpickr-wrapper {
  display: block;
}

.flatpickr-hidden-input {
  visibility: hidden;
  height: 0;
}

.flatpickr-calendar-right .flatpickr-calendar {
  right: -1.5rem;
}
@media (min-width: 576px) {
  .flatpickr-calendar-right .flatpickr-calendar {
    right: 0;
  }
}

.flatpickr-calendar.inline {
  box-shadow: none;
  border: none;
}
.flatpickr-calendar.inline:before, .flatpickr-calendar.inline:after {
  display: none;
}
.flatpickr-calendar.inline,
.flatpickr-calendar.inline .flatpickr-days,
.flatpickr-calendar.inline .dayContainer {
  width: 100%;
  max-width: none;
}

.flatpickr-wrapper .flatpickr-input {
  height: 0;
  visibility: hidden;
  padding: 0;
}

.chart {
  position: relative;
  height: 300px;
}

.chart-legend {
  display: flex;
  margin-top: 2.5rem;
  white-space: nowrap;
  justify-content: center;
}
.chart-legend-item {
  display: flex;
  align-items: center;
  justify-content: start;
  font-size: 0.8125rem;
  color: rgba(39, 44, 51, 0.5);
  padding: 0.25rem 0.5rem;
  border: 1px solid #E9EDF2;
  border-radius: 0.2rem;
}
.chart-legend-indicator {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.25rem;
  border-radius: 50%;
}
.chart-legend:not(.chart-legend--vertical) .chart-legend-item + .chart-legend-item {
  margin-left: 0.5rem;
}
.chart-legend--vertical {
  flex-direction: column;
}
.chart-legend--vertical .chart-legend-item + .chart-legend-item {
  margin-top: 0.5rem;
}

#chart-tooltip {
  z-index: 0;
  pointer-events: none;
}
#chart-tooltip .popover-header {
  border-bottom: 0;
}
#chart-tooltip .popover-body {
  text-transform: uppercase;
  font-size: 0.707rem;
  color: rgba(39, 44, 51, 0.7);
}
#chart-tooltip .popover-body + .popover-body {
  padding-top: 0;
}
#chart-tooltip .popover-body-label,
#chart-tooltip .popover-body-value {
  margin-left: 0.25rem;
}
#chart-tooltip .popover-body-indicator {
  margin-right: 0.25rem;
}
#chart-tooltip .arrow {
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateX(-0.5rem);
}
#chart-tooltip .popover-body-indicator {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}

.avatar {
  font-size: 1rem;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  position: relative;
  z-index: 0;
}
.avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.avatar-online::before, .avatar-online::after, .avatar-offline::before, .avatar-offline::after {
  position: absolute;
  right: 5%;
  bottom: 5%;
  width: 20%;
  height: 20%;
  content: "";
  border-radius: 50%;
}
.avatar-online::before, .avatar-offline::before {
  width: 22%;
  height: 22%;
  right: 4%;
  bottom: 4%;
  background-color: white;
}
.avatar-online::after {
  background-color: #77c13a;
}
.avatar-offline::after {
  background-color: #818A91;
}
.avatar-title {
  display: flex;
  width: 100%;
  height: 100%;
  color: #fff;
  background-color: #818A91;
  align-items: center;
  justify-content: center;
}
.avatar-xl, .avatar-xxl {
  font-size: 1.70833rem;
  width: 5.125rem;
  height: 5.125rem;
}
.avatar-lg {
  font-size: 1.33333rem;
  width: 4rem;
  height: 4rem;
}
.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
}
.avatar-xs {
  font-size: 0.54167rem;
  width: 1.625rem;
  height: 1.625rem;
}
@media (min-width: 768px) {
  .avatar-xxl {
    font-size: 2.66667rem;
    width: 8rem;
    height: 8rem;
  }
}
.avatar.avatar-4by3 {
  width: 4rem;
}
.avatar-xxl.avatar-4by3 {
  width: 10.66667rem;
}
.avatar-xl.avatar-4by3 {
  width: 6.83333rem;
}
.avatar-lg.avatar-4by3 {
  width: 5.33333rem;
}
.avatar-group {
  display: inline-flex;
}
.avatar-group .avatar:hover {
  z-index: 1;
}
.avatar-group .avatar-img,
.avatar-group .avatar-title {
  border: 2px solid white;
}
.avatar-group .avatar + .avatar {
  margin-left: -0.75rem;
}
.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1rem;
}
.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.28125rem;
}
.avatar-group .avatar-xxl + .avatar-xxl {
  margin-left: -2rem;
}
.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -0.625rem;
}
.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -0.40625rem;
}

.avatar-list > .avatar,
.avatar-list > .avatar-group {
  margin-bottom: 0.75rem;
  margin-left: 0.5rem;
}

.is-loading {
  position: relative;
  color: transparent !important;
}

.is-loading:after,
.loader {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  animation: 1s is-loading linear infinite;
  border: 3px solid #272C33;
  border-bottom-color: transparent;
  border-radius: 50%;
  background: transparent;
}

.is-loading-sm:after, .loader-sm {
  width: 1rem;
  height: 1rem;
  border-width: 2px;
}

.is-loading-lg:after, .loader-lg {
  width: 2rem;
  height: 2rem;
  border-width: 5px;
}

.is-loading > * {
  opacity: 0 !important;
}

.is-loading:after {
  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);
  content: "";
}

.is-loading-sm:after {
  top: calc(50% - 0.5rem);
  left: calc(50% - 0.5rem);
}

.is-loading-lg:after {
  top: calc(50% - 1rem);
  left: calc(50% - 1rem);
}

.is-loading-primary:after,
.btn-outline-primary.is-loading:after,
.loader-primary {
  border-color: #4AA2EE;
  border-bottom-color: transparent;
}

.btn-primary.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-secondary:after,
.btn-outline-secondary.is-loading:after,
.loader-secondary {
  border-color: #868e96;
  border-bottom-color: transparent;
}

.btn-secondary.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-success:after,
.btn-outline-success.is-loading:after,
.loader-success {
  border-color: #77c13a;
  border-bottom-color: transparent;
}

.btn-success.is-loading:after {
  border-color: #212529;
  border-bottom-color: transparent;
}

.is-loading-info:after,
.btn-outline-info.is-loading:after,
.loader-info {
  border-color: #17a2b8;
  border-bottom-color: transparent;
}

.btn-info.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-warning:after,
.btn-outline-warning.is-loading:after,
.loader-warning {
  border-color: #E4A93C;
  border-bottom-color: transparent;
}

.btn-warning.is-loading:after {
  border-color: #212529;
  border-bottom-color: transparent;
}

.is-loading-danger:after,
.btn-outline-danger.is-loading:after,
.loader-danger {
  border-color: #d9534f;
  border-bottom-color: transparent;
}

.btn-danger.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-light:after,
.btn-outline-light.is-loading:after,
.loader-light {
  border-color: #f8f9fa;
  border-bottom-color: transparent;
}

.btn-light.is-loading:after, .navbar-light .is-loading.navbar-btn:after {
  border-color: #212529;
  border-bottom-color: transparent;
}

.is-loading-dark:after,
.btn-outline-dark.is-loading:after,
.loader-dark {
  border-color: #303840;
  border-bottom-color: transparent;
}

.btn-dark.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-black:after,
.btn-outline-black.is-loading:after,
.loader-black {
  border-color: #272C33;
  border-bottom-color: transparent;
}

.btn-black.is-loading:after, .navbar-dark .is-loading.navbar-btn:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-accent:after,
.btn-outline-accent.is-loading:after,
.loader-accent {
  border-color: #00BCC2;
  border-bottom-color: transparent;
}

.btn-accent.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-accent-red:after,
.btn-outline-accent-red.is-loading:after,
.loader-accent-red {
  border-color: #ED0B4C;
  border-bottom-color: transparent;
}

.btn-accent-red.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-accent-yellow:after,
.btn-outline-accent-yellow.is-loading:after,
.loader-accent-yellow {
  border-color: #E4A93C;
  border-bottom-color: transparent;
}

.btn-accent-yellow.is-loading:after {
  border-color: #212529;
  border-bottom-color: transparent;
}

.is-loading-accent-dodger-blue:after,
.btn-outline-accent-dodger-blue.is-loading:after,
.loader-accent-dodger-blue {
  border-color: #5567FF;
  border-bottom-color: transparent;
}

.btn-accent-dodger-blue.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-accent-pickled-bluewood:after,
.btn-outline-accent-pickled-bluewood.is-loading:after,
.loader-accent-pickled-bluewood {
  border-color: #303956;
  border-bottom-color: transparent;
}

.btn-accent-pickled-bluewood.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-accent-electric-violet:after,
.btn-outline-accent-electric-violet.is-loading:after,
.loader-accent-electric-violet {
  border-color: #9C42FF;
  border-bottom-color: transparent;
}

.btn-accent-electric-violet.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-primary-purple:after,
.btn-outline-primary-purple.is-loading:after,
.loader-primary-purple {
  border-color: #824EE1;
  border-bottom-color: transparent;
}

.btn-primary-purple.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-primary-red:after,
.btn-outline-primary-red.is-loading:after,
.loader-primary-red {
  border-color: #ED0B4C;
  border-bottom-color: transparent;
}

.btn-primary-red.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-primary-yellow:after,
.btn-outline-primary-yellow.is-loading:after,
.loader-primary-yellow {
  border-color: #E4A93C;
  border-bottom-color: transparent;
}

.btn-primary-yellow.is-loading:after {
  border-color: #212529;
  border-bottom-color: transparent;
}

.is-loading-primary-light:after,
.btn-outline-primary-light.is-loading:after,
.loader-primary-light {
  border-color: #99ccff;
  border-bottom-color: transparent;
}

.btn-primary-light.is-loading:after {
  border-color: #212529;
  border-bottom-color: transparent;
}

.is-loading-primary-dodger-blue:after,
.btn-outline-primary-dodger-blue.is-loading:after,
.loader-primary-dodger-blue {
  border-color: #5567FF;
  border-bottom-color: transparent;
}

.btn-primary-dodger-blue.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

.is-loading-primary-pickled-bluewood:after,
.btn-outline-primary-pickled-bluewood.is-loading:after,
.loader-primary-pickled-bluewood {
  border-color: #303956;
  border-bottom-color: transparent;
}

.btn-primary-pickled-bluewood.is-loading:after {
  border-color: #fff;
  border-bottom-color: transparent;
}

@keyframes is-loading {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
.loader-list {
  display: flex;
  align-items: center;
}
.loader-list > .loader {
  margin-bottom: 0.75rem;
  margin-left: 0.5rem;
}

.corner-ribbon {
  margin: 0;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  overflow: hidden;
  font-weight: 500;
  box-sizing: border-box;
}

.corner-ribbon--default-left-top {
  transform-origin: 100% 100%;
  height: 30px;
  width: 100px;
  transform: rotateZ(-45deg);
  padding-right: -30px;
  padding-left: -29px;
  line-height: 30px;
  left: -30px;
  top: -30px;
}

.corner-ribbon--default-left-bottom {
  transform-origin: 100% 0;
  height: 30px;
  width: 100px;
  transform: rotateZ(45deg);
  padding-right: 31px;
  padding-left: 30px;
  line-height: 30px;
  left: -30px;
  bottom: -30px;
}

.corner-ribbon--default-right-top {
  transform-origin: 0 100%;
  height: 30px;
  width: 100px;
  transform: rotateZ(45deg);
  padding-right: 31px;
  padding-left: 30px;
  line-height: 30px;
  right: -30px;
  top: -30px;
}

.corner-ribbon--default-right-bottom {
  transform-origin: 0 0;
  height: 30px;
  width: 100px;
  transform: rotateZ(-45deg);
  padding-right: -30px;
  padding-left: -29px;
  line-height: 30px;
  right: -30px;
  bottom: -30px;
}

.corner-ribbon--fixed {
  position: fixed;
}

.corner-ribbon--shadow {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.corner-ribbon--primary {
  color: #fff;
  background-color: #2c84dd;
}

.corner-ribbon--success {
  color: #fff;
  background-color: #77c13a;
}

.mdk-header {
  position: relative;
  z-index: 1;
  margin-bottom: 1.25rem;
  height: 200px;
}
.mdk-header :last-child {
  margin-bottom: 0;
}
.mdk-header[data-condenses], .mdk-header[data-reveals] {
  will-change: transform;
}
.mdk-header [data-fx-condenses] {
  transform-origin: left top;
  opacity: 0;
}
.mdk-header [data-fx-condenses][data-fx-id] {
  opacity: 1;
}
.mdk-header [data-primary] {
  z-index: 1;
}

.mdk-header__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}

[data-effects*=parallax-background] .mdk-header__bg {
  visibility: hidden;
}

.mdk-header__bg-rear,
.mdk-header__bg-front {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-size: cover;
}

.mdk-header__bg-rear {
  opacity: 0;
}

.mdk-header__content {
  position: relative;
  width: 100%;
  height: 100%;
}

.mdk-header[data-condenses] .mdk-header__content {
  z-index: 0;
}
.mdk-header[data-condenses] [data-primary] {
  z-index: 1;
}

.mdk-header--shadow::after {
  position: absolute;
  right: 0;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 5px;
  content: "";
  pointer-events: none;
  opacity: 0;
  box-shadow: inset 0px 5px 6px -3px rgba(0, 0, 0, 0.4);
  will-change: opacity;
  z-index: -1;
}

.mdk-header--shadow-show::after {
  opacity: 1;
}

.mdk-header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-bottom: 0;
}

.mdk-header[disabled], .mdk-header[disabled]::after,
.mdk-header[disabled] .mdk-header__bg-rear,
.mdk-header[disabled] .mdk-header__bg-front,
.mdk-header[disabled] .mdk-header__content > [data-primary] {
  transition: none !important;
}

.mdk-header-layout {
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.mdk-header-layout--fullbleed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.mdk-header-layout > .mdk-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}
.mdk-header-layout[data-fullbleed] > .mdk-header, .mdk-header-layout[data-has-scrolling-region] > .mdk-header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.mdk-header-layout__content {
  position: relative;
  z-index: 0;
}
.mdk-header-layout__content--fullbleed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.mdk-header-layout__content--scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mdk-box {
  position: relative;
  margin-bottom: 1.25rem;
}

.mdk-box__bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
}

[data-effects*=parallax-background] .mdk-box__bg {
  visibility: hidden;
}

.mdk-box__bg-rear,
.mdk-box__bg-front {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-size: cover;
}

.mdk-box__bg-rear {
  opacity: 0;
}

.mdk-box__content {
  position: relative;
  width: 100%;
  height: 100%;
}

.mdk-box[disabled], .mdk-box[disabled]::after,
.mdk-box[disabled] .mdk-box__bg-rear,
.mdk-box[disabled] .mdk-box__bg-front,
.mdk-box[disabled] .mdk-box__content > [data-primary] {
  transition: none !important;
}

.mdk-drawer {
  position: relative;
  visibility: hidden;
}
.mdk-drawer[data-opened], .mdk-drawer[data-closing] {
  visibility: visible;
}
.mdk-drawer[data-persistent] {
  width: 256px;
}
.mdk-drawer[data-persistent][data-position=left] {
  right: auto/* rtl:ignore */;
}
.mdk-drawer[data-persistent][data-position=right] {
  left: auto/* rtl:ignore */;
}

.mdk-drawer__content {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 256px;
  transition: transform 0.2s ease;
  background-color: #fff;
  left: 0 /* rtl:ignore */;
  transform: translate3d(-100%, 0, 0) /* rtl:ignore */;
}
[data-position=right] > .mdk-drawer__content {
  right: 0 /* rtl:ignore */;
  left: auto/* rtl:ignore */;
  transform: translate3d(100%, 0, 0) /* rtl:ignore */;
}
[data-opened] > .mdk-drawer__content {
  transform: translate3d(0, 0, 0);
}

.mdk-drawer__scrim {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: opacity 0.2s ease;
  transform: translateZ(0);
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
[data-opened] > .mdk-drawer__scrim {
  opacity: 1;
}
[data-persistent] > .mdk-drawer__scrim {
  visibility: hidden;
  opacity: 0;
}

.mdk-drawer-layout {
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
}
.mdk-drawer-layout--fullbleed {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.mdk-drawer-layout .mdk-drawer {
  position: relative;
}
.mdk-drawer-layout .mdk-drawer[data-persistent] {
  width: 256px;
}
.mdk-drawer-layout .mdk-drawer[data-persistent][data-position=left] {
  order: 0 /* rtl:2 */;
}
.mdk-drawer-layout .mdk-drawer[data-persistent][data-position=right] {
  order: 2 /* rtl:0 */;
}

.mdk-drawer-layout__content {
  position: relative;
  height: 100%;
  transition: transform 0.2s;
  min-width: 320px;
  z-index: 0;
  order: 1;
  flex: 1 1 0%;
}
.mdk-drawer-layout__content--scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.mdk-reveal {
  z-index: 0;
  position: relative;
  overflow: hidden;
  display: block;
}
.mdk-reveal[data-opened] .mdk-reveal__partial, .mdk-reveal--hover:hover .mdk-reveal__partial {
  opacity: 0;
}

.mdk-reveal__content {
  transition: transform 0.2s;
  background-color: #ffffff;
  position: relative;
  z-index: 0;
}

.mdk-reveal__partial {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  content: "";
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 60%, rgba(255, 255, 255, 0.95) 80%, white 100%);
  opacity: 1;
  z-index: 1;
  transition: 0.2s opacity;
  pointer-events: none;
}

.mdk-carousel__content {
  transition: transform 0.4s;
  transform: translate3d(0, 0, 0);
  touch-action: pan-x;
}

.mdk-carousel__item {
  float: left;
  user-select: none;
  cursor: move;
  cursor: grab;
  -webkit-touch-callout: none;
  width: 100%;
}

.mdk-tooltip {
  transform: scale(0);
  transition: transform 0.2s;
  transform-origin: top center;
  will-change: transform;
  display: inline-block;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 13px;
  z-index: 999;
  font-weight: 500;
  text-align: center;
  position: fixed;
  line-height: 1;
  max-width: 200px;
  padding: 0.4rem;
  border-radius: 0.25rem;
  pointer-events: none;
}
.mdk-tooltip[data-opened] {
  transform: scale(1);
}

.flex {
  flex: 1 1 0% !important;
}

.flex-none,
.flex-0 {
  flex: none !important;
}

.flex-grow {
  flex: 1 1 auto !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

@media (min-width: 576px) {
  .flex-sm {
    flex: 1 1 0% !important;
  }

  .flex-sm-none,
.flex-sm-0 {
    flex: none !important;
  }

  .flex-sm-grow {
    flex: 1 1 auto !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
}
@media (min-width: 768px) {
  .flex-md {
    flex: 1 1 0% !important;
  }

  .flex-md-none,
.flex-md-0 {
    flex: none !important;
  }

  .flex-md-grow {
    flex: 1 1 auto !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
}
@media (min-width: 992px) {
  .flex-lg {
    flex: 1 1 0% !important;
  }

  .flex-lg-none,
.flex-lg-0 {
    flex: none !important;
  }

  .flex-lg-grow {
    flex: 1 1 auto !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl {
    flex: 1 1 0% !important;
  }

  .flex-xl-none,
.flex-xl-0 {
    flex: none !important;
  }

  .flex-xl-grow {
    flex: 1 1 auto !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
}
@media (min-width: 1366px) {
  .flex-xxl {
    flex: 1 1 0% !important;
  }

  .flex-xxl-none,
.flex-xxl-0 {
    flex: none !important;
  }

  .flex-xxl-grow {
    flex: 1 1 auto !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
}
.w-100px {
  width: !important;
}

.w-30px {
  width: !important;
}

.h-100px {
  height: !important;
}

.h-30px {
  height: !important;
}

@media (min-width: 576px) {
  .w-sm-100px {
    width: !important;
  }

  .w-sm-30px {
    width: !important;
  }

  .h-sm-100px {
    height: !important;
  }

  .h-sm-30px {
    height: !important;
  }
}
@media (min-width: 768px) {
  .w-md-100px {
    width: !important;
  }

  .w-md-30px {
    width: !important;
  }

  .h-md-100px {
    height: !important;
  }

  .h-md-30px {
    height: !important;
  }
}
@media (min-width: 992px) {
  .w-lg-100px {
    width: !important;
  }

  .w-lg-30px {
    width: !important;
  }

  .h-lg-100px {
    height: !important;
  }

  .h-lg-30px {
    height: !important;
  }
}
@media (min-width: 1200px) {
  .w-xl-100px {
    width: !important;
  }

  .w-xl-30px {
    width: !important;
  }

  .h-xl-100px {
    height: !important;
  }

  .h-xl-30px {
    height: !important;
  }
}
@media (min-width: 1366px) {
  .w-xxl-100px {
    width: !important;
  }

  .w-xxl-30px {
    width: !important;
  }

  .h-xxl-100px {
    height: !important;
  }

  .h-xxl-30px {
    height: !important;
  }
}
.text-underline {
  text-decoration: underline;
}

.text-facebook {
  color: #3b5998;
}

.text-twitter {
  color: #0084b4;
}

.text-dribbble {
  color: #ea4c89;
}

.hover-text-decoration-0:hover, .hover-text-decoration-0:focus {
  text-decoration: none;
}

.lh-1 {
  line-height: 1rem;
}

.text-20, .text-black-20 {
  color: rgba(39, 44, 51, 0.2) !important;
}

.text-50, .text-black-50 {
  color: rgba(39, 44, 51, 0.5) !important;
}

.text-70, .text-black-70 {
  color: rgba(39, 44, 51, 0.7) !important;
}

.text-100, .text-black-100 {
  color: #272C33 !important;
}

.text-white-20 {
  color: rgba(255, 255, 255, 0.2) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-white-60 {
  color: rgba(255, 255, 255, 0.6) !important;
}

.text-white-70 {
  color: rgba(255, 255, 255, 0.7) !important;
}

.mb-heading {
  margin-bottom: 1rem !important;
}

.mb-grid-gutter {
  margin-bottom: 24px !important;
}

.mb-card-y {
  margin-bottom: 1rem;
}

@media (max-width: 575.98px) {
  .p-0-xs {
    padding: 0 !important;
  }
}
.col {
  padding-left: 12px;
  padding-right: 12px;
}

.icon--left {
  margin-right: 0.5rem;
}

.icon--right {
  margin-left: 0.5rem;
}

.fullbleed {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.position-absolute-top,
.absolute-top {
  position: absolute;
  left: 0;
  top: 0;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.o-hidden {
  overflow: hidden !important;
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .hidden-sm {
    display: none;
  }
}
.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.bg-primary {
  background-color: #4AA2EE !important;
}

.bg-secondary {
  background-color: #868e96 !important;
}

.bg-success {
  background-color: #77c13a !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

.bg-warning {
  background-color: #E4A93C !important;
}

.bg-danger {
  background-color: #d9534f !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #303840 !important;
}

.bg-black {
  background-color: #272C33 !important;
}

.bg-accent {
  background-color: #00BCC2 !important;
}

.bg-accent-red {
  background-color: #ED0B4C !important;
}

.bg-accent-yellow {
  background-color: #E4A93C !important;
}

.bg-accent-dodger-blue {
  background-color: #5567FF !important;
}

.bg-accent-pickled-bluewood {
  background-color: #303956 !important;
}

.bg-accent-electric-violet {
  background-color: #9C42FF !important;
}

.bg-primary-purple {
  background-color: #824EE1 !important;
}

.bg-primary-red {
  background-color: #ED0B4C !important;
}

.bg-primary-yellow {
  background-color: #E4A93C !important;
}

.bg-primary-light {
  background-color: #99ccff !important;
}

.bg-primary-dodger-blue {
  background-color: #5567FF !important;
}

.bg-primary-pickled-bluewood {
  background-color: #303956 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-alt {
  background: white !important;
}

.bg-body {
  background-color: #F5F7FA !important;
}

.bg-transparent {
  background: transparent !important;
}

.bg-darker {
  background-color: #293037 !important;
}

.bg-gradient-purple {
  background-image: linear-gradient(-128deg, #8A4182 0%, #3289DC 100%);
}

.bg-gradient-primary {
  background-image: linear-gradient(128deg, #1E6DBD 0%, #3287DB 100%);
}

.bg-dark-blue {
  background-color: #0C2440 !important;
}

.bg-dark-purple {
  background: #824EE1 !important;
}

.border-1 {
  border: 1px solid #E9EDF2 !important;
}

.border-left-1 {
  border-left: 1px solid #E9EDF2 !important;
}

.border-left-1 {
  border-left: 1px solid #E9EDF2 !important;
}

.border-right-1 {
  border-right: 1px solid #E9EDF2 !important;
}

.border-right-1 {
  border-right: 1px solid #E9EDF2 !important;
}

.border-top-1 {
  border-top: 1px solid #E9EDF2 !important;
}

.border-top-1 {
  border-top: 1px solid #E9EDF2 !important;
}

.border-bottom-1 {
  border-bottom: 1px solid #E9EDF2 !important;
}

.border-bottom-1 {
  border-bottom: 1px solid #E9EDF2 !important;
}

.border-2 {
  border: 2px solid #E9EDF2 !important;
}

.border-left-2 {
  border-left: 2px solid #E9EDF2 !important;
}

.border-left-2 {
  border-left: 2px solid #E9EDF2 !important;
}

.border-right-2 {
  border-right: 2px solid #E9EDF2 !important;
}

.border-right-2 {
  border-right: 2px solid #E9EDF2 !important;
}

.border-top-2 {
  border-top: 2px solid #E9EDF2 !important;
}

.border-top-2 {
  border-top: 2px solid #E9EDF2 !important;
}

.border-bottom-2 {
  border-bottom: 2px solid #E9EDF2 !important;
}

.border-bottom-2 {
  border-bottom: 2px solid #E9EDF2 !important;
}

.border-3 {
  border: 3px solid #E9EDF2 !important;
}

.border-left-3 {
  border-left: 3px solid #E9EDF2 !important;
}

.border-left-3 {
  border-left: 3px solid #E9EDF2 !important;
}

.border-right-3 {
  border-right: 3px solid #E9EDF2 !important;
}

.border-right-3 {
  border-right: 3px solid #E9EDF2 !important;
}

.border-top-3 {
  border-top: 3px solid #E9EDF2 !important;
}

.border-top-3 {
  border-top: 3px solid #E9EDF2 !important;
}

.border-bottom-3 {
  border-bottom: 3px solid #E9EDF2 !important;
}

.border-bottom-3 {
  border-bottom: 3px solid #E9EDF2 !important;
}

.border-4 {
  border: 4px solid #E9EDF2 !important;
}

.border-left-4 {
  border-left: 4px solid #E9EDF2 !important;
}

.border-left-4 {
  border-left: 4px solid #E9EDF2 !important;
}

.border-right-4 {
  border-right: 4px solid #E9EDF2 !important;
}

.border-right-4 {
  border-right: 4px solid #E9EDF2 !important;
}

.border-top-4 {
  border-top: 4px solid #E9EDF2 !important;
}

.border-top-4 {
  border-top: 4px solid #E9EDF2 !important;
}

.border-bottom-4 {
  border-bottom: 4px solid #E9EDF2 !important;
}

.border-bottom-4 {
  border-bottom: 4px solid #E9EDF2 !important;
}

.border {
  border: 1px solid #E9EDF2 !important;
}

.border-top {
  border-top: 1px solid #E9EDF2 !important;
}

.border-right {
  border-right: 1px solid #E9EDF2 !important;
}

.border-bottom {
  border-bottom: 1px solid #E9EDF2 !important;
}

.border-left {
  border-left: 1px solid #E9EDF2 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

@media (min-width: 576px) {
  .border-sm {
    border: 1px solid #E9EDF2 !important;
  }

  .border-top-sm {
    border-top: 1px solid #E9EDF2 !important;
  }

  .border-right-sm {
    border-right: 1px solid #E9EDF2 !important;
  }

  .border-bottom-sm {
    border-bottom: 1px solid #E9EDF2 !important;
  }

  .border-left-sm {
    border-left: 1px solid #E9EDF2 !important;
  }

  .border-sm-0 {
    border: 0 !important;
  }

  .border-top-sm-0 {
    border-top: 0 !important;
  }

  .border-right-sm-0 {
    border-right: 0 !important;
  }

  .border-bottom-sm-0 {
    border-bottom: 0 !important;
  }

  .border-left-sm-0 {
    border-left: 0 !important;
  }
}
@media (min-width: 768px) {
  .border-md {
    border: 1px solid #E9EDF2 !important;
  }

  .border-top-md {
    border-top: 1px solid #E9EDF2 !important;
  }

  .border-right-md {
    border-right: 1px solid #E9EDF2 !important;
  }

  .border-bottom-md {
    border-bottom: 1px solid #E9EDF2 !important;
  }

  .border-left-md {
    border-left: 1px solid #E9EDF2 !important;
  }

  .border-md-0 {
    border: 0 !important;
  }

  .border-top-md-0 {
    border-top: 0 !important;
  }

  .border-right-md-0 {
    border-right: 0 !important;
  }

  .border-bottom-md-0 {
    border-bottom: 0 !important;
  }

  .border-left-md-0 {
    border-left: 0 !important;
  }
}
@media (min-width: 992px) {
  .border-lg {
    border: 1px solid #E9EDF2 !important;
  }

  .border-top-lg {
    border-top: 1px solid #E9EDF2 !important;
  }

  .border-right-lg {
    border-right: 1px solid #E9EDF2 !important;
  }

  .border-bottom-lg {
    border-bottom: 1px solid #E9EDF2 !important;
  }

  .border-left-lg {
    border-left: 1px solid #E9EDF2 !important;
  }

  .border-lg-0 {
    border: 0 !important;
  }

  .border-top-lg-0 {
    border-top: 0 !important;
  }

  .border-right-lg-0 {
    border-right: 0 !important;
  }

  .border-bottom-lg-0 {
    border-bottom: 0 !important;
  }

  .border-left-lg-0 {
    border-left: 0 !important;
  }
}
@media (min-width: 1200px) {
  .border-xl {
    border: 1px solid #E9EDF2 !important;
  }

  .border-top-xl {
    border-top: 1px solid #E9EDF2 !important;
  }

  .border-right-xl {
    border-right: 1px solid #E9EDF2 !important;
  }

  .border-bottom-xl {
    border-bottom: 1px solid #E9EDF2 !important;
  }

  .border-left-xl {
    border-left: 1px solid #E9EDF2 !important;
  }

  .border-xl-0 {
    border: 0 !important;
  }

  .border-top-xl-0 {
    border-top: 0 !important;
  }

  .border-right-xl-0 {
    border-right: 0 !important;
  }

  .border-bottom-xl-0 {
    border-bottom: 0 !important;
  }

  .border-left-xl-0 {
    border-left: 0 !important;
  }
}
@media (min-width: 1366px) {
  .border-xxl {
    border: 1px solid #E9EDF2 !important;
  }

  .border-top-xxl {
    border-top: 1px solid #E9EDF2 !important;
  }

  .border-right-xxl {
    border-right: 1px solid #E9EDF2 !important;
  }

  .border-bottom-xxl {
    border-bottom: 1px solid #E9EDF2 !important;
  }

  .border-left-xxl {
    border-left: 1px solid #E9EDF2 !important;
  }

  .border-xxl-0 {
    border: 0 !important;
  }

  .border-top-xxl-0 {
    border-top: 0 !important;
  }

  .border-right-xxl-0 {
    border-right: 0 !important;
  }

  .border-bottom-xxl-0 {
    border-bottom: 0 !important;
  }

  .border-left-xxl-0 {
    border-left: 0 !important;
  }
}
.border-primary {
  border-color: #4AA2EE !important;
}

.border-left-primary {
  border-left-color: #4AA2EE !important;
}

.border-right-primary {
  border-right-color: #4AA2EE !important;
}

.border-top-primary {
  border-top-color: #4AA2EE !important;
}

.border-bottom-primary {
  border-bottom-color: #4AA2EE !important;
}

.border-secondary {
  border-color: #868e96 !important;
}

.border-left-secondary {
  border-left-color: #868e96 !important;
}

.border-right-secondary {
  border-right-color: #868e96 !important;
}

.border-top-secondary {
  border-top-color: #868e96 !important;
}

.border-bottom-secondary {
  border-bottom-color: #868e96 !important;
}

.border-success {
  border-color: #77c13a !important;
}

.border-left-success {
  border-left-color: #77c13a !important;
}

.border-right-success {
  border-right-color: #77c13a !important;
}

.border-top-success {
  border-top-color: #77c13a !important;
}

.border-bottom-success {
  border-bottom-color: #77c13a !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-left-info {
  border-left-color: #17a2b8 !important;
}

.border-right-info {
  border-right-color: #17a2b8 !important;
}

.border-top-info {
  border-top-color: #17a2b8 !important;
}

.border-bottom-info {
  border-bottom-color: #17a2b8 !important;
}

.border-warning {
  border-color: #E4A93C !important;
}

.border-left-warning {
  border-left-color: #E4A93C !important;
}

.border-right-warning {
  border-right-color: #E4A93C !important;
}

.border-top-warning {
  border-top-color: #E4A93C !important;
}

.border-bottom-warning {
  border-bottom-color: #E4A93C !important;
}

.border-danger {
  border-color: #d9534f !important;
}

.border-left-danger {
  border-left-color: #d9534f !important;
}

.border-right-danger {
  border-right-color: #d9534f !important;
}

.border-top-danger {
  border-top-color: #d9534f !important;
}

.border-bottom-danger {
  border-bottom-color: #d9534f !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-left-light {
  border-left-color: #f8f9fa !important;
}

.border-right-light {
  border-right-color: #f8f9fa !important;
}

.border-top-light {
  border-top-color: #f8f9fa !important;
}

.border-bottom-light {
  border-bottom-color: #f8f9fa !important;
}

.border-dark {
  border-color: #303840 !important;
}

.border-left-dark {
  border-left-color: #303840 !important;
}

.border-right-dark {
  border-right-color: #303840 !important;
}

.border-top-dark {
  border-top-color: #303840 !important;
}

.border-bottom-dark {
  border-bottom-color: #303840 !important;
}

.border-black {
  border-color: #272C33 !important;
}

.border-left-black {
  border-left-color: #272C33 !important;
}

.border-right-black {
  border-right-color: #272C33 !important;
}

.border-top-black {
  border-top-color: #272C33 !important;
}

.border-bottom-black {
  border-bottom-color: #272C33 !important;
}

.border-accent {
  border-color: #00BCC2 !important;
}

.border-left-accent {
  border-left-color: #00BCC2 !important;
}

.border-right-accent {
  border-right-color: #00BCC2 !important;
}

.border-top-accent {
  border-top-color: #00BCC2 !important;
}

.border-bottom-accent {
  border-bottom-color: #00BCC2 !important;
}

.border-accent-red {
  border-color: #ED0B4C !important;
}

.border-left-accent-red {
  border-left-color: #ED0B4C !important;
}

.border-right-accent-red {
  border-right-color: #ED0B4C !important;
}

.border-top-accent-red {
  border-top-color: #ED0B4C !important;
}

.border-bottom-accent-red {
  border-bottom-color: #ED0B4C !important;
}

.border-accent-yellow {
  border-color: #E4A93C !important;
}

.border-left-accent-yellow {
  border-left-color: #E4A93C !important;
}

.border-right-accent-yellow {
  border-right-color: #E4A93C !important;
}

.border-top-accent-yellow {
  border-top-color: #E4A93C !important;
}

.border-bottom-accent-yellow {
  border-bottom-color: #E4A93C !important;
}

.border-accent-dodger-blue {
  border-color: #5567FF !important;
}

.border-left-accent-dodger-blue {
  border-left-color: #5567FF !important;
}

.border-right-accent-dodger-blue {
  border-right-color: #5567FF !important;
}

.border-top-accent-dodger-blue {
  border-top-color: #5567FF !important;
}

.border-bottom-accent-dodger-blue {
  border-bottom-color: #5567FF !important;
}

.border-accent-pickled-bluewood {
  border-color: #303956 !important;
}

.border-left-accent-pickled-bluewood {
  border-left-color: #303956 !important;
}

.border-right-accent-pickled-bluewood {
  border-right-color: #303956 !important;
}

.border-top-accent-pickled-bluewood {
  border-top-color: #303956 !important;
}

.border-bottom-accent-pickled-bluewood {
  border-bottom-color: #303956 !important;
}

.border-accent-electric-violet {
  border-color: #9C42FF !important;
}

.border-left-accent-electric-violet {
  border-left-color: #9C42FF !important;
}

.border-right-accent-electric-violet {
  border-right-color: #9C42FF !important;
}

.border-top-accent-electric-violet {
  border-top-color: #9C42FF !important;
}

.border-bottom-accent-electric-violet {
  border-bottom-color: #9C42FF !important;
}

.border-primary-purple {
  border-color: #824EE1 !important;
}

.border-left-primary-purple {
  border-left-color: #824EE1 !important;
}

.border-right-primary-purple {
  border-right-color: #824EE1 !important;
}

.border-top-primary-purple {
  border-top-color: #824EE1 !important;
}

.border-bottom-primary-purple {
  border-bottom-color: #824EE1 !important;
}

.border-primary-red {
  border-color: #ED0B4C !important;
}

.border-left-primary-red {
  border-left-color: #ED0B4C !important;
}

.border-right-primary-red {
  border-right-color: #ED0B4C !important;
}

.border-top-primary-red {
  border-top-color: #ED0B4C !important;
}

.border-bottom-primary-red {
  border-bottom-color: #ED0B4C !important;
}

.border-primary-yellow {
  border-color: #E4A93C !important;
}

.border-left-primary-yellow {
  border-left-color: #E4A93C !important;
}

.border-right-primary-yellow {
  border-right-color: #E4A93C !important;
}

.border-top-primary-yellow {
  border-top-color: #E4A93C !important;
}

.border-bottom-primary-yellow {
  border-bottom-color: #E4A93C !important;
}

.border-primary-light {
  border-color: #99ccff !important;
}

.border-left-primary-light {
  border-left-color: #99ccff !important;
}

.border-right-primary-light {
  border-right-color: #99ccff !important;
}

.border-top-primary-light {
  border-top-color: #99ccff !important;
}

.border-bottom-primary-light {
  border-bottom-color: #99ccff !important;
}

.border-primary-dodger-blue {
  border-color: #5567FF !important;
}

.border-left-primary-dodger-blue {
  border-left-color: #5567FF !important;
}

.border-right-primary-dodger-blue {
  border-right-color: #5567FF !important;
}

.border-top-primary-dodger-blue {
  border-top-color: #5567FF !important;
}

.border-bottom-primary-dodger-blue {
  border-bottom-color: #5567FF !important;
}

.border-primary-pickled-bluewood {
  border-color: #303956 !important;
}

.border-left-primary-pickled-bluewood {
  border-left-color: #303956 !important;
}

.border-right-primary-pickled-bluewood {
  border-right-color: #303956 !important;
}

.border-top-primary-pickled-bluewood {
  border-top-color: #303956 !important;
}

.border-bottom-primary-pickled-bluewood {
  border-bottom-color: #303956 !important;
}

.border-bottom-white {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.order-0 {
  order: 0 !important;
}

@media (min-width: 576px) {
  .order-sm-0 {
    order: 0 !important;
  }
}
@media (min-width: 768px) {
  .order-md-0 {
    order: 0 !important;
  }
}
@media (min-width: 992px) {
  .order-lg-0 {
    order: 0 !important;
  }
}
@media (min-width: 1200px) {
  .order-xl-0 {
    order: 0 !important;
  }
}
@media (min-width: 1366px) {
  .order-xxl-0 {
    order: 0 !important;
  }
}
.order-1 {
  order: 1 !important;
}

@media (min-width: 576px) {
  .order-sm-1 {
    order: 1 !important;
  }
}
@media (min-width: 768px) {
  .order-md-1 {
    order: 1 !important;
  }
}
@media (min-width: 992px) {
  .order-lg-1 {
    order: 1 !important;
  }
}
@media (min-width: 1200px) {
  .order-xl-1 {
    order: 1 !important;
  }
}
@media (min-width: 1366px) {
  .order-xxl-1 {
    order: 1 !important;
  }
}
.order-2 {
  order: 2 !important;
}

@media (min-width: 576px) {
  .order-sm-2 {
    order: 2 !important;
  }
}
@media (min-width: 768px) {
  .order-md-2 {
    order: 2 !important;
  }
}
@media (min-width: 992px) {
  .order-lg-2 {
    order: 2 !important;
  }
}
@media (min-width: 1200px) {
  .order-xl-2 {
    order: 2 !important;
  }
}
@media (min-width: 1366px) {
  .order-xxl-2 {
    order: 2 !important;
  }
}
.position-close-4pt {
  position: absolute;
  right: 4px;
  top: 4px;
  display: flex;
}

.text-white-100 {
  color: white !important;
}

.text-shadow {
  text-shadow: rgba(39, 44, 51, 0.2) 0px 9px 10px;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  white-space: nowrap;
}

.text-headings {
  font-family: "Exo 2", "Helvetica Neue", Arial, sans-serif !important;
}

.lh-24pt {
  line-height: 1.5rem;
}

.bg-dark-blue {
  background-image: #0C2440;
}

.bg-purple-gradient {
  background-image: linear-gradient(180deg, #9A55FE 0%, #753EFD 100%);
}

.bg-black,
.bg-black-100 {
  background-color: #272C33 !important;
}

.bg-black-50 {
  background-color: rgba(39, 44, 51, 0.5) !important;
}

.bg-black-20 {
  background-color: rgba(39, 44, 51, 0.2) !important;
}

.p-card-header {
  padding: 1rem 1rem;
}

.link-date {
  color: rgba(39, 44, 51, 0.7);
  position: relative;
  overflow: hidden;
  background: rgba(74, 162, 238, 0.05);
  padding: 2px 4px;
  border-radius: 2px;
}
.link-date::after {
  content: "................................................................";
  color: rgba(39, 44, 51, 0.4);
  font-size: 0.75rem;
  position: absolute;
  left: 0;
  overflow: hidden;
  width: 100%;
  top: 0.525rem;
  white-space: nowrap;
}
.link-date:hover {
  text-decoration: none;
}

.material-icons {
  vertical-align: middle;
}

.icon-16pt {
  font-size: 1rem !important;
}
.icon-24pt {
  font-size: 1.5rem !important;
}
.icon-32pt {
  font-size: 2rem !important;
}
.icon-40pt {
  font-size: 2.5rem !important;
}
.icon-48pt {
  font-size: 3rem !important;
}
.icon-64pt {
  font-size: 4rem !important;
}

.icon-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  padding: 0;
}
.icon-holder[href]:hover {
  text-decoration: none;
}
.icon-holder--small {
  width: 30px;
  height: 30px;
}
.icon-holder--default {
  background-color: #e9ecef;
}

.icon-holder--primary {
  color: #fff;
  background-image: none;
  background-color: #4AA2EE;
  border: 1px solid #4AA2EE;
}
.icon-holder--primary:hover {
  color: #4AA2EE;
  background-image: none;
  background-color: transparent;
  border: 1px solid #4AA2EE;
}
.icon-holder--primary:hover:hover {
  color: #fff;
  background-color: #4AA2EE;
  border-color: #4AA2EE;
}
.icon-holder--outline-primary {
  color: #4AA2EE;
  background-image: none;
  background-color: transparent;
  border: 1px solid #4AA2EE;
}
.icon-holder--outline-primary:hover {
  color: #fff;
  background-color: #4AA2EE;
  border-color: #4AA2EE;
}
.icon-holder--secondary {
  color: #fff;
  background-image: none;
  background-color: #868e96;
  border: 1px solid #868e96;
}
.icon-holder--secondary:hover {
  color: #868e96;
  background-image: none;
  background-color: transparent;
  border: 1px solid #868e96;
}
.icon-holder--secondary:hover:hover {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}
.icon-holder--outline-secondary {
  color: #868e96;
  background-image: none;
  background-color: transparent;
  border: 1px solid #868e96;
}
.icon-holder--outline-secondary:hover {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}
.icon-holder--success {
  color: #fff;
  background-image: none;
  background-color: #77c13a;
  border: 1px solid #77c13a;
}
.icon-holder--success:hover {
  color: #77c13a;
  background-image: none;
  background-color: transparent;
  border: 1px solid #77c13a;
}
.icon-holder--success:hover:hover {
  color: #fff;
  background-color: #77c13a;
  border-color: #77c13a;
}
.icon-holder--outline-success {
  color: #77c13a;
  background-image: none;
  background-color: transparent;
  border: 1px solid #77c13a;
}
.icon-holder--outline-success:hover {
  color: #fff;
  background-color: #77c13a;
  border-color: #77c13a;
}
.icon-holder--info {
  color: #fff;
  background-image: none;
  background-color: #17a2b8;
  border: 1px solid #17a2b8;
}
.icon-holder--info:hover {
  color: #17a2b8;
  background-image: none;
  background-color: transparent;
  border: 1px solid #17a2b8;
}
.icon-holder--info:hover:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.icon-holder--outline-info {
  color: #17a2b8;
  background-image: none;
  background-color: transparent;
  border: 1px solid #17a2b8;
}
.icon-holder--outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.icon-holder--warning {
  color: #fff;
  background-image: none;
  background-color: #E4A93C;
  border: 1px solid #E4A93C;
}
.icon-holder--warning:hover {
  color: #E4A93C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #E4A93C;
}
.icon-holder--warning:hover:hover {
  color: #fff;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.icon-holder--outline-warning {
  color: #E4A93C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #E4A93C;
}
.icon-holder--outline-warning:hover {
  color: #fff;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.icon-holder--danger {
  color: #fff;
  background-image: none;
  background-color: #d9534f;
  border: 1px solid #d9534f;
}
.icon-holder--danger:hover {
  color: #d9534f;
  background-image: none;
  background-color: transparent;
  border: 1px solid #d9534f;
}
.icon-holder--danger:hover:hover {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.icon-holder--outline-danger {
  color: #d9534f;
  background-image: none;
  background-color: transparent;
  border: 1px solid #d9534f;
}
.icon-holder--outline-danger:hover {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.icon-holder--light {
  color: #fff;
  background-image: none;
  background-color: #f8f9fa;
  border: 1px solid #f8f9fa;
}
.icon-holder--light:hover {
  color: #f8f9fa;
  background-image: none;
  background-color: transparent;
  border: 1px solid #f8f9fa;
}
.icon-holder--light:hover:hover {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.icon-holder--outline-light {
  color: #f8f9fa;
  background-image: none;
  background-color: transparent;
  border: 1px solid #f8f9fa;
}
.icon-holder--outline-light:hover {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.icon-holder--dark {
  color: #fff;
  background-image: none;
  background-color: #303840;
  border: 1px solid #303840;
}
.icon-holder--dark:hover {
  color: #303840;
  background-image: none;
  background-color: transparent;
  border: 1px solid #303840;
}
.icon-holder--dark:hover:hover {
  color: #fff;
  background-color: #303840;
  border-color: #303840;
}
.icon-holder--outline-dark {
  color: #303840;
  background-image: none;
  background-color: transparent;
  border: 1px solid #303840;
}
.icon-holder--outline-dark:hover {
  color: #fff;
  background-color: #303840;
  border-color: #303840;
}
.icon-holder--black {
  color: #fff;
  background-image: none;
  background-color: #272C33;
  border: 1px solid #272C33;
}
.icon-holder--black:hover {
  color: #272C33;
  background-image: none;
  background-color: transparent;
  border: 1px solid #272C33;
}
.icon-holder--black:hover:hover {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}
.icon-holder--outline-black {
  color: #272C33;
  background-image: none;
  background-color: transparent;
  border: 1px solid #272C33;
}
.icon-holder--outline-black:hover {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}
.icon-holder--accent {
  color: #fff;
  background-image: none;
  background-color: #00BCC2;
  border: 1px solid #00BCC2;
}
.icon-holder--accent:hover {
  color: #00BCC2;
  background-image: none;
  background-color: transparent;
  border: 1px solid #00BCC2;
}
.icon-holder--accent:hover:hover {
  color: #fff;
  background-color: #00BCC2;
  border-color: #00BCC2;
}
.icon-holder--outline-accent {
  color: #00BCC2;
  background-image: none;
  background-color: transparent;
  border: 1px solid #00BCC2;
}
.icon-holder--outline-accent:hover {
  color: #fff;
  background-color: #00BCC2;
  border-color: #00BCC2;
}
.icon-holder--accent-red {
  color: #fff;
  background-image: none;
  background-color: #ED0B4C;
  border: 1px solid #ED0B4C;
}
.icon-holder--accent-red:hover {
  color: #ED0B4C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #ED0B4C;
}
.icon-holder--accent-red:hover:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.icon-holder--outline-accent-red {
  color: #ED0B4C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #ED0B4C;
}
.icon-holder--outline-accent-red:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.icon-holder--accent-yellow {
  color: #fff;
  background-image: none;
  background-color: #E4A93C;
  border: 1px solid #E4A93C;
}
.icon-holder--accent-yellow:hover {
  color: #E4A93C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #E4A93C;
}
.icon-holder--accent-yellow:hover:hover {
  color: #fff;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.icon-holder--outline-accent-yellow {
  color: #E4A93C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #E4A93C;
}
.icon-holder--outline-accent-yellow:hover {
  color: #fff;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.icon-holder--accent-dodger-blue {
  color: #fff;
  background-image: none;
  background-color: #5567FF;
  border: 1px solid #5567FF;
}
.icon-holder--accent-dodger-blue:hover {
  color: #5567FF;
  background-image: none;
  background-color: transparent;
  border: 1px solid #5567FF;
}
.icon-holder--accent-dodger-blue:hover:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.icon-holder--outline-accent-dodger-blue {
  color: #5567FF;
  background-image: none;
  background-color: transparent;
  border: 1px solid #5567FF;
}
.icon-holder--outline-accent-dodger-blue:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.icon-holder--accent-pickled-bluewood {
  color: #fff;
  background-image: none;
  background-color: #303956;
  border: 1px solid #303956;
}
.icon-holder--accent-pickled-bluewood:hover {
  color: #303956;
  background-image: none;
  background-color: transparent;
  border: 1px solid #303956;
}
.icon-holder--accent-pickled-bluewood:hover:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.icon-holder--outline-accent-pickled-bluewood {
  color: #303956;
  background-image: none;
  background-color: transparent;
  border: 1px solid #303956;
}
.icon-holder--outline-accent-pickled-bluewood:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.icon-holder--accent-electric-violet {
  color: #fff;
  background-image: none;
  background-color: #9C42FF;
  border: 1px solid #9C42FF;
}
.icon-holder--accent-electric-violet:hover {
  color: #9C42FF;
  background-image: none;
  background-color: transparent;
  border: 1px solid #9C42FF;
}
.icon-holder--accent-electric-violet:hover:hover {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}
.icon-holder--outline-accent-electric-violet {
  color: #9C42FF;
  background-image: none;
  background-color: transparent;
  border: 1px solid #9C42FF;
}
.icon-holder--outline-accent-electric-violet:hover {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}
.icon-holder--primary-purple {
  color: #fff;
  background-image: none;
  background-color: #824EE1;
  border: 1px solid #824EE1;
}
.icon-holder--primary-purple:hover {
  color: #824EE1;
  background-image: none;
  background-color: transparent;
  border: 1px solid #824EE1;
}
.icon-holder--primary-purple:hover:hover {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}
.icon-holder--outline-primary-purple {
  color: #824EE1;
  background-image: none;
  background-color: transparent;
  border: 1px solid #824EE1;
}
.icon-holder--outline-primary-purple:hover {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}
.icon-holder--primary-red {
  color: #fff;
  background-image: none;
  background-color: #ED0B4C;
  border: 1px solid #ED0B4C;
}
.icon-holder--primary-red:hover {
  color: #ED0B4C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #ED0B4C;
}
.icon-holder--primary-red:hover:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.icon-holder--outline-primary-red {
  color: #ED0B4C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #ED0B4C;
}
.icon-holder--outline-primary-red:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.icon-holder--primary-yellow {
  color: #fff;
  background-image: none;
  background-color: #E4A93C;
  border: 1px solid #E4A93C;
}
.icon-holder--primary-yellow:hover {
  color: #E4A93C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #E4A93C;
}
.icon-holder--primary-yellow:hover:hover {
  color: #fff;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.icon-holder--outline-primary-yellow {
  color: #E4A93C;
  background-image: none;
  background-color: transparent;
  border: 1px solid #E4A93C;
}
.icon-holder--outline-primary-yellow:hover {
  color: #fff;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.icon-holder--primary-light {
  color: #fff;
  background-image: none;
  background-color: #99ccff;
  border: 1px solid #99ccff;
}
.icon-holder--primary-light:hover {
  color: #99ccff;
  background-image: none;
  background-color: transparent;
  border: 1px solid #99ccff;
}
.icon-holder--primary-light:hover:hover {
  color: #fff;
  background-color: #99ccff;
  border-color: #99ccff;
}
.icon-holder--outline-primary-light {
  color: #99ccff;
  background-image: none;
  background-color: transparent;
  border: 1px solid #99ccff;
}
.icon-holder--outline-primary-light:hover {
  color: #fff;
  background-color: #99ccff;
  border-color: #99ccff;
}
.icon-holder--primary-dodger-blue {
  color: #fff;
  background-image: none;
  background-color: #5567FF;
  border: 1px solid #5567FF;
}
.icon-holder--primary-dodger-blue:hover {
  color: #5567FF;
  background-image: none;
  background-color: transparent;
  border: 1px solid #5567FF;
}
.icon-holder--primary-dodger-blue:hover:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.icon-holder--outline-primary-dodger-blue {
  color: #5567FF;
  background-image: none;
  background-color: transparent;
  border: 1px solid #5567FF;
}
.icon-holder--outline-primary-dodger-blue:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.icon-holder--primary-pickled-bluewood {
  color: #fff;
  background-image: none;
  background-color: #303956;
  border: 1px solid #303956;
}
.icon-holder--primary-pickled-bluewood:hover {
  color: #303956;
  background-image: none;
  background-color: transparent;
  border: 1px solid #303956;
}
.icon-holder--primary-pickled-bluewood:hover:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.icon-holder--outline-primary-pickled-bluewood {
  color: #303956;
  background-image: none;
  background-color: transparent;
  border: 1px solid #303956;
}
.icon-holder--outline-primary-pickled-bluewood:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.icon-holder--outline-muted {
  color: rgba(39, 44, 51, 0.5);
  background-image: none;
  background-color: transparent;
  border: 1px solid rgba(39, 44, 51, 0.5);
}
.icon-holder--outline-muted:hover {
  color: #fff;
  background-color: rgba(39, 44, 51, 0.5);
  border-color: rgba(39, 44, 51, 0.5);
}
.icon-holder--light, .icon-holder--light:hover, .icon-holder--light:focus {
  color: rgba(39, 44, 51, 0.7);
}

html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

b,
strong {
  font-weight: 500;
}

.display-1 {
  line-height: 1.25;
  font-family: "Exo 2", "Helvetica Neue", Arial, sans-serif;
}

h1, .h1 {
  line-height: 1.5;
}

h2, .h2 {
  line-height: 1.5;
}

h3, .h3 {
  line-height: 1.414;
}

h4, .h4 {
  line-height: 1.414;
}

h5, .h5 {
  line-height: 1.5;
  text-transform: uppercase;
  color: #272C33;
}

.headings-family-exo2 h1, .headings-family-exo2 h2, .headings-family-exo2 h3, .headings-family-exo2 h4, .headings-family-exo2 h5, .headings-family-exo2 h6,
.headings-family-exo2 .h1, .headings-family-exo2 .h2, .headings-family-exo2 .h3, .headings-family-exo2 .h4, .headings-family-exo2 .h5, .headings-family-exo2 .h6 {
  font-family: "Exo 2", "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
}

.headings-family-lato h1, .headings-family-lato h2, .headings-family-lato h3, .headings-family-lato h4, .headings-family-lato h5, .headings-family-lato h6,
.headings-family-lato .h1, .headings-family-lato .h2, .headings-family-lato .h3, .headings-family-lato .h4, .headings-family-lato .h5, .headings-family-lato .h6 {
  font-family: "Lato", "Helvetica Neue", Arial, sans-serif;
  font-weight: 700;
}

.headings-family-oswald h1, .headings-family-oswald h2, .headings-family-oswald h3, .headings-family-oswald h4, .headings-family-oswald h5, .headings-family-oswald h6,
.headings-family-oswald .h1, .headings-family-oswald .h2, .headings-family-oswald .h3, .headings-family-oswald .h4, .headings-family-oswald .h5, .headings-family-oswald .h6 {
  font-family: "Oswald", "Helvetica Neue", Arial, sans-serif;
  font-weight: 500;
}

.lead {
  line-height: 1.556;
}
.lead:last-child {
  margin-bottom: 0;
}

.blockquote {
  padding: 0.5rem 1rem;
  border-left: 0.25rem solid #E9EDF2;
}

.blockquote--reverse {
  border-left: none;
  border-right: 0.25rem solid #E9EDF2;
}

small,
.small {
  line-height: 1.5;
}

a:hover {
  text-decoration: none;
}

.font-size-16pt {
  font-size: 1rem !important;
}

.font-size-20pt {
  font-size: 1.25rem !important;
}

.font-size-24pt {
  font-size: 1.5rem !important;
}

.font-size-32pt {
  font-size: 2rem !important;
}

.font-size-48pt {
  font-size: 3rem !important;
}

.font-size-56pt {
  font-size: 3.5rem !important;
}

.font-size-64pt {
  font-size: 4rem !important;
}

.measure-paragraph {
  max-width: 456px;
}

.measure-paragraph-max {
  max-width: 536px;
}

.measure-lead {
  max-width: 536px;
}

.measure-lead-max {
  max-width: 616px;
}

.measure-hero-lead {
  max-width: 696px;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.page__container {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

@media (max-width: 767.98px) {
  .has-drawer-opened .mdk-header-layout__content {
    z-index: 2;
  }
}
.mdk-header-layout {
  overflow: visible;
}

@media (min-width: 768px) {
  .mdk-drawer-layout .container,
.mdk-drawer-layout .container-fluid,
.mdk-drawer-layout .container-sm,
.mdk-drawer-layout .container-md,
.mdk-drawer-layout .container-lg,
.mdk-drawer-layout .container-xl {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  .mdk-drawer-layout .container,
.mdk-drawer-layout .container-fluid,
.mdk-drawer-layout .container-sm,
.mdk-drawer-layout .container-md,
.mdk-drawer-layout .container-lg,
.mdk-drawer-layout .container-xl {
    max-width: 960px;
  }
}

.sub-layout .mdk-header-layout__content {
  height: 100%;
}
.sub-layout .page-content {
  display: flex;
  flex-direction: column;
}
.sub-layout #page-content {
  height: 100%;
}

@media (min-width: 992px) {
  .sub-layout-drawer .mdk-drawer__content {
    position: absolute;
  }
}
html,
body {
  min-height: 100vh;
}

body,
.mdk-header-layout,
.page-content {
  display: flex;
  flex-direction: column;
}

.mdk-header-layout,
.mdk-drawer-layout,
.page-content {
  flex: 1 1 0%;
}

.mdk-drawer-layout__content {
  min-height: 100vh;
}

.mdk-header-layout .mdk-drawer-layout__content {
  min-height: calc(100vh - 64px);
}

.sidebar {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-wrap: nowrap;
}

.sidebar-brand {
  flex-shrink: 0;
}

@media (min-width: 1200px) {
  .layout-app .container-fluid, .layout-app .container-sm, .layout-app .container-md, .layout-app .container-lg, .layout-app .container-xl {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.mdk-drawer[data-persistent].layout-mini__drawer,
.layout-mini__drawer .mdk-drawer__content {
  width: 64px;
}

.layout-mini .sidebar-secondary {
  transition: transform 0.2s ease;
  transform: translate3d(-100%, 0, 0) /* rtl:ignore */;
  box-shadow: none;
}

.layout-mini-secondary .sidebar-secondary,
.layout-mini--open .sidebar-secondary {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
}

.layout-mini__drawer[data-position=right] .sidebar-secondary {
  transform: translate3d(100%, 0, 0) /* rtl:ignore */;
}

@media (min-width: 1200px) {
  .layout-mini .page__container,
.layout-mini-secondary .page__container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.layout-mini .mdk-drawer[data-persistent].layout-mini-secondary__drawer,
.layout-mini .layout-mini-secondary__drawer .mdk-drawer__content,
.layout-mini-secondary .mdk-drawer[data-persistent].layout-mini-secondary__drawer,
.layout-mini-secondary .layout-mini-secondary__drawer .mdk-drawer__content {
  width: 320px;
}
.layout-mini .sidebar-mini,
.layout-mini-secondary .sidebar-mini {
  width: 64px;
  position: relative;
  z-index: 1;
}
.layout-mini .sidebar-mini.sidebar-light .active.show .sidebar-menu-icon,
.layout-mini-secondary .sidebar-mini.sidebar-light .active.show .sidebar-menu-icon {
  color: #303840;
}
.layout-mini .sidebar-mini.sidebar-dark .active.show .sidebar-menu-icon,
.layout-mini-secondary .sidebar-mini.sidebar-dark .active.show .sidebar-menu-icon {
  color: white;
}
.layout-mini .sidebar-mini .sidebar-menu-text,
.layout-mini .sidebar-mini .sidebar-badge,
.layout-mini .sidebar-mini .sidebar-menu-toggle-icon,
.layout-mini-secondary .sidebar-mini .sidebar-menu-text,
.layout-mini-secondary .sidebar-mini .sidebar-badge,
.layout-mini-secondary .sidebar-mini .sidebar-menu-toggle-icon {
  display: none;
}
.layout-mini .sidebar-mini .sidebar-menu-item,
.layout-mini-secondary .sidebar-mini .sidebar-menu-item {
  margin: 0;
  border-radius: 0;
}
.layout-mini .sidebar-mini .sidebar-menu-button,
.layout-mini-secondary .sidebar-mini .sidebar-menu-button {
  flex-direction: column;
  padding: 0.5rem 0;
}
.layout-mini .sidebar-mini .sidebar-menu-initials,
.layout-mini-secondary .sidebar-mini .sidebar-menu-initials {
  content: attr(data-initials);
  display: block;
  visibility: visible;
}
.layout-mini .sidebar-mini .sidebar-menu-button,
.layout-mini-secondary .sidebar-mini .sidebar-menu-button {
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.layout-mini .sidebar-mini .sidebar-menu-icon,
.layout-mini-secondary .sidebar-mini .sidebar-menu-icon {
  margin: 0;
}
.layout-mini .sidebar-secondary,
.layout-mini-secondary .sidebar-secondary {
  width: 256px;
  position: absolute;
  top: 0;
  left: 64px /* rtl:ignore */;
}
.layout-mini__drawer[data-position=right] .sidebar-secondary,
.layout-mini-secondary__drawer[data-position=right] .sidebar-secondary {
  left: auto/* rtl:ignore */;
  right: 64px /* rtl:ignore */;
}
.layout-mini__drawer .mdk-drawer__content,
.layout-mini-secondary__drawer .mdk-drawer__content {
  display: flex;
  z-index: 0;
}
@media (min-width: 576px) {
  .layout-mini .layout-mini__d-none,
.layout-mini .layout-mini-secondary__d-none,
.layout-mini-secondary .layout-mini__d-none,
.layout-mini-secondary .layout-mini-secondary__d-none {
    display: none;
  }
}
.layout-mini--open .sidebar-secondary,
.layout-mini--open .layout-mini__drawer[data-position=right] .sidebar-secondary,
.layout-mini-secondary--open .sidebar-secondary,
.layout-mini-secondary--open .layout-mini__drawer[data-position=right] .sidebar-secondary {
  transform: translate3d(0, 0, 0) /* rtl:ignore */;
}

@media (min-width: 1200px) {
  .layout-mini-secondary .container {
    max-width: 936px;
  }
}
@media (min-width: 1200px) {
  .layout-compact .container-fluid, .layout-compact .container-sm, .layout-compact .container-md, .layout-compact .container-lg, .layout-compact .container-xl {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.layout-compact .mdk-drawer[data-persistent].layout-compact__drawer, .layout-compact__drawer .mdk-drawer__content {
  width: 120px;
}
.layout-compact .sidebar-secondary {
  left: 120px /* rtl:ignore */;
  transition: transform 0.2s ease;
  transform: translate3d(-100%, 0, 0) /* rtl:ignore */;
  width: 256px;
  position: absolute;
  top: 0;
  box-shadow: none;
}
.layout-compact__drawer[data-position=right] .sidebar-secondary {
  transform: translate3d(100%, 0, 0) /* rtl:ignore */;
  left: auto/* rtl:ignore */;
  right: 120px /* rtl:ignore */;
}
.layout-compact__drawer .mdk-drawer__content {
  display: flex;
  z-index: 0;
}
.layout-compact .sidebar-mini {
  width: 120px;
  position: relative;
  z-index: 1;
}
.layout-compact .sidebar-mini.sidebar-light .active.show .sidebar-menu-icon {
  color: #303840;
}
.layout-compact .sidebar-mini.sidebar-dark .active.show .sidebar-menu-icon {
  color: white;
}
.layout-compact .sidebar-mini .sidebar-menu-toggle-icon {
  display: none;
}
.layout-compact .sidebar-mini .sidebar-menu-item {
  margin: 0;
  border-radius: 0;
}
.layout-compact .sidebar-mini .sidebar-menu-initials {
  content: attr(data-initials);
  display: block;
  visibility: visible;
}
.layout-compact .sidebar-mini .sidebar-menu-button {
  flex-direction: column;
  padding: 1.25rem 0;
  justify-content: center;
  font-size: 0.8125rem;
  line-height: 1rem;
}
.layout-compact .sidebar-mini .sidebar-menu-icon {
  margin: 0 0 0.25rem;
  font-size: 2rem;
}
@media (min-width: 576px) {
  .layout-compact .layout-compact__d-none {
    display: none;
  }
}
.layout-compact--open .sidebar-secondary,
.layout-compact--open .layout-compact__drawer[data-position=right] .sidebar-secondary {
  transform: translate3d(0, 0, 0);
}
.layout-compact--open .sidebar-secondary {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
}

@media (min-width: 992px) {
  .layout-boxed > .mdk-drawer-layout {
    padding: 1rem 0;
  }
}
.layout-boxed .page-content {
  overflow: hidden;
}
@media (min-width: 992px) {
  .layout-boxed .page-content {
    /* @alternate */
    box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
    margin: 0 1rem 0 0;
    border-radius: 10px;
  }
}
@media (min-width: 992px) {
  .layout-boxed #default-drawer .mdk-drawer__content {
    padding-top: 1rem;
  }
  .layout-boxed #default-drawer .sidebar {
    background-color: transparent !important;
    box-shadow: none;
    border: none;
    border-radius: 10px;
    padding: 0 1rem 0 0;
  }
  .layout-boxed #default-drawer .sidebar .open {
    background: transparent !important;
  }
}
.layout-boxed #default-drawer .sidebar-brand {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
  margin-bottom: 2rem;
  height: 64px;
  flex-direction: row;
  align-items: center;
  padding: 0;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
}
@media (min-width: 992px) {
  .layout-boxed #default-drawer .sidebar-brand {
    border-radius: 10px;
    margin-left: 1rem;
  }
}
.layout-boxed #default-drawer .sidebar-brand-icon {
  margin-left: 1rem;
  margin-right: 0.5rem;
  margin-bottom: 0;
  width: 2.5rem;
}
.layout-boxed #default-drawer .sidebar-brand-dark {
  color: white;
}
@media (min-width: 992px) {
  .layout-boxed #default-drawer .sidebar-menu > .sidebar-menu-item {
    margin-right: 0;
  }
}

.badge {
  display: inline-flex;
  align-items: center;
  font-weight: normal;
}
.badge .material-icons {
  font-size: inherit;
}
.badge > .material-icons {
  margin-right: 5px;
}

.badge-notifications {
  border-radius: 100px;
  padding: 0 0.5rem;
  line-height: 1rem;
  font-weight: 700;
}

.badge-filter a {
  color: inherit;
  padding: 0 2px 0 0;
  opacity: 0.7;
}
.badge-filter a:hover {
  opacity: 1;
}
.badge-filter_name {
  opacity: 0.7;
  padding-right: 5px;
}

.badge-lg {
  padding: 6px 12px;
}

.badge-success,
.badge-warning {
  color: #fff;
}

.breadcrumb {
  align-items: center;
  font-size: 0.8125rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Exo 2", "Helvetica Neue", Arial, sans-serif;
  letter-spacing: 2px;
}
.container > .breadcrumb:first-of-type {
  padding-top: 1rem;
}

.text-breadcrumb {
  font-size: 0.8125rem;
  font-weight: 600;
  text-transform: uppercase;
  font-family: "Exo 2", "Helvetica Neue", Arial, sans-serif;
  letter-spacing: 2px;
  color: rgba(39, 44, 51, 0.5);
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  color: rgba(39, 44, 51, 0.5);
}
.breadcrumb-item > a {
  color: inherit;
  display: flex;
  align-items: center;
}
.breadcrumb-item + .breadcrumb-item::before {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  content: "keyboard_arrow_right";
}

.alert-soft-primary {
  color: #4AA2EE;
  background-color: rgba(74, 162, 238, 0.05);
  border-color: #cce5fa;
}
.alert-soft-primary hr {
  border-top-color: #b5d9f8;
}
.alert-soft-primary .alert-link {
  color: #1b8aea;
}

.alert-soft-secondary {
  color: #868e96;
  background-color: rgba(134, 142, 150, 0.05);
  border-color: #dddfe2;
}
.alert-soft-secondary hr {
  border-top-color: #cfd2d6;
}
.alert-soft-secondary .alert-link {
  color: #6c757d;
}

.alert-soft-success {
  color: #77c13a;
  background-color: rgba(119, 193, 58, 0.05);
  border-color: #d9eec8;
}
.alert-soft-success hr {
  border-top-color: #cce8b5;
}
.alert-soft-success .alert-link {
  color: #5f9a2e;
}

.alert-soft-info {
  color: #17a2b8;
  background-color: rgba(23, 162, 184, 0.05);
  border-color: #bee5eb;
}
.alert-soft-info hr {
  border-top-color: #abdde5;
}
.alert-soft-info .alert-link {
  color: #117a8b;
}

.alert-soft-warning {
  color: #E4A93C;
  background-color: rgba(228, 169, 60, 0.05);
  border-color: #f7e7c8;
}
.alert-soft-warning hr {
  border-top-color: #f4ddb2;
}
.alert-soft-warning .alert-link {
  color: #d0911d;
}

.alert-soft-danger {
  color: #d9534f;
  background-color: rgba(217, 83, 79, 0.05);
  border-color: #f4cfce;
}
.alert-soft-danger hr {
  border-top-color: #efbbb9;
}
.alert-soft-danger .alert-link {
  color: #c9302c;
}

.alert-soft-light {
  color: #f8f9fa;
  background-color: rgba(248, 249, 250, 0.05);
  border-color: #fdfdfe;
}
.alert-soft-light hr {
  border-top-color: #ececf6;
}
.alert-soft-light .alert-link {
  color: #dae0e5;
}

.alert-soft-dark {
  color: #303840;
  background-color: rgba(48, 56, 64, 0.05);
  border-color: #c5c7ca;
}
.alert-soft-dark hr {
  border-top-color: #b8babe;
}
.alert-soft-dark .alert-link {
  color: #1a1f23;
}

.alert-soft-black {
  color: #272C33;
  background-color: rgba(39, 44, 51, 0.05);
  border-color: #c3c4c6;
}
.alert-soft-black hr {
  border-top-color: #b6b7ba;
}
.alert-soft-black .alert-link {
  color: #111316;
}

.alert-soft-accent {
  color: #00BCC2;
  background-color: rgba(0, 188, 194, 0.05);
  border-color: #b8ecee;
}
.alert-soft-accent hr {
  border-top-color: #a3e6e9;
}
.alert-soft-accent .alert-link {
  color: #008b8f;
}

.alert-soft-accent-red {
  color: #ED0B4C;
  background-color: rgba(237, 11, 76, 0.05);
  border-color: #fabbcd;
}
.alert-soft-accent-red hr {
  border-top-color: #f8a3bc;
}
.alert-soft-accent-red .alert-link {
  color: #bc093c;
}

.alert-soft-accent-yellow {
  color: #E4A93C;
  background-color: rgba(228, 169, 60, 0.05);
  border-color: #f7e7c8;
}
.alert-soft-accent-yellow hr {
  border-top-color: #f4ddb2;
}
.alert-soft-accent-yellow .alert-link {
  color: #d0911d;
}

.alert-soft-accent-dodger-blue {
  color: #5567FF;
  background-color: rgba(85, 103, 255, 0.05);
  border-color: #cfd4ff;
}
.alert-soft-accent-dodger-blue hr {
  border-top-color: #b6bdff;
}
.alert-soft-accent-dodger-blue .alert-link {
  color: #2239ff;
}

.alert-soft-accent-pickled-bluewood {
  color: #303956;
  background-color: rgba(48, 57, 86, 0.05);
  border-color: #c5c8d0;
}
.alert-soft-accent-pickled-bluewood hr {
  border-top-color: #b7bbc5;
}
.alert-soft-accent-pickled-bluewood .alert-link {
  color: #1e2335;
}

.alert-soft-accent-electric-violet {
  color: #9C42FF;
  background-color: rgba(156, 66, 255, 0.05);
  border-color: #e3caff;
}
.alert-soft-accent-electric-violet hr {
  border-top-color: #d6b1ff;
}
.alert-soft-accent-electric-violet .alert-link {
  color: #810fff;
}

.alert-soft-primary-purple {
  color: #824EE1;
  background-color: rgba(130, 78, 225, 0.05);
  border-color: #dccdf7;
}
.alert-soft-primary-purple hr {
  border-top-color: #cdb7f3;
}
.alert-soft-primary-purple .alert-link {
  color: #6425d7;
}

.alert-soft-primary-red {
  color: #ED0B4C;
  background-color: rgba(237, 11, 76, 0.05);
  border-color: #fabbcd;
}
.alert-soft-primary-red hr {
  border-top-color: #f8a3bc;
}
.alert-soft-primary-red .alert-link {
  color: #bc093c;
}

.alert-soft-primary-yellow {
  color: #E4A93C;
  background-color: rgba(228, 169, 60, 0.05);
  border-color: #f7e7c8;
}
.alert-soft-primary-yellow hr {
  border-top-color: #f4ddb2;
}
.alert-soft-primary-yellow .alert-link {
  color: #d0911d;
}

.alert-soft-primary-light {
  color: #99ccff;
  background-color: rgba(153, 204, 255, 0.05);
  border-color: #e2f1ff;
}
.alert-soft-primary-light hr {
  border-top-color: #c9e5ff;
}
.alert-soft-primary-light .alert-link {
  color: #66b3ff;
}

.alert-soft-primary-dodger-blue {
  color: #5567FF;
  background-color: rgba(85, 103, 255, 0.05);
  border-color: #cfd4ff;
}
.alert-soft-primary-dodger-blue hr {
  border-top-color: #b6bdff;
}
.alert-soft-primary-dodger-blue .alert-link {
  color: #2239ff;
}

.alert-soft-primary-pickled-bluewood {
  color: #303956;
  background-color: rgba(48, 57, 86, 0.05);
  border-color: #c5c8d0;
}
.alert-soft-primary-pickled-bluewood hr {
  border-top-color: #b7bbc5;
}
.alert-soft-primary-pickled-bluewood .alert-link {
  color: #1e2335;
}

.alert-light,
.alert-soft-light {
  color: #94979a;
  background-color: #f8f9fa;
  border-color: #e9ecef;
}
.alert-light hr,
.alert-soft-light hr {
  border-top-color: #e9ecef;
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.btn--raised {
  /* @alternate */
  box-shadow: 0px 6px 6px -3px rgba(39, 44, 51, 0.1), 0px 10px 14px 1px rgba(39, 44, 51, 0.04), 0px 4px 18px 3px rgba(39, 44, 51, 0.02);
}

.button-list > .btn,
.button-list > .btn-group {
  margin-bottom: 0.75rem;
  margin-left: 0.5rem;
}

.btn-flush {
  background: none;
  box-shadow: none;
  border: none;
  padding: 0;
  line-height: 1;
  color: inherit;
}

.dropdown-toggle::after {
  align-self: center;
}

.btn .material-icons {
  font-size: inherit;
  line-height: inherit;
}

.btn-rounded {
  border-radius: 100px;
}

.dropdown-toggle[data-caret=false]::after {
  display: none;
}

.btn__secondary-text {
  display: block;
  margin-top: -2px;
  font-size: 0.925rem;
}

.btn-success:not(:hover),
.btn-warning:not(:hover) {
  color: #fff;
}

.btn-outline-light {
  color: #212529;
  border-color: #212529;
}

.btn-outline-success:hover,
.btn-outline-warning:hover {
  color: #fff;
}

.btn-primary--dark {
  color: #fff;
  background-color: #1b8aea;
  border-color: #1b8aea;
  color: #fff;
}
.btn-primary--dark:hover {
  color: #fff;
  background-color: #1376cc;
  border-color: #126fc0;
}
.btn-primary--dark:focus, .btn-primary--dark.focus {
  color: #fff;
  background-color: #1376cc;
  border-color: #126fc0;
  box-shadow: 0 0 0 1px rgba(61, 156, 237, 0.5);
}
.btn-primary--dark.disabled, .btn-primary--dark:disabled {
  color: #fff;
  background-color: #1b8aea;
  border-color: #1b8aea;
}
.btn-primary--dark:not(:disabled):not(.disabled):active, .btn-primary--dark:not(:disabled):not(.disabled).active, .show > .btn-primary--dark.dropdown-toggle {
  color: #fff;
  background-color: #126fc0;
  border-color: #1169b4;
}
.btn-primary--dark:not(:disabled):not(.disabled):active:focus, .btn-primary--dark:not(:disabled):not(.disabled).active:focus, .show > .btn-primary--dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(61, 156, 237, 0.5);
}

.btn-primary .btn__secondary-text,
.btn-primary--dark .btn__secondary-text {
  color: rgba(255, 255, 255, 0.5);
}

.btn-black, .navbar-dark .navbar-btn {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}
.btn-black:hover, .navbar-dark .navbar-btn:hover {
  color: #fff;
  background-color: #16191d;
  border-color: #111316;
}
.btn-black:focus, .navbar-dark .navbar-btn:focus, .btn-black.focus, .navbar-dark .focus.navbar-btn {
  color: #fff;
  background-color: #16191d;
  border-color: #111316;
  box-shadow: 0 0 0 1px rgba(71, 76, 82, 0.5);
}
.btn-black.disabled, .navbar-dark .disabled.navbar-btn, .btn-black:disabled, .navbar-dark .navbar-btn:disabled {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}
.btn-black:not(:disabled):not(.disabled):active, .navbar-dark .navbar-btn:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active, .navbar-dark .navbar-btn:not(:disabled):not(.disabled).active, .show > .btn-black.dropdown-toggle, .navbar-dark .show > .dropdown-toggle.navbar-btn {
  color: #fff;
  background-color: #111316;
  border-color: #0b0d0f;
}
.btn-black:not(:disabled):not(.disabled):active:focus, .navbar-dark .navbar-btn:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus, .navbar-dark .navbar-btn:not(:disabled):not(.disabled).active:focus, .show > .btn-black.dropdown-toggle:focus, .navbar-dark .show > .dropdown-toggle.navbar-btn:focus {
  box-shadow: 0 0 0 1px rgba(71, 76, 82, 0.5);
}

.btn-white {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:hover {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
}
.btn-white:focus, .btn-white.focus {
  color: #212529;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 1px rgba(222, 222, 223, 0.5);
}
.btn-white.disabled, .btn-white:disabled {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active, .show > .btn-white.dropdown-toggle {
  color: #212529;
  background-color: #e6e6e6;
  border-color: #dfdfdf;
}
.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(222, 222, 223, 0.5);
}
.btn-white .btn__secondary-text {
  color: rgba(0, 0, 0, 0.3);
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}
.btn-outline-white:hover {
  color: #4AA2EE;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-white:focus, .btn-outline-white.focus {
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
}
.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}
.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active, .show > .btn-outline-white.dropdown-toggle {
  color: #212529;
  background-color: #fff;
  border-color: #fff;
}
.btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.5);
}
.btn-outline-white .btn__secondary-text {
  color: rgba(255, 255, 255, 0.54);
}
.btn-outline-white:hover .btn__secondary-text, .btn-outline-white:focus .btn__secondary-text {
  color: rgba(39, 44, 51, 0.5);
}

@media (max-width: 575.98px) {
  .btn-block-xs {
    display: block;
    width: 100%;
  }
  .btn-block-xs:not(:last-child) {
    margin-bottom: 5px;
  }
}
@media (max-width: 575.98px) {
  .btn-group-xs > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.7109375rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
}
.btn-light, .navbar-light .navbar-btn {
  color: #212529;
  background-color: #EDF0F2;
  border-color: #EDF0F2;
}
.btn-light:hover, .navbar-light .navbar-btn:hover {
  color: #212529;
  background-color: #d7dde2;
  border-color: #cfd7dd;
}
.btn-light:focus, .navbar-light .navbar-btn:focus, .btn-light.focus, .navbar-light .focus.navbar-btn {
  color: #212529;
  background-color: #d7dde2;
  border-color: #cfd7dd;
  box-shadow: 0 0 0 1px rgba(206, 210, 212, 0.5);
}
.btn-light.disabled, .navbar-light .disabled.navbar-btn, .btn-light:disabled, .navbar-light .navbar-btn:disabled {
  color: #212529;
  background-color: #EDF0F2;
  border-color: #EDF0F2;
}
.btn-light:not(:disabled):not(.disabled):active, .navbar-light .navbar-btn:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .navbar-light .navbar-btn:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle, .navbar-light .show > .dropdown-toggle.navbar-btn {
  color: #212529;
  background-color: #cfd7dd;
  border-color: #c8d1d7;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .navbar-light .navbar-btn:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .navbar-light .navbar-btn:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus, .navbar-light .show > .dropdown-toggle.navbar-btn:focus {
  box-shadow: 0 0 0 1px rgba(206, 210, 212, 0.5);
}

.btn-accent-yellow, .btn-accent-yellow:hover, .btn-accent-yellow:focus, .btn-accent-yellow:active {
  color: white;
}

.badge-accent-yellow {
  color: white;
}

.card, .card-nav .tab-content,
.card-group {
  margin-bottom: 1.5rem;
}

.card, .card-nav .tab-content {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
}
.card--elevated:hover {
  /* @alternate */
  box-shadow: 0px 3px 5px -1px rgba(39, 44, 51, 0.1), 0px 5px 8px 0px rgba(39, 44, 51, 0.04), 0px 1px 14px 0px rgba(39, 44, 51, 0.02);
}
.card--raised {
  /* @alternate */
  box-shadow: 0px 6px 6px -3px rgba(39, 44, 51, 0.1), 0px 10px 14px 1px rgba(39, 44, 51, 0.04), 0px 4px 18px 3px rgba(39, 44, 51, 0.02);
}
.card--scaled {
  transform: scale(1.05);
}
.card--transparent {
  background-color: rgba(255, 255, 255, 0.9);
}
.card--connect {
  position: relative;
}
.card--connect::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #E9EDF2;
  left: 50%;
  margin-left: -2px;
  z-index: -1;
}

.card.card-flush, .card-nav .card-flush.tab-content {
  box-shadow: none;
  border: none;
}
.card.card-flush, .card-nav .card-flush.tab-content,
.card.card-flush .card-img,
.card-nav .card-flush.tab-content .card-img,
.card.card-flush .card-img-top,
.card-nav .card-flush.tab-content .card-img-top {
  border-radius: 0;
}

.card.card-flush-y, .card-nav .card-flush-y.tab-content {
  box-shadow: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.card-2by1 .card-body {
  padding: 0.5rem 1rem;
}

.card-sm .card-body,
.card-sm .card-footer {
  padding: 0.625rem 0.75rem;
}

.card-lg .card-body,
.card-lg .card-footer {
  padding: 1.25rem;
}

.card-title {
  font-size: 1rem;
  font-family: "Exo 2", "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  color: #272C33;
  line-height: 1.25;
  margin-bottom: 0;
}

.card-subtitle {
  font-size: 0.8125rem;
  font-weight: 400;
  margin-top: 0;
}

@media (min-width: 576px) {
  .card-group > .card, .card-nav .card-group > .tab-content {
    margin-bottom: 0;
  }
}
@media (max-width: 991.98px) {
  .card-group--lg-up .card, .card-group--lg-up .card-nav .tab-content, .card-nav .card-group--lg-up .tab-content {
    flex: 0 0 100% !important;
    margin-bottom: 1rem;
  }
}
.card-group-row {
  display: flex;
  flex-flow: row wrap;
}
.card-group-row__col {
  display: flex;
  min-width: 0;
}
.card-group-row__card {
  flex: 1 0 0;
}

.card-group-flush > .card, .card-nav .card-group-flush > .tab-content {
  box-shadow: none;
  border-bottom: 0;
  margin-bottom: 0;
  background: transparent;
}
.card-group-flush > .card, .card-nav .card-group-flush > .tab-content,
.card-group-flush > .card .card-img,
.card-nav .card-group-flush > .tab-content .card-img,
.card-group-flush > .card .card-img-top,
.card-nav .card-group-flush > .tab-content .card-img-top {
  border-radius: 0;
}
.card-group-flush > .card:first-child, .card-nav .card-group-flush > .tab-content:first-child {
  border-left: none;
}
.card-group-flush > .card:last-child, .card-nav .card-group-flush > .tab-content:last-child {
  border-right: none;
}
@media (max-width: 575.98px) {
  .card-group-flush > .card, .card-nav .card-group-flush > .tab-content {
    border-left: none;
    border-right: none;
  }
}

@media (min-width: 576px) {
  .card-columns--2 {
    column-count: 2;
  }
}
.card-column {
  display: inline-block;
  width: 100%;
}

.card-body + .card-body {
  border-top: 1px solid #DFE2E6;
}

.card-deck-row {
  display: flex;
  flex-flow: row wrap;
}
.card-deck-row > [class*=col-] {
  display: flex;
}
.card-deck-row .card, .card-deck-row .card-nav .tab-content, .card-nav .card-deck-row .tab-content {
  flex: 1 1 0%;
}

.card-title {
  font-weight: 500;
}
.card-title a, .card-title[href] {
  color: inherit;
  display: flex;
  text-decoration: none;
}
.card-title:last-child {
  margin-bottom: 0;
}

.card-header {
  position: relative;
  border-bottom: 1px solid #DFE2E6;
}

.card-footer {
  border-top: 1px solid #DFE2E6;
  padding: 0.75rem;
}
.card-footer:last-child {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.card > .fullbleed, .card-nav .tab-content > .fullbleed {
  border-radius: 0.5rem;
}

.card-img-cover {
  position: relative;
  height: 200px;
  object-fit: cover;
}

.card-feedback::before, .card-feedback::after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  left: 24px;
  z-index: -1;
  bottom: -20px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent;
  border-top-color: #DFE2E6;
}
.card-feedback::after {
  border-top-color: #fff;
  bottom: -19px;
  z-index: 2;
}

.card-header-tabs-basic {
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
}
.card-header-tabs-basic > div {
  display: flex;
}
.card-header-tabs-basic a {
  padding: 0.5rem 0.75rem;
  color: rgba(39, 44, 51, 0.54);
  font-family: "Exo 2", "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
}
.card-header-tabs-basic a:hover {
  text-decoration: none;
  color: rgba(39, 44, 51, 0.84);
}
.card-header-tabs-basic .active {
  color: #272C33;
  font-weight: 600;
  border-bottom: 2px solid #4AA2EE;
}

.popover-lg {
  /* @alternate */
  box-shadow: 0px 6px 6px -3px rgba(39, 44, 51, 0.1), 0px 10px 14px 1px rgba(39, 44, 51, 0.04), 0px 4px 18px 3px rgba(39, 44, 51, 0.02);
  border: none;
}
.popover-lg .popover-body {
  padding: 1.5rem;
}
.popover-lg .bs-popover-top .arrow::before, .popover-lg .bs-popover-auto[x-placement^=top] .arrow::before {
  border-top-color: transparent;
}
.popover-lg .bs-popover-right .arrow::before, .popover-lg .bs-popover-auto[x-placement^=right] .arrow::before {
  border-right-color: transparent;
}
.popover-lg .bs-popover-bottom .arrow::before, .popover-lg .bs-popover-auto[x-placement^=bottom] .arrow::before {
  border-bottom-color: transparent;
}
.popover-lg .bs-popover-left .arrow::before, .popover-lg .bs-popover-auto[x-placement^=left] .arrow::before {
  border-left-color: transparent;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 1rem;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 1rem;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 1rem;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 1rem;
}

.img-fluid {
  width: 100%;
  max-width: none;
}

.img-fluid-max {
  max-width: 100%;
  height: auto;
}

.page-link {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1rem;
  border-left: none;
  border-top: none;
  border-bottom: none;
  display: flex;
  align-items: center;
  line-height: 1.25 !important;
}
.page-link:hover, .page-link:focus {
  text-decoration: none;
}

.page-item:last-of-type .page-link {
  border-right: none;
}

.page-link .material-icons {
  line-height: 1rem;
}

.pagination {
  position: relative;
  z-index: 0;
}
.pagination:last-child {
  margin-bottom: 0;
}

.pagination-xsm .page-link {
  padding: 0 0.5rem;
  font-size: 0.8125rem;
  line-height: 24px;
}
.pagination-xsm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-xsm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
.pagination-xsm .page-link {
  line-height: 24px !important;
}
.pagination-xsm .material-icons {
  font-size: 1rem;
}

.rating {
  display: flex;
  align-items: center;
}
.rating__item {
  color: #f9c32c;
  display: flex;
  align-items: center;
}
.rating .material-icons {
  font-size: 1rem;
}
.rating-24 .material-icons {
  font-size: 1.5rem;
}
.rating-32 .material-icons {
  font-size: 2rem;
}

.table thead th,
.table tbody td {
  vertical-align: middle;
  line-height: 1.2;
}

.table-nowrap th,
.table-nowrap td {
  white-space: nowrap;
}

.table .sort {
  color: inherit;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
}
.table .sort::after {
  margin-left: 0.5rem;
  content: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='rgba(39, 44, 51, 0.54)'/></svg>");
}
.table .sort.asc, .table .sort.desc {
  text-decoration: underline;
  font-weight: 600;
}
.table .sort + .sort {
  margin-left: 1rem;
}

.table--elevated {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
}

.table-flush {
  margin-bottom: 0;
}
.table-flush thead th {
  border-top: 0;
}

.table-responsive {
  margin-bottom: 0;
}

.table thead th {
  color: #272C33;
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-family: "Exo 2", "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
}

.table tbody .selected > td {
  background-color: rgba(245, 247, 250, 0.5);
}

.thead-border-top-0 thead th {
  border-top: 0;
}

.table thead th {
  font-size: 0.8125rem;
  padding: 0.5rem 0.75rem;
}
.table tbody td {
  line-height: 1.5;
  padding: 0.75rem;
}

.table-flush {
  border-bottom: 0;
}
.table-flush thead th:first-of-type,
.table-flush tbody td:first-of-type {
  border-left: 0;
}
.table-flush thead th:last-of-type,
.table-flush tbody td:last-of-type {
  border-right: 0;
}

.list-group {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
}

.list-group-flush {
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 0;
}
.list-group-flush > .list-group-item:not(.active) {
  background: none;
}
.list-group-flush > .list-group-item:first-child {
  border-top: 0;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom: 0;
}

.list-group-item.active a {
  color: inherit;
}

.media-left,
.media-right {
  display: flex;
  flex-direction: column;
}

.media-left {
  margin-right: 0.5rem;
}

.media-right {
  margin-left: 0.5rem;
}

.media-body {
  flex: 1 1 0%;
}

.media {
  display: flex;
  flex-flow: row wrap;
}

@media (max-width: 575.98px) {
  .media-stack-xs {
    flex-direction: column;
  }
  .media-stack-xs .media-left {
    width: 100% !important;
    margin: 0 0 1rem 0;
  }
}
@media (max-width: 767.98px) {
  .media-stack-sm {
    flex-direction: column;
  }
  .media-stack-sm .media-left {
    width: 100% !important;
    margin: 0 0 1rem 0;
  }
}
@media (max-width: 991.98px) {
  .media-stack-md {
    flex-direction: column;
  }
  .media-stack-md .media-left {
    width: 100% !important;
    margin: 0 0 1rem 0;
  }
}
@media (max-width: 1199.98px) {
  .media-stack-lg {
    flex-direction: column;
  }
  .media-stack-lg .media-left {
    width: 100% !important;
    margin: 0 0 1rem 0;
  }
}
@media (max-width: 1365.98px) {
  .media-stack-xl {
    flex-direction: column;
  }
  .media-stack-xl .media-left {
    width: 100% !important;
    margin: 0 0 1rem 0;
  }
}
.media-stack-xxl {
  flex-direction: column;
}
.media-stack-xxl .media-left {
  width: 100% !important;
  margin: 0 0 1rem 0;
}

@media (min-width: 576px) {
  .media__thumbnail {
    width: 150px;
  }
}
.nav-link__secondary-text {
  display: block;
  margin-top: -2px;
  font-size: 0.825rem;
}

.nav-link {
  color: rgba(39, 44, 51, 0.5);
  display: flex;
}

.nav--flush .nav-link {
  padding: 0;
}

.nav-link.active,
.active .nav-link {
  font-weight: 500;
  color: #272C33;
}

.nav-tabs--flush .nav-link {
  border: none;
}
.nav-tabs--flush .nav-link:hover, .nav-tabs--flush .nav-link:focus {
  border-color: transparent;
}
.nav-tabs--flush .nav-link.active,
.nav-tabs--flush .nav-item.show .nav-link {
  background-color: transparent;
}

.nav-tabs--active-bg .nav-link.active,
.nav-tabs--active-bg .nav-item.show .nav-link {
  color: #fff;
  background-color: #4AA2EE;
  border-color: #4AA2EE;
}

.nav-tabs--active-underline .nav-link.active,
.nav-tabs--active-underline .nav-item.show .nav-link {
  border: none;
  border-bottom: 4px solid #4AA2EE;
}

.card-nav {
  z-index: 0;
}
.card-nav .nav-tabs {
  border-bottom: none;
  z-index: 2;
  position: relative;
}
.card-nav .nav-tabs .nav-item {
  margin-bottom: 0;
}
.card-nav .nav-tabs .nav-link:not(.active):hover {
  border-color: transparent;
}
@media (min-width: 576px) {
  .card-nav:not(.card-nav--vertical) .nav-tabs {
    padding: 0 1rem;
  }
  .card-nav:not(.card-nav--vertical) .tab-content {
    margin-top: -1px;
  }
}
.card-nav--vertical .nav-tabs {
  flex-direction: column;
}
@media (max-width: 991.98px) {
  .card-nav--vertical .nav-tabs .active {
    border-color: #DFE2E6;
  }
  .card-nav--vertical .nav-tabs {
    margin-bottom: 1rem;
  }
  .card-nav--vertical .nav-tabs .nav-link {
    border-radius: 0.25rem;
  }
}
@media (min-width: 992px) {
  .card-nav--vertical {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
  }
  .card-nav--vertical .tab-content {
    overflow: hidden;
    margin-bottom: 0;
    flex: 1;
  }
  .card-nav--vertical .tab-content:last-child {
    margin-left: -1px;
  }
  .card-nav--vertical .tab-content:first-child {
    margin-right: -1px;
  }
  .card-nav--vertical .nav-tabs {
    padding: 1rem 0;
  }
  .card-nav--vertical .nav-tabs:last-child .nav-link.active,
.card-nav--vertical .nav-tabs:last-child .nav-item.show .nav-link {
    border-color: transparent transparent #F5F7FA transparent transparent #F5F7FA transparent transparent #F5F7FA #F5F7FA;
  }
  .card-nav--vertical .nav-tabs:last-child .nav-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-nav--vertical .nav-tabs:first-child .nav-link.active,
.card-nav--vertical .nav-tabs:first-child .nav-item.show .nav-link {
    border-color: transparent transparent #F5F7FA #F5F7FA transparent transparent #F5F7FA transparent transparent #F5F7FA;
  }
  .card-nav--vertical .nav-tabs:first-child .nav-link {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.nav-tabs--pill {
  border-bottom: none;
}
.nav-tabs--pill .nav-link {
  border-radius: 0.25rem;
}
.nav-tabs--pill .nav-item {
  margin-bottom: 0;
}
.nav-tabs--pill .nav-item:not(:last-child) {
  margin-bottom: 5px;
}
@media (min-width: 576px) {
  .nav-tabs--pill .nav-item {
    margin-bottom: 0 !important;
  }
  .nav-tabs--pill .nav-item:not(:last-child) {
    margin-right: 5px;
  }
}

.nav-tabs--inactive-dark .nav-link {
  color: #fff;
}
.nav-tabs--inactive-dark .nav-link__secondary-text {
  color: rgba(255, 255, 255, 0.5);
}
.nav-tabs--inactive-dark .nav-link.disabled {
  color: rgba(255, 255, 255, 0.7);
}

.nav-tabs--inactive-primary .nav-link {
  background-color: #4AA2EE;
  border-color: #4AA2EE;
}

.nav-tabs--inactive-primary-dark .nav-link {
  background-color: #1b8aea;
  border-color: #1b8aea;
}

.nav-tabs--active-white .nav-link.active,
.nav-tabs--active-white .nav-item.show .nav-link {
  color: #272C33;
  background-color: #fff;
  border-color: #4AA2EE;
}
.nav-tabs--active-white .nav-link.active .nav-link__secondary-text,
.nav-tabs--active-white .nav-item.show .nav-link .nav-link__secondary-text {
  color: rgba(0, 0, 0, 0.7);
}

@media (min-width: 768px) {
  .nav-tabs--rounded-top-md-0 .nav-link {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

@media (min-width: 768px) {
  .nav-tabs--rounded-bottom-md-0 .nav-link {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.nav-tabs--rounded-0 .nav-link {
  border-radius: 0;
}

.nav-tabs-card .nav-link {
  border-radius: 0;
}
.nav-tabs-card .nav-link:hover {
  color: #272C33;
}
.nav-tabs-card + .card-footer {
  border-top: none;
}
.card > .nav-tabs-card:not(:first-child), .card-nav .tab-content > .nav-tabs-card:not(:first-child) {
  border-top: 1px solid #DFE2E6;
}

.layout-sticky-subnav .mdk-header-layout {
  overflow: initial;
}
.layout-sticky-subnav .page__subnav {
  position: sticky;
  top: 64px;
  z-index: 2;
}
.layout-sticky-subnav .page__container {
  z-index: 0;
  position: relative;
}

.avatar-group {
  flex-direction: row;
}

.avatar-title {
  font-size: 0.8125rem;
  font-weight: 500;
}

.avatar-32pt {
  width: 2rem;
  height: 2rem;
}

.page-heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}
.page-heading h4,
.page-heading .h4 {
  margin-bottom: 0.25rem;
}
@media (min-width: 576px) {
  .page-heading h4,
.page-heading .h4 {
    margin-bottom: 0;
  }
}
@media (min-width: 576px) {
  .page-heading {
    flex-direction: row;
    align-items: flex-end;
  }
}

.page-headline {
  position: relative;
  padding-bottom: 1.5rem;
}
@media (min-width: 992px) {
  .page-headline {
    padding-bottom: 3rem;
  }
}
.page-headline :last-child {
  margin-bottom: 0;
}
.page-headline > :first-child {
  position: relative;
  display: inline-flex;
  margin-bottom: calc(1rem + 12px);
}
.page-headline > :first-child::after {
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: #4AA2EE;
  content: " ";
  bottom: -12px;
}
.page-headline--title > :first-child {
  margin-bottom: 12px;
}
.page-headline--white > :first-child::after {
  background-color: #fff;
}

.page-separator {
  position: relative;
  color: #868e96;
  display: flex;
  align-items: center;
  justify-content: start;
  z-index: 0;
  margin-bottom: 1rem;
}
.page-separator::before {
  content: "";
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  top: 50%;
  left: 0;
  position: absolute;
  z-index: -1;
}
.page-separator__text {
  display: inline-flex;
  padding-right: 0.5rem;
  border-radius: 0.25rem;
  background-color: #F5F7FA;
  max-width: 90%;
  text-transform: uppercase;
  font-size: 0.9375rem;
  color: #272C33;
  font-weight: 600;
  letter-spacing: 2px;
  font-family: "Exo 2", "Helvetica Neue", Arial, sans-serif;
}
.justify-content-center .page-separator__text {
  padding-left: 0.5rem;
}
.bg-alt .page-separator__text {
  background-color: white;
}
.page-separator__text:hover {
  text-decoration: none;
}
.page-separator__bg-bottom {
  content: "";
  height: 50%;
  background-color: #F5F7FA;
  width: 100%;
  top: 50%;
  left: 0;
  position: absolute;
  z-index: -2;
}
.page-separator__bg-top {
  content: "";
  height: 50%;
  background-color: #F5F7FA;
  width: 100%;
  bottom: 50%;
  left: 0;
  position: absolute;
  z-index: -2;
}

.page-separator-mask {
  position: relative;
  overflow: hidden;
  z-index: 0;
  height: 356px;
}
.page-separator-mask .page-separator-mask__content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 84px;
}
.page-separator-mask .page-separator {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  margin: 0;
}
.page-separator-mask .page-separator-mask__top {
  background-image: linear-gradient(-180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.8) 17%, rgba(255, 255, 255, 0.9) 32%, #ffffff 70%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 64px;
}
.page-separator-mask .page-separator-mask__bottom {
  background: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 20px;
}

.page-section {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
@media (min-width: 768px) {
  .page-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

@media (min-width: 992px) {
  .layout-default .page-section {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.page-nav {
  z-index: 0;
  position: relative;
  position: sticky;
  top: 4rem;
  max-height: calc(100vh - 4rem);
  overflow-y: auto;
  display: block;
}
.page-nav .page-nav__content {
  margin-left: 21px;
  padding-left: 17px;
}
.page-nav::before {
  position: absolute;
  width: 2px;
  left: 33px;
  top: 0;
  bottom: 0;
  background-color: #E9EDF2;
  content: "";
  z-index: -1;
}
.page-nav .page-nav__menu {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  margin-left: 21px;
}
.page-nav .page-nav__menu a {
  color: rgba(39, 44, 51, 0.7);
  position: relative;
  padding: 0 0 0 17px;
  margin-bottom: 0.5rem;
}
.page-nav .page-nav__menu a::before {
  background-color: #D2D5D9;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid #F5F7FA;
  content: "";
  left: -5px;
  top: 6px;
  position: absolute;
}
.page-nav .page-nav__menu a.active {
  font-weight: bold;
}
.page-nav .page-nav__menu a.active::before {
  background-color: #4AA2EE;
  border-color: white;
}

.page-num {
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;
  border: 2px solid #E9EDF2;
  background-color: #F5F7FA;
  font-family: "Exo 2", "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  line-height: 1.5;
  font-size: 1.414rem;
  line-height: 1.414;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #272C33;
}
[href] .page-num {
  color: #272C33;
}

.page-num-container {
  margin-left: -3.25rem;
  margin-bottom: 1rem;
}
.page-num-container .page-num {
  margin-right: 0.75rem;
  flex-shrink: 0;
}
.page-num-container :last-child {
  margin-bottom: 0;
}

.page-num-timeline {
  position: relative;
  z-index: 0;
}
.page-num-timeline::after {
  content: "";
  width: 0.3125rem;
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  background-color: #E9EDF2;
  z-index: -1;
  margin-left: -0.15625rem;
}
.page-num-timeline__item {
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.page-num-timeline__item-tip {
  width: 0.75rem;
  height: 0.75rem;
  display: block;
  border-radius: 100%;
  background-color: #D2D5D9;
  border: 2px solid #F5F7FA;
  margin-bottom: 5px !important;
}
.page-num-timeline__item-current {
  margin-top: 0.75rem !important;
}
.page-num-timeline__item-current .page-num {
  background-color: #4AA2EE;
  color: #99ccff;
  width: 4rem;
  height: 4rem;
  border: none;
}
.page-num-timeline__item-current .page-num-timeline__item-tip {
  position: absolute;
  top: -0.375rem;
  left: 50%;
  margin-left: -0.375rem;
  border-color: white;
  background-color: #4AA2EE;
}
.page-num-timeline__item.active .page-num-timeline__item-tip {
  border-color: #99ccff;
  background-color: #4AA2EE;
}
@media (min-width: 576px) {
  .page-num-timeline {
    margin-top: -0.375rem;
  }
  .page-num-timeline__item {
    margin: 0 1rem;
  }
  .page-num-timeline::after {
    width: 100%;
    height: 0.3125rem;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: 0.375rem;
    margin-left: 0;
  }
}

.progression-bar {
  display: flex;
  color: rgba(39, 44, 51, 0.5);
  flex-direction: column;
  align-items: center;
}
.progression-bar__item {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1 0%;
  text-align: center;
  position: relative;
  z-index: 0;
  color: inherit;
}
.progression-bar__item-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1 1 0%;
}
.progression-bar__item:not(:last-child) {
  padding-bottom: 15px;
}
.progression-bar__item[href]:hover {
  text-decoration: none;
  color: inherit;
}
.progression-bar__item::before, .progression-bar__item::after {
  content: "";
  position: absolute;
  z-index: -1;
  background-color: #f2f2f2;
  width: 2px;
  top: 0;
  left: 50%;
  height: 100%;
  margin-left: -1px;
}
.progression-bar__item::before {
  display: none;
}
.progression-bar__item-icon {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 2px solid #f2f2f2;
  color: inherit;
  text-align: center;
  line-height: 22px;
  background-color: #fff;
  font-size: 16px;
  margin-bottom: 5px;
}
.progression-bar__item-text {
  color: inherit;
  background-color: #ffffff;
}
.progression-bar__item-icon:not(.progression-bar__item--disabled), .progression-bar__item-text:not(.progression-bar__item--disabled) {
  cursor: pointer;
}
.progression-bar__item--complete::after {
  background-color: rgba(39, 44, 51, 0.7);
}
.progression-bar__item--complete {
  color: rgba(39, 44, 51, 0.7);
}
.progression-bar__item--complete .progression-bar__item-icon {
  border-color: rgba(39, 44, 51, 0.7);
}
.progression-bar__item--active {
  color: #272C33;
}
.progression-bar__item--active:not(:last-child)::after {
  height: 50%;
}
.progression-bar__item--active:not(:last-child)::before {
  display: block;
}
.progression-bar--active-primary .progression-bar__item--active .progression-bar__item-icon {
  background-color: #4AA2EE;
  border-color: #4AA2EE;
  color: #fff;
}
.progression-bar--active-secondary .progression-bar__item--active .progression-bar__item-icon {
  background-color: #868e96;
  border-color: #868e96;
  color: #fff;
}
.progression-bar--active-success .progression-bar__item--active .progression-bar__item-icon {
  background-color: #77c13a;
  border-color: #77c13a;
  color: #fff;
}
.progression-bar--active-info .progression-bar__item--active .progression-bar__item-icon {
  background-color: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
}
.progression-bar--active-warning .progression-bar__item--active .progression-bar__item-icon {
  background-color: #E4A93C;
  border-color: #E4A93C;
  color: #fff;
}
.progression-bar--active-danger .progression-bar__item--active .progression-bar__item-icon {
  background-color: #d9534f;
  border-color: #d9534f;
  color: #fff;
}
.progression-bar--active-light .progression-bar__item--active .progression-bar__item-icon {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  color: #fff;
}
.progression-bar--active-dark .progression-bar__item--active .progression-bar__item-icon {
  background-color: #303840;
  border-color: #303840;
  color: #fff;
}
.progression-bar--active-black .progression-bar__item--active .progression-bar__item-icon {
  background-color: #272C33;
  border-color: #272C33;
  color: #fff;
}
.progression-bar--active-accent .progression-bar__item--active .progression-bar__item-icon {
  background-color: #00BCC2;
  border-color: #00BCC2;
  color: #fff;
}
.progression-bar--active-accent-red .progression-bar__item--active .progression-bar__item-icon {
  background-color: #ED0B4C;
  border-color: #ED0B4C;
  color: #fff;
}
.progression-bar--active-accent-yellow .progression-bar__item--active .progression-bar__item-icon {
  background-color: #E4A93C;
  border-color: #E4A93C;
  color: #fff;
}
.progression-bar--active-accent-dodger-blue .progression-bar__item--active .progression-bar__item-icon {
  background-color: #5567FF;
  border-color: #5567FF;
  color: #fff;
}
.progression-bar--active-accent-pickled-bluewood .progression-bar__item--active .progression-bar__item-icon {
  background-color: #303956;
  border-color: #303956;
  color: #fff;
}
.progression-bar--active-accent-electric-violet .progression-bar__item--active .progression-bar__item-icon {
  background-color: #9C42FF;
  border-color: #9C42FF;
  color: #fff;
}
.progression-bar--active-primary-purple .progression-bar__item--active .progression-bar__item-icon {
  background-color: #824EE1;
  border-color: #824EE1;
  color: #fff;
}
.progression-bar--active-primary-red .progression-bar__item--active .progression-bar__item-icon {
  background-color: #ED0B4C;
  border-color: #ED0B4C;
  color: #fff;
}
.progression-bar--active-primary-yellow .progression-bar__item--active .progression-bar__item-icon {
  background-color: #E4A93C;
  border-color: #E4A93C;
  color: #fff;
}
.progression-bar--active-primary-light .progression-bar__item--active .progression-bar__item-icon {
  background-color: #99ccff;
  border-color: #99ccff;
  color: #fff;
}
.progression-bar--active-primary-dodger-blue .progression-bar__item--active .progression-bar__item-icon {
  background-color: #5567FF;
  border-color: #5567FF;
  color: #fff;
}
.progression-bar--active-primary-pickled-bluewood .progression-bar__item--active .progression-bar__item-icon {
  background-color: #303956;
  border-color: #303956;
  color: #fff;
}
@media (min-width: 576px) {
  .progression-bar {
    flex-direction: row;
    align-items: start;
  }
  .progression-bar__item {
    padding-bottom: 0 !important;
  }
  .progression-bar__item:first-child {
    align-items: flex-start;
  }
  .progression-bar__item:last-child {
    align-items: flex-end;
  }
  .progression-bar__item::before, .progression-bar__item::after {
    left: 0;
    top: 12px;
    height: 2px !important;
    width: 100%;
    margin-left: 0;
  }
  .progression-bar__item--active:not(:last-child)::after {
    width: 50%;
  }
  .progression-bar__item--active:not(:last-child)::before {
    display: block;
  }
}

code.django .hljs-template-tag {
  color: #00BCC2;
}

.bg-dark code {
  color: white;
}

.tooltip-inner {
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(39, 44, 51, 0.1), 0px 1px 1px 0px rgba(39, 44, 51, 0.04), 0px 1px 3px 0px rgba(39, 44, 51, 0.02);
  font-size: 0.9375rem;
  font-weight: 600;
  font-family: "Exo 2", "Helvetica Neue", Arial, sans-serif;
}

.chip {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.25rem 1rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  border-radius: 100px;
}
.chip + .chip {
  margin-left: 0.5rem;
}

.chip-primary {
  color: #fff;
  background-color: #4AA2EE;
  border-color: #4AA2EE;
}
.chip-primary:hover {
  color: #fff;
  background-color: #2790eb;
  border-color: #1b8aea;
}
.chip-primary:focus, .chip-primary.focus {
  color: #fff;
  background-color: #2790eb;
  border-color: #1b8aea;
  box-shadow: 0 0 0 1px rgba(101, 176, 241, 0.5);
}
.chip-primary.disabled, .chip-primary:disabled {
  color: #fff;
  background-color: #4AA2EE;
  border-color: #4AA2EE;
}
.chip-primary:not(:disabled):not(.disabled):active, .chip-primary:not(:disabled):not(.disabled).active, .show > .chip-primary.dropdown-toggle {
  color: #fff;
  background-color: #1b8aea;
  border-color: #1584e3;
}
.chip-primary:not(:disabled):not(.disabled):active:focus, .chip-primary:not(:disabled):not(.disabled).active:focus, .show > .chip-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(101, 176, 241, 0.5);
}

.chip-outline-primary {
  color: #4AA2EE;
  border-color: #4AA2EE;
}
.chip-outline-primary:hover {
  color: #fff;
  background-color: #4AA2EE;
  border-color: #4AA2EE;
}
.chip-outline-primary:focus, .chip-outline-primary.focus {
  box-shadow: 0 0 0 1px rgba(74, 162, 238, 0.5);
}
.chip-outline-primary.disabled, .chip-outline-primary:disabled {
  color: #4AA2EE;
  background-color: transparent;
}
.chip-outline-primary:not(:disabled):not(.disabled):active, .chip-outline-primary:not(:disabled):not(.disabled).active, .show > .chip-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #4AA2EE;
  border-color: #4AA2EE;
}
.chip-outline-primary:not(:disabled):not(.disabled):active:focus, .chip-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(74, 162, 238, 0.5);
}

.chip-secondary {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}
.chip-secondary:hover {
  color: #fff;
  background-color: #727b84;
  border-color: #6c757d;
}
.chip-secondary:focus, .chip-secondary.focus {
  color: #fff;
  background-color: #727b84;
  border-color: #6c757d;
  box-shadow: 0 0 0 1px rgba(152, 159, 166, 0.5);
}
.chip-secondary.disabled, .chip-secondary:disabled {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}
.chip-secondary:not(:disabled):not(.disabled):active, .chip-secondary:not(:disabled):not(.disabled).active, .show > .chip-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #666e76;
}
.chip-secondary:not(:disabled):not(.disabled):active:focus, .chip-secondary:not(:disabled):not(.disabled).active:focus, .show > .chip-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(152, 159, 166, 0.5);
}

.chip-outline-secondary {
  color: #868e96;
  border-color: #868e96;
}
.chip-outline-secondary:hover {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}
.chip-outline-secondary:focus, .chip-outline-secondary.focus {
  box-shadow: 0 0 0 1px rgba(134, 142, 150, 0.5);
}
.chip-outline-secondary.disabled, .chip-outline-secondary:disabled {
  color: #868e96;
  background-color: transparent;
}
.chip-outline-secondary:not(:disabled):not(.disabled):active, .chip-outline-secondary:not(:disabled):not(.disabled).active, .show > .chip-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}
.chip-outline-secondary:not(:disabled):not(.disabled):active:focus, .chip-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(134, 142, 150, 0.5);
}

.chip-success {
  color: #212529;
  background-color: #77c13a;
  border-color: #77c13a;
}
.chip-success:hover {
  color: #fff;
  background-color: #65a431;
  border-color: #5f9a2e;
}
.chip-success:focus, .chip-success.focus {
  color: #fff;
  background-color: #65a431;
  border-color: #5f9a2e;
  box-shadow: 0 0 0 1px rgba(106, 170, 55, 0.5);
}
.chip-success.disabled, .chip-success:disabled {
  color: #212529;
  background-color: #77c13a;
  border-color: #77c13a;
}
.chip-success:not(:disabled):not(.disabled):active, .chip-success:not(:disabled):not(.disabled).active, .show > .chip-success.dropdown-toggle {
  color: #fff;
  background-color: #5f9a2e;
  border-color: #59902b;
}
.chip-success:not(:disabled):not(.disabled):active:focus, .chip-success:not(:disabled):not(.disabled).active:focus, .show > .chip-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(106, 170, 55, 0.5);
}

.chip-outline-success {
  color: #77c13a;
  border-color: #77c13a;
}
.chip-outline-success:hover {
  color: #212529;
  background-color: #77c13a;
  border-color: #77c13a;
}
.chip-outline-success:focus, .chip-outline-success.focus {
  box-shadow: 0 0 0 1px rgba(119, 193, 58, 0.5);
}
.chip-outline-success.disabled, .chip-outline-success:disabled {
  color: #77c13a;
  background-color: transparent;
}
.chip-outline-success:not(:disabled):not(.disabled):active, .chip-outline-success:not(:disabled):not(.disabled).active, .show > .chip-outline-success.dropdown-toggle {
  color: #212529;
  background-color: #77c13a;
  border-color: #77c13a;
}
.chip-outline-success:not(:disabled):not(.disabled):active:focus, .chip-outline-success:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(119, 193, 58, 0.5);
}

.chip-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.chip-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.chip-info:focus, .chip-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 1px rgba(58, 176, 195, 0.5);
}
.chip-info.disabled, .chip-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.chip-info:not(:disabled):not(.disabled):active, .chip-info:not(:disabled):not(.disabled).active, .show > .chip-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.chip-info:not(:disabled):not(.disabled):active:focus, .chip-info:not(:disabled):not(.disabled).active:focus, .show > .chip-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(58, 176, 195, 0.5);
}

.chip-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.chip-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.chip-outline-info:focus, .chip-outline-info.focus {
  box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5);
}
.chip-outline-info.disabled, .chip-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.chip-outline-info:not(:disabled):not(.disabled):active, .chip-outline-info:not(:disabled):not(.disabled).active, .show > .chip-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.chip-outline-info:not(:disabled):not(.disabled):active:focus, .chip-outline-info:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(23, 162, 184, 0.5);
}

.chip-warning {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.chip-warning:hover {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
}
.chip-warning:focus, .chip-warning.focus {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
  box-shadow: 0 0 0 1px rgba(199, 149, 57, 0.5);
}
.chip-warning.disabled, .chip-warning:disabled {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.chip-warning:not(:disabled):not(.disabled):active, .chip-warning:not(:disabled):not(.disabled).active, .show > .chip-warning.dropdown-toggle {
  color: #212529;
  background-color: #d0911d;
  border-color: #c5891b;
}
.chip-warning:not(:disabled):not(.disabled):active:focus, .chip-warning:not(:disabled):not(.disabled).active:focus, .show > .chip-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.chip-outline-warning {
  color: #E4A93C;
  border-color: #E4A93C;
}
.chip-outline-warning:hover {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.chip-outline-warning:focus, .chip-outline-warning.focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}
.chip-outline-warning.disabled, .chip-outline-warning:disabled {
  color: #E4A93C;
  background-color: transparent;
}
.chip-outline-warning:not(:disabled):not(.disabled):active, .chip-outline-warning:not(:disabled):not(.disabled).active, .show > .chip-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.chip-outline-warning:not(:disabled):not(.disabled):active:focus, .chip-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.chip-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.chip-danger:hover {
  color: #fff;
  background-color: #d23430;
  border-color: #c9302c;
}
.chip-danger:focus, .chip-danger.focus {
  color: #fff;
  background-color: #d23430;
  border-color: #c9302c;
  box-shadow: 0 0 0 1px rgba(223, 109, 105, 0.5);
}
.chip-danger.disabled, .chip-danger:disabled {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.chip-danger:not(:disabled):not(.disabled):active, .chip-danger:not(:disabled):not(.disabled).active, .show > .chip-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #bf2e29;
}
.chip-danger:not(:disabled):not(.disabled):active:focus, .chip-danger:not(:disabled):not(.disabled).active:focus, .show > .chip-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(223, 109, 105, 0.5);
}

.chip-outline-danger {
  color: #d9534f;
  border-color: #d9534f;
}
.chip-outline-danger:hover {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.chip-outline-danger:focus, .chip-outline-danger.focus {
  box-shadow: 0 0 0 1px rgba(217, 83, 79, 0.5);
}
.chip-outline-danger.disabled, .chip-outline-danger:disabled {
  color: #d9534f;
  background-color: transparent;
}
.chip-outline-danger:not(:disabled):not(.disabled):active, .chip-outline-danger:not(:disabled):not(.disabled).active, .show > .chip-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.chip-outline-danger:not(:disabled):not(.disabled):active:focus, .chip-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(217, 83, 79, 0.5);
}

.chip-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.chip-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.chip-light:focus, .chip-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 1px rgba(216, 217, 219, 0.5);
}
.chip-light.disabled, .chip-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.chip-light:not(:disabled):not(.disabled):active, .chip-light:not(:disabled):not(.disabled).active, .show > .chip-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.chip-light:not(:disabled):not(.disabled):active:focus, .chip-light:not(:disabled):not(.disabled).active:focus, .show > .chip-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(216, 217, 219, 0.5);
}

.chip-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.chip-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.chip-outline-light:focus, .chip-outline-light.focus {
  box-shadow: 0 0 0 1px rgba(248, 249, 250, 0.5);
}
.chip-outline-light.disabled, .chip-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.chip-outline-light:not(:disabled):not(.disabled):active, .chip-outline-light:not(:disabled):not(.disabled).active, .show > .chip-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.chip-outline-light:not(:disabled):not(.disabled):active:focus, .chip-outline-light:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(248, 249, 250, 0.5);
}

.chip-dark {
  color: #fff;
  background-color: #303840;
  border-color: #303840;
}
.chip-dark:hover {
  color: #fff;
  background-color: #20252a;
  border-color: #1a1f23;
}
.chip-dark:focus, .chip-dark.focus {
  color: #fff;
  background-color: #20252a;
  border-color: #1a1f23;
  box-shadow: 0 0 0 1px rgba(79, 86, 93, 0.5);
}
.chip-dark.disabled, .chip-dark:disabled {
  color: #fff;
  background-color: #303840;
  border-color: #303840;
}
.chip-dark:not(:disabled):not(.disabled):active, .chip-dark:not(:disabled):not(.disabled).active, .show > .chip-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1f23;
  border-color: #15181c;
}
.chip-dark:not(:disabled):not(.disabled):active:focus, .chip-dark:not(:disabled):not(.disabled).active:focus, .show > .chip-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(79, 86, 93, 0.5);
}

.chip-outline-dark {
  color: #303840;
  border-color: #303840;
}
.chip-outline-dark:hover {
  color: #fff;
  background-color: #303840;
  border-color: #303840;
}
.chip-outline-dark:focus, .chip-outline-dark.focus {
  box-shadow: 0 0 0 1px rgba(48, 56, 64, 0.5);
}
.chip-outline-dark.disabled, .chip-outline-dark:disabled {
  color: #303840;
  background-color: transparent;
}
.chip-outline-dark:not(:disabled):not(.disabled):active, .chip-outline-dark:not(:disabled):not(.disabled).active, .show > .chip-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #303840;
  border-color: #303840;
}
.chip-outline-dark:not(:disabled):not(.disabled):active:focus, .chip-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(48, 56, 64, 0.5);
}

.chip-black {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}
.chip-black:hover {
  color: #fff;
  background-color: #16191d;
  border-color: #111316;
}
.chip-black:focus, .chip-black.focus {
  color: #fff;
  background-color: #16191d;
  border-color: #111316;
  box-shadow: 0 0 0 1px rgba(71, 76, 82, 0.5);
}
.chip-black.disabled, .chip-black:disabled {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}
.chip-black:not(:disabled):not(.disabled):active, .chip-black:not(:disabled):not(.disabled).active, .show > .chip-black.dropdown-toggle {
  color: #fff;
  background-color: #111316;
  border-color: #0b0d0f;
}
.chip-black:not(:disabled):not(.disabled):active:focus, .chip-black:not(:disabled):not(.disabled).active:focus, .show > .chip-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(71, 76, 82, 0.5);
}

.chip-outline-black {
  color: #272C33;
  border-color: #272C33;
}
.chip-outline-black:hover {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}
.chip-outline-black:focus, .chip-outline-black.focus {
  box-shadow: 0 0 0 1px rgba(39, 44, 51, 0.5);
}
.chip-outline-black.disabled, .chip-outline-black:disabled {
  color: #272C33;
  background-color: transparent;
}
.chip-outline-black:not(:disabled):not(.disabled):active, .chip-outline-black:not(:disabled):not(.disabled).active, .show > .chip-outline-black.dropdown-toggle {
  color: #fff;
  background-color: #272C33;
  border-color: #272C33;
}
.chip-outline-black:not(:disabled):not(.disabled):active:focus, .chip-outline-black:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(39, 44, 51, 0.5);
}

.chip-accent {
  color: #fff;
  background-color: #00BCC2;
  border-color: #00BCC2;
}
.chip-accent:hover {
  color: #fff;
  background-color: #00979c;
  border-color: #008b8f;
}
.chip-accent:focus, .chip-accent.focus {
  color: #fff;
  background-color: #00979c;
  border-color: #008b8f;
  box-shadow: 0 0 0 1px rgba(38, 198, 203, 0.5);
}
.chip-accent.disabled, .chip-accent:disabled {
  color: #fff;
  background-color: #00BCC2;
  border-color: #00BCC2;
}
.chip-accent:not(:disabled):not(.disabled):active, .chip-accent:not(:disabled):not(.disabled).active, .show > .chip-accent.dropdown-toggle {
  color: #fff;
  background-color: #008b8f;
  border-color: #007e82;
}
.chip-accent:not(:disabled):not(.disabled):active:focus, .chip-accent:not(:disabled):not(.disabled).active:focus, .show > .chip-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(38, 198, 203, 0.5);
}

.chip-outline-accent {
  color: #00BCC2;
  border-color: #00BCC2;
}
.chip-outline-accent:hover {
  color: #fff;
  background-color: #00BCC2;
  border-color: #00BCC2;
}
.chip-outline-accent:focus, .chip-outline-accent.focus {
  box-shadow: 0 0 0 1px rgba(0, 188, 194, 0.5);
}
.chip-outline-accent.disabled, .chip-outline-accent:disabled {
  color: #00BCC2;
  background-color: transparent;
}
.chip-outline-accent:not(:disabled):not(.disabled):active, .chip-outline-accent:not(:disabled):not(.disabled).active, .show > .chip-outline-accent.dropdown-toggle {
  color: #fff;
  background-color: #00BCC2;
  border-color: #00BCC2;
}
.chip-outline-accent:not(:disabled):not(.disabled):active:focus, .chip-outline-accent:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(0, 188, 194, 0.5);
}

.chip-accent-red {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.chip-accent-red:hover {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
}
.chip-accent-red:focus, .chip-accent-red.focus {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
  box-shadow: 0 0 0 1px rgba(240, 48, 103, 0.5);
}
.chip-accent-red.disabled, .chip-accent-red:disabled {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.chip-accent-red:not(:disabled):not(.disabled):active, .chip-accent-red:not(:disabled):not(.disabled).active, .show > .chip-accent-red.dropdown-toggle {
  color: #fff;
  background-color: #bc093c;
  border-color: #b00838;
}
.chip-accent-red:not(:disabled):not(.disabled):active:focus, .chip-accent-red:not(:disabled):not(.disabled).active:focus, .show > .chip-accent-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(240, 48, 103, 0.5);
}

.chip-outline-accent-red {
  color: #ED0B4C;
  border-color: #ED0B4C;
}
.chip-outline-accent-red:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.chip-outline-accent-red:focus, .chip-outline-accent-red.focus {
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}
.chip-outline-accent-red.disabled, .chip-outline-accent-red:disabled {
  color: #ED0B4C;
  background-color: transparent;
}
.chip-outline-accent-red:not(:disabled):not(.disabled):active, .chip-outline-accent-red:not(:disabled):not(.disabled).active, .show > .chip-outline-accent-red.dropdown-toggle {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.chip-outline-accent-red:not(:disabled):not(.disabled):active:focus, .chip-outline-accent-red:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-accent-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.chip-accent-yellow {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.chip-accent-yellow:hover {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
}
.chip-accent-yellow:focus, .chip-accent-yellow.focus {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
  box-shadow: 0 0 0 1px rgba(199, 149, 57, 0.5);
}
.chip-accent-yellow.disabled, .chip-accent-yellow:disabled {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.chip-accent-yellow:not(:disabled):not(.disabled):active, .chip-accent-yellow:not(:disabled):not(.disabled).active, .show > .chip-accent-yellow.dropdown-toggle {
  color: #212529;
  background-color: #d0911d;
  border-color: #c5891b;
}
.chip-accent-yellow:not(:disabled):not(.disabled):active:focus, .chip-accent-yellow:not(:disabled):not(.disabled).active:focus, .show > .chip-accent-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.chip-outline-accent-yellow {
  color: #E4A93C;
  border-color: #E4A93C;
}
.chip-outline-accent-yellow:hover {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.chip-outline-accent-yellow:focus, .chip-outline-accent-yellow.focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}
.chip-outline-accent-yellow.disabled, .chip-outline-accent-yellow:disabled {
  color: #E4A93C;
  background-color: transparent;
}
.chip-outline-accent-yellow:not(:disabled):not(.disabled):active, .chip-outline-accent-yellow:not(:disabled):not(.disabled).active, .show > .chip-outline-accent-yellow.dropdown-toggle {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.chip-outline-accent-yellow:not(:disabled):not(.disabled):active:focus, .chip-outline-accent-yellow:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-accent-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.chip-accent-dodger-blue {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.chip-accent-dodger-blue:hover {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
}
.chip-accent-dodger-blue:focus, .chip-accent-dodger-blue.focus {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
  box-shadow: 0 0 0 1px rgba(111, 126, 255, 0.5);
}
.chip-accent-dodger-blue.disabled, .chip-accent-dodger-blue:disabled {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.chip-accent-dodger-blue:not(:disabled):not(.disabled):active, .chip-accent-dodger-blue:not(:disabled):not(.disabled).active, .show > .chip-accent-dodger-blue.dropdown-toggle {
  color: #fff;
  background-color: #2239ff;
  border-color: #152eff;
}
.chip-accent-dodger-blue:not(:disabled):not(.disabled):active:focus, .chip-accent-dodger-blue:not(:disabled):not(.disabled).active:focus, .show > .chip-accent-dodger-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(111, 126, 255, 0.5);
}

.chip-outline-accent-dodger-blue {
  color: #5567FF;
  border-color: #5567FF;
}
.chip-outline-accent-dodger-blue:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.chip-outline-accent-dodger-blue:focus, .chip-outline-accent-dodger-blue.focus {
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}
.chip-outline-accent-dodger-blue.disabled, .chip-outline-accent-dodger-blue:disabled {
  color: #5567FF;
  background-color: transparent;
}
.chip-outline-accent-dodger-blue:not(:disabled):not(.disabled):active, .chip-outline-accent-dodger-blue:not(:disabled):not(.disabled).active, .show > .chip-outline-accent-dodger-blue.dropdown-toggle {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.chip-outline-accent-dodger-blue:not(:disabled):not(.disabled):active:focus, .chip-outline-accent-dodger-blue:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-accent-dodger-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.chip-accent-pickled-bluewood {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.chip-accent-pickled-bluewood:hover {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
}
.chip-accent-pickled-bluewood:focus, .chip-accent-pickled-bluewood.focus {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
  box-shadow: 0 0 0 1px rgba(79, 87, 111, 0.5);
}
.chip-accent-pickled-bluewood.disabled, .chip-accent-pickled-bluewood:disabled {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.chip-accent-pickled-bluewood:not(:disabled):not(.disabled):active, .chip-accent-pickled-bluewood:not(:disabled):not(.disabled).active, .show > .chip-accent-pickled-bluewood.dropdown-toggle {
  color: #fff;
  background-color: #1e2335;
  border-color: #191e2d;
}
.chip-accent-pickled-bluewood:not(:disabled):not(.disabled):active:focus, .chip-accent-pickled-bluewood:not(:disabled):not(.disabled).active:focus, .show > .chip-accent-pickled-bluewood.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(79, 87, 111, 0.5);
}

.chip-outline-accent-pickled-bluewood {
  color: #303956;
  border-color: #303956;
}
.chip-outline-accent-pickled-bluewood:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.chip-outline-accent-pickled-bluewood:focus, .chip-outline-accent-pickled-bluewood.focus {
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}
.chip-outline-accent-pickled-bluewood.disabled, .chip-outline-accent-pickled-bluewood:disabled {
  color: #303956;
  background-color: transparent;
}
.chip-outline-accent-pickled-bluewood:not(:disabled):not(.disabled):active, .chip-outline-accent-pickled-bluewood:not(:disabled):not(.disabled).active, .show > .chip-outline-accent-pickled-bluewood.dropdown-toggle {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.chip-outline-accent-pickled-bluewood:not(:disabled):not(.disabled):active:focus, .chip-outline-accent-pickled-bluewood:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-accent-pickled-bluewood.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.chip-accent-electric-violet {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}
.chip-accent-electric-violet:hover {
  color: #fff;
  background-color: #881cff;
  border-color: #810fff;
}
.chip-accent-electric-violet:focus, .chip-accent-electric-violet.focus {
  color: #fff;
  background-color: #881cff;
  border-color: #810fff;
  box-shadow: 0 0 0 1px rgba(171, 94, 255, 0.5);
}
.chip-accent-electric-violet.disabled, .chip-accent-electric-violet:disabled {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}
.chip-accent-electric-violet:not(:disabled):not(.disabled):active, .chip-accent-electric-violet:not(:disabled):not(.disabled).active, .show > .chip-accent-electric-violet.dropdown-toggle {
  color: #fff;
  background-color: #810fff;
  border-color: #7b02ff;
}
.chip-accent-electric-violet:not(:disabled):not(.disabled):active:focus, .chip-accent-electric-violet:not(:disabled):not(.disabled).active:focus, .show > .chip-accent-electric-violet.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(171, 94, 255, 0.5);
}

.chip-outline-accent-electric-violet {
  color: #9C42FF;
  border-color: #9C42FF;
}
.chip-outline-accent-electric-violet:hover {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}
.chip-outline-accent-electric-violet:focus, .chip-outline-accent-electric-violet.focus {
  box-shadow: 0 0 0 1px rgba(156, 66, 255, 0.5);
}
.chip-outline-accent-electric-violet.disabled, .chip-outline-accent-electric-violet:disabled {
  color: #9C42FF;
  background-color: transparent;
}
.chip-outline-accent-electric-violet:not(:disabled):not(.disabled):active, .chip-outline-accent-electric-violet:not(:disabled):not(.disabled).active, .show > .chip-outline-accent-electric-violet.dropdown-toggle {
  color: #fff;
  background-color: #9C42FF;
  border-color: #9C42FF;
}
.chip-outline-accent-electric-violet:not(:disabled):not(.disabled):active:focus, .chip-outline-accent-electric-violet:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-accent-electric-violet.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(156, 66, 255, 0.5);
}

.chip-primary-purple {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}
.chip-primary-purple:hover {
  color: #fff;
  background-color: #6b2ddb;
  border-color: #6425d7;
}
.chip-primary-purple:focus, .chip-primary-purple.focus {
  color: #fff;
  background-color: #6b2ddb;
  border-color: #6425d7;
  box-shadow: 0 0 0 1px rgba(149, 105, 230, 0.5);
}
.chip-primary-purple.disabled, .chip-primary-purple:disabled {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}
.chip-primary-purple:not(:disabled):not(.disabled):active, .chip-primary-purple:not(:disabled):not(.disabled).active, .show > .chip-primary-purple.dropdown-toggle {
  color: #fff;
  background-color: #6425d7;
  border-color: #5f23cd;
}
.chip-primary-purple:not(:disabled):not(.disabled):active:focus, .chip-primary-purple:not(:disabled):not(.disabled).active:focus, .show > .chip-primary-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(149, 105, 230, 0.5);
}

.chip-outline-primary-purple {
  color: #824EE1;
  border-color: #824EE1;
}
.chip-outline-primary-purple:hover {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}
.chip-outline-primary-purple:focus, .chip-outline-primary-purple.focus {
  box-shadow: 0 0 0 1px rgba(130, 78, 225, 0.5);
}
.chip-outline-primary-purple.disabled, .chip-outline-primary-purple:disabled {
  color: #824EE1;
  background-color: transparent;
}
.chip-outline-primary-purple:not(:disabled):not(.disabled):active, .chip-outline-primary-purple:not(:disabled):not(.disabled).active, .show > .chip-outline-primary-purple.dropdown-toggle {
  color: #fff;
  background-color: #824EE1;
  border-color: #824EE1;
}
.chip-outline-primary-purple:not(:disabled):not(.disabled):active:focus, .chip-outline-primary-purple:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-primary-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(130, 78, 225, 0.5);
}

.chip-primary-red {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.chip-primary-red:hover {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
}
.chip-primary-red:focus, .chip-primary-red.focus {
  color: #fff;
  background-color: #c80940;
  border-color: #bc093c;
  box-shadow: 0 0 0 1px rgba(240, 48, 103, 0.5);
}
.chip-primary-red.disabled, .chip-primary-red:disabled {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.chip-primary-red:not(:disabled):not(.disabled):active, .chip-primary-red:not(:disabled):not(.disabled).active, .show > .chip-primary-red.dropdown-toggle {
  color: #fff;
  background-color: #bc093c;
  border-color: #b00838;
}
.chip-primary-red:not(:disabled):not(.disabled):active:focus, .chip-primary-red:not(:disabled):not(.disabled).active:focus, .show > .chip-primary-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(240, 48, 103, 0.5);
}

.chip-outline-primary-red {
  color: #ED0B4C;
  border-color: #ED0B4C;
}
.chip-outline-primary-red:hover {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.chip-outline-primary-red:focus, .chip-outline-primary-red.focus {
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}
.chip-outline-primary-red.disabled, .chip-outline-primary-red:disabled {
  color: #ED0B4C;
  background-color: transparent;
}
.chip-outline-primary-red:not(:disabled):not(.disabled):active, .chip-outline-primary-red:not(:disabled):not(.disabled).active, .show > .chip-outline-primary-red.dropdown-toggle {
  color: #fff;
  background-color: #ED0B4C;
  border-color: #ED0B4C;
}
.chip-outline-primary-red:not(:disabled):not(.disabled):active:focus, .chip-outline-primary-red:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-primary-red.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(237, 11, 76, 0.5);
}

.chip-primary-yellow {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.chip-primary-yellow:hover {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
}
.chip-primary-yellow:focus, .chip-primary-yellow.focus {
  color: #212529;
  background-color: #db991e;
  border-color: #d0911d;
  box-shadow: 0 0 0 1px rgba(199, 149, 57, 0.5);
}
.chip-primary-yellow.disabled, .chip-primary-yellow:disabled {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.chip-primary-yellow:not(:disabled):not(.disabled):active, .chip-primary-yellow:not(:disabled):not(.disabled).active, .show > .chip-primary-yellow.dropdown-toggle {
  color: #212529;
  background-color: #d0911d;
  border-color: #c5891b;
}
.chip-primary-yellow:not(:disabled):not(.disabled):active:focus, .chip-primary-yellow:not(:disabled):not(.disabled).active:focus, .show > .chip-primary-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(199, 149, 57, 0.5);
}

.chip-outline-primary-yellow {
  color: #E4A93C;
  border-color: #E4A93C;
}
.chip-outline-primary-yellow:hover {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.chip-outline-primary-yellow:focus, .chip-outline-primary-yellow.focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}
.chip-outline-primary-yellow.disabled, .chip-outline-primary-yellow:disabled {
  color: #E4A93C;
  background-color: transparent;
}
.chip-outline-primary-yellow:not(:disabled):not(.disabled):active, .chip-outline-primary-yellow:not(:disabled):not(.disabled).active, .show > .chip-outline-primary-yellow.dropdown-toggle {
  color: #212529;
  background-color: #E4A93C;
  border-color: #E4A93C;
}
.chip-outline-primary-yellow:not(:disabled):not(.disabled):active:focus, .chip-outline-primary-yellow:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-primary-yellow.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(228, 169, 60, 0.5);
}

.chip-primary-light {
  color: #212529;
  background-color: #99ccff;
  border-color: #99ccff;
}
.chip-primary-light:hover {
  color: #212529;
  background-color: #73b9ff;
  border-color: #66b3ff;
}
.chip-primary-light:focus, .chip-primary-light.focus {
  color: #212529;
  background-color: #73b9ff;
  border-color: #66b3ff;
  box-shadow: 0 0 0 1px rgba(135, 179, 223, 0.5);
}
.chip-primary-light.disabled, .chip-primary-light:disabled {
  color: #212529;
  background-color: #99ccff;
  border-color: #99ccff;
}
.chip-primary-light:not(:disabled):not(.disabled):active, .chip-primary-light:not(:disabled):not(.disabled).active, .show > .chip-primary-light.dropdown-toggle {
  color: #212529;
  background-color: #66b3ff;
  border-color: #59acff;
}
.chip-primary-light:not(:disabled):not(.disabled):active:focus, .chip-primary-light:not(:disabled):not(.disabled).active:focus, .show > .chip-primary-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(135, 179, 223, 0.5);
}

.chip-outline-primary-light {
  color: #99ccff;
  border-color: #99ccff;
}
.chip-outline-primary-light:hover {
  color: #212529;
  background-color: #99ccff;
  border-color: #99ccff;
}
.chip-outline-primary-light:focus, .chip-outline-primary-light.focus {
  box-shadow: 0 0 0 1px rgba(153, 204, 255, 0.5);
}
.chip-outline-primary-light.disabled, .chip-outline-primary-light:disabled {
  color: #99ccff;
  background-color: transparent;
}
.chip-outline-primary-light:not(:disabled):not(.disabled):active, .chip-outline-primary-light:not(:disabled):not(.disabled).active, .show > .chip-outline-primary-light.dropdown-toggle {
  color: #212529;
  background-color: #99ccff;
  border-color: #99ccff;
}
.chip-outline-primary-light:not(:disabled):not(.disabled):active:focus, .chip-outline-primary-light:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-primary-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(153, 204, 255, 0.5);
}

.chip-primary-dodger-blue {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.chip-primary-dodger-blue:hover {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
}
.chip-primary-dodger-blue:focus, .chip-primary-dodger-blue.focus {
  color: #fff;
  background-color: #2f45ff;
  border-color: #2239ff;
  box-shadow: 0 0 0 1px rgba(111, 126, 255, 0.5);
}
.chip-primary-dodger-blue.disabled, .chip-primary-dodger-blue:disabled {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.chip-primary-dodger-blue:not(:disabled):not(.disabled):active, .chip-primary-dodger-blue:not(:disabled):not(.disabled).active, .show > .chip-primary-dodger-blue.dropdown-toggle {
  color: #fff;
  background-color: #2239ff;
  border-color: #152eff;
}
.chip-primary-dodger-blue:not(:disabled):not(.disabled):active:focus, .chip-primary-dodger-blue:not(:disabled):not(.disabled).active:focus, .show > .chip-primary-dodger-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(111, 126, 255, 0.5);
}

.chip-outline-primary-dodger-blue {
  color: #5567FF;
  border-color: #5567FF;
}
.chip-outline-primary-dodger-blue:hover {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.chip-outline-primary-dodger-blue:focus, .chip-outline-primary-dodger-blue.focus {
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}
.chip-outline-primary-dodger-blue.disabled, .chip-outline-primary-dodger-blue:disabled {
  color: #5567FF;
  background-color: transparent;
}
.chip-outline-primary-dodger-blue:not(:disabled):not(.disabled):active, .chip-outline-primary-dodger-blue:not(:disabled):not(.disabled).active, .show > .chip-outline-primary-dodger-blue.dropdown-toggle {
  color: #fff;
  background-color: #5567FF;
  border-color: #5567FF;
}
.chip-outline-primary-dodger-blue:not(:disabled):not(.disabled):active:focus, .chip-outline-primary-dodger-blue:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-primary-dodger-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(85, 103, 255, 0.5);
}

.chip-primary-pickled-bluewood {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.chip-primary-pickled-bluewood:hover {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
}
.chip-primary-pickled-bluewood:focus, .chip-primary-pickled-bluewood.focus {
  color: #fff;
  background-color: #22293d;
  border-color: #1e2335;
  box-shadow: 0 0 0 1px rgba(79, 87, 111, 0.5);
}
.chip-primary-pickled-bluewood.disabled, .chip-primary-pickled-bluewood:disabled {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.chip-primary-pickled-bluewood:not(:disabled):not(.disabled):active, .chip-primary-pickled-bluewood:not(:disabled):not(.disabled).active, .show > .chip-primary-pickled-bluewood.dropdown-toggle {
  color: #fff;
  background-color: #1e2335;
  border-color: #191e2d;
}
.chip-primary-pickled-bluewood:not(:disabled):not(.disabled):active:focus, .chip-primary-pickled-bluewood:not(:disabled):not(.disabled).active:focus, .show > .chip-primary-pickled-bluewood.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(79, 87, 111, 0.5);
}

.chip-outline-primary-pickled-bluewood {
  color: #303956;
  border-color: #303956;
}
.chip-outline-primary-pickled-bluewood:hover {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.chip-outline-primary-pickled-bluewood:focus, .chip-outline-primary-pickled-bluewood.focus {
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}
.chip-outline-primary-pickled-bluewood.disabled, .chip-outline-primary-pickled-bluewood:disabled {
  color: #303956;
  background-color: transparent;
}
.chip-outline-primary-pickled-bluewood:not(:disabled):not(.disabled):active, .chip-outline-primary-pickled-bluewood:not(:disabled):not(.disabled).active, .show > .chip-outline-primary-pickled-bluewood.dropdown-toggle {
  color: #fff;
  background-color: #303956;
  border-color: #303956;
}
.chip-outline-primary-pickled-bluewood:not(:disabled):not(.disabled):active:focus, .chip-outline-primary-pickled-bluewood:not(:disabled):not(.disabled).active:focus, .show > .chip-outline-primary-pickled-bluewood.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(48, 57, 86, 0.5);
}

.brand {
  font-size: 1.625rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  line-height: 1.231;
}
.brand-icon {
  margin-right: 0.5rem;
  display: inline-block;
  vertical-align: sub;
}

.carousel-card .carousel-control-icon {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  background-color: white;
  /* @alternate */
  box-shadow: 0px 3px 1px -2px rgba(39, 44, 51, 0.2), 0px 2px 2px 0px rgba(39, 44, 51, 0.14), 0px 1px 5px 0px rgba(39, 44, 51, 0.12);
  color: #272C33;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel-card .carousel-control-prev,
.carousel-card .carousel-control-next {
  width: 2.5rem;
  opacity: 1;
}
.carousel-card .carousel-control-prev {
  left: -1.25rem;
}
.carousel-card .carousel-control-next {
  right: -1.25rem;
}

.mdk-header {
  margin-bottom: 1rem;
  height: auto;
}
.mdk-header--shadow {
  transition: 0.25s box-shadow;
}
.mdk-header--shadow-show {
  /* @alternate */
  box-shadow: 0px 6px 6px -3px rgba(39, 44, 51, 0.1), 0px 10px 14px 1px rgba(39, 44, 51, 0.04), 0px 4px 18px 3px rgba(39, 44, 51, 0.02);
  border-color: transparent !important;
}
.mdk-header--shadow::after {
  display: none;
}
.mdk-header__bg-front {
  background-position: center;
}
.mdk-header__bg-front::after {
  display: block;
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}
.mdk-header__content {
  display: flex;
  flex-direction: column;
}

.mdk-header--bg-gradient-primary .mdk-header__bg-front::after {
  background-image: linear-gradient(128deg, #1E6DBD 0%, #3287DB 100%);
  opacity: 0.9;
}

.mdk-header--bg-gradient-purple .mdk-header__bg-front::after {
  background-image: linear-gradient(-128deg, #8A4182 0%, #3289DC 100%);
  opacity: 0.9;
}

.mdk-header--bg-white .mdk-header__bg-front::after {
  background-color: rgba(255, 255, 255, 0.9);
}

.mdk-header--bg-primary .mdk-header__bg-front::after {
  background-color: rgba(74, 162, 238, 0.9);
}

.mdk-header--bg-secondary .mdk-header__bg-front::after {
  background-color: rgba(134, 142, 150, 0.9);
}

.mdk-header--bg-success .mdk-header__bg-front::after {
  background-color: rgba(119, 193, 58, 0.9);
}

.mdk-header--bg-info .mdk-header__bg-front::after {
  background-color: rgba(23, 162, 184, 0.9);
}

.mdk-header--bg-warning .mdk-header__bg-front::after {
  background-color: rgba(228, 169, 60, 0.9);
}

.mdk-header--bg-danger .mdk-header__bg-front::after {
  background-color: rgba(217, 83, 79, 0.9);
}

.mdk-header--bg-light .mdk-header__bg-front::after {
  background-color: rgba(248, 249, 250, 0.9);
}

.mdk-header--bg-dark .mdk-header__bg-front::after {
  background-color: rgba(48, 56, 64, 0.9);
}

.mdk-header--bg-black .mdk-header__bg-front::after {
  background-color: rgba(39, 44, 51, 0.9);
}

.mdk-header--bg-accent .mdk-header__bg-front::after {
  background-color: rgba(0, 188, 194, 0.9);
}

.mdk-header--bg-accent-red .mdk-header__bg-front::after {
  background-color: rgba(237, 11, 76, 0.9);
}

.mdk-header--bg-accent-yellow .mdk-header__bg-front::after {
  background-color: rgba(228, 169, 60, 0.9);
}

.mdk-header--bg-accent-dodger-blue .mdk-header__bg-front::after {
  background-color: rgba(85, 103, 255, 0.9);
}

.mdk-header--bg-accent-pickled-bluewood .mdk-header__bg-front::after {
  background-color: rgba(48, 57, 86, 0.9);
}

.mdk-header--bg-accent-electric-violet .mdk-header__bg-front::after {
  background-color: rgba(156, 66, 255, 0.9);
}

.mdk-header--bg-primary-purple .mdk-header__bg-front::after {
  background-color: rgba(130, 78, 225, 0.9);
}

.mdk-header--bg-primary-red .mdk-header__bg-front::after {
  background-color: rgba(237, 11, 76, 0.9);
}

.mdk-header--bg-primary-yellow .mdk-header__bg-front::after {
  background-color: rgba(228, 169, 60, 0.9);
}

.mdk-header--bg-primary-light .mdk-header__bg-front::after {
  background-color: rgba(153, 204, 255, 0.9);
}

.mdk-header--bg-primary-dodger-blue .mdk-header__bg-front::after {
  background-color: rgba(85, 103, 255, 0.9);
}

.mdk-header--bg-primary-pickled-bluewood .mdk-header__bg-front::after {
  background-color: rgba(48, 57, 86, 0.9);
}

@media (min-width: 992px) {
  .hero h1, .hero .h1 {
    font-size: 3.998rem;
    font-weight: 700;
    line-height: 1.25;
  }
  .hero .lead, .hero__lead {
    font-size: 1.414rem;
    line-height: 1.414;
  }
  .hero .lead:not(:last-child), .hero__lead:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.mdk-box__bg-front {
  background-position: center;
}
.mdk-box__bg-front::after {
  display: block;
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
}

.mdk-box--bg-gradient-primary .mdk-box__bg-front::after {
  background-image: linear-gradient(128deg, #1E6DBD 0%, #3287DB 100%);
  opacity: 0.9;
}

.mdk-box--bg-primary .mdk-box__bg-front::after {
  background-color: rgba(74, 162, 238, 0.9);
}

.mdk-box--bg-secondary .mdk-box__bg-front::after {
  background-color: rgba(134, 142, 150, 0.9);
}

.mdk-box--bg-success .mdk-box__bg-front::after {
  background-color: rgba(119, 193, 58, 0.9);
}

.mdk-box--bg-info .mdk-box__bg-front::after {
  background-color: rgba(23, 162, 184, 0.9);
}

.mdk-box--bg-warning .mdk-box__bg-front::after {
  background-color: rgba(228, 169, 60, 0.9);
}

.mdk-box--bg-danger .mdk-box__bg-front::after {
  background-color: rgba(217, 83, 79, 0.9);
}

.mdk-box--bg-light .mdk-box__bg-front::after {
  background-color: rgba(248, 249, 250, 0.9);
}

.mdk-box--bg-dark .mdk-box__bg-front::after {
  background-color: rgba(48, 56, 64, 0.9);
}

.mdk-box--bg-black .mdk-box__bg-front::after {
  background-color: rgba(39, 44, 51, 0.9);
}

.mdk-box--bg-accent .mdk-box__bg-front::after {
  background-color: rgba(0, 188, 194, 0.9);
}

.mdk-box--bg-accent-red .mdk-box__bg-front::after {
  background-color: rgba(237, 11, 76, 0.9);
}

.mdk-box--bg-accent-yellow .mdk-box__bg-front::after {
  background-color: rgba(228, 169, 60, 0.9);
}

.mdk-box--bg-accent-dodger-blue .mdk-box__bg-front::after {
  background-color: rgba(85, 103, 255, 0.9);
}

.mdk-box--bg-accent-pickled-bluewood .mdk-box__bg-front::after {
  background-color: rgba(48, 57, 86, 0.9);
}

.mdk-box--bg-accent-electric-violet .mdk-box__bg-front::after {
  background-color: rgba(156, 66, 255, 0.9);
}

.mdk-box--bg-primary-purple .mdk-box__bg-front::after {
  background-color: rgba(130, 78, 225, 0.9);
}

.mdk-box--bg-primary-red .mdk-box__bg-front::after {
  background-color: rgba(237, 11, 76, 0.9);
}

.mdk-box--bg-primary-yellow .mdk-box__bg-front::after {
  background-color: rgba(228, 169, 60, 0.9);
}

.mdk-box--bg-primary-light .mdk-box__bg-front::after {
  background-color: rgba(153, 204, 255, 0.9);
}

.mdk-box--bg-primary-dodger-blue .mdk-box__bg-front::after {
  background-color: rgba(85, 103, 255, 0.9);
}

.mdk-box--bg-primary-pickled-bluewood .mdk-box__bg-front::after {
  background-color: rgba(48, 57, 86, 0.9);
}

.mdk-box--bg-white-25 .mdk-box__bg-front::after {
  background-color: rgba(255, 255, 255, 0.25);
}

.mdk-box--bg-white-35 .mdk-box__bg-front::after {
  background-color: rgba(255, 255, 255, 0.35);
}

.mdk-box--bg-white-45 .mdk-box__bg-front::after {
  background-color: rgba(255, 255, 255, 0.45);
}

.mdk-box--bg-white-90 .mdk-box__bg-front::after {
  background-color: rgba(255, 255, 255, 0.9);
}

.mdk-box--bg-white-95 .mdk-box__bg-front::after {
  background-color: rgba(255, 255, 255, 0.95);
}

.will-shrink-to-hidden {
  will-change: transform;
  transition: 0.2s transform;
}

.shrink-to-hidden {
  transform: scale3d(0, 0, 0);
}

.will-fade-background {
  transition: 0.2s background-color;
}

.search-form {
  display: flex;
  align-items: center;
  background-color: white;
  border: 1px solid #EDF0F2;
  border-radius: 0.25rem;
}
.search-form .form-control {
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
}
.search-form .form-control:focus {
  border-color: transparent;
  box-shadow: none;
}
.search-form .btn {
  padding: 0 0.75rem;
  box-shadow: none;
  background-color: transparent;
}
.search-form .btn + .form-control {
  padding-left: 0;
}

.search-form--black, .navbar-dark .navbar-search {
  background-color: #272C33;
  border-color: #272C33;
}
.search-form--black .form-control, .navbar-dark .navbar-search .form-control {
  color: #fff;
}
.search-form--black .form-control:focus, .navbar-dark .navbar-search .form-control:focus {
  background-color: #272C33 !important;
}
.search-form--black .btn, .navbar-dark .navbar-search .btn {
  background: transparent;
  color: #868e96;
}

.search-form--light, .navbar-light .navbar-search {
  background-color: #EDF0F2;
  border-color: #EDF0F2;
}
.search-form--light .form-control, .navbar-light .navbar-search .form-control {
  color: #272C33;
}
.search-form--light .form-control::placeholder, .navbar-light .navbar-search .form-control::placeholder {
  color: #949595;
}
.search-form--light .btn, .navbar-light .navbar-search .btn {
  color: rgba(39, 44, 51, 0.4);
}

.stack {
  margin-bottom: calc(1rem + 10px);
  position: relative;
  overflow: initial;
  z-index: 0;
}
.stack::before, .stack::after {
  position: absolute;
  top: 100%;
  left: 10px;
  right: 10px;
  transition: 0.2s transform ease-in-out;
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.07), 0px 2px 1px -1px #dfe2e6 inset, 0px 1px 2px 0px rgba(223, 226, 230, 0.14) inset, 0px 1px 3px 0px rgba(48, 56, 64, 0.12) inset;
  display: block;
  content: "";
  background: #ffffff;
  height: 5px;
  z-index: -1;
  pointer-events: none;
}
.stack::before {
  height: 10px;
  left: 20px;
  right: 20px;
}
.stack--hidden::before, .stack--hidden::after, .stack--hidden-hover:hover::before, .stack--hidden-hover:hover::after {
  transform: translate3d(0, -12px, 0);
}
.stack--primary-dark:before, .stack--primary-dark:after {
  background: #1b8aea;
  box-shadow: 0 2px 0 #1685e5 inset, 0 2px 0 #1685e5;
}
.stack--1::before {
  display: none;
}

.footer {
  background-color: white;
}

label {
  font-weight: 500;
}

.form-control-rounded {
  border-radius: 20rem;
}

.form-control-flush {
  padding: 0;
  border-width: 0;
  box-shadow: none;
  background-color: transparent;
}
.form-control-flush:focus {
  background-color: transparent;
  box-shadow: none;
}

.list-group-form .list-group-item {
  padding: 1rem 1.5rem;
}

.custom-controls-stacked .custom-control:last-child {
  margin-bottom: 0;
}

.form-inline label {
  margin-right: 0.25rem;
}

.form-label {
  color: #272C33;
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-family: "Exo 2", "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
}

.card-form .card-body {
  padding: 1.6875rem;
}
.card-form .card-body-form-group {
  padding: 1rem;
  padding-bottom: 0rem;
}
.card-form__body {
  background-color: #f8fafc;
  border-radius: 0.5rem 0.5rem 0 0;
}
@media (min-width: 576px) {
  .card-form__body {
    border-radius: 0.5rem 0 0 0.5rem;
  }
}
.card-form__body label:not([class]) {
  color: #272C33;
  text-transform: uppercase;
  font-size: 0.8125rem;
  font-family: "Exo 2", "Helvetica Neue", Arial, sans-serif;
  font-weight: 600;
  letter-spacing: 2px;
}

.form-image-group [disabled] ~ label::after,
.form-image-group :disabled ~ label::after {
  display: block;
  position: absolute;
  left: 4px;
  right: 4px;
  top: 4px;
  bottom: 4px;
  background: #e9ecef;
  width: initial;
  height: initial;
  border-radius: 0;
  content: " ";
  opacity: 0.4;
}

.input-group-text .material-icons {
  font-size: inherit;
}

.input-group.input-group-merge .form-control {
  box-shadow: none;
}
.input-group.input-group-merge .form-control:focus ~ [class*=input-group] .input-group-text {
  border-color: white;
  color: #272C33;
  background-color: white;
}
.input-group.input-group-merge .form-control.is-valid ~ [class*=input-group] .input-group-text, .was-validated .input-group.input-group-merge .form-control:valid ~ [class*=input-group] .input-group-text {
  border-color: #77c13a;
  color: #77c13a;
}
.input-group.input-group-merge .form-control.is-invalid ~ [class*=input-group] .input-group-text, .was-validated .input-group.input-group-merge .form-control:invalid ~ [class*=input-group] .input-group-text {
  border-color: #d9534f;
  color: #d9534f;
}
.input-group.input-group-merge .form-control-prepended {
  padding-left: 0;
  border-left-width: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.input-group.input-group-merge .form-control-appended {
  padding-right: 0;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.input-group-merge .input-group-prepend {
  order: -1;
}
.input-group.input-group-merge .input-group-prepend > .input-group-text {
  border-right-width: 0 !important;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.input-group.input-group-merge .input-group-append > .input-group-text {
  border-left-width: 0 !important;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.custom-file-naked {
  cursor: pointer;
  width: auto;
  height: auto;
}
.custom-file-naked .custom-file-input {
  width: auto;
  height: auto;
  cursor: pointer;
  line-height: 1;
}
.custom-file-naked .custom-file-label {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  height: auto;
  cursor: pointer;
  line-height: 1;
  box-shadow: none;
}
.custom-file-naked .custom-file-label::after {
  display: none;
}

.custom-radio-icon {
  padding-left: 1rem;
}
.custom-radio-icon .custom-control-indicator {
  font-size: 1rem;
  color: white;
  background-color: transparent;
  background-image: none;
}
.custom-radio-icon .custom-control-input:checked ~ .custom-control-indicator {
  background-color: transparent;
  background-image: none;
  color: #4AA2EE;
}
.custom-radio-icon .custom-control-input:active ~ .custom-control-indicator {
  background-color: transparent;
  background-image: none;
  color: #d6eafb;
}

.custom-select-icon {
  position: relative;
  z-index: 0;
}
.custom-select-icon__select {
  padding-left: 1.75rem;
}
.custom-select-icon__select.form-control-lg {
  padding-left: 2rem;
}
.custom-select-icon__icon {
  position: absolute;
  left: 0;
  top: 0;
  padding-left: 0.75rem;
  padding-top: 0.5rem;
  line-height: inherit !important;
  font-size: 0.8125rem;
  z-index: 2;
  pointer-events: none;
}
.form-control-sm + .custom-select-icon__icon, .custom-select-sm + .custom-select-icon__icon {
  font-size: 0.7109375rem;
}
.form-control-lg + .custom-select-icon__icon {
  padding-top: 0.5rem;
  font-size: 1.21875rem;
}

.sidebar-p-a {
  padding: 1.5rem 1.5rem;
}

.sidebar-p-x {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.sidebar-p-y {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.sidebar-p-t {
  padding-top: 1.5rem;
}

.sidebar-p-b {
  padding-bottom: 1.5rem;
}

.sidebar-p-l {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.sidebar-p-r {
  padding-right: 1.5rem;
}

.sidebar-m-a {
  margin: 1.5rem 1.5rem;
}

.sidebar-m-x {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.sidebar-m-y {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.sidebar-m-t {
  margin-top: 1.5rem;
}

.sidebar-m-b {
  margin-bottom: 1.5rem;
}

.sidebar-m-l {
  margin-left: 1.5rem;
}

.sidebar-m-r {
  margin-right: 1.5rem;
}

.sidebar-b-a {
  border: 1px solid transparent;
}

.sidebar-b-x {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
}

.sidebar-b-y {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
}

.sidebar-b-t {
  border-top: 1px solid transparent;
}

.sidebar-b-b {
  border-bottom: 1px solid transparent;
}

.sidebar-block {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
}

.sidebar > p,
.sidebar > .sidebar-text {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
}

.sidebar-brand {
  font-weight: 500;
  font-size: 1.625rem;
  margin-bottom: 0.75rem;
  display: flex;
  align-items: center;
}
.sidebar-brand:hover {
  text-decoration: none;
}

.sidebar-brand-icon {
  margin-right: 0.75rem;
}

.sidebar-brand-header {
  height: 56px;
  line-height: 56px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
}

.sidebar-brand-border {
  border-bottom: 1px solid transparent;
}

.sidebar-heading {
  font-weight: 600;
  font-size: 0.9rem;
  font-family: "Exo 2", "Helvetica Neue", Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: normal;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 1.5rem;
}

.sidebar-badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.sidebar-light {
  color: rgba(0, 0, 0, 0.54);
  background: #ffffff;
}
.sidebar-light.sidebar-left {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #e5e5e5;
}
.sidebar-light.sidebar-right {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: #e5e5e5;
}
.sidebar-light .sidebar-link {
  color: inherit;
}
.sidebar-light [class*=sidebar-b-] {
  border-color: #e5e5e5;
}
.sidebar-light .sidebar-text, .sidebar-light p {
  color: rgba(0, 0, 0, 0.54);
}
.sidebar-light .sidebar-heading, .sidebar-light .sidebar-heading > a {
  color: #949799;
}
.sidebar-light hr {
  border-color: #e5e5e5;
}
.sidebar-light .sidebar-brand {
  color: #272C33;
}
.sidebar-light .sidebar-brand-bg {
  background: rgba(0, 0, 0, 0.07);
}
.sidebar-light .sidebar-brand-border {
  border-color: #e5e5e5;
}
.sidebar-light .sidebar-menu-button {
  color: rgba(47, 55, 64, 0.4);
}
.sidebar-light .sidebar-menu-toggle-icon {
  color: rgba(0, 0, 0, 0.24);
}
.sidebar-light .sidebar-menu-icon {
  color: rgba(47, 55, 64, 0.2);
}
.sidebar-light .sidebar-menu-label {
  background: rgba(0, 0, 0, 0.3);
  color: #ffffff;
}
.sidebar-light .sidebar-menu-button:hover {
  color: #8A9199;
  background: transparent;
}
.sidebar-light .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #8A9199;
}
.sidebar-light .sidebar-menu-button:hover .sidebar-menu-toggle-icon {
  color: #8A9199;
}
.sidebar-light .open {
  background: #F5F7FA;
}
.sidebar-light .open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #8A9199;
}
.sidebar-light .open > .sidebar-menu-button {
  background: transparent;
  color: #8A9199;
}
.sidebar-light .open > .sidebar-menu-button .sidebar-menu-icon {
  color: #8A9199;
}
.sidebar-light .active > .sidebar-menu-button {
  color: #8A9199;
}
.sidebar-light .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #8A9199;
}
.sidebar-light .sidebar-submenu .sidebar-menu-button {
  color: rgba(47, 55, 64, 0.4);
}
.sidebar-light .sidebar-submenu .sidebar-menu-icon {
  color: rgba(47, 55, 64, 0.2);
}
.sidebar-light .sidebar-submenu .sidebar-menu-button:hover {
  color: #8A9199;
}
.sidebar-light .sidebar-submenu .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #8A9199;
}
.sidebar-light .sidebar-submenu .active > .sidebar-menu-button {
  color: #8A9199;
}
.sidebar-light .sidebar-submenu .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #8A9199;
}
.sidebar-light .sm-bordered {
  border-color: #e5e5e5;
}
.sidebar-light .sm-bordered.sidebar-submenu {
  border-color: #e5e5e5;
}
.sidebar-light .sm-item-bordered > .sidebar-menu-item {
  border-color: #E9EDF2;
}
.sidebar-light .sm-item-bordered.sidebar-submenu > .sidebar-menu-item {
  border-color: #E9EDF2;
}
.sidebar-light .sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(47, 55, 64, 0.2);
}
.sidebar-light .sm-icons-block > .sidebar-menu-item.open > .sidebar-menu-button .sidebar-menu-icon {
  color: #8A9199;
  background: rgba(0, 0, 0, 0.12);
}
.sidebar-light .sm-icons-block > .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-icon {
  background: #4AA2EE;
  color: #fff;
}
.sidebar-light .sm-active-button-bg > .active > .sidebar-menu-button {
  background: #4AA2EE;
  color: #fff;
}
.sidebar-light .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #fff;
}
.sidebar-light .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #fff;
}
.sidebar-light .sm-icons-block.sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  background: #4AA2EE;
}

.sidebar-dark {
  color: rgba(255, 255, 255, 0.54);
  background: #303840;
}
.sidebar-dark.sidebar-left {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.15);
}
.sidebar-dark.sidebar-right {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(0, 0, 0, 0.15);
}
.sidebar-dark .sidebar-link {
  color: #fff;
}
.sidebar-dark [class*=sidebar-b-] {
  border-color: rgba(46, 49, 51, 0.9);
}
.sidebar-dark .sidebar-text, .sidebar-dark p {
  color: rgba(255, 255, 255, 0.54);
}
.sidebar-dark .sidebar-heading, .sidebar-dark .sidebar-heading > a {
  color: #949799;
}
.sidebar-dark hr {
  border-color: rgba(46, 49, 51, 0.9);
}
.sidebar-dark .sidebar-brand {
  color: #ffffff;
}
.sidebar-dark .sidebar-brand-bg {
  background: rgba(0, 0, 0, 0.12);
}
.sidebar-dark .sidebar-brand-border {
  border-color: rgba(46, 49, 51, 0.9);
}
.sidebar-dark .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}
.sidebar-dark .sidebar-menu-toggle-icon {
  color: rgba(172, 182, 191, 0.5);
}
.sidebar-dark .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-dark .sidebar-menu-button:hover {
  color: #ffffff;
  background: transparent;
}
.sidebar-dark .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #8A9199;
}
.sidebar-dark .sidebar-menu-button:hover .sidebar-menu-toggle-icon {
  color: #8A9199;
}
.sidebar-dark .open {
  background: #272C33;
}
.sidebar-dark .open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #8A9199;
}
.sidebar-dark .open > .sidebar-menu-button {
  background: transparent;
  color: #8A9199;
}
.sidebar-dark .open > .sidebar-menu-button .sidebar-menu-icon {
  color: #8A9199;
}
.sidebar-dark .active > .sidebar-menu-button {
  color: white;
}
.sidebar-dark .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}
.sidebar-dark .sidebar-submenu .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}
.sidebar-dark .sidebar-submenu .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-dark .sidebar-submenu .sidebar-menu-button:hover {
  color: #ffffff;
}
.sidebar-dark .sidebar-submenu .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #ffffff;
}
.sidebar-dark .sidebar-submenu .active > .sidebar-menu-button {
  color: white;
}
.sidebar-dark .sidebar-submenu .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}
.sidebar-dark .sm-bordered {
  border-color: rgba(46, 49, 51, 0.9);
}
.sidebar-dark .sm-bordered.sidebar-submenu {
  border-color: rgba(46, 49, 51, 0.9);
}
.sidebar-dark .sm-item-bordered > .sidebar-menu-item {
  border-color: rgba(46, 49, 51, 0.9);
}
.sidebar-dark .sm-item-bordered.sidebar-submenu > .sidebar-menu-item {
  border-color: rgba(46, 49, 51, 0.9);
}
.sidebar-dark .sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-dark .sm-icons-block > .sidebar-menu-item.open > .sidebar-menu-button .sidebar-menu-icon {
  color: #8A9199;
  background: rgba(0, 0, 0, 0.12);
}
.sidebar-dark .sm-icons-block > .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}
.sidebar-dark .sm-active-button-bg > .active > .sidebar-menu-button {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}
.sidebar-dark .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #fff;
}
.sidebar-dark .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #fff;
}
.sidebar-dark .sm-icons-block.sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
}

.sidebar-menu {
  margin-bottom: 2rem;
  padding: 0 0;
  list-style: none;
}

.sidebar-menu-item {
  position: relative;
  overflow: hidden;
}

.sidebar-menu-button {
  display: flex;
  align-items: center;
  font-weight: 500;
  line-height: 40px;
  padding: 0 1.5rem;
  font-size: 15px;
}
.sidebar-menu-button, .sidebar-menu-button:hover, .sidebar-menu-button:focus {
  text-decoration: none;
}

.active > .sidebar-menu-button {
  font-weight: 500;
}

.sidebar-menu-toggle-icon {
  position: relative;
  font-size: 1.25rem;
}

.sidebar-menu-toggle-icon:before {
  font-family: "Material Icons";
  content: "\e315";
  display: flex;
  align-items: center;
  font-size: inherit;
  color: inherit;
}

.open > .sidebar-menu-button .sidebar-menu-toggle-icon:before {
  content: "\e315";
}

.sidebar-menu-icon {
  vertical-align: middle;
  font-size: 24px;
  display: inline-block;
  line-height: normal;
  position: relative;
}
.sidebar-menu-icon--left {
  margin-right: 0.5rem;
}
.sidebar-menu-icon--right {
  margin-left: 0.5rem;
}

.sidebar-menu-badge {
  padding: 0.2rem 0.3rem;
  font-size: 0.75rem;
}

.sidebar-menu-badge,
.sidebar-menu-toggle-icon {
  margin-left: 8px;
}

.sidebar-submenu {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
}
.open > .sidebar-submenu {
  display: block;
}
.sidebar-submenu .sidebar-menu-button {
  line-height: 32px;
  font-size: 15px;
}
.sidebar-submenu .sidebar-menu-icon {
  font-size: 24px;
}

.sm-icons-1 .sidebar-menu-icon {
  font-size: 1rem;
}

.sm-icons-small .sidebar-menu-icon {
  font-size: 0.875rem;
}

.sm-condensed > .sidebar-menu-item > .sidebar-menu-button {
  line-height: 30px;
}

.sm-condensed.sidebar-submenu > .sidebar-menu-item > .sidebar-menu-button {
  line-height: 24px;
}

.sm-bordered {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.sm-item-bordered > .sidebar-menu-item {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.sm-item-bordered > .sidebar-menu-item:last-of-type {
  border-bottom: none;
}
.sm-item-bordered .sidebar-submenu {
  border-bottom: none;
}

.sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  text-align: center;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  width: 30px;
}

.sm-icons-block.sidebar-submenu > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  height: 30px;
  line-height: 30px;
  width: 30px;
}

.sm-indent > .sidebar-menu-item .sidebar-menu-button {
  padding-left: 3rem;
}

.mdk-drawer {
  z-index: 1;
}

.mdk-drawer__content {
  background: transparent;
}

.sidebar {
  position: relative;
  height: 100%;
  text-align: initial;
  overscroll-behavior: none;
}

.sidebar-brand {
  margin-bottom: 0;
  height: 64px;
  flex-direction: column;
  padding: 2.5rem 0;
}

.sidebar-brand-icon {
  display: flex;
  width: 40px;
  height: 40px;
}

.sidebar-menu-toggle-icon {
  transition: transform 0.15s;
  transform: translate(0, 0);
}

.open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  transform: rotate(90deg);
}

.sidebar-submenu .sidebar-menu-item:first-child {
  padding-top: 0.75rem;
}
.sidebar-submenu .sidebar-menu-item:last-child {
  padding-bottom: 0.75rem;
}

.sidebar-menu-item.active .sidebar-menu-button::after {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  border-radius: 1px;
  background-color: #4AA2EE;
  content: "";
}

.sidebar-menu-item.active .sidebar-menu-button::after, .sidebar-menu-item.open .sidebar-menu-button::after {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  border-radius: 1px;
  content: "";
}

.sidebar-menu-item.open > .sidebar-menu-button::after {
  background-color: rgba(172, 182, 191, 0.2);
}

.sidebar-menu-item.active > .sidebar-menu-button::after {
  background-color: #4AA2EE;
}

.sidebar-menu:last-child {
  margin-bottom: 0;
}

.sidebar-brand {
  height: auto;
  line-height: 2rem;
  margin-right: 0;
}
.sidebar-brand-icon {
  margin-right: 0;
  margin-bottom: 0.5rem;
}

.sidebar-heading {
  line-height: 1.5rem;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

.sidebar-menu > .sidebar-menu-item {
  margin-right: 1rem;
  border-radius: 0 10px 10px 0;
}

.sidebar-menu-button {
  padding-right: 0.5rem;
}
.sidebar-menu-button.disabled, .sidebar-menu-button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.sidebar-menu-badge {
  padding: 0 0.5rem;
}

.sm-indent > .sidebar-menu-item .sidebar-menu-button {
  padding-left: 2.1875rem;
}

.sidebar-submenu .sidebar-menu-item:first-child {
  padding-top: 0;
}

.sidebar-menu-item.active .sidebar-menu-button::after, .sidebar-menu-item.open .sidebar-menu-button::after {
  display: none;
}

.sidebar-submenu .sidebar-menu-text {
  padding-left: 1.25rem;
  position: relative;
}
.sidebar-submenu .sidebar-menu-text::after {
  content: "";
  width: 8px;
  height: 8px;
  position: absolute;
  background-color: #00BCC2;
  display: none;
  left: -4px;
  top: 12px;
  border-radius: 100%;
}

.active > .sidebar-menu-button .sidebar-menu-text::after {
  display: block;
}

.sidebar-account {
  border-radius: 100px;
  /* @alternate */
  box-shadow: 0px 2px 1px -1px rgba(39, 44, 51, 0.1), 0px 1px 1px 0px rgba(39, 44, 51, 0.04), 0px 1px 3px 0px rgba(39, 44, 51, 0.02);
}

.sidebar-dark .sidebar-submenu .sidebar-menu-text {
  border-left: 1px solid rgba(46, 49, 51, 0.9);
}

.sidebar-dark .sidebar-account {
  background: white;
}

.sidebar-dark .navbar-toggler {
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-dark .navbar-toggler:hover {
  color: #8A9199;
}

.sidebar-dark {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.1), 0px 3px 4px 0px rgba(0, 0, 0, 0.04), 0px 1px 8px 0px rgba(0, 0, 0, 0.02);
}
.sidebar-dark .text-100 {
  color: rgba(255, 255, 255, 0.54) !important;
}
.sidebar-dark .text-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.sidebar-black {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
  color: rgba(255, 255, 255, 0.54);
  background: #1D2126;
}
.sidebar-black.sidebar-left {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.15);
}
.sidebar-black.sidebar-right {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(0, 0, 0, 0.15);
}
.sidebar-black .sidebar-link {
  color: #fff;
}
.sidebar-black [class*=sidebar-b-] {
  border-color: rgba(46, 49, 51, 0.9);
}
.sidebar-black .sidebar-text, .sidebar-black p {
  color: rgba(255, 255, 255, 0.54);
}
.sidebar-black .text-100 {
  color: rgba(255, 255, 255, 0.54);
}
.sidebar-black .text-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}
.sidebar-black .sidebar-heading, .sidebar-black .sidebar-heading > a {
  color: #949799;
}
.sidebar-black hr {
  border-color: rgba(46, 49, 51, 0.9);
}
.sidebar-black .sidebar-brand {
  color: #ffffff;
}
.sidebar-black .sidebar-brand-bg {
  background: rgba(0, 0, 0, 0.12);
}
.sidebar-black .sidebar-brand-border {
  border-color: rgba(46, 49, 51, 0.9);
}
.sidebar-black .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}
.sidebar-black .sidebar-menu-toggle-icon {
  color: rgba(172, 182, 191, 0.5);
}
.sidebar-black .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-black .sidebar-menu-button:hover {
  color: #ffffff;
  background: transparent;
}
.sidebar-black .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #8A9199;
}
.sidebar-black .sidebar-menu-button:hover .sidebar-menu-toggle-icon {
  color: #8A9199;
}
.sidebar-black .open {
  background: rgba(0, 0, 0, 0.2);
}
.sidebar-black .open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #8A9199;
}
.sidebar-black .open > .sidebar-menu-button {
  background: transparent;
  color: #8A9199;
}
.sidebar-black .open > .sidebar-menu-button .sidebar-menu-icon {
  color: #8A9199;
}
.sidebar-black .active > .sidebar-menu-button {
  color: white;
}
.sidebar-black .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}
.sidebar-black .sidebar-submenu .sidebar-menu-text {
  border-left: 1px solid rgba(46, 49, 51, 0.9);
}
.sidebar-black .sidebar-submenu .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}
.sidebar-black .sidebar-submenu .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-black .sidebar-submenu .sidebar-menu-button:hover {
  color: #ffffff;
}
.sidebar-black .sidebar-submenu .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #ffffff;
}
.sidebar-black .sidebar-submenu .active > .sidebar-menu-button {
  color: white;
}
.sidebar-black .sidebar-submenu .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}
.sidebar-black .sm-bordered {
  border-color: rgba(46, 49, 51, 0.9);
}
.sidebar-black .sm-bordered.sidebar-submenu {
  border-color: rgba(46, 49, 51, 0.9);
}
.sidebar-black .sm-item-bordered > .sidebar-menu-item {
  border-color: rgba(0, 0, 0, 0.15);
}
.sidebar-black .sm-item-bordered.sidebar-submenu > .sidebar-menu-item {
  border-color: rgba(0, 0, 0, 0.15);
}
.sidebar-black .sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-black .sm-icons-block > .sidebar-menu-item.open > .sidebar-menu-button .sidebar-menu-icon {
  color: #8A9199;
  background: rgba(0, 0, 0, 0.12);
}
.sidebar-black .sm-icons-block > .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}
.sidebar-black .sm-active-button-bg > .active > .sidebar-menu-button {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}
.sidebar-black .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #fff;
}
.sidebar-black .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #fff;
}
.sidebar-black .sm-icons-block.sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
}
.sidebar-black .sidebar-account {
  background: white;
}
.sidebar-black .sidebar-submenu .sidebar-menu-text::after {
  background-color: #ED0B4C;
}
.sidebar-black .search-form--black, .sidebar-black .navbar-dark .navbar-search, .navbar-dark .sidebar-black .navbar-search {
  background-color: #303840;
  border-color: #303840;
}
.sidebar-black .search-form--black .form-control, .sidebar-black .navbar-dark .navbar-search .form-control, .navbar-dark .sidebar-black .navbar-search .form-control {
  color: #fff;
}
.sidebar-black .search-form--black .form-control::placeholder, .sidebar-black .navbar-dark .navbar-search .form-control::placeholder, .navbar-dark .sidebar-black .navbar-search .form-control::placeholder {
  color: #8A9199;
}
.sidebar-black .search-form--black .form-control:focus, .sidebar-black .navbar-dark .navbar-search .form-control:focus, .navbar-dark .sidebar-black .navbar-search .form-control:focus {
  background-color: transparent !important;
}
.sidebar-black .search-form--black .btn, .sidebar-black .navbar-dark .navbar-search .btn, .navbar-dark .sidebar-black .navbar-search .btn {
  background: transparent;
  color: #8A9199;
}
.sidebar-black .navbar-toggler {
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-black .navbar-toggler:hover {
  color: #8A9199;
}

.sidebar-black-dodger-blue {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
  color: rgba(255, 255, 255, 0.54);
  background: #22242E;
}
.sidebar-black-dodger-blue.sidebar-left {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.15);
}
.sidebar-black-dodger-blue.sidebar-right {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(0, 0, 0, 0.15);
}
.sidebar-black-dodger-blue .sidebar-link {
  color: #fff;
}
.sidebar-black-dodger-blue [class*=sidebar-b-] {
  border-color: #19191A;
}
.sidebar-black-dodger-blue .sidebar-text,
.sidebar-black-dodger-blue .text-100,
.sidebar-black-dodger-blue p {
  color: white !important;
}
.sidebar-black-dodger-blue .text-50 {
  color: rgba(172, 182, 191, 0.5) !important;
}
.sidebar-black-dodger-blue .sidebar-heading, .sidebar-black-dodger-blue .sidebar-heading > a {
  color: #8A9199;
}
.sidebar-black-dodger-blue hr {
  border-color: #19191A;
}
.sidebar-black-dodger-blue .sidebar-brand {
  color: #ffffff;
}
.sidebar-black-dodger-blue .sidebar-brand-bg {
  background: rgba(0, 0, 0, 0.12);
}
.sidebar-black-dodger-blue .sidebar-brand-border {
  border-color: rgba(46, 49, 51, 0.9);
}
.sidebar-black-dodger-blue .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}
.sidebar-black-dodger-blue .sidebar-menu-toggle-icon {
  color: rgba(172, 182, 191, 0.5);
}
.sidebar-black-dodger-blue .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-black-dodger-blue .sidebar-menu-button:hover {
  color: #ffffff;
  background: transparent;
}
.sidebar-black-dodger-blue .sidebar-menu-button:hover .sidebar-menu-icon {
  color: white;
}
.sidebar-black-dodger-blue .sidebar-menu-button:hover .sidebar-menu-toggle-icon {
  color: white;
}
.sidebar-black-dodger-blue .open {
  background: #1D1E26;
}
.sidebar-black-dodger-blue .open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-black-dodger-blue .open > .sidebar-menu-button {
  background: transparent;
  color: #8A9199;
}
.sidebar-black-dodger-blue .open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-black-dodger-blue .active > .sidebar-menu-button {
  color: white;
}
.sidebar-black-dodger-blue .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}
.sidebar-black-dodger-blue .sidebar-submenu .sidebar-menu-text {
  border-left: 1px solid #19191A;
}
.sidebar-black-dodger-blue .sidebar-submenu .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}
.sidebar-black-dodger-blue .sidebar-submenu .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-black-dodger-blue .sidebar-submenu .sidebar-menu-button:hover {
  color: #ffffff;
}
.sidebar-black-dodger-blue .sidebar-submenu .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #ffffff;
}
.sidebar-black-dodger-blue .sidebar-submenu .active > .sidebar-menu-button {
  color: white;
}
.sidebar-black-dodger-blue .sidebar-submenu .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}
.sidebar-black-dodger-blue .sm-bordered {
  border-color: #19191A;
}
.sidebar-black-dodger-blue .sm-bordered.sidebar-submenu {
  border-color: #19191A;
}
.sidebar-black-dodger-blue .sm-item-bordered > .sidebar-menu-item {
  border-color: #19191A;
}
.sidebar-black-dodger-blue .sm-item-bordered.sidebar-submenu > .sidebar-menu-item {
  border-color: #19191A;
}
.sidebar-black-dodger-blue .sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-black-dodger-blue .sm-icons-block > .sidebar-menu-item.open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(0, 0, 0, 0.12);
}
.sidebar-black-dodger-blue .sm-icons-block > .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}
.sidebar-black-dodger-blue .sm-active-button-bg > .active > .sidebar-menu-button {
  background: #0A1D33;
  color: #824EE1;
}
.sidebar-black-dodger-blue .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #824EE1;
}
.sidebar-black-dodger-blue .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #824EE1;
}
.sidebar-black-dodger-blue .sm-icons-block.sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
}
.sidebar-black-dodger-blue .sidebar-account {
  background: white;
}
.sidebar-black-dodger-blue .sidebar-submenu .sidebar-menu-text::after {
  background-color: #E4A93C;
}
.sidebar-black-dodger-blue .search-form--black, .sidebar-black-dodger-blue .navbar-dark .navbar-search, .navbar-dark .sidebar-black-dodger-blue .navbar-search {
  background-color: #1D1E26;
  border-color: #1D1E26;
}
.sidebar-black-dodger-blue .search-form--black .form-control, .sidebar-black-dodger-blue .navbar-dark .navbar-search .form-control, .navbar-dark .sidebar-black-dodger-blue .navbar-search .form-control {
  color: #fff;
}
.sidebar-black-dodger-blue .search-form--black .form-control::placeholder, .sidebar-black-dodger-blue .navbar-dark .navbar-search .form-control::placeholder, .navbar-dark .sidebar-black-dodger-blue .navbar-search .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-black-dodger-blue .search-form--black .form-control:focus, .sidebar-black-dodger-blue .navbar-dark .navbar-search .form-control:focus, .navbar-dark .sidebar-black-dodger-blue .navbar-search .form-control:focus {
  background-color: transparent !important;
}
.sidebar-black-dodger-blue .search-form--black .btn, .sidebar-black-dodger-blue .navbar-dark .navbar-search .btn, .navbar-dark .sidebar-black-dodger-blue .navbar-search .btn {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-black-dodger-blue .navbar-toggler {
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-black-dodger-blue .navbar-toggler:hover {
  color: white;
}

.sidebar-dark-purple {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
  color: rgba(255, 255, 255, 0.54);
  background: linear-gradient(180deg, #9A55FE 0%, #753EFD 100%);
}
.sidebar-dark-purple.sidebar-left {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.15);
}
.sidebar-dark-purple.sidebar-right {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(0, 0, 0, 0.15);
}
.sidebar-dark-purple .sidebar-link {
  color: #fff;
}
.sidebar-dark-purple [class*=sidebar-b-] {
  border-color: rgba(255, 255, 255, 0.2);
}
.sidebar-dark-purple .sidebar-text,
.sidebar-dark-purple .text-100,
.sidebar-dark-purple p {
  color: #BF9DF2 !important;
}
.sidebar-dark-purple .text-50 {
  color: rgba(191, 157, 242, 0.5) !important;
}
.sidebar-dark-purple .sidebar-heading, .sidebar-dark-purple .sidebar-heading > a {
  color: #C8B8E6;
}
.sidebar-dark-purple hr {
  border-color: rgba(255, 255, 255, 0.2);
}
.sidebar-dark-purple .sidebar-brand {
  color: #ffffff;
}
.sidebar-dark-purple .sidebar-brand-bg {
  background: rgba(0, 0, 0, 0.12);
}
.sidebar-dark-purple .sidebar-brand-border {
  border-color: rgba(46, 49, 51, 0.9);
}
.sidebar-dark-purple .sidebar-menu-button {
  color: #BF9DF2;
}
.sidebar-dark-purple .sidebar-menu-toggle-icon {
  color: rgba(172, 182, 191, 0.5);
}
.sidebar-dark-purple .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark-purple .sidebar-menu-button:hover {
  color: #ffffff;
  background: transparent;
}
.sidebar-dark-purple .sidebar-menu-button:hover .sidebar-menu-icon {
  color: white;
}
.sidebar-dark-purple .sidebar-menu-button:hover .sidebar-menu-toggle-icon {
  color: white;
}
.sidebar-dark-purple .open {
  background: rgba(50, 50, 51, 0.05);
}
.sidebar-dark-purple .open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark-purple .open > .sidebar-menu-button {
  background: transparent;
  color: #DECCFF;
}
.sidebar-dark-purple .open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark-purple .active > .sidebar-menu-button {
  color: #DECCFF;
}
.sidebar-dark-purple .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}
.sidebar-dark-purple .sidebar-submenu .sidebar-menu-text {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}
.sidebar-dark-purple .sidebar-submenu .sidebar-menu-button {
  color: #BF9DF2;
}
.sidebar-dark-purple .sidebar-submenu .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark-purple .sidebar-submenu .sidebar-menu-button:hover {
  color: #ffffff;
}
.sidebar-dark-purple .sidebar-submenu .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #ffffff;
}
.sidebar-dark-purple .sidebar-submenu .active > .sidebar-menu-button {
  color: white;
}
.sidebar-dark-purple .sidebar-submenu .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}
.sidebar-dark-purple .sm-bordered {
  border-color: rgba(255, 255, 255, 0.2);
}
.sidebar-dark-purple .sm-bordered.sidebar-submenu {
  border-color: rgba(255, 255, 255, 0.2);
}
.sidebar-dark-purple .sm-item-bordered > .sidebar-menu-item {
  border-color: rgba(0, 0, 0, 0.15);
}
.sidebar-dark-purple .sm-item-bordered.sidebar-submenu > .sidebar-menu-item {
  border-color: rgba(0, 0, 0, 0.15);
}
.sidebar-dark-purple .sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-dark-purple .sm-icons-block > .sidebar-menu-item.open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(0, 0, 0, 0.12);
}
.sidebar-dark-purple .sm-icons-block > .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}
.sidebar-dark-purple .sm-active-button-bg > .active > .sidebar-menu-button {
  background: white;
  color: #824EE1;
}
.sidebar-dark-purple .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #824EE1;
}
.sidebar-dark-purple .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #824EE1;
}
.sidebar-dark-purple .sm-icons-block.sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
}
.sidebar-dark-purple .sidebar-account {
  background: white;
}
.sidebar-dark-purple .sidebar-submenu .sidebar-menu-text::after {
  background-color: #ED0B4C;
}
.sidebar-dark-purple .search-form--black, .sidebar-dark-purple .navbar-dark .navbar-search, .navbar-dark .sidebar-dark-purple .navbar-search {
  background-color: rgba(50, 50, 51, 0.05);
  border-color: rgba(50, 50, 51, 0.05);
}
.sidebar-dark-purple .search-form--black .form-control, .sidebar-dark-purple .navbar-dark .navbar-search .form-control, .navbar-dark .sidebar-dark-purple .navbar-search .form-control {
  color: #fff;
}
.sidebar-dark-purple .search-form--black .form-control::placeholder, .sidebar-dark-purple .navbar-dark .navbar-search .form-control::placeholder, .navbar-dark .sidebar-dark-purple .navbar-search .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark-purple .search-form--black .form-control:focus, .sidebar-dark-purple .navbar-dark .navbar-search .form-control:focus, .navbar-dark .sidebar-dark-purple .navbar-search .form-control:focus {
  background-color: transparent !important;
}
.sidebar-dark-purple .search-form--black .btn, .sidebar-dark-purple .navbar-dark .navbar-search .btn, .navbar-dark .sidebar-dark-purple .navbar-search .btn {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark-purple .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark-purple .navbar-toggler:hover {
  color: white;
}

.sidebar-dark-blue {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
  color: rgba(255, 255, 255, 0.54);
  background: #0C2440;
}
.sidebar-dark-blue.sidebar-left {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.15);
}
.sidebar-dark-blue.sidebar-right {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(0, 0, 0, 0.15);
}
.sidebar-dark-blue .sidebar-link {
  color: #fff;
}
.sidebar-dark-blue [class*=sidebar-b-] {
  border-color: rgba(15, 43, 77, 0.9);
}
.sidebar-dark-blue .sidebar-text,
.sidebar-dark-blue .text-100,
.sidebar-dark-blue p {
  color: rgba(172, 182, 191, 0.5) !important;
}
.sidebar-dark-blue .text-50 {
  color: rgba(172, 182, 191, 0.5) !important;
}
.sidebar-dark-blue .sidebar-heading, .sidebar-dark-blue .sidebar-heading > a {
  color: #8A9199;
}
.sidebar-dark-blue hr {
  border-color: rgba(15, 43, 77, 0.9);
}
.sidebar-dark-blue .sidebar-brand {
  color: #ffffff;
}
.sidebar-dark-blue .sidebar-brand-bg {
  background: rgba(0, 0, 0, 0.12);
}
.sidebar-dark-blue .sidebar-brand-border {
  border-color: rgba(46, 49, 51, 0.9);
}
.sidebar-dark-blue .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}
.sidebar-dark-blue .sidebar-menu-toggle-icon {
  color: rgba(172, 182, 191, 0.5);
}
.sidebar-dark-blue .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-dark-blue .sidebar-menu-button:hover {
  color: #ffffff;
  background: transparent;
}
.sidebar-dark-blue .sidebar-menu-button:hover .sidebar-menu-icon {
  color: white;
}
.sidebar-dark-blue .sidebar-menu-button:hover .sidebar-menu-toggle-icon {
  color: white;
}
.sidebar-dark-blue .open {
  background: #0A1D33;
}
.sidebar-dark-blue .open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark-blue .open > .sidebar-menu-button {
  background: transparent;
  color: #8A9199;
}
.sidebar-dark-blue .open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark-blue .active > .sidebar-menu-button {
  color: #8A9199;
}
.sidebar-dark-blue .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}
.sidebar-dark-blue .sidebar-submenu .sidebar-menu-text {
  border-left: 1px solid rgba(15, 43, 77, 0.9);
}
.sidebar-dark-blue .sidebar-submenu .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}
.sidebar-dark-blue .sidebar-submenu .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-dark-blue .sidebar-submenu .sidebar-menu-button:hover {
  color: #ffffff;
}
.sidebar-dark-blue .sidebar-submenu .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #ffffff;
}
.sidebar-dark-blue .sidebar-submenu .active > .sidebar-menu-button {
  color: white;
}
.sidebar-dark-blue .sidebar-submenu .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}
.sidebar-dark-blue .sm-bordered {
  border-color: rgba(15, 43, 77, 0.9);
}
.sidebar-dark-blue .sm-bordered.sidebar-submenu {
  border-color: rgba(15, 43, 77, 0.9);
}
.sidebar-dark-blue .sm-item-bordered > .sidebar-menu-item {
  border-color: rgba(15, 43, 77, 0.9);
}
.sidebar-dark-blue .sm-item-bordered.sidebar-submenu > .sidebar-menu-item {
  border-color: rgba(15, 43, 77, 0.9);
}
.sidebar-dark-blue .sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-dark-blue .sm-icons-block > .sidebar-menu-item.open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(0, 0, 0, 0.12);
}
.sidebar-dark-blue .sm-icons-block > .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}
.sidebar-dark-blue .sm-active-button-bg > .active > .sidebar-menu-button {
  background: #0A1D33;
  color: #824EE1;
}
.sidebar-dark-blue .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #824EE1;
}
.sidebar-dark-blue .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #824EE1;
}
.sidebar-dark-blue .sm-icons-block.sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
}
.sidebar-dark-blue .sidebar-account {
  background: white;
}
.sidebar-dark-blue .sidebar-submenu .sidebar-menu-text::after {
  background-color: #E4A93C;
}
.sidebar-dark-blue .search-form--black, .sidebar-dark-blue .navbar-dark .navbar-search, .navbar-dark .sidebar-dark-blue .navbar-search {
  background-color: #0A1D33;
  border-color: #0A1D33;
}
.sidebar-dark-blue .search-form--black .form-control, .sidebar-dark-blue .navbar-dark .navbar-search .form-control, .navbar-dark .sidebar-dark-blue .navbar-search .form-control {
  color: #fff;
}
.sidebar-dark-blue .search-form--black .form-control::placeholder, .sidebar-dark-blue .navbar-dark .navbar-search .form-control::placeholder, .navbar-dark .sidebar-dark-blue .navbar-search .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark-blue .search-form--black .form-control:focus, .sidebar-dark-blue .navbar-dark .navbar-search .form-control:focus, .navbar-dark .sidebar-dark-blue .navbar-search .form-control:focus {
  background-color: transparent !important;
}
.sidebar-dark-blue .search-form--black .btn, .sidebar-dark-blue .navbar-dark .navbar-search .btn, .navbar-dark .sidebar-dark-blue .navbar-search .btn {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark-blue .navbar-toggler {
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-dark-blue .navbar-toggler:hover {
  color: white;
}

.sidebar-dark-pickled-bluewood {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
  color: rgba(255, 255, 255, 0.54);
  background: #303956;
}
.sidebar-dark-pickled-bluewood.sidebar-left {
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.15);
}
.sidebar-dark-pickled-bluewood.sidebar-right {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(0, 0, 0, 0.15);
}
.sidebar-dark-pickled-bluewood .sidebar-link {
  color: #fff;
}
.sidebar-dark-pickled-bluewood [class*=sidebar-b-] {
  border-color: #212A45;
}
.sidebar-dark-pickled-bluewood .sidebar-text,
.sidebar-dark-pickled-bluewood .text-100,
.sidebar-dark-pickled-bluewood p {
  color: white !important;
}
.sidebar-dark-pickled-bluewood .text-50 {
  color: rgba(172, 182, 191, 0.5) !important;
}
.sidebar-dark-pickled-bluewood .sidebar-heading, .sidebar-dark-pickled-bluewood .sidebar-heading > a {
  color: #8A9199;
}
.sidebar-dark-pickled-bluewood hr {
  border-color: #212A45;
}
.sidebar-dark-pickled-bluewood .sidebar-brand {
  color: #ffffff;
}
.sidebar-dark-pickled-bluewood .sidebar-brand-bg {
  background: rgba(0, 0, 0, 0.12);
}
.sidebar-dark-pickled-bluewood .sidebar-brand-border {
  border-color: rgba(46, 49, 51, 0.9);
}
.sidebar-dark-pickled-bluewood .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}
.sidebar-dark-pickled-bluewood .sidebar-menu-toggle-icon {
  color: rgba(172, 182, 191, 0.5);
}
.sidebar-dark-pickled-bluewood .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-dark-pickled-bluewood .sidebar-menu-button:hover {
  color: #ffffff;
  background: transparent;
}
.sidebar-dark-pickled-bluewood .sidebar-menu-button:hover .sidebar-menu-icon {
  color: white;
}
.sidebar-dark-pickled-bluewood .sidebar-menu-button:hover .sidebar-menu-toggle-icon {
  color: white;
}
.sidebar-dark-pickled-bluewood .open {
  background: #212A45;
}
.sidebar-dark-pickled-bluewood .open > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark-pickled-bluewood .open > .sidebar-menu-button {
  background: transparent;
  color: #8A9199;
}
.sidebar-dark-pickled-bluewood .open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark-pickled-bluewood .active > .sidebar-menu-button {
  color: white;
}
.sidebar-dark-pickled-bluewood .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}
.sidebar-dark-pickled-bluewood .sidebar-submenu .sidebar-menu-text {
  border-left: 1px solid #303956;
}
.sidebar-dark-pickled-bluewood .sidebar-submenu .sidebar-menu-button {
  color: rgba(172, 182, 191, 0.5);
}
.sidebar-dark-pickled-bluewood .sidebar-submenu .sidebar-menu-icon {
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-dark-pickled-bluewood .sidebar-submenu .sidebar-menu-button:hover {
  color: #ffffff;
}
.sidebar-dark-pickled-bluewood .sidebar-submenu .sidebar-menu-button:hover .sidebar-menu-icon {
  color: #ffffff;
}
.sidebar-dark-pickled-bluewood .sidebar-submenu .active > .sidebar-menu-button {
  color: white;
}
.sidebar-dark-pickled-bluewood .sidebar-submenu .active > .sidebar-menu-button .sidebar-menu-icon {
  color: white;
}
.sidebar-dark-pickled-bluewood .sm-bordered {
  border-color: #212A45;
}
.sidebar-dark-pickled-bluewood .sm-bordered.sidebar-submenu {
  border-color: #212A45;
}
.sidebar-dark-pickled-bluewood .sm-item-bordered > .sidebar-menu-item {
  border-color: #212A45;
}
.sidebar-dark-pickled-bluewood .sm-item-bordered.sidebar-submenu > .sidebar-menu-item {
  border-color: #212A45;
}
.sidebar-dark-pickled-bluewood .sm-icons-block > .sidebar-menu-item > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(0, 0, 0, 0.12);
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-dark-pickled-bluewood .sm-icons-block > .sidebar-menu-item.open > .sidebar-menu-button .sidebar-menu-icon {
  color: rgba(255, 255, 255, 0.5);
  background: rgba(0, 0, 0, 0.12);
}
.sidebar-dark-pickled-bluewood .sm-icons-block > .sidebar-menu-item.active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
  color: #fff;
}
.sidebar-dark-pickled-bluewood .sm-active-button-bg > .active > .sidebar-menu-button {
  background: #0A1D33;
  color: #824EE1;
}
.sidebar-dark-pickled-bluewood .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  color: #824EE1;
}
.sidebar-dark-pickled-bluewood .sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-toggle-icon {
  color: #824EE1;
}
.sidebar-dark-pickled-bluewood .sm-icons-block.sm-active-button-bg > .active > .sidebar-menu-button .sidebar-menu-icon {
  background: rgba(255, 255, 255, 0.12);
}
.sidebar-dark-pickled-bluewood .sidebar-account {
  background: white;
}
.sidebar-dark-pickled-bluewood .sidebar-submenu .sidebar-menu-text::after {
  background-color: #00BCC2;
}
.sidebar-dark-pickled-bluewood .search-form--black, .sidebar-dark-pickled-bluewood .navbar-dark .navbar-search, .navbar-dark .sidebar-dark-pickled-bluewood .navbar-search {
  background-color: #212A45;
  border-color: #212A45;
}
.sidebar-dark-pickled-bluewood .search-form--black .form-control, .sidebar-dark-pickled-bluewood .navbar-dark .navbar-search .form-control, .navbar-dark .sidebar-dark-pickled-bluewood .navbar-search .form-control {
  color: #fff;
}
.sidebar-dark-pickled-bluewood .search-form--black .form-control::placeholder, .sidebar-dark-pickled-bluewood .navbar-dark .navbar-search .form-control::placeholder, .navbar-dark .sidebar-dark-pickled-bluewood .navbar-search .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark-pickled-bluewood .search-form--black .form-control:focus, .sidebar-dark-pickled-bluewood .navbar-dark .navbar-search .form-control:focus, .navbar-dark .sidebar-dark-pickled-bluewood .navbar-search .form-control:focus {
  background-color: transparent !important;
}
.sidebar-dark-pickled-bluewood .search-form--black .btn, .sidebar-dark-pickled-bluewood .navbar-dark .navbar-search .btn, .navbar-dark .sidebar-dark-pickled-bluewood .navbar-search .btn {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark-pickled-bluewood .navbar-toggler {
  color: rgba(172, 182, 191, 0.2);
}
.sidebar-dark-pickled-bluewood .navbar-toggler:hover {
  color: white;
}

.sidebar-light {
  /* @alternate */
  box-shadow: 0px 3px 3px -2px rgba(39, 44, 51, 0.1), 0px 3px 4px 0px rgba(39, 44, 51, 0.04), 0px 1px 8px 0px rgba(39, 44, 51, 0.02);
}

.sidebar-light .sidebar-submenu .sidebar-menu-text {
  border-left: 1px solid #E9EDF2;
}

.sidebar-light-yellow .sidebar-submenu .sidebar-menu-text::after {
  background-color: #E4A93C;
}

.sidebar-light-red .sidebar-submenu .sidebar-menu-text::after {
  background-color: #ED0B4C;
}

.sidebar-light-purple .sidebar-submenu .sidebar-menu-text::after {
  background-color: #824EE1;
}

.sidebar-light-dodger-blue .sidebar-submenu .sidebar-menu-text::after {
  background-color: #5567FF;
}

.navbar {
  min-height: 64px;
  padding-top: 0;
  padding-bottom: 0;
}

.navbar-shadow {
  /* @alternate */
  box-shadow: 0px 6px 6px -3px rgba(39, 44, 51, 0.1), 0px 10px 14px 1px rgba(39, 44, 51, 0.04), 0px 4px 18px 3px rgba(39, 44, 51, 0.02);
}

.top-navbar {
  top: 64px;
}

@media (min-width: 576px) {
  .top-sm-navbar {
    top: 64px;
  }
}
@media (min-width: 768px) {
  .top-md-navbar {
    top: 64px;
  }
}
@media (min-width: 992px) {
  .top-lg-navbar {
    top: 64px;
  }
}
@media (min-width: 1200px) {
  .top-xl-navbar {
    top: 64px;
  }
}
@media (min-width: 1366px) {
  .top-xxl-navbar {
    top: 64px;
  }
}
.navbar-height {
  min-height: 64px;
}

.navbar-brand {
  font-size: 1.625rem;
  font-weight: 500;
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}

.navbar-brand-icon {
  margin-right: 0.5rem;
}

.navbar-divider {
  width: 1px;
  height: 64px;
  background: rgba(255, 255, 255, 0.24);
  margin-left: 1rem;
  margin-right: 1rem;
}

.navbar-nav .nav-link {
  font-size: 1rem;
  align-items: center;
  font-weight: 500;
  position: relative;
  padding: 0;
}

.navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-toggler {
  border: none;
  width: 64px;
  height: 64px;
  padding: 0;
  text-align: center;
  display: inline-block;
}

.navbar-toggler-right {
  order: 1;
}

@media (max-width: 767.98px) {
  .navbar .container {
    max-width: none;
  }
}

.navbar-expand-sm .nav-link {
  height: 64px;
}
@media (max-width: 575.98px) {
  .navbar-expand-sm.navbar-list {
    height: auto;
  }
  .navbar-expand-sm.navbar-list .navbar-list__item {
    padding: 1rem;
    width: 100%;
  }
  .navbar-expand-sm.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
    border-bottom: 1px solid #E9EDF2;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav .nav-item {
    display: flex;
    align-items: center;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-sm .navbar-nav .nav-link + .nav-link,
.navbar-expand-sm .navbar-nav .nav-item + .nav-item, .navbar-expand-sm .navbar-nav + .navbar-nav {
    margin-left: 1rem;
  }
  .navbar-expand-sm .navbar-list__content {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-collapse__content {
    display: flex;
    width: 100%;
  }
  .navbar-expand-sm .navbar-collapse__content .navbar-nav {
    align-items: center;
  }
}
.navbar-expand-md .nav-link {
  height: 64px;
}
@media (max-width: 767.98px) {
  .navbar-expand-md.navbar-list {
    height: auto;
  }
  .navbar-expand-md.navbar-list .navbar-list__item {
    padding: 1rem;
    width: 100%;
  }
  .navbar-expand-md.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
    border-bottom: 1px solid #E9EDF2;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-item {
    display: flex;
    align-items: center;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-md .navbar-nav .nav-link + .nav-link,
.navbar-expand-md .navbar-nav .nav-item + .nav-item, .navbar-expand-md .navbar-nav + .navbar-nav {
    margin-left: 1rem;
  }
  .navbar-expand-md .navbar-list__content {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-collapse__content {
    display: flex;
    width: 100%;
  }
  .navbar-expand-md .navbar-collapse__content .navbar-nav {
    align-items: center;
  }
}
.navbar-expand-lg .nav-link {
  height: 64px;
}
@media (max-width: 991.98px) {
  .navbar-expand-lg.navbar-list {
    height: auto;
  }
  .navbar-expand-lg.navbar-list .navbar-list__item {
    padding: 1rem;
    width: 100%;
  }
  .navbar-expand-lg.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
    border-bottom: 1px solid #E9EDF2;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-item {
    display: flex;
    align-items: center;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-lg .navbar-nav .nav-link + .nav-link,
.navbar-expand-lg .navbar-nav .nav-item + .nav-item, .navbar-expand-lg .navbar-nav + .navbar-nav {
    margin-left: 1rem;
  }
  .navbar-expand-lg .navbar-list__content {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-collapse__content {
    display: flex;
    width: 100%;
  }
  .navbar-expand-lg .navbar-collapse__content .navbar-nav {
    align-items: center;
  }
}
.navbar-expand-xl .nav-link {
  height: 64px;
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl.navbar-list {
    height: auto;
  }
  .navbar-expand-xl.navbar-list .navbar-list__item {
    padding: 1rem;
    width: 100%;
  }
  .navbar-expand-xl.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
    border-bottom: 1px solid #E9EDF2;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .nav-item {
    display: flex;
    align-items: center;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-xl .navbar-nav .nav-link + .nav-link,
.navbar-expand-xl .navbar-nav .nav-item + .nav-item, .navbar-expand-xl .navbar-nav + .navbar-nav {
    margin-left: 1rem;
  }
  .navbar-expand-xl .navbar-list__content {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-collapse__content {
    display: flex;
    width: 100%;
  }
  .navbar-expand-xl .navbar-collapse__content .navbar-nav {
    align-items: center;
  }
}
.navbar-expand-xxl .nav-link {
  height: 64px;
}
@media (max-width: 1365.98px) {
  .navbar-expand-xxl.navbar-list {
    height: auto;
  }
  .navbar-expand-xxl.navbar-list .navbar-list__item {
    padding: 1rem;
    width: 100%;
  }
  .navbar-expand-xxl.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
    border-bottom: 1px solid #E9EDF2;
  }
}
@media (min-width: 1366px) {
  .navbar-expand-xxl .navbar-nav .nav-item {
    display: flex;
    align-items: center;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-expand-xxl .navbar-nav .nav-link + .nav-link,
.navbar-expand-xxl .navbar-nav .nav-item + .nav-item, .navbar-expand-xxl .navbar-nav + .navbar-nav {
    margin-left: 1rem;
  }
  .navbar-expand-xxl .navbar-list__content {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-collapse__content {
    display: flex;
    width: 100%;
  }
  .navbar-expand-xxl .navbar-collapse__content .navbar-nav {
    align-items: center;
  }
}
.navbar-expand .nav-link {
  height: 64px;
}
.navbar-expand.navbar-list {
  height: auto;
}
.navbar-expand.navbar-list .navbar-list__item {
  padding: 1rem;
  width: 100%;
}
.navbar-expand.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
  border-bottom: 1px solid #E9EDF2;
}
.navbar-expand .navbar-nav .nav-item {
  display: flex;
  align-items: center;
}
.navbar-expand .navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}
.navbar-expand .navbar-nav .nav-link + .nav-link,
.navbar-expand .navbar-nav .nav-item + .nav-item, .navbar-expand .navbar-nav + .navbar-nav {
  margin-left: 1rem;
}
.navbar-expand .navbar-list__content {
  flex-direction: row;
}
.navbar-expand .navbar-collapse__content {
  display: flex;
  width: 100%;
}
.navbar-expand .navbar-collapse__content .navbar-nav {
  align-items: center;
}

@media (max-width: 575.98px) {
  .navbar-collapse__content {
    padding: 1rem;
  }
  .navbar-collapse .navbar-nav + .navbar-nav {
    margin-top: 1rem;
  }
}
.navbar-list {
  padding-left: 0;
  padding-right: 0;
}
.navbar-list__content {
  display: flex;
  flex-direction: column;
}
.navbar-list__item {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-submenu {
  background: #ffffff;
  border-bottom: 2px solid #E9EDF2;
}
@media (max-width: 575.98px) {
  .navbar-submenu .navbar-collapse {
    border-bottom: 2px solid #E9EDF2;
  }
}

.navbar-mini {
  min-height: 40px;
}
.navbar-mini .navbar-nav .nav-link {
  font-size: 0.8rem;
  font-weight: 400;
}
.navbar-mini .navbar-nav > .active > .nav-link::before {
  display: none;
}

.mr-navbar-x {
  margin-right: 1rem;
}

.bg-primary .navbar-dark .navbar-toggler {
  color: #fff;
}

.navbar .ps {
  padding-top: 3px;
  padding-bottom: 3px;
}
.navbar .ps .nav {
  flex-wrap: nowrap;
}

.navbar--active-undeline .navbar-nav > .active > .nav-link::before,
.navbar--active-undeline .navbar-nav .nav-link.active::before {
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: #4AA2EE;
  content: " ";
  bottom: 0;
}

.navbar-light .badge-notifications {
  border: 1px solid #fff;
}
.navbar-light .navbar-text-50 {
  color: rgba(39, 44, 51, 0.5);
}
.navbar-light .navbar-text-70 {
  color: rgba(39, 44, 51, 0.7);
}
.navbar-light .navbar-text-100 {
  color: #272C33;
}
.navbar-light .navbar-avatar {
  background-color: #EDF0F2;
  border: 1px solid #EDF0F2;
  color: #272C33;
}

.navbar-dark .navbar-border {
  border-color: rgba(255, 255, 255, 0.1) !important;
}
.navbar-dark .badge-notifications {
  border: 1px solid #303840;
}
.navbar-dark .navbar-text-50 {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text-70 {
  color: rgba(255, 255, 255, 0.7);
}
.navbar-dark .navbar-text-100 {
  color: #fff;
}
.navbar-dark.navbar--active-undeline .navbar-nav > .active > .nav-link::before,
.navbar-dark.navbar--active-undeline .navbar-nav .nav-link.active::before {
  background-color: white;
}

.navbar-dark-white .navbar-brand {
  color: #fff;
}
.navbar-dark-white .navbar-brand:hover, .navbar-dark-white .navbar-brand:focus {
  color: #fff;
}
.navbar-dark-white .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.navbar-dark-white .navbar-nav .nav-link:hover, .navbar-dark-white .navbar-nav .nav-link:focus {
  color: #ffffff;
}
.navbar-dark-white .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark-white .navbar-nav .show > .nav-link,
.navbar-dark-white .navbar-nav .active > .nav-link,
.navbar-dark-white .navbar-nav .nav-link.show,
.navbar-dark-white .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark-white .navbar-toggler {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark-white .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#858D94' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar-dark-white .navbar-text {
  color: rgba(255, 255, 255, 0.7);
}
.navbar-dark-white .navbar-text a {
  color: #fff;
}
.navbar-dark-white .navbar-text a:hover, .navbar-dark-white .navbar-text a:focus {
  color: #fff;
}

.navbar-black {
  background: #1D2126;
}
.navbar-black .navbar-text-50 {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-black .navbar-text-70 {
  color: rgba(255, 255, 255, 0.7);
}
.navbar-black .navbar-text-100 {
  color: #fff;
}
.navbar-black .navbar-search {
  background-color: #303840;
  border-color: #303840;
}
.navbar-black .navbar-search .form-control {
  color: #fff;
}
.navbar-black .navbar-search .form-control::placeholder {
  color: #8A9199;
}
.navbar-black .navbar-search .form-control:focus {
  background-color: transparent !important;
}
.navbar-black .navbar-search .btn {
  background: transparent;
  color: #8A9199;
}
.navbar-black .navbar-btn {
  color: #fff;
  background-color: #303840;
  border-color: #303840;
}
.navbar-black .navbar-btn:hover {
  color: #fff;
  background-color: #20252a;
  border-color: #1a1f23;
}
.navbar-black .navbar-btn:focus, .navbar-black .navbar-btn.focus {
  color: #fff;
  background-color: #20252a;
  border-color: #1a1f23;
  box-shadow: 0 0 0 1px rgba(79, 86, 93, 0.5);
}
.navbar-black .navbar-btn.disabled, .navbar-black .navbar-btn:disabled {
  color: #fff;
  background-color: #303840;
  border-color: #303840;
}
.navbar-black .navbar-btn:not(:disabled):not(.disabled):active, .navbar-black .navbar-btn:not(:disabled):not(.disabled).active, .show > .navbar-black .navbar-btn.dropdown-toggle {
  color: #fff;
  background-color: #1a1f23;
  border-color: #15181c;
}
.navbar-black .navbar-btn:not(:disabled):not(.disabled):active:focus, .navbar-black .navbar-btn:not(:disabled):not(.disabled).active:focus, .show > .navbar-black .navbar-btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(79, 86, 93, 0.5);
}
.navbar-black .navbar-brand {
  color: #fff;
}
.navbar-black .navbar-brand:hover, .navbar-black .navbar-brand:focus {
  color: #fff;
}
.navbar-black .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.navbar-black .navbar-nav .nav-link:hover, .navbar-black .navbar-nav .nav-link:focus {
  color: #ffffff;
}
.navbar-black .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-black .navbar-nav .show > .nav-link,
.navbar-black .navbar-nav .active > .nav-link,
.navbar-black .navbar-nav .nav-link.show,
.navbar-black .navbar-nav .nav-link.active {
  color: #fff;
}
@media (min-width: 576px) {
  .navbar-black .navbar-nav .show > .nav-link::before,
.navbar-black .navbar-nav .active > .nav-link::before,
.navbar-black .navbar-nav .nav-link.show::before,
.navbar-black .navbar-nav .nav-link.active::before {
    background-color: white;
  }
}
.navbar-black .navbar-toggler {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-black .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#858D94' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}

.navbar-dark-blue {
  background: #0C2440;
}
.navbar-dark-blue .navbar-text-50 {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark-blue .navbar-text-70 {
  color: rgba(255, 255, 255, 0.7);
}
.navbar-dark-blue .navbar-text-100 {
  color: #fff;
}
.navbar-dark-blue .navbar-search {
  background-color: #0A1D33;
  border-color: #0A1D33;
}
.navbar-dark-blue .navbar-search .form-control {
  color: #fff;
}
.navbar-dark-blue .navbar-search .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark-blue .navbar-search .form-control:focus {
  background-color: transparent !important;
}
.navbar-dark-blue .navbar-search .btn {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark-blue .navbar-btn {
  color: #fff;
  background-color: #0A1D33;
  border-color: #0A1D33;
}
.navbar-dark-blue .navbar-btn:hover {
  color: #fff;
  background-color: #040b13;
  border-color: #020508;
}
.navbar-dark-blue .navbar-btn:focus, .navbar-dark-blue .navbar-btn.focus {
  color: #fff;
  background-color: #040b13;
  border-color: #020508;
  box-shadow: 0 0 0 1px rgba(47, 63, 82, 0.5);
}
.navbar-dark-blue .navbar-btn.disabled, .navbar-dark-blue .navbar-btn:disabled {
  color: #fff;
  background-color: #0A1D33;
  border-color: #0A1D33;
}
.navbar-dark-blue .navbar-btn:not(:disabled):not(.disabled):active, .navbar-dark-blue .navbar-btn:not(:disabled):not(.disabled).active, .show > .navbar-dark-blue .navbar-btn.dropdown-toggle {
  color: #fff;
  background-color: #020508;
  border-color: black;
}
.navbar-dark-blue .navbar-btn:not(:disabled):not(.disabled):active:focus, .navbar-dark-blue .navbar-btn:not(:disabled):not(.disabled).active:focus, .show > .navbar-dark-blue .navbar-btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(47, 63, 82, 0.5);
}
.navbar-dark-blue .navbar-brand {
  color: #fff;
}
.navbar-dark-blue .navbar-brand:hover, .navbar-dark-blue .navbar-brand:focus {
  color: #fff;
}
.navbar-dark-blue .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.navbar-dark-blue .navbar-nav .nav-link:hover, .navbar-dark-blue .navbar-nav .nav-link:focus {
  color: #ffffff;
}
.navbar-dark-blue .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark-blue .navbar-nav .show > .nav-link,
.navbar-dark-blue .navbar-nav .active > .nav-link,
.navbar-dark-blue .navbar-nav .nav-link.show,
.navbar-dark-blue .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark-blue .navbar-toggler {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark-blue .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#858D94' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar-dark-blue .navbar-border {
  border-color: rgba(255, 255, 255, 0.1) !important;
}
.navbar-dark-blue .badge-notifications {
  border: 1px solid #303840;
}

.navbar-dark-pickled-bluewood {
  background: #303956;
}
.navbar-dark-pickled-bluewood .navbar-text-50 {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark-pickled-bluewood .navbar-text-70 {
  color: rgba(255, 255, 255, 0.7);
}
.navbar-dark-pickled-bluewood .navbar-text-100 {
  color: #fff;
}
.navbar-dark-pickled-bluewood .navbar-search {
  background-color: #212A45;
  border-color: #212A45;
}
.navbar-dark-pickled-bluewood .navbar-search .form-control {
  color: #fff;
}
.navbar-dark-pickled-bluewood .navbar-search .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark-pickled-bluewood .navbar-search .form-control:focus {
  background-color: transparent !important;
}
.navbar-dark-pickled-bluewood .navbar-search .btn {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark-pickled-bluewood .navbar-avatar {
  background-color: #212A45;
}
.navbar-dark-pickled-bluewood .navbar-btn {
  color: #fff;
  background-color: #212A45;
  border-color: #212A45;
}
.navbar-dark-pickled-bluewood .navbar-btn:hover {
  color: #fff;
  background-color: #151a2b;
  border-color: #111523;
}
.navbar-dark-pickled-bluewood .navbar-btn:focus, .navbar-dark-pickled-bluewood .navbar-btn.focus {
  color: #fff;
  background-color: #151a2b;
  border-color: #111523;
  box-shadow: 0 0 0 1px rgba(66, 74, 97, 0.5);
}
.navbar-dark-pickled-bluewood .navbar-btn.disabled, .navbar-dark-pickled-bluewood .navbar-btn:disabled {
  color: #fff;
  background-color: #212A45;
  border-color: #212A45;
}
.navbar-dark-pickled-bluewood .navbar-btn:not(:disabled):not(.disabled):active, .navbar-dark-pickled-bluewood .navbar-btn:not(:disabled):not(.disabled).active, .show > .navbar-dark-pickled-bluewood .navbar-btn.dropdown-toggle {
  color: #fff;
  background-color: #111523;
  border-color: #0c101a;
}
.navbar-dark-pickled-bluewood .navbar-btn:not(:disabled):not(.disabled):active:focus, .navbar-dark-pickled-bluewood .navbar-btn:not(:disabled):not(.disabled).active:focus, .show > .navbar-dark-pickled-bluewood .navbar-btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(66, 74, 97, 0.5);
}
.navbar-dark-pickled-bluewood .navbar-brand {
  color: #fff;
}
.navbar-dark-pickled-bluewood .navbar-brand:hover, .navbar-dark-pickled-bluewood .navbar-brand:focus {
  color: #fff;
}
.navbar-dark-pickled-bluewood .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.navbar-dark-pickled-bluewood .navbar-nav .nav-link:hover, .navbar-dark-pickled-bluewood .navbar-nav .nav-link:focus {
  color: #ffffff;
}
.navbar-dark-pickled-bluewood .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark-pickled-bluewood .navbar-nav .show > .nav-link,
.navbar-dark-pickled-bluewood .navbar-nav .active > .nav-link,
.navbar-dark-pickled-bluewood .navbar-nav .nav-link.show,
.navbar-dark-pickled-bluewood .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark-pickled-bluewood .navbar-toggler {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark-pickled-bluewood .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#858D94' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar-dark-pickled-bluewood .navbar-border {
  border-color: rgba(255, 255, 255, 0.1) !important;
}
.navbar-dark-pickled-bluewood .badge-notifications {
  border: 1px solid #303840;
}

.navbar-dark-purple {
  background: linear-gradient(180deg, #9A55FE 0%, #753EFD 100%);
}
.navbar-dark-purple .navbar-text-50 {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark-purple .navbar-text-70 {
  color: rgba(255, 255, 255, 0.7);
}
.navbar-dark-purple .navbar-text-100 {
  color: #fff;
}
.navbar-dark-purple .navbar-search {
  background-color: rgba(50, 50, 51, 0.05);
  border-color: rgba(50, 50, 51, 0.05);
}
.navbar-dark-purple .navbar-search .form-control {
  color: #fff;
}
.navbar-dark-purple .navbar-search .form-control::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark-purple .navbar-search .form-control:focus {
  background-color: transparent !important;
}
.navbar-dark-purple .navbar-search .btn {
  background: transparent;
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark-purple .navbar-btn {
  color: #fff;
  background-color: rgba(50, 50, 51, 0.05);
  border-color: rgba(50, 50, 51, 0.05);
}
.navbar-dark-purple .navbar-btn:hover {
  color: #fff;
  background-color: rgba(31, 31, 32, 0.05);
  border-color: rgba(25, 25, 25, 0.05);
}
.navbar-dark-purple .navbar-btn:focus, .navbar-dark-purple .navbar-btn.focus {
  color: #fff;
  background-color: rgba(31, 31, 32, 0.05);
  border-color: rgba(25, 25, 25, 0.05);
  box-shadow: 0 0 0 1px rgba(229, 229, 229, 0.5);
}
.navbar-dark-purple .navbar-btn.disabled, .navbar-dark-purple .navbar-btn:disabled {
  color: #fff;
  background-color: rgba(50, 50, 51, 0.05);
  border-color: rgba(50, 50, 51, 0.05);
}
.navbar-dark-purple .navbar-btn:not(:disabled):not(.disabled):active, .navbar-dark-purple .navbar-btn:not(:disabled):not(.disabled).active, .show > .navbar-dark-purple .navbar-btn.dropdown-toggle {
  color: #fff;
  background-color: rgba(25, 25, 25, 0.05);
  border-color: rgba(18, 18, 19, 0.05);
}
.navbar-dark-purple .navbar-btn:not(:disabled):not(.disabled):active:focus, .navbar-dark-purple .navbar-btn:not(:disabled):not(.disabled).active:focus, .show > .navbar-dark-purple .navbar-btn.dropdown-toggle:focus {
  box-shadow: 0 0 0 1px rgba(229, 229, 229, 0.5);
}
.navbar-dark-purple .navbar-brand {
  color: #fff;
}
.navbar-dark-purple .navbar-brand:hover, .navbar-dark-purple .navbar-brand:focus {
  color: #fff;
}
.navbar-dark-purple .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.7);
}
.navbar-dark-purple .navbar-nav .nav-link:hover, .navbar-dark-purple .navbar-nav .nav-link:focus {
  color: #ffffff;
}
.navbar-dark-purple .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark-purple .navbar-nav .show > .nav-link,
.navbar-dark-purple .navbar-nav .active > .nav-link,
.navbar-dark-purple .navbar-nav .nav-link.show,
.navbar-dark-purple .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark-purple .navbar-toggler {
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark-purple .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#858D94' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar-dark-purple .navbar-border {
  border-color: rgba(255, 255, 255, 0.1) !important;
}
.navbar-dark-purple .badge-notifications {
  border: 1px solid #824EE1;
}

.dropdown-toggle:focus {
  outline: 0;
}

[data-caret=false]::before, [data-caret=false]::after {
  display: none !important;
}

.dropdown-menu {
  /* @alternate */
  box-shadow: 0px 5px 5px -3px rgba(39, 44, 51, 0.1), 0px 8px 10px 1px rgba(39, 44, 51, 0.04), 0px 3px 14px 2px rgba(39, 44, 51, 0.02);
  display: block;
  visibility: hidden;
  opacity: 0;
  margin-top: 0 !important;
  transition: opacity 0.2s cubic-bezier(0.3, 0.5, 0.5, 1), margin 0.2s cubic-bezier(0.1, 0.3, 0.8, 1), visibility 0.3s ease;
  background-clip: initial;
}
.dropdown-menu::before, .dropdown-menu::after {
  border: 8px solid transparent;
  border-bottom-color: #fff;
  content: "";
  height: 0;
  left: 10px;
  opacity: 0;
  transition: 0.1s opacity cubic-bezier(0.3, 0.5, 0.5, 1);
  position: absolute;
  top: calc((8px + 0.5rem) * -1);
  width: 1px;
}
.dropdown-menu::before {
  top: calc((9px + 0.5rem) * -1);
  border-bottom-color: transparent;
}

.dropdown-menu-right::before, .dropdown-menu-right::after {
  left: initial;
  right: 10px;
}

.dropup .dropdown-menu {
  margin-bottom: 0 !important;
}
.dropup .dropdown-menu::before, .dropup .dropdown-menu::after {
  top: auto;
  bottom: calc((8px + 0.5rem) * -1);
  border-bottom-color: transparent;
  border-top-color: #fff;
}
.dropup .dropdown-menu::before {
  bottom: calc((9px + 0.5rem) * -1);
  border-top-color: transparent;
}

.show > .dropdown-menu,
.dropdown-menu.show {
  visibility: visible;
  opacity: 1;
  margin-top: 0.5rem !important;
}
.dropup .show > .dropdown-menu,
.dropup .dropdown-menu.show {
  margin-bottom: 0.5rem !important;
}
.show > .dropdown-menu::before, .show > .dropdown-menu::after,
.dropdown-menu.show::before,
.dropdown-menu.show::after {
  opacity: 1;
}

.dropdown-item {
  display: flex;
  align-items: center;
}
.dropdown-item > .material-icons {
  font-size: 18px;
  margin-right: 5px;
}
.dropdown-item.active, .dropdown-item:active {
  color: #272C33;
  font-weight: bold;
  background-color: transparent;
  position: relative;
}
.dropdown-item.active::after, .dropdown-item:active::after {
  position: absolute;
  left: -1px;
  top: 0;
  bottom: 0;
  width: 4px;
  border-radius: 1px;
  background-color: #4AA2EE;
  content: "";
}

.dropdown-menu .close {
  padding: 0.5rem;
  line-height: 0;
}

.dropdown-menu-full {
  left: 0 !important;
  right: 0 !important;
}

.dropdown-menu-caret-center {
  transform-origin: 50% top;
}
.dropup .dropdown-menu-caret-center {
  transform-origin: 50% bottom;
}
.dropdown-menu-caret-center::before, .dropdown-menu-caret-center::after {
  left: 50%;
  margin-left: -4px;
}

@media (max-width: 767.98px) {
  .dropdown-xs-down-full {
    position: initial !important;
  }
  .dropdown-xs-down-full .dropdown-menu {
    left: 0;
    right: 0;
    width: 100% !important;
    border-radius: 0;
  }
  .dropdown-xs-down-full .dropdown-menu::before, .dropdown-xs-down-full .dropdown-menu::after {
    display: none;
  }
}
.dropdown-header {
  color: #272C33;
  font-weight: 500;
}

.dropdown-notifications .dropdown-menu {
  min-width: 300px;
  padding: 0;
}
.dropdown-notifications .dropdown-menu .list-group {
  position: relative;
  overflow: hidden;
}
.dropdown-notifications .dropdown-menu .list-group-item {
  display: flex;
  flex-direction: column;
  border-color: rgba(39, 44, 51, 0.05);
}
.dropdown-notifications .dropdown-menu .unread-indicator {
  display: inline-block;
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
}
.dropdown-notifications .dropdown-toggle {
  display: flex;
}
.dropdown-notifications .dropdown-toggle .material-icons {
  font-size: 2rem;
}
.dropdown-notifications .badge-notifications {
  position: relative;
  display: block;
  margin-left: -0.75rem;
}

.dropdown-notifications .dropdown-menu {
  width: 300px;
}

.overlay {
  position: relative;
}
.overlay__content {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  transition: opacity 0.4s, background-color 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  pointer-events: none;
}
.overlay__action {
  transition: opacity 0.4s, transform 0.4s;
  opacity: 0;
  transform: translate3d(0, 10px, 0);
}
.overlay--show .overlay__content {
  opacity: 1;
}
.overlay--show .overlay__action {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.overlay--duserselect {
  user-select: none;
}

.overlay--primary .overlay__content {
  background-color: rgba(74, 162, 238, 0.35);
}
.overlay--primary.overlay--show .overlay__content {
  background-color: rgba(74, 162, 238, 0.95);
}

.overlay--secondary .overlay__content {
  background-color: rgba(134, 142, 150, 0.35);
}
.overlay--secondary.overlay--show .overlay__content {
  background-color: rgba(134, 142, 150, 0.95);
}

.overlay--success .overlay__content {
  background-color: rgba(119, 193, 58, 0.35);
}
.overlay--success.overlay--show .overlay__content {
  background-color: rgba(119, 193, 58, 0.95);
}

.overlay--info .overlay__content {
  background-color: rgba(23, 162, 184, 0.35);
}
.overlay--info.overlay--show .overlay__content {
  background-color: rgba(23, 162, 184, 0.95);
}

.overlay--warning .overlay__content {
  background-color: rgba(228, 169, 60, 0.35);
}
.overlay--warning.overlay--show .overlay__content {
  background-color: rgba(228, 169, 60, 0.95);
}

.overlay--danger .overlay__content {
  background-color: rgba(217, 83, 79, 0.35);
}
.overlay--danger.overlay--show .overlay__content {
  background-color: rgba(217, 83, 79, 0.95);
}

.overlay--light .overlay__content {
  background-color: rgba(248, 249, 250, 0.35);
}
.overlay--light.overlay--show .overlay__content {
  background-color: rgba(248, 249, 250, 0.95);
}

.overlay--dark .overlay__content {
  background-color: rgba(48, 56, 64, 0.35);
}
.overlay--dark.overlay--show .overlay__content {
  background-color: rgba(48, 56, 64, 0.95);
}

.overlay--black .overlay__content {
  background-color: rgba(39, 44, 51, 0.35);
}
.overlay--black.overlay--show .overlay__content {
  background-color: rgba(39, 44, 51, 0.95);
}

.overlay--accent .overlay__content {
  background-color: rgba(0, 188, 194, 0.35);
}
.overlay--accent.overlay--show .overlay__content {
  background-color: rgba(0, 188, 194, 0.95);
}

.overlay--accent-red .overlay__content {
  background-color: rgba(237, 11, 76, 0.35);
}
.overlay--accent-red.overlay--show .overlay__content {
  background-color: rgba(237, 11, 76, 0.95);
}

.overlay--accent-yellow .overlay__content {
  background-color: rgba(228, 169, 60, 0.35);
}
.overlay--accent-yellow.overlay--show .overlay__content {
  background-color: rgba(228, 169, 60, 0.95);
}

.overlay--accent-dodger-blue .overlay__content {
  background-color: rgba(85, 103, 255, 0.35);
}
.overlay--accent-dodger-blue.overlay--show .overlay__content {
  background-color: rgba(85, 103, 255, 0.95);
}

.overlay--accent-pickled-bluewood .overlay__content {
  background-color: rgba(48, 57, 86, 0.35);
}
.overlay--accent-pickled-bluewood.overlay--show .overlay__content {
  background-color: rgba(48, 57, 86, 0.95);
}

.overlay--accent-electric-violet .overlay__content {
  background-color: rgba(156, 66, 255, 0.35);
}
.overlay--accent-electric-violet.overlay--show .overlay__content {
  background-color: rgba(156, 66, 255, 0.95);
}

.overlay--primary-purple .overlay__content {
  background-color: rgba(130, 78, 225, 0.35);
}
.overlay--primary-purple.overlay--show .overlay__content {
  background-color: rgba(130, 78, 225, 0.95);
}

.overlay--primary-red .overlay__content {
  background-color: rgba(237, 11, 76, 0.35);
}
.overlay--primary-red.overlay--show .overlay__content {
  background-color: rgba(237, 11, 76, 0.95);
}

.overlay--primary-yellow .overlay__content {
  background-color: rgba(228, 169, 60, 0.35);
}
.overlay--primary-yellow.overlay--show .overlay__content {
  background-color: rgba(228, 169, 60, 0.95);
}

.overlay--primary-light .overlay__content {
  background-color: rgba(153, 204, 255, 0.35);
}
.overlay--primary-light.overlay--show .overlay__content {
  background-color: rgba(153, 204, 255, 0.95);
}

.overlay--primary-dodger-blue .overlay__content {
  background-color: rgba(85, 103, 255, 0.35);
}
.overlay--primary-dodger-blue.overlay--show .overlay__content {
  background-color: rgba(85, 103, 255, 0.95);
}

.overlay--primary-pickled-bluewood .overlay__content {
  background-color: rgba(48, 57, 86, 0.35);
}
.overlay--primary-pickled-bluewood.overlay--show .overlay__content {
  background-color: rgba(48, 57, 86, 0.95);
}

.indicator-line {
  display: block;
  width: 18px;
  height: 4px;
  background-color: #E9EDF2;
}

.map {
  width: 100%;
  min-height: 300px;
  position: relative;
  overflow: hidden;
}

.map-pin {
  width: 64px;
  height: 50px;
  position: absolute;
  top: -25px;
  left: -32px;
  background-size: 32px 32px;
  background-repeat: no-repeat;
  text-align: center;
  background-position: top center;
  color: #888;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}

.map-pin span {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  left: 0;
}

.blue {
  background-image: url("../images/marker/blue.png");
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  box-sizing: content-box;
  background: #4AA2EE;
  padding: 0.25rem;
}

.jqvmap-label {
  z-index: 1;
  border: 1px solid #E9EDF2;
  border-radius: 0.25rem;
  background: white;
  padding: 0.25rem 0.5rem;
  color: #272C33;
}

.dashboard-area-tabs__tab {
  display: flex;
  flex-direction: column;
}
.dashboard-area-tabs__tab:hover {
  text-decoration: none;
}
.dashboard-area-tabs__tab, .dashboard-area-tabs__tab * {
  color: #272C33;
}
.dashboard-area-tabs__tab:not(.active) {
  background-color: #F5F7FA;
}
.dashboard-area-tabs__tab:not(.active), .dashboard-area-tabs__tab:not(.active) * {
  color: rgba(39, 44, 51, 0.5);
}
.dashboard-area-tabs__tab.active {
  position: relative;
}
.dashboard-area-tabs__tab.active::before {
  content: " ";
  height: 4px;
  background-color: #4AA2EE;
  left: 0;
  top: 0;
  right: 0;
  position: absolute;
}

.dashboard-location-tabs__tab {
  margin-bottom: 8px;
  cursor: pointer;
}
.dashboard-location-tabs__tab:not(.active) .progress-bar {
  background: rgba(39, 44, 51, 0.2) !important;
}

.list-todo:last-child {
  margin-bottom: 0;
}
.list-todo li:not(:last-child) {
  margin-bottom: 1rem;
}
.list-todo .custom-control-input:checked ~ .custom-control-label {
  text-decoration: line-through;
}
.list-todo .custom-control {
  padding-left: 2rem;
}
.list-todo .custom-control-label::before, .list-todo .custom-control-label::after {
  left: -2rem;
}

.list-skills li {
  display: flex;
  align-items: center;
}
.list-skills li:not(:last-child) {
  margin-bottom: 1rem;
}
.list-skills li > div + div {
  padding-left: 1rem;
}
.list-skills li > div:first-child {
  width: 110px;
}
.list-skills:last-child {
  margin-bottom: 0;
}

.posts-card {
  margin-bottom: 12px;
}
.posts-card:last-child {
  margin-bottom: 0;
}
.posts-card__content {
  padding: 0.5rem;
}
.posts-card__title {
  white-space: nowrap;
}
.posts-card__title, .posts-card__tag,
.posts-card .card-title,
.posts-card .card-title > a {
  max-width: 180px;
}
.posts-card__tag {
  font-size: 0.8125rem;
}
.posts-card__tag,
.posts-card .card-title {
  display: inline-block;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 576px) {
  .posts-card__tag {
    width: 100px;
  }
  .posts-card__date {
    width: 140px;
  }
  .posts-card__title,
.posts-card .card-title,
.posts-card .card-title > a {
    max-width: 300px;
  }
}
@media (max-width: 1199.98px) {
  .posts-card__meta {
    padding-top: 0.4375rem;
    width: 100%;
    order: 3;
    align-items: flex-start;
  }
}
@media (max-width: 575.98px) {
  .posts-card--wrap .posts-card__meta {
    padding-top: 0.4375rem;
    width: 100%;
    order: 3;
    align-items: flex-start;
  }
  .posts-card--wrap .posts-card__tag, .posts-card--wrap .posts-card__date, .posts-card--wrap .posts-card__title, .posts-card--wrap .card-title, .posts-card--wrap .card-title > a {
    width: auto;
    max-width: none;
  }
}
@media (max-width: 767.98px) {
  .posts-card--wrap-sm .posts-card__meta {
    padding-top: 0.4375rem;
    width: 100%;
    order: 3;
    align-items: flex-start;
  }
  .posts-card--wrap-sm .posts-card__tag, .posts-card--wrap-sm .posts-card__date, .posts-card--wrap-sm .posts-card__title, .posts-card--wrap-sm .card-title, .posts-card--wrap-sm .card-title > a {
    width: auto;
    max-width: none;
  }
}
@media (max-width: 991.98px) {
  .posts-card--wrap-md .posts-card__meta {
    padding-top: 0.4375rem;
    width: 100%;
    order: 3;
    align-items: flex-start;
  }
  .posts-card--wrap-md .posts-card__tag, .posts-card--wrap-md .posts-card__date, .posts-card--wrap-md .posts-card__title, .posts-card--wrap-md .card-title, .posts-card--wrap-md .card-title > a {
    width: auto;
    max-width: none;
  }
}
@media (max-width: 1199.98px) {
  .posts-card--wrap-lg .posts-card__meta {
    padding-top: 0.4375rem;
    width: 100%;
    order: 3;
    align-items: flex-start;
  }
  .posts-card--wrap-lg .posts-card__tag, .posts-card--wrap-lg .posts-card__date, .posts-card--wrap-lg .posts-card__title, .posts-card--wrap-lg .card-title, .posts-card--wrap-lg .card-title > a {
    width: auto;
    max-width: none;
  }
}
@media (max-width: 1365.98px) {
  .posts-card--wrap-xl .posts-card__meta {
    padding-top: 0.4375rem;
    width: 100%;
    order: 3;
    align-items: flex-start;
  }
  .posts-card--wrap-xl .posts-card__tag, .posts-card--wrap-xl .posts-card__date, .posts-card--wrap-xl .posts-card__title, .posts-card--wrap-xl .card-title, .posts-card--wrap-xl .card-title > a {
    width: auto;
    max-width: none;
  }
}
.posts-card--wrap-xxl .posts-card__meta {
  padding-top: 0.4375rem;
  width: 100%;
  order: 3;
  align-items: flex-start;
}
.posts-card--wrap-xxl .posts-card__tag, .posts-card--wrap-xxl .posts-card__date, .posts-card--wrap-xxl .posts-card__title, .posts-card--wrap-xxl .card-title, .posts-card--wrap-xxl .card-title > a {
  width: auto;
  max-width: none;
}
.posts-cards {
  margin-bottom: 12px;
}
.posts-card-popular {
  position: relative;
  overflow: hidden;
}
.posts-card-popular__content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.posts-card-popular .card-img {
  height: 196px;
  object-fit: cover;
}
.posts-card-popular .card-title,
.posts-card-popular a {
  color: white;
}
.posts-card-popular .card-body {
  border: none;
}
.posts-card-popular__title {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}
.posts-card-popular__title .text-muted {
  color: rgba(255, 255, 255, 0.54) !important;
}

.app-messages {
  height: 100%;
  overflow: hidden;
  position: relative;
}
.app-messages .mdk-drawer-layout,
.app-messages .mdk-header-layout,
.app-messages .mdk-header-layout__content,
.app-messages .mdk-drawer-layout__content {
  height: 100%;
}
.app-messages .page-content {
  height: 100%;
  position: relative;
  padding: 0;
}
.app-messages__container {
  position: relative;
  overflow: hidden;
  z-index: 0;
}
.app-messages__fullbleed {
  flex: 1 1 0%;
  position: relative;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.message__aside {
  margin-right: 1rem;
}
.message:nth-child(even) .message__aside {
  order: 1;
  margin-right: 0;
  margin-left: 1rem;
}
.message:nth-child(even) .message__body {
  margin-left: auto;
}

.messages-toggle {
  position: absolute;
  right: -1px;
  top: 1rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.layout-login-image {
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.layout-login-image__overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(48, 56, 64, 0.5);
  z-index: -1;
  pointer-events: none;
}
.layout-login-image__form {
  padding: 2rem;
  position: relative;
}
@media (min-width: 768px) {
  .layout-login-image {
    display: flex;
    flex-shrink: 0;
  }
  .layout-login-image__form {
    width: calc(320px + 6rem);
    padding: 3rem;
  }
}

.layout-login-centered-boxed {
  height: initial;
}
.layout-login-centered-boxed__form {
  padding: 2rem;
  position: relative;
  max-width: 26rem;
  margin: 2rem 0;
}
@media (min-width: 768px) {
  .layout-login-centered-boxed {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .layout-login-centered-boxed__form {
    min-width: calc(320px + 6rem);
    padding: 3rem;
  }
}

.ui .page__container {
  max-width: 944px;
}