// @material/elevation
@use '@material/elevation/mixins' as mdc-elevation-mixins;
@use '@material/elevation/variables' as mdc-elevation-variables;

////////////////
// LIST GROUP //
////////////////

.list-group {
  @if $enable-card-shadow {
    @include mdc-elevation-mixins.elevation(3, $card-shadow-base-color, $shadow-opacity-boost);
  }

  @include border-radius($list-group-border-radius);
  margin-bottom: $spacer;
}
.list-group-flush {
  #{mdc-elevation-variables.$property}: none;
  border-radius: 0;
  margin-bottom: 0;
  > .list-group-item:not(.active) {
    background: none;
  }
	> .list-group-item:first-child {
		border-top: 0;
	}
	> .list-group-item:last-child {
		border-bottom: 0;
	}
}
.list-group-item.active a {
	color: inherit;
}