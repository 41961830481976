/////////////
// GENERAL //
/////////////

.mdk-box__bg-front {
  background-position: center;
  &::after {
    display: block;
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
  }
}

.mdk-box--bg-gradient-primary .mdk-box__bg-front::after {
  background-image: $primary-gradient;
  opacity: .9;
}

@each $color, $value in $theme-colors {
  .mdk-box--bg-#{$color} .mdk-box__bg-front::after {
    background-color: rgba($value, .9);
  }
}

@each $value in (25, 35, 45, 90, 95) {
  .mdk-box--bg-white-#{$value} .mdk-box__bg-front::after {
    background-color: rgba(white, $value/100);
  }
}