.posts {
  &-card {
    margin-bottom: $grid-gutter-width/2;

    &:last-child {
      margin-bottom: 0;
    }
    &__content {
      padding: $spacer/2;
    }
    &__title {
      white-space: nowrap;
    }
    &__title,
    &__tag,
    .card-title,
    .card-title > a {
      max-width: 180px; 
    }

    &__tag {
      font-size: $small-font-size;
    }
    
    &__tag,
    .card-title {
      display: inline-block; 
      overflow-x: hidden; 
      text-overflow: ellipsis;
    }

    @mixin wrap-meta-properties {
      padding-top: .4375rem;
      width: 100%;
      order: 3;
      align-items: flex-start;
    }

    @include media-breakpoint-up(sm) {
      &__tag {
        width: 100px;
      }
      &__date {
        width: 140px;
      }
      &__title,
      .card-title,
      .card-title > a {
        max-width: 300px;
      }
    }

    @include media-breakpoint-down(lg) {
      &__meta {
        @include wrap-meta-properties();
      }
    }

    @each $breakpoint in map-keys($grid-breakpoints) {
      @include media-breakpoint-down($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        &--wrap#{$infix} &__meta {
          @include wrap-meta-properties();
        }
        &--wrap#{$infix} &__tag,
        &--wrap#{$infix} &__date,
        &--wrap#{$infix} &__title,
        &--wrap#{$infix} .card-title,
        &--wrap#{$infix} .card-title > a {
          width: auto;
          max-width: none;
        }
      }
    }
  }
  &-cards {
    margin-bottom: $card-group-margin;
  }

  &-card-popular {
    position: relative;
    overflow: hidden;
    &__content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .card-img {
      height: 196px;
      object-fit: cover;
    }
    .card-title,
    a {
      color: white;
    }
    .card-body {
      border: none;
    }
    &__title {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      .text-muted {
        color: rgba(255,255,255, .54) !important;
      }
    }
  }
}