///////////////////////////////////////////
// White links against a dark background //
///////////////////////////////////////////

.navbar-dark-white {
  .navbar-brand {
    color: $navbar-dark-active-color;

    @include hover-focus {
      color: $navbar-dark-active-color;
    }
  }

  .navbar-nav {
    .nav-link {
      color: rgba(white, .7);

      @include hover-focus {
        color: $navbar-dark-hover-color;
      }

      &.disabled {
        color: $navbar-dark-disabled-color;
      }
    }

    .show > .nav-link,
    .active > .nav-link,
    .nav-link.show,
    .nav-link.active {
      color: $navbar-dark-active-color;
    }
  }

  .navbar-toggler {
    color: rgba(white, .7);
    border-color: $navbar-dark-toggler-border-color;
  }

  .navbar-toggler-icon {
    background-image: $navbar-dark-toggler-icon-bg;
  }

  .navbar-text {
    color: rgba(white, .7);
    a {
      color: $navbar-dark-active-color;

      @include hover-focus {
        color: $navbar-dark-active-color;
      }
    }
  }
}