.card-feedback {
  &::before, 
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    left: 24px;
    z-index: -1;
    bottom: -20px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent;
    border-top-color: $card-border-color;
  }
  &::after {
    border-top-color: #fff;
    bottom: -19px;
    z-index: 2;
  }
}